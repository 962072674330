import {
  ContentVolkswagenV1Node,
  ContentVolkswagenV1Widget,
  getNodeAttribute,
  SnippetName,
} from '@cms/volkswagen-widgets';
import { SnippetNameExtend, WidgetName } from '@console/api';

export const NodeKeys: Record<keyof ContentVolkswagenV1Node, keyof ContentVolkswagenV1Node> = {
  accordion: 'accordion',
  accordionItem: 'accordionItem',
  address: 'address',
  article: 'article',
  background: 'background',
  blueprint: 'blueprint',
  box: 'box',
  breadcrumbs: 'breadcrumbs',
  carStock: 'carStock',
  carStockFilter: 'carStockFilter',
  carousel: 'carousel',
  carouselItem: 'carouselItem',
  comparison: 'comparison',
  comparisonItem: 'comparisonItem',
  comparisonProperty: 'comparisonProperty',
  divider: 'divider',
  emphasis: 'emphasis',
  featureCluster: 'featureCluster',
  featureClusterItem: 'featureClusterItem',
  flex: 'flex',
  font: 'font',
  footer: 'footer',
  form: 'form',
  gallery: 'gallery',
  galleryItem: 'galleryItem',
  gradient: 'gradient',
  grid: 'grid',
  header: 'header',
  heading: 'heading',
  iframe: 'iframe',
  image: 'image',
  link: 'link',
  list: 'list',
  listItem: 'listItem',
  modal: 'modal',
  navigation: 'navigation',
  pagination: 'pagination',
  paginationItem: 'paginationItem',
  paragraph: 'paragraph',
  placeholder: 'placeholder',
  quoting: 'quoting',
  richtext: 'richtext',
  script: 'script',
  section: 'section',
  sideMenu: 'sideMenu',
  sideMenuLink: 'sideMenuLink',
  stack: 'stack',
  strike: 'strike',
  strong: 'strong',
  subscript: 'subscript',
  superscript: 'superscript',
  table: 'table',
  tableData: 'tableData',
  tableHead: 'tableHead',
  tableRow: 'tableRow',
  tabs: 'tabs',
  tabsItem: 'tabsItem',
  tabsLink: 'tabsLink',
  teaser: 'teaser',
  teaserFilter: 'teaserFilter',
  text: 'text',
  theme: 'theme',
  topMenu: 'topMenu',
  topMenuItem: 'topMenuItem',
  topMenuLink: 'topMenuLink',
  underline: 'underline',
  video: 'video',
};

export type NodeType = keyof ContentVolkswagenV1Node;

export const getNodeId = (node: ContentVolkswagenV1Node): string | null => {
  const currentNode = Object.values(NodeKeys).find((key) => node[key]);

  if (!currentNode) return null;

  return node[currentNode]?.id ?? null;
};

export function getNodeWidgetType(node: ContentVolkswagenV1Widget): WidgetName | null {
  // exception
  if (node.placeholder) {
    return SnippetNameExtend.Placeholder;
  }

  if (!node.blueprint?.attributes) {
    return null;
  }

  const name = getNodeAttribute(node.blueprint.attributes, 'name');

  return (name as SnippetName) || null;
}
