import { Button, Form, FormInstance, Input, Modal, Alert } from 'antd';
import React from 'react';
import { ValidationConst } from 'src/utils/validation';

export type EditFolderFormValues = {
  title: string;
  name: string;
};

export type EditFolderModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<EditFolderFormValues>;
  isCreate: boolean;
  isEmptyContent?: boolean;
  onClose: () => void;
  onSubmit: (values: EditFolderFormValues) => void;
};

export const EditFolderModal: React.FC<EditFolderModalProps> = ({
  open,
  loading,
  form,
  isCreate,
  onClose,
  onSubmit,
  isEmptyContent,
}) => {
  return (
    <Modal
      title={`${isCreate ? 'Создание' : 'Редактирование'} папки`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
          <Input placeholder="Введите заголовок" />
        </Form.Item>

        {!isCreate && !isEmptyContent && <Alert description="Нельзя изменить имя папки c содержимым" type="warning" />}
        <Form.Item label="Имя" name="name" rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}>
          <Input placeholder="Введите имя" disabled={!isCreate && !isEmptyContent} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
