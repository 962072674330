export const getSitesUrl = (): string => '/sites';

export const getFilesUrl = (): string => '/pages';

export const getPageDetailUrl = (path: string): string => `/pages/${path}`;

export const getPagePreviewUrl = (path: string): string => `/preview/${path}`;

export const getMediaUrl = (): string => '/media';

export const getUsersUrl = (): string => '/users';

export const isPreviewPage = (path: string): boolean => path.startsWith('/preview/');
