import { DeleteOutlined, EditOutlined, UndoOutlined, CopyOutlined } from '@ant-design/icons';
import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { Button, Row, Space } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';

const StyledRow = styled(Row)`
  height: 100%;
`;

type FilesControlBlockProps = {
  selectedRows: ContentFilesV1File[];
  resetSelectedRows: () => void;
  onEditClick: () => void;
  onCopyClick: () => void;
  onMoveClick: () => void;
  onDeleteClick: () => void;
};

export const FilesControlBlock: React.FC<FilesControlBlockProps> = ({
  selectedRows,
  resetSelectedRows,
  onEditClick,
  onMoveClick,
  onDeleteClick,
  onCopyClick,
}) => {
  const oneRowIsSelected = selectedRows.length === 1;
  const pageOrFolderSelected = selectedRows[0].filesV1Folder || selectedRows[0].volkswagenV1Page;

  return (
    <StyledRow justify="space-between" align="middle">
      <Space>
        <Button icon={<CopyOutlined />} disabled={!(oneRowIsSelected && pageOrFolderSelected)} onClick={onCopyClick}>
          Копировать
        </Button>
        <Button icon={<CopyOutlined />} disabled={!(oneRowIsSelected && pageOrFolderSelected)} onClick={onMoveClick}>
          Переместить
        </Button>
        <Button icon={<EditOutlined />} disabled={!(oneRowIsSelected && pageOrFolderSelected)} onClick={onEditClick}>
          Редактировать
        </Button>
        <Button icon={<DeleteOutlined />} disabled={!oneRowIsSelected} onClick={onDeleteClick}>
          Удалить
        </Button>
      </Space>
      <Button type="link" icon={<UndoOutlined />} onClick={resetSelectedRows}>
        Сбросить выбранные
      </Button>
    </StyledRow>
  );
};
