import { ContentVolkswagenV1Richtext, ContentVolkswagenV1TextNode } from '@cms/volkswagen-widgets';
import { Alert } from 'antd';
import React from 'react';
import {
  ConfigType,
  ControlsConfig,
  DataType,
  EntityControlsConfig,
  PresetsStylesConfig,
  RichTextEditor,
} from '@console/uikit';

const { ErrorBoundary } = Alert;

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type RichTextContentEditorProps = {
  value?: ContentVolkswagenV1TextNode[];
  onChange?: (value: ContentVolkswagenV1TextNode[]) => void;
  presetsStyles?: PresetsStylesConfig;
  controlsConfig?: ControlsConfig;
  availableIcons?: EntityControlsConfig['icon'];
  availableIconColors?: EntityControlsConfig['iconColor'];
  disabled: boolean;
};

// Компонент необходим для редактирования полей,
// у которых значение это Array<ContentVolkswagenV1TextNode>,
// вместо richtext?: ContentVolkswagenV1Richtext;
// Пример: header?: Array<ContentVolkswagenV1TextNode>; в ContentVolkswagenV1AccordionItem
export const RichtextTextNodesEditor: React.FC<RichTextContentEditorProps> = ({
  value,
  onChange,
  disabled,
  availableIcons,
  availableIconColors,
  controlsConfig,
  presetsStyles,
}) => {
  if (!value) {
    console.warn(`Invalid structure RichtextContentEditor, not found content`);

    return null;
  }

  const handleChange = (value: ContentVolkswagenV1Richtext['content']) => {
    onChange && value && onChange(value);
  };

  return (
    <ErrorBoundary message="Ошибка при рендере текстового редактора" description="">
      <RichTextEditor
        singleLine
        initialValue={value}
        presetsStyles={presetsStyles}
        controlsConfig={{
          [ConfigType.Blockquote]: [],
          [ConfigType.Heading]: [],
          [ConfigType.List]: [],
          ...controlsConfig,
        }}
        entityControlsConfig={{
          [DataType.Disclaimer]: false,
          [DataType.Link]: false,
          [DataType.Placeholder]: false,
          [DataType.Icon]: Array.isArray(availableIcons) ? availableIcons : false,
          [DataType.IconColor]: Array.isArray(availableIconColors) ? availableIconColors : false,
        }}
        onChange={handleChange}
        disabled={disabled}
      />
    </ErrorBoundary>
  );
};
