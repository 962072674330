import { UsersV1ListUsersResponse, UsersV1UsersAPIApi } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseListContentData = {
  usersResponse: UsersV1ListUsersResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type ListUsersParams = {
  name?: string;
  enabled: boolean;
  search?: string;
  size?: number;
};

export function useListUsers(
  usersApi: UsersV1UsersAPIApi,
  queryKey: QueryKey,
  params: ListUsersParams,
): UseListContentData {
  const { name, enabled, search, size } = params;

  const {
    data: usersResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      usersApi.usersV1UsersAPIListUsers(
        undefined,
        undefined,
        name ? [name] : undefined,
        undefined,
        size,
        undefined,
        search,
        undefined,
        undefined,
      ),
    { enabled },
  );

  return {
    usersResponse: usersResponse?.data,
    isListLoading,
    isListFetching,
  };
}
