import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Table, TableProps } from 'antd';
import React from 'react';
import { SitesActionsTableCell, SitesNameTableCell, SitesTitleTableCell, SitesPathTableCell } from './components';

export type SitesTableProps = {
  sites: ContentSitesV1Site[];
  selectedSites: ContentSitesV1Site[];
  onSelectSitesChange: (sites: ContentSitesV1Site[]) => void;
  setCurrentSite: (site: ContentSitesV1Site) => void;
  isLoading: boolean;
};

export const SitesTable: React.FC<SitesTableProps> = ({
  sites,
  selectedSites,
  isLoading,
  setCurrentSite,
  onSelectSitesChange,
}) => {
  const columns: TableProps<ContentSitesV1Site>['columns'] = [
    {
      title: 'Имя',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => <SitesNameTableCell site={record} />,
    },
    {
      title: 'Заголовок',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => <SitesTitleTableCell site={record} />,
    },
    {
      title: 'Корневая папка',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => <SitesPathTableCell site={record} />,
    },
    {
      title: '',
      render: (_, record) => <SitesActionsTableCell site={record} setCurrentSite={setCurrentSite} />,
    },
  ];

  const rowSelection = React.useMemo(
    () => ({
      selectedRowKeys: selectedSites.map((site) => site.name),
      onChange: (_: React.Key[], selectedRows: ContentSitesV1Site[]) => onSelectSitesChange(selectedRows),
    }),
    [selectedSites, onSelectSitesChange],
  );

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={sites}
      pagination={false}
      rowKey="name"
      rowSelection={rowSelection}
      sticky
    />
  );
};
