import { PlusOutlined } from '@ant-design/icons';
import { SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Button } from 'antd';
import React from 'react';
import { SnippetNameExtend } from '@console/api';
import { styled } from '@console/uikit';
import { AddWidgetDrawer, WidgetEditorDrawer } from '../../../../components';
import { useContentView } from '../../contexts';
import { MainViewContainer } from '../main-view';
import { SiblingComponentPosition, useAddWidgetState, useUpdateWidgetState } from './hooks';

const StyledWrapper = styled.div`
  flex: 1;
  background: ${(props) => props.theme.colors.background1};
`;

const AddPlaceholderWidgetButton = styled(Button).attrs(() => ({
  type: 'link',
  icon: <PlusOutlined />,
  block: true,
}))<{ $visible: boolean }>`
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.25s ease;
  z-index: 1;
  height: 64px;

  &:hover {
    color: ${(props) => props.theme.colors.blue7};
  }
`;

export const ContentViewContainer: React.FC = () => {
  const { rootType, isRootHovered, rootContent } = useContentView();
  const addWidgetState = useAddWidgetState();
  const updateWidgetState = useUpdateWidgetState();

  const addBlockOnTop = () => addWidgetState.open(0, SiblingComponentPosition.Above);

  // open add widget drawer on initial load page
  // if no snippets have been added
  React.useEffect(() => {
    if (rootContent.length === 0 && rootType === 'page') {
      addBlockOnTop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* top button to add widget in placeholder area */}
      {rootType === 'placeholder' && (
        <AddPlaceholderWidgetButton $visible={isRootHovered} onClick={addBlockOnTop}>
          Добавить виджет в слот
        </AddPlaceholderWidgetButton>
      )}

      {/* widgets */}
      <StyledWrapper>
        {rootContent.length === 0 && rootType === 'page' && (
          <div style={{ padding: '8px 16px' }}>
            <Alert
              message="Нет добавленных блоков"
              description='Нажмите на кнопку "Добавить блок" для добавления первого блока.'
              type="info"
              showIcon
              action={<Button onClick={addBlockOnTop}>Добавить блок</Button>}
            />
          </div>
        )}
        {rootContent.length > 0 && (
          <MainViewContainer
            openUpdateWidgetDrawer={updateWidgetState.open}
            openAddWidgetDrawer={addWidgetState.open}
          />
        )}
      </StyledWrapper>

      {/* bottom button to add widget in placeholder area */}
      {rootType === 'placeholder' && rootContent.length > 0 && (
        <AddPlaceholderWidgetButton
          $visible={isRootHovered}
          onClick={() => addWidgetState.open(rootContent.length - 1, SiblingComponentPosition.Below)}
        >
          Добавить виджет в слот
        </AddPlaceholderWidgetButton>
      )}

      {/* drawer to update widget */}
      <WidgetEditorDrawer
        node={updateWidgetState.currentNode}
        loading={updateWidgetState.loading}
        title="Редактирование блока"
        onClose={updateWidgetState.close}
        onSave={updateWidgetState.updateWidget}
      />

      {/* drawer to add new widget  */}
      <AddWidgetDrawer
        open={addWidgetState.state.type === 'open'}
        onClose={addWidgetState.close}
        onSave={addWidgetState.createWidget}
        loading={addWidgetState.loading}
        disallowedSnippets={[
          ...(rootType === 'placeholder' ? [SnippetNameExtend.Placeholder] : []),
          SnippetName.BundlesCarFragment,
          SnippetName.Card,
          SnippetName.CarFragment,
          SnippetName.ContactInfo,
          SnippetName.ContactsTab,
          SnippetName.EnginesTab,
          SnippetName.FeaturesIconsRow,
          SnippetName.FeedbackItem,
          SnippetName.Image,
          SnippetName.Video,
          SnippetName.ModelsCarouselCarFragment,
          SnippetName.ModelsCarouselTab,
          SnippetName.ModelsTab,
          SnippetName.Quote,
          SnippetName.Richtext,
          SnippetName.TeamItem,
          SnippetName.TopMenuPromoArea,
          SnippetName.VacanciesAccordions,
          SnippetName.VacanciesGrid,
          SnippetName.TeamGrid,
          SnippetName.FooterBottomNav,
          SnippetName.FooterSocials,
          SnippetName.FooterColumn,
          SnippetName.FooterContacts,
        ]}
      />
    </>
  );
};
