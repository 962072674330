import React from 'react';
import { useHistory } from 'react-router-dom';
import { getPageDetailUrl } from 'src/utils/urls';
import { useFilesContext } from '../../contexts';
import { FilesControlBlock } from './components/files-control-block';
import { useDeleteFile } from './hooks/use-delete-file';

export const FilesControlContainer: React.FC = () => {
  const { selectedRows, resetSelectedRows, setEditFolder, setMoveFolder, setMovePage, setCopyPage, setCopyFolder } =
    useFilesContext();
  const firstSelectedRow = selectedRows[0];
  const history = useHistory();

  const { showDeleteConfirm } = useDeleteFile(selectedRows[0]);

  const handleEditClick = () => {
    if (firstSelectedRow.filesV1Folder) {
      setEditFolder(firstSelectedRow.filesV1Folder);
    }

    if (firstSelectedRow.volkswagenV1Page?.path) {
      history.push(getPageDetailUrl((firstSelectedRow.volkswagenV1Page?.path || '').substring(1)));
    }
  };

  const handleMoveClick = () => {
    if (firstSelectedRow.filesV1Folder) {
      setMoveFolder(firstSelectedRow.filesV1Folder);
    }

    if (firstSelectedRow.volkswagenV1Page?.path) {
      setMovePage(firstSelectedRow.volkswagenV1Page);
    }
  };

  const handleCopyClick = () => {
    if (firstSelectedRow.filesV1Folder) {
      setCopyFolder(firstSelectedRow.filesV1Folder);
    }

    if (firstSelectedRow.volkswagenV1Page?.path) {
      setCopyPage(firstSelectedRow.volkswagenV1Page);
    }
  };

  return (
    <>
      <FilesControlBlock
        selectedRows={selectedRows}
        resetSelectedRows={resetSelectedRows}
        onEditClick={handleEditClick}
        onDeleteClick={showDeleteConfirm}
        onCopyClick={handleCopyClick}
        onMoveClick={handleMoveClick}
      />
    </>
  );
};
