import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { SitesService } from '@console/api';
import React from 'react';

export enum SiteTabs {
  Structure = 'Structure',
  Publication = 'Publication',
  Main = 'Main',
  Redirects = 'Redirects',
  Robots = 'Robots',
  Sitemap = 'Sitemap',
}

type ISitesContext = {
  tab: SiteTabs;
  setTab: (tab: SiteTabs) => void;
  currentSite?: ContentSitesV1Site;
  setCurrentSite: (site?: ContentSitesV1Site) => void;
  sites: ContentSitesV1Site[];
  service: SitesService;
};

const SitesContext = React.createContext<ISitesContext>(null as unknown as ISitesContext);

export const SitesContextProvider = SitesContext.Provider;

export function useSitesContext(): ISitesContext {
  const context = React.useContext(SitesContext);
  if (context === null) {
    throw new Error('useSitesContext must be used within an SitesContextProvider');
  }
  return context;
}
