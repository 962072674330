import { ContentVolkswagenV1Page, ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { FileService } from '@console/api';

type IContentViewContext = {
  rootType: 'page' | 'placeholder';
  rootContent: ContentVolkswagenV1Widget[];
  isRootHovered: boolean;
  isBlueprintPage: boolean;
  page: ContentVolkswagenV1Page;
  service: FileService;
  addNode: (index: number, node: ContentVolkswagenV1Widget) => Promise<unknown>;
  updateNode: (node: ContentVolkswagenV1Widget, index: number, prefixPath?: string) => Promise<unknown>;
  moveNode: (fromIndex: number, toIndex: number) => Promise<unknown>;
  removeNode: (index: number) => Promise<unknown>;
  loading: boolean;
};

export const ContentViewContext = React.createContext<IContentViewContext>(null as unknown as IContentViewContext);

export const ContentViewContextProvider = ContentViewContext.Provider;

export function useContentView(): IContentViewContext {
  const context = React.useContext(ContentViewContext);

  if (context === null) {
    throw new Error('useContentView must be used within an ContentViewContextProvider');
  }

  return context;
}
