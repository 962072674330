import { UsersV1UserProps, GoogleRpcStatus, UsersV1User, UsersV1UsersAPIApi } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<UsersV1User>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = UsersV1UserProps;

export type UseCreateUserData = {
  createUser: UseMutateFunction<Data, Error, Variables>;
  createUserAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateUser(
  usersApi: UsersV1UsersAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseCreateUserData {
  const queryClient = useQueryClient();

  const {
    mutate: createUser,
    mutateAsync: createUserAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>((body) => usersApi.usersV1UsersAPICreateUser(body), {
    onSuccess: () => {
      if (isInvalidate) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (error) => {
      notification.error({
        message: 'Ошибка создания пользователя',
        description: error?.response?.data.message,
      });
    },
  });

  return { createUser, createUserAsync, isCreateLoading };
}
