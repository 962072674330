import { AttributesV1Attribute } from '@cms/volkswagen-widgets';
import { Radio, RadioGroupProps } from 'antd';
import React from 'react';

type PossibleValue = {
  value: string;
  label: string;
};

const DEFAULT_VALUE = 'DEFAULT_VALUE';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type AttributesRadioGroupProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  attributeName: string;
  possibleValues: PossibleValue[];
  defaultValue?: string;
  disabled: boolean;
};

export const AttributesRadioGroup: React.FC<AttributesRadioGroupProps> & {
  defaultValue: string;
} = ({ value, onChange, attributeName, possibleValues, defaultValue = DEFAULT_VALUE, disabled }) => {
  if (!value) {
    throw new Error(`Invalid structure AttributesRadioGroup, not found attributes`);
  }

  const currentValue = value.find(({ name }) => name === attributeName)?.values?.[0] ?? defaultValue;

  const handleChange: RadioGroupProps['onChange'] = (event) => {
    const newValue = value.filter(({ name }) => name !== attributeName);

    onChange &&
      onChange([
        ...newValue,
        ...(event.target.value === defaultValue ? [] : [{ name: attributeName, values: [event.target.value] }]),
      ]);
  };

  return (
    <Radio.Group value={currentValue} onChange={handleChange} disabled={disabled}>
      {possibleValues.map((radio) => (
        <Radio key={radio.value} value={radio.value}>
          {radio.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

AttributesRadioGroup.defaultValue = DEFAULT_VALUE;
