import {
  AccordionNode,
  BoxNode,
  ContentVolkswagenV1Node,
  IframeNode,
  snippetGenerators,
  SnippetName,
} from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Alert, Checkbox, Divider, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton, AddSnippetButton, AddSnippetButtonProps, SnippetItemBlock } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { ContactsTabAccordionItem, ContactsTabCTALink } from './components';

const mapAccordionAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  accordionItem: AccordionNode.Item.generators.entity({
    header: [],
    side: [
      BoxNode.generators.node({
        styles: [{ width: '100%', height: '300px' }],
        content: IframeNode.generators.node({
          source: '',
        }),
      }),
    ],
    content: [node],
  }),
});

const flexContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const ctaAllowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.Richtext];

const contactsAllowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.ContactInfo];

const StyledContactsTabWrapper = styled.div`
  display: flex;

  > * {
    min-width: 100px;
  }
`;

export const ContactsTabEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexContentData, titleData, ctaLinksData, accordionsData, gridIconsData, aboutContentData, galleryData } =
    React.useMemo(() => {
      // starting snippet point
      const flexContentData = getPlaceholderedPath({
        node,
        commonPath: flexContentPath,
      });

      // topBlock
      // title
      const titleData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
      });
      // ctaLinks
      const ctaLinksData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 0, 'flex', 'content', 1, 'flex', 'content'],
      });

      // contacts block
      // accordions
      const accordionsData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 1, 'flex', 'content', 1, 'accordion', 'items'],
      });

      // service block
      // gridIcons
      const gridIconsData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 2],
      });

      // about block
      // aboutContent
      const aboutContentData = getPlaceholderedPath({
        node,
        commonPath: [
          ...flexContentData.path,
          3,
          'grid',
          'content',
          0,
          'flex',
          'content',
          0,
          'blueprint',
          'content',
          0,
          'richtext',
        ],
      });
      // gallery
      const galleryData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 3, 'grid', 'content', 1],
      });

      return {
        flexContentData,
        titleData,
        ctaLinksData,
        accordionsData,
        gridIconsData,
        aboutContentData,
        galleryData,
      };
    }, [node]);

  const disabled = flexContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* title edit */}
      <RichtextFormItem label="Заголовок" contentFieldName={titleData.path} disabled={disabled} />

      <Divider orientation="right">CTA блок</Divider>

      {/* ctaLinks edit */}
      <Form.List name={ctaLinksData.path}>
        {(fields, { add, remove, move }) => (
          <>
            <StyledContactsTabWrapper>
              {fields.map((field, index) => (
                <ContactsTabCTALink
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  contentPath={ctaLinksData.path}
                  allowedSnippets={ctaAllowedSnippets}
                />
              ))}
            </StyledContactsTabWrapper>
            <AddEnrichedNodeButton
              add={add}
              nodeToEnrich={snippetGenerators.Richtext({ content: [] })}
              isDisabled={disabled}
              label="Добавить иконку"
            />
          </>
        )}
      </Form.List>

      <Divider orientation="right">Блок контактов</Divider>

      {/* accordions edit */}
      <Form.List name={accordionsData.path}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <ContactsTabAccordionItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={accordionsData.path}
                allowedSnippets={contactsAllowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapAccordionAdd}
              isDisabled={disabled}
              label="Добавить аккордион-итем"
              allowedSnippets={contactsAllowedSnippets}
            />
          </>
        )}
      </Form.List>

      <Divider orientation="right">Блок сервиса</Divider>

      {/* gridIcons edit */}
      <SnippetItemBlock
        allowedSnippets={[SnippetName.GridIconsSection]}
        contentPath={gridIconsData.path}
        disabled={disabled}
      >
        <Alert message="Редактирование блока сервиса" type="info" />
      </SnippetItemBlock>

      <Divider orientation="right">Блок информации о дилерском центре</Divider>

      {/* aboutContent edit */}
      <RichtextFormItem label="О нас" contentFieldName={aboutContentData.path} disabled={disabled} />

      {/* gallery edit */}
      <SnippetItemBlock
        allowedSnippets={[SnippetName.GallerySection]}
        contentPath={galleryData.path}
        disabled={disabled}
      >
        <Alert message="Редактирование галереи" type="info" />
      </SnippetItemBlock>
    </Form>
  );
};
