import { EditFilled, EyeFilled, RightOutlined, SettingOutlined } from '@ant-design/icons';
import { ContentFilesV1File, ContentFilesV1Folder } from '@cms/volkswagen-widgets';
import { Button, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@console/uikit';
import { getLastPathParam } from 'src/utils/string';
import { getPageDetailUrl } from 'src/utils/urls';

const StyledEditFilled = styled(EditFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledRightOutlined = styled(RightOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledSettingOutlined = styled(SettingOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export type FilesTableActionsProps = {
  file: ContentFilesV1File;
  addBreadcrumb: (breadcrumb: string) => void;
  onEditFolder?: (value: ContentFilesV1Folder) => void;
  onPageSettingsClick: (file: ContentFilesV1File) => void;
  onSnippetViewClick: (file: ContentFilesV1File) => void;
  // onEditBlueprint: (value: SpacesBlueprint.Blueprint) => void;
};

export const FilesTableActions: React.FC<FilesTableActionsProps> = ({
  file,
  addBreadcrumb,
  onEditFolder,
  onPageSettingsClick,
  onSnippetViewClick,
}) => {
  const history = useHistory();

  return (
    <StyledActions>
      {file.volkswagenV1Page && (
        <>
          <Button
            type="text"
            shape="circle"
            icon={<StyledEditFilled />}
            onClick={() => history.push(getPageDetailUrl((file.volkswagenV1Page?.path || '').substring(1)))}
          />
          <Button
            type="text"
            shape="circle"
            icon={<StyledSettingOutlined />}
            onClick={() => onPageSettingsClick(file)}
          />
        </>
      )}
      {file.volkswagenV1PageBlueprint && (
        <>
          <Button
            type="text"
            shape="circle"
            icon={<StyledEditFilled />}
            onClick={() => history.push(getPageDetailUrl((file.volkswagenV1PageBlueprint?.path || '').substring(1)))}
          />
          <Button
            type="text"
            shape="circle"
            icon={<StyledSettingOutlined />}
            onClick={() => onPageSettingsClick(file)}
          />
        </>
      )}
      {file.filesV1Folder && (
        <>
          {onEditFolder && (
            <Button
              type="text"
              shape="circle"
              icon={<StyledEditFilled />}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onEditFolder(file.filesV1Folder!)}
            />
          )}
          <Button
            type="text"
            shape="circle"
            onClick={() => addBreadcrumb(getLastPathParam(file.filesV1Folder?.path || ''))}
            icon={<StyledRightOutlined />}
          />
        </>
      )}
      {file.filesV1FolderBlueprint && (
        <>
          {/*{onEditFolder && (*/}
          {/*  <Button*/}
          {/*    type="text"*/}
          {/*    shape="circle"*/}
          {/*    icon={<StyledEditFilled />}*/}
          {/*    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
          {/*    onClick={() => onEditFolder(file.filesV1FolderBlueprint!)}*/}
          {/*  />*/}
          {/*)}*/}
          {addBreadcrumb && (
            <Button
              type="text"
              shape="circle"
              onClick={() => addBreadcrumb(getLastPathParam(file.filesV1FolderBlueprint?.path || ''))}
              icon={<StyledRightOutlined />}
            />
          )}
        </>
      )}
      {file.volkswagenV1Snippet && (
        <>
          <Button type="text" shape="circle" icon={<EyeFilled />} onClick={() => onSnippetViewClick(file)} />
        </>
      )}
      {/* {file.blueprint?.folder && (
                <>
                    <Button
                        type="text"
                        shape="circle"
                        icon={<StyledEditFilled />}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        onClick={() => onEditBlueprint(file.blueprint!)}
                    />
                    <Button
                        type="text"
                        shape="circle"
                        onClick={() =>
                            addBreadcrumb(
                                getLastPathParam(
                                    file.blueprint?.folder?.path || '',
                                ),
                            )
                        }
                        icon={<StyledRightOutlined />}
                    />
                </>
            )} */}
      {/* {file.site && (
                <Button
                    type="text"
                    shape="circle"
                    onClick={() =>
                        addBreadcrumb(
                            getLastPathParam(
                                file.site?.volkswagenV1?.path || '',
                            ),
                        )
                    }
                    icon={<StyledRightOutlined />}
                />
            )} */}
    </StyledActions>
  );
};
