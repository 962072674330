import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { SnippetItemBlock } from '../../../custom-inputs';
import { TableCellInput } from './table-cell-input';

export type TableCellProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
};

export const TableCell: React.FC<TableCellProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item noStyle style={{ margin: 0 }} name={[field.name]} fieldKey={[field.fieldKey]}>
        <TableCellInput index={index} field={field} disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};
