import { ContentSitesV1Redirect, ContentSitesV1RedirectsAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { ContentSitesV1RedirectProps } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Redirect>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { name: string; body: ContentSitesV1RedirectProps };

export type UseUpdateRedirectData = {
  updateRedirect: UseMutateFunction<Data, Error, Variables>;
  updateRedirectAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isUpdateLoading: boolean;
};

export function useUpdateRedirect(
  redirectsApi: ContentSitesV1RedirectsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseUpdateRedirectData {
  const queryClient = useQueryClient();

  const {
    mutate: updateRedirect,
    mutateAsync: updateRedirectAsync,
    isLoading: isUpdateLoading,
  } = useMutation<Data, Error, Variables>(
    ({ name, body }) =>
      redirectsApi.contentSitesV1RedirectsAPIUpdateRedirect(
        site, // siteName: string,
        name, // redirectName: string,
        body, // body: ContentSitesV1RedirectProps,
        // siteRevision?: string,
        // redirectRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка обновления редиректа',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { updateRedirect, updateRedirectAsync, isUpdateLoading };
}
