import { ModalsContextProvider } from '@cms/volkswagen-widgets';
import { Modal, Typography } from 'antd';
import React from 'react';
import { getNodeId } from 'src/utils/nodes';
import { BlockView } from '../../components/block/block-view';
import { useContentView } from '../../contexts';
import { SiblingComponentPosition } from '../content-view/hooks';

const { confirm } = Modal;
const { Text } = Typography;

type MainViewContainerProps = {
  openUpdateWidgetDrawer: (componentIndex: number) => void;
  openAddWidgetDrawer: (siblingComponentIndex: number, siblingComponentPosition: SiblingComponentPosition) => void;
};

export const MainViewContainer: React.FC<MainViewContainerProps> = ({
  openUpdateWidgetDrawer,
  openAddWidgetDrawer,
}) => {
  const { moveNode, removeNode, rootContent, rootType, isBlueprintPage } = useContentView();

  // preview modals
  const [openModals, setOpenModals] = React.useState<string[]>([]);
  const closeModal = (name: string) => setOpenModals(openModals.filter((n) => n !== name));
  const openModal = (name: string) => setOpenModals([...openModals, name]);

  const handleRemoveBlock = (index: number) => {
    const node = rootContent[index];

    if (node) {
      confirm({
        title: 'Удаление блока',
        content: (
          <>
            <Text>
              Вы собираетесь удалить
              {node.placeholder ? ' слот' : ` виджет «${node.blueprint?.source}»`}.
            </Text>
            <br />
            <Text>Отменить это действие будет невозможно!</Text>
            <br />
            <br />
            <Text>Удалить блок?</Text>
          </>
        ),
        onOk() {
          removeNode(index);
        },
        okText: 'Удалить',
        okType: 'danger',
        cancelText: 'Отменить',
      });
    }
  };

  return (
    // mock modals context
    <ModalsContextProvider value={{ openModals, closeModal }}>
      {rootContent.map((node, index) => (
        <BlockView
          key={getNodeId(node) || index}
          node={node}
          rootContent={rootContent}
          index={index}
          isEditable={!isBlueprintPage || rootType === 'placeholder'}
          horizontalIndent={rootType === 'page'}
          onEditBlock={openUpdateWidgetDrawer}
          onMoveBlock={moveNode}
          onAddBlock={openAddWidgetDrawer}
          onRemoveBlock={handleRemoveBlock}
          openModal={openModal}
        />
      ))}
    </ModalsContextProvider>
  );
};
