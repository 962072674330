import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { useState } from 'react';
import { useContentView } from 'src/pages/page-detail/contexts';

type UpdateWidgetState =
  | {
      type: 'open';
      componentIndex: number;
    }
  | { type: 'default' };

export type UseUpdateWidgetState = {
  state: UpdateWidgetState;
  loading: boolean;
  currentNode: ContentVolkswagenV1Widget | null;
  updateWidget: (node: ContentVolkswagenV1Widget) => void;
  open: (componentIndex: number) => void;
  close: () => void;
};

export const useUpdateWidgetState = (): UseUpdateWidgetState => {
  const [state, setState] = useState<UpdateWidgetState>({
    type: 'default',
  });

  const { loading, updateNode, rootContent } = useContentView();

  const currentNode = state.type === 'open' ? rootContent[state.componentIndex] : null;

  const open = (componentIndex: number) => setState({ type: 'open', componentIndex });
  const close = () => setState({ type: 'default' });

  const updateWidget = (node: ContentVolkswagenV1Widget) => {
    if (state.type === 'open') {
      updateNode(node, state.componentIndex)
        .then(() => {
          close();
        })
        .catch(() => null);
    }
  };

  return {
    state,
    loading,
    currentNode,
    updateWidget,
    open,
    close,
  };
};
