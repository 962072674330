import React from 'react';
import { d6 } from '@cms/volkswagen-widgets';

const { IntegratorRoot, ThemeProvider } = d6;

export const BlockRoot: React.FC = ({ children }) => {
  return (
    <IntegratorRoot>
      <ThemeProvider theme="main">{children}</ThemeProvider>
    </IntegratorRoot>
  );
};
