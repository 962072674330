import React, { useMemo } from 'react';
import { Avatar, Dropdown, Menu, Card } from 'antd';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import { styled } from '../../theme-provider';
import { ProfileType } from '../types';
import { IDP_URL } from '../constants';

const { Meta } = Card;

const ProfileWrapper = styled.div`
  cursor: pointer;
`;

const ProfileAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.colors.blue6};
  text-transform: uppercase;
`;

const ActionLink = styled.a`
  font-size: 16px;
`;

type ProfileProps = {
  profile: ProfileType;
  logout: () => void;
};

export const Profile: React.FC<ProfileProps> = React.memo<ProfileProps>(({ profile, logout }) => {
  const intitials = `${profile?.givenName?.[0] ?? ''}${profile?.familyName?.[0] ?? ''}`;
  const content = useMemo(
    () => (
      // Default menu creates offset from top 4px, for pixel-perfect solution we reset it
      <Menu style={{ padding: 0, top: -4 }}>
        <Card
          style={{ width: 296 }}
          actions={[
            <ActionLink href={`${IDP_URL}/${profile.preferredUsername}`} key="edit">
              <EditOutlined />
            </ActionLink>,
            <ActionLink onClick={logout} key="logout">
              <ExportOutlined />
            </ActionLink>,
          ]}
        >
          <Meta
            avatar={<ProfileAvatar>{intitials || profile?.email?.[0]}</ProfileAvatar>}
            title={profile.name}
            description={profile.email}
          />
        </Card>
      </Menu>
    ),
    [profile.preferredUsername, profile.email, profile.name, logout, intitials],
  );

  return (
    <Dropdown overlay={content} trigger={['click']} placement="bottomCenter">
      <ProfileWrapper>
        <ProfileAvatar>{intitials || profile?.email?.[0]}</ProfileAvatar>
      </ProfileWrapper>
    </Dropdown>
  );
});

Profile.displayName = 'Profile';
