import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { Progress } from 'antd';
import React from 'react';
import { MediaStatus, Progresses } from '../../contexts/media-context';
import { getFileAttribute, getFilePath, getFileSize } from '../../utils/entities';

export type FilesTypeTableCellProps = {
  file: ContentFilesV1File;
  progresses: Progresses;
};

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

const progressStatuses: Record<MediaStatus, 'normal' | 'exception' | 'active' | 'success' | undefined> = {
  [MediaStatus.Selected]: undefined,
  [MediaStatus.Wait]: undefined,
  [MediaStatus.Error]: 'exception',
  [MediaStatus.Uploaded]: undefined,
  [MediaStatus.Uploading]: undefined,
};

export const FilesSizeTableCell: React.FC<FilesTypeTableCellProps> = ({ file, progresses }) => {
  const filePath = getFilePath(file);

  if (file.mediaV1Media && filePath) {
    const fileSize = getFileSize(file);
    const mediaStatus = getFileAttribute(file, 'status');
    const progress = progresses[filePath];

    if (filePath && mediaStatus && mediaStatus === `${MediaStatus.Uploading}`) {
      const percent = Math.floor(Number(progress) * 100);

      return (
        <Progress percent={percent} size="small" status={progressStatuses[mediaStatus as unknown as MediaStatus]} />
      );
    }

    return <span>{bytesToSize(fileSize ? fileSize : 0)}</span>;
  }

  return null;
};
