import { DesignTokenSizeEnum } from '@volkswagen-onehub/components-core';
import { Checkbox, CheckboxProps, Form, FormInstance } from 'antd';
import React from 'react';
import { TreePath } from 'src/utils/content-path';

export type HalfHeightCheckboxProps = {
  gridPath: TreePath;
  disabled: boolean;
};

export const HalfHeightCheckbox: React.FC<HalfHeightCheckboxProps> = ({ gridPath, disabled }) => {
  const gridPaddingPath = [...gridPath, 'styles', 0, 'rows', 0, 'height'];
  const flexPaddingPath = [...gridPath, 'content', 1, 'flex', 'styles', 0, 'padding', 'top'];

  const handleChange: (setFields: FormInstance['setFields']) => CheckboxProps['onChange'] = (setFields) => (e) => {
    setFields([
      {
        name: gridPaddingPath,
        value: e.target.checked ? '55vh' : '100vh',
      },
      {
        name: flexPaddingPath,
        value: e.target.checked ? DesignTokenSizeEnum.dynamic0200 : DesignTokenSizeEnum.dynamic0350,
      },
    ]);
  };

  return (
    <Form.Item label="Отображать 55vh" shouldUpdate>
      {({ getFieldValue, setFields }) => {
        const isGridPaddingHalf = getFieldValue(gridPaddingPath) === '55vh';

        const isFlexPaddingHalf = getFieldValue(flexPaddingPath) === DesignTokenSizeEnum.dynamic0200;

        return (
          <Checkbox
            onChange={handleChange(setFields)}
            checked={isGridPaddingHalf && isFlexPaddingHalf}
            disabled={disabled}
          />
        );
      }}
    </Form.Item>
  );
};
