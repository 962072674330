import { Button, Form, FormInstance, Input, Modal } from 'antd';
import { AutocompleteOptionItem } from '@console/uikit';
import React from 'react';
import { PagePathFormItemContainer } from 'src/containers/page-path-form-item';
import { ValidationConst } from '../../../../../utils/validation';

export type CreateSiteFormValues = {
  title: string;
  name: string;
  path: AutocompleteOptionItem[];
};

export type CreateSiteModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<CreateSiteFormValues>;
  onClose: () => void;
  onSubmit: (values: CreateSiteFormValues) => void;
};

export const CreateSiteModal: React.FC<CreateSiteModalProps> = ({ open, loading, form, onClose, onSubmit }) => {
  return (
    <Modal
      title="Создание сайта"
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button loading={loading} key="submit" type="primary" onClick={form.submit}>
          Создать
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
          <Input placeholder="Введите название" />
        </Form.Item>

        <Form.Item label="Имя" name="name" rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}>
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item name="path" label="Корневая папка" rules={[{ required: true }]} initialValue={[]}>
          <PagePathFormItemContainer />
        </Form.Item>
      </Form>
    </Modal>
  );
};
