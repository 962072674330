import { ContentSitesV1ListRobotsResponse } from '@cms/volkswagen-widgets';
import { robotsApi } from '../../../const';
import { Service } from '../../../interfaces';
import { useCreateRobot, UseCreateRobotData } from './actions/useCreateRobot';
import { GetRobotsParams, useGetRobots } from './actions/useGetRobots';
import { useRemoveRobot, UseRemoveRobotData } from './actions/useRemoveRobot';
import { useUpdateRobot, UseUpdateRobotData } from './actions/useUpdateRobot';

type Models = {
  robotsResponse: ContentSitesV1ListRobotsResponse | undefined;
};

type Operations = {
  createRobot: UseCreateRobotData['createRobot'];
  createRobotAsync: UseCreateRobotData['createRobotAsync'];
  updateRobot: UseUpdateRobotData['updateRobot'];
  updateRobotAsync: UseUpdateRobotData['updateRobotAsync'];
  removeRobot: UseRemoveRobotData['removeRobot'];
  removeRobotAsync: UseRemoveRobotData['removeRobotAsync'];
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
  isRemoveLoading: boolean;
};

type Parameters = GetRobotsParams;

export type RobotsService = Service<Models, Operations, Meta>;

export const useRobots = (params: Parameters): RobotsService => {
  const { site } = params;
  const queryKey = ['robots', site];

  const { isListFetching, isListLoading, robotsResponse } = useGetRobots(robotsApi, queryKey, params);

  const { isCreateLoading, createRobot, createRobotAsync } = useCreateRobot(robotsApi, queryKey, site);
  const { isUpdateLoading, updateRobot, updateRobotAsync } = useUpdateRobot(robotsApi, queryKey, site);
  const { isRemoveLoading, removeRobot, removeRobotAsync } = useRemoveRobot(robotsApi, queryKey, site);

  return {
    models: {
      robotsResponse,
    },
    operations: {
      createRobot,
      createRobotAsync,
      updateRobot,
      updateRobotAsync,
      removeRobot,
      removeRobotAsync,
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isUpdateLoading,
      isRemoveLoading,
    },
  };
};
