import { ContentVolkswagenV1Widget, SnippetPathByName } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../../../../utils/content-path';
import { GalleryColumnQuote } from './components/quote';
import { GalleryColumnText } from './components/text';

export type GalleryColumnContentProps = {
  field: FormListFieldData;
  remove: FormListOperation['remove'];
  disabled: boolean;
  index: number;
  contentPath: TreePath;
};

export const GalleryColumnContent: React.FC<GalleryColumnContentProps> = ({
  field,
  disabled,
  remove,
  index,
  contentPath,
}) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const node: ContentVolkswagenV1Widget = getFieldValue([...contentPath, index]);

        const isQuote = node.blueprint?.source === SnippetPathByName.Quote;

        return isQuote ? (
          <GalleryColumnQuote
            disabled={disabled}
            field={field}
            contentPath={contentPath}
            index={index}
            node={node}
            remove={remove}
          />
        ) : (
          <GalleryColumnText
            disabled={disabled}
            field={field}
            contentPath={contentPath}
            index={index}
            remove={remove}
          />
        );
      }}
    </Form.Item>
  );
};
