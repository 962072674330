import { EllipsisOutlined } from '@ant-design/icons';
import { Drawer, Dropdown, Menu, Typography } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { VersionHistoryData } from '..';

type VersionHistoryDrawerProps = {
  isOpen: boolean;
  data: VersionHistoryData[];
  onCreateCopy: (id: string) => void;
  onRollback: (id: string) => void;
  onClose: () => void;
};

const HistoryBlockWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 14px 16px;
  background: ${(props) => props.theme.colors.gray2};
`;

const HistoryBlockTopRow = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

type HistoryBlockProps = {
  block: VersionHistoryData;
  onCreateCopy: (id: string) => void;
  onRollback: (id: string) => void;
};

const HistoryBlock: React.FC<HistoryBlockProps> = ({ block, onCreateCopy, onRollback }) => {
  const menu = (
    <Menu>
      <Menu.Item onClick={() => onCreateCopy(block.id)}>
        <Typography.Text>Создать копию версии</Typography.Text>
      </Menu.Item>
      <Menu.Item onClick={() => onRollback(block.id)}>
        <Typography.Text>Откатиться до версии</Typography.Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <HistoryBlockWrapper>
      <HistoryBlockTopRow>
        <Typography.Text strong>{block.date}</Typography.Text>
        <Dropdown trigger={['click']} overlay={menu}>
          <EllipsisOutlined style={{ fontSize: 24 }} />
        </Dropdown>
      </HistoryBlockTopRow>
      <Typography.Text>{block.author}</Typography.Text>
    </HistoryBlockWrapper>
  );
};

export const VersionHistoryDrawer: React.FC<VersionHistoryDrawerProps> = ({
  isOpen,
  onCreateCopy,
  onRollback,
  onClose,
  data,
}) => {
  return (
    <Drawer
      title="История версий"
      visible={isOpen}
      onClose={onClose}
      width={308}
      bodyStyle={{
        padding: '0 8px',
      }}
    >
      {data.map((historyBlock) => (
        <HistoryBlock block={historyBlock} onCreateCopy={onCreateCopy} onRollback={onRollback} key={historyBlock.id} />
      ))}
    </Drawer>
  );
};

VersionHistoryDrawer.displayName = 'VersionHistoryDrawer';
