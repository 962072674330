import { SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import {
  AddSnippetButtonProps,
  ContentButtonsInput,
  RichtextTextNodesEditor,
  SnippetItemBlock,
} from '../../../custom-inputs';
import { RichtextFormItem } from '../../../node-editors';

export type AccordionItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  accordionContentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  allowedSnippets,
  disallowedSnippets,
  accordionContentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      allowedSnippets={allowedSnippets}
      disallowedSnippets={disallowedSnippets}
      contentPath={[...accordionContentPath, index, 'accordionItem', 'content', 0]}
    >
      <Alert message={`Аккордеон №${index + 1}`} type="info" style={{ marginBottom: 12 }} />
      <EditorContainer label="Первая колонка">
        <ContentButtonsInput
          contentPath={[...accordionContentPath, index, 'accordionItem', 'side', 0]}
          label="Медиа"
          allowedSnippets={[SnippetName.Video, SnippetName.Image, SnippetName.GallerySection]}
        />
      </EditorContainer>
      <EditorContainer label="Вторая колонка">
        <Form.Item
          label={`Заголовок`}
          name={[field.name, 'accordionItem', 'header']}
          fieldKey={[field.fieldKey, 'accordionItem', 'header']}
        >
          <RichtextTextNodesEditor disabled={disabled} />
        </Form.Item>
        <RichtextFormItem
          label="Контент"
          contentFieldName={[field.name, 'accordionItem', 'content', 0, 'blueprint', 'content', 0, 'richtext']}
          disabled={disabled}
        />
      </EditorContainer>
    </SnippetItemBlock>
  );
};
