import { ContentSitesV1ListFilesResponse } from '@cms/volkswagen-widgets';
import { useQueryClient } from 'react-query';
import { Service, sitesFilesApi } from '../../../';
import { ListSiteFilesParams, useListSiteFiles } from './actions/useListSiteFiles';
import { usePublishSite, UsePublishSiteData } from './actions/usePublishSiteFile';

type Models = {
  siteFilesResponse: ContentSitesV1ListFilesResponse | undefined;
};

type Operations = {
  publishSiteFile: UsePublishSiteData['publishSiteFile'];
  publishSiteFileAsync: UsePublishSiteData['publishSiteFileAsync'];
  invalidateQueries(): void;
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isPublishLoading: boolean;
};

type Parameters = ListSiteFilesParams & {
  isInvalidate?: boolean;
};

export type SiteFilesService = Service<Models, Operations, Meta>;

export const useSiteFiles = (params: Parameters): SiteFilesService => {
  const { siteName, path, isInvalidate } = params;
  const queryKey = ['sites', siteName, path ?? ''];

  const queryClient = useQueryClient();

  const { isListFetching, isListLoading, siteFilesResponse } = useListSiteFiles(sitesFilesApi, queryKey, params);
  const { isPublishLoading, publishSiteFile, publishSiteFileAsync } = usePublishSite(
    sitesFilesApi,
    queryKey,
    isInvalidate,
  );

  return {
    models: {
      siteFilesResponse,
    },
    operations: {
      publishSiteFile,
      publishSiteFileAsync,
      invalidateQueries() {
        queryClient.invalidateQueries(queryKey);
      },
    },
    meta: {
      isListLoading,
      isListFetching,
      isPublishLoading,
    },
  };
};
