import {
  ContentFilesV1File,
  ContentFilesV1Folder,
  ContentVolkswagenV1Page,
  ContentVolkswagenV1Snippet,
} from '@cms/volkswagen-widgets';
import React from 'react';
import { UseBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import { FilesService } from '@console/api';

type IFilesContext = {
  breadcrumbs: UseBreadcrumbs;

  selectedRows: ContentFilesV1File[];
  setSelectedRows: (rows: ContentFilesV1File[]) => void;
  resetSelectedRows: () => void;

  editPage: boolean | ContentVolkswagenV1Page;
  setEditPage: (value: ContentVolkswagenV1Page | boolean) => void;
  editFolder: boolean | ContentFilesV1Folder;
  setEditFolder: (value: ContentFilesV1Folder | boolean) => void;
  editSnippet: boolean | ContentVolkswagenV1Snippet;
  setEditSnippet: (value: ContentVolkswagenV1Snippet | boolean) => void;
  // editBlueprint: boolean | SpacesBlueprint.Blueprint;
  // setEditBlueprint: (value: SpacesBlueprint.Blueprint | boolean) => void;

  search: string | undefined;
  setSearch: (value: string) => void;

  moveFolder?: ContentFilesV1Folder;
  setMoveFolder: (file: ContentFilesV1Folder) => void;

  copyFolder?: ContentFilesV1Folder;
  setCopyFolder: (file: ContentFilesV1Folder) => void;

  movePage?: ContentVolkswagenV1Page;
  setMovePage: (file: ContentVolkswagenV1Page) => void;

  copyPage?: ContentVolkswagenV1Page;
  setCopyPage: (file: ContentVolkswagenV1Page) => void;

  service: FilesService;
};

const FilesContext = React.createContext<IFilesContext>(null as unknown as IFilesContext);

export const FilesContextProvider = FilesContext.Provider;

export function useFilesContext(): IFilesContext {
  const context = React.useContext(FilesContext);
  if (context === null) {
    throw new Error('useFilesContext must be used within an FilesContextProvider');
  }
  return context;
}
