import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { useSites } from '@console/api';
import React, { useEffect, useMemo } from 'react';
import { useSearch } from '../../hooks/useSearch';
import { MainTabContentContainer } from './containers/main-tab-content';
import { PublicationTabContent } from './containers/publication-tab-content';
import { RedirectsTabContentContainer } from './containers/redirects-tab-content';
import { RobotsTabContentContainer } from './containers/robots-tab-content';
import { SitemapTabContentContainer } from './containers/sitemap-tab-content';
import { SitesListContentContainer } from './containers/sites-list-content';
import StructureTabContentContainer from './containers/structure-tab-content';
import { SitesContextProvider, SiteTabs } from './contexts/sites-context';

const componentByTab: Record<SiteTabs, React.ComponentType> = {
  [SiteTabs.Structure]: StructureTabContentContainer,
  [SiteTabs.Publication]: PublicationTabContent,
  [SiteTabs.Main]: MainTabContentContainer,
  [SiteTabs.Redirects]: RedirectsTabContentContainer,
  [SiteTabs.Robots]: RobotsTabContentContainer,
  [SiteTabs.Sitemap]: SitemapTabContentContainer,
};

export const SitesPage: React.FC = () => {
  const service = useSites({
    enabled: true,
  });

  const {
    models: { sitesResponse },
  } = service;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sites = sitesResponse?.sites || [];

  const [tab, setTab, clearTab] = useSearch<SiteTabs>('tab', SiteTabs.Structure);
  const [siteName, setSiteName, clearSiteName] = useSearch('site');

  const currentSite = useMemo(() => sites.find((site) => site.name === siteName), [siteName, sites]);

  const setCurrentSite = (site?: ContentSitesV1Site) => {
    if (site) {
      setSiteName(site.name);
    } else {
      clearSiteName();
    }
  };

  const Content = componentByTab[tab];

  useEffect(() => {
    if (!Content) {
      clearTab();
    }
  }, [Content, clearTab]);

  return (
    <SitesContextProvider
      value={{
        tab,
        currentSite,
        setCurrentSite,
        sites,
        service,
        setTab,
      }}
    >
      {currentSite ? <Content /> : <SitesListContentContainer />}
    </SitesContextProvider>
  );
};
