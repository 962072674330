import { ContentSitesV1RedirectKind } from '@cms/volkswagen-widgets';
import { VersionV1Version } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { Button, Form, FormInstance, Input, InputNumber, Modal, Select } from 'antd';
import React from 'react';

export type EditRedirectFormValues = {
  name: string;
  kind: ContentSitesV1RedirectKind;
  version: VersionV1Version;
  source: string;
  target: string;
  weight?: number;
  // priority: number;
};

export type EditRedirectModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<EditRedirectFormValues>;
  initialValues?: EditRedirectFormValues;
  onClose: () => void;
  onSubmit: (values: Omit<EditRedirectFormValues, 'name'>) => void;
};

export const EditRedirectModal: React.FC<EditRedirectModalProps> = ({
  open,
  loading,
  form,
  initialValues,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      title={`${initialValues ? 'Редактирование' : 'Создание'} редиректа`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button loading={loading} key="submit" type="primary" onClick={form.submit}>
          {initialValues ? 'Сохранить' : 'Создать'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Тип редиректа"
          name="kind"
          rules={[
            {
              required: true,
              enum: [
                ContentSitesV1RedirectKind.Internal,
                ContentSitesV1RedirectKind.Permanent,
                ContentSitesV1RedirectKind.Temporary,
              ],
            },
          ]}
        >
          <Select placeholder="Выберите тип редиректа">
            <Select.Option value={ContentSitesV1RedirectKind.Internal}>rewrite</Select.Option>
            <Select.Option value={ContentSitesV1RedirectKind.Permanent}>301</Select.Option>
            <Select.Option value={ContentSitesV1RedirectKind.Temporary}>302</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="URL старой страницы" name="source" rules={[{ required: true }]}>
          <Input placeholder="Введите URL старой страницы" />
        </Form.Item>

        <Form.Item label="URL новой страницы" name="target" rules={[{ required: true }]}>
          <Input placeholder="Введите URL новой страницы" />
        </Form.Item>

        <Form.Item label="Приоритет" name="weight">
          <InputNumber placeholder="Введите приоритет" min={0} />
        </Form.Item>

        {/*<Form.Item label="Приоритет" name="priority" rules={[{ required: true }]}>*/}
        {/*  <InputNumber style={{ width: '100%' }} placeholder="Введите приоритет" />*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>
  );
};
