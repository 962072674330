import { FontSizeOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { StyledControl, StyledDropdownMenu, StyledDropdownControl } from '../../../elements';
import { ConfigType, useConfigByStyles, useInline } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';

export enum TextSizeInlineType {
  copy100 = 'copy100',
  copy150 = 'copy150',
  copy200 = 'copy200',
  copy250 = 'copy250',
  copy300 = 'copy300',
  headline200 = 'headline200',
  headline250 = 'headline250',
  headline300 = 'headline300',
  headline350 = 'headline350',
  headline400 = 'headline400',
  headline450 = 'headline450',
  headline500 = 'headline500',
  headline550 = 'headline550',
  headline600 = 'headline600',
  label100 = 'label100',
  label150 = 'label150',
  label200 = 'label200',
  label250 = 'label250',
  label300 = 'label300',
}

export const inlineTextSizeList = Object.values<TextSizeInlineType>(TextSizeInlineType);

const TEXT_SIZE_STYLES: StyleItem<TextSizeInlineType>[] = inlineTextSizeList.map((size) => ({
  label: size,
  style: size,
}));

export const TextSizeInline: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleTextSize, currentStyle] = useInline<TextSizeInlineType>(editorState, setEditorState, inlineTextSizeList);

  const types = useConfigByStyles(ConfigType.TextSize, TEXT_SIZE_STYLES);

  const isActive = types.some(({ style }) => currentStyle.has(style));

  if (!types.length) {
    return null;
  }

  return (
    <StyledControl>
      <Dropdown
        trigger={['click']}
        overlay={
          <StyledDropdownMenu>
            {types.map(({ label, style, name }) => (
              <Menu.Item key={label}>
                <ControlButton title={name} isActive={currentStyle.has(style)} value={style} onToggle={toggleTextSize}>
                  {label}
                </ControlButton>
              </Menu.Item>
            ))}
          </StyledDropdownMenu>
        }
      >
        <StyledDropdownControl>
          <ControlButton title="Размер шрифта" isActive={isActive}>
            <FontSizeOutlined />
          </ControlButton>
        </StyledDropdownControl>
      </Dropdown>
    </StyledControl>
  );
});

TextSizeInline.displayName = 'TextSizeInline';
