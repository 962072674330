import React from 'react';
import { ControlsConfig, EntityControlsConfig } from './controls-hooks';

export const ControlsContext = React.createContext<{
  entityConfig: EntityControlsConfig | undefined;
  config: ControlsConfig | undefined;
  isClearButton?: boolean;
}>({
  entityConfig: undefined,
  config: undefined,
});
