import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type ThemeCheckboxProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  themeName: string;
  label: string;
  disabled: boolean;
};

export const ThemeCheckbox: React.FC<ThemeCheckboxProps> = ({ value = [], onChange, themeName, label, disabled }) => {
  const isChecked = value.includes(themeName);

  const handleChange: CheckboxProps['onChange'] = (event) => {
    onChange && onChange(event.target.checked ? [...value, themeName] : value.filter((theme) => theme !== themeName));
  };

  return (
    <Checkbox checked={isChecked} onChange={handleChange} disabled={disabled}>
      {label}
    </Checkbox>
  );
};
