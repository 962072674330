import { Breadcrumb } from 'antd';
import React from 'react';
import { UseBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import { styled } from '@console/uikit';

const StyledBreadcrumbsWrapper = styled.div`
  padding: 8px 16px;
  background: ${(props) => props.theme.colors.gray1};
`;

type BreadcrumbsProps = UseBreadcrumbs & {
  homeTitle: string;
  className?: string;
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  homeTitle,
  breadcrumbs,
  clearBreadcrumbs,
  selectBreadcrumb,
  className,
}) => {
  const onClear = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearBreadcrumbs();
  };

  const onSelect = (index: number) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    selectBreadcrumb(index);
  };

  return (
    <StyledBreadcrumbsWrapper className={className}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/" onClick={onClear}>
            {homeTitle}
          </a>
        </Breadcrumb.Item>
        {breadcrumbs.map((breadcrumb, i) => (
          <Breadcrumb.Item key={breadcrumb + i}>
            {i === breadcrumbs.length - 1 ? (
              breadcrumb
            ) : (
              <a href="/" onClick={onSelect(i)}>
                {breadcrumb}
              </a>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </StyledBreadcrumbsWrapper>
  );
};
