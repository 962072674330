import React from 'react';
import { Editor } from './components/editor';
import { withDraftState, WithExternalState, WithLocalState } from './hocs';

export * from './hooks/controls-hooks';
export * from './hooks/state-hooks';
export * from './converter';
export type { StyledWrapperType, StyledWrapperProps } from './elements';

export type RichTextEditorProps = WithLocalState | WithExternalState;

export const RichTextEditor: React.FC<RichTextEditorProps> = withDraftState(Editor);

RichTextEditor.displayName = 'RichTextEditor';
