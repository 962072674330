import { Form, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, TreePath } from 'src/utils/content-path';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const richtextPath: TreePath = ['blueprint', 'content', 0, 'richtext'];

export const RichtextEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { richtextData } = React.useMemo(() => {
    // content
    const richtextData = getPlaceholderedPath({
      node,
      commonPath: richtextPath,
    });

    return { richtextData };
  }, [node]);

  return (
    <Form name={formName} initialValues={node} onFinish={onSave}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* content edit */}
      <RichtextFormItem label="Контент" contentFieldName={richtextData.path} disabled={false} />
    </Form>
  );
};
