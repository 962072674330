import { ContentSitesV1Redirect } from '@cms/volkswagen-widgets';
import { useRedirects } from '@console/api';
import { Spin } from 'antd';
import React from 'react';
import { MainLayout } from '../../../../components';
import { useSitesContext } from '../../contexts/sites-context';
import { EditRedirectModalContainer } from '../edit-redirect-modal';
import { RedirectsTabHeader } from './components/redirects-tab-header';
import { RedirectsTable } from './components/redirects-table';
import { RedirectsTableHeader } from './components/redirects-table-header';

export const RedirectsTabContentContainer: React.FC = () => {
  const { currentSite } = useSitesContext();

  const [editRedirect, setEditRedirect] = React.useState<ContentSitesV1Redirect | boolean>(false);

  const {
    models: { redirectsResponse },
    meta: { isListLoading, isListFetching, isRemoveLoading },
    operations: { removeRedirect },
  } = useRedirects({ enabled: true, site: currentSite?.name || '' });

  const redirects = redirectsResponse?.redirects || [];
  const loading = isListLoading || isListFetching || isRemoveLoading;

  const handleRemoveRedirect = (name: string) => {
    removeRedirect(name);
  };

  const handleAddRule = () => setEditRedirect(true);

  return (
    <MainLayout header={<RedirectsTabHeader />}>
      <Spin spinning={loading}>
        <RedirectsTableHeader onAddRule={handleAddRule} />
        <RedirectsTable
          redirects={redirects}
          setEditRedirect={setEditRedirect}
          onRemoveRedirect={handleRemoveRedirect}
        />
      </Spin>

      <EditRedirectModalContainer
        open={editRedirect !== false}
        onClose={() => setEditRedirect(false)}
        initialValues={typeof editRedirect === 'boolean' ? undefined : editRedirect}
      />
    </MainLayout>
  );
};
