import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, NodePreview, SnippetItemBlock } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const gridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.TeamItem];

export const TeamGridEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { gridData } = React.useMemo(() => {
    const gridData = getPlaceholderedPath({
      node,
      commonPath: gridPath,
    });

    return { gridData };
  }, [node]);

  const disabled = gridData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.List name={[...gridData.path, 'content']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <SnippetItemBlock
                disabled={disabled}
                onDelete={() => remove(field.name)}
                onMoveTop={fields.length > 1 && index > 0 ? () => move(index, index - 1) : undefined}
                onMoveBottom={fields.length > 0 && index < fields.length - 1 ? () => move(index, index + 1) : undefined}
                contentPath={[...gridData.path, 'content', index]}
                allowedSnippets={allowedSnippets}
              >
                <div style={{ width: '200px' }}>
                  <Form.Item name={[field.name]}>
                    <NodePreview />
                  </Form.Item>
                </div>
              </SnippetItemBlock>
            ))}
            <AddSnippetButton
              add={add}
              isDisabled={disabled}
              label="Добавить сотрудника"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
