import { UsersV1User } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';

type FilesNameTableCellProps = {
  user: UsersV1User;
};

export const NameTableCell: React.FC<FilesNameTableCellProps> = ({ user }) => {
  return (
    <Space size="middle">
      <Typography.Text>{user.title || user.name}</Typography.Text>
    </Space>
  );
};
