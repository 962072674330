import { UsersV1User } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';

type FilesNameTableCellProps = {
  user: UsersV1User;
};

export const SitesTableCell: React.FC<FilesNameTableCellProps> = ({ user }) => {
  return (
    <Space size="middle">
      <Typography.Text>{user.sites.join(', ')}</Typography.Text>
    </Space>
  );
};
