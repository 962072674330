import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1TopMenuNode, SnippetName, TopMenuNode } from '@cms/volkswagen-widgets';
import { Alert, Button, Collapse, ColProps, Form, Input, Space } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { AddSnippetButton, SnippetItemBlock } from 'src/components/editors/custom-inputs';
import { TreePath } from 'src/utils/content-path';
import { getInitialMenuItem, getInitialMenuLink } from '../generators';
import { TopMenuFlyoutItem } from './top-menu-flyout-item';

const labelCol: ColProps = { span: 3 };

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type TopMenuFlyoutItemInputProps = {
  disabled: boolean;
  field: FormListFieldData;
  index: number;
  itemsPath: TreePath;
  menuLevel: number;
  onChange?: (value: ContentVolkswagenV1TopMenuNode) => void;
  value?: ContentVolkswagenV1TopMenuNode;
};

export const TopMenuFlyoutItemInput: React.FC<TopMenuFlyoutItemInputProps> = ({
  disabled,
  field: globalField,
  index,
  itemsPath,
  value,
  menuLevel,
}) => {
  // wrong data
  if (!value) {
    return null;
  }

  // it's additional link
  if (value.topMenuLink?.themes?.includes(TopMenuNode.Link.themes.additionalLink)) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`[Уровень ${menuLevel}] Ссылка в нижнем блоке №${index + 1}`} type="info" />

        {/* link.href edit */}
        <Form.Item
          label="Ссылка"
          name={[globalField.name, 'topMenuLink', 'source']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'source']}
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {/* link.title edit */}
        <Form.Item
          label="Текст ссылки"
          name={[globalField.name, 'topMenuLink', 'content', 0, 'text', 'content']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'content', 0, 'text', 'content']}
          style={{ margin: 0 }}
          labelCol={labelCol}
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </Space>
    );
  }

  // it's main link
  if (value.topMenuLink?.themes?.includes(TopMenuNode.Link.themes.mainLink)) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`[Уровень ${menuLevel}] Главная ссылка в нижнем блоке №${index + 1}`} type="info" />

        {/* link.href edit */}
        <Form.Item
          label="Ссылка"
          name={[globalField.name, 'topMenuLink', 'source']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'source']}
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {/* link.title edit */}
        <Form.Item
          label="Текст ссылки"
          name={[globalField.name, 'topMenuLink', 'content', 0, 'text', 'content']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'content', 0, 'text', 'content']}
          style={{ margin: 0 }}
          labelCol={labelCol}
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </Space>
    );
  }

  // it's menu link
  if (value.topMenuLink) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`[Уровень ${menuLevel}] Ссылка в меню №${index + 1}`} type="info" />

        {/* item.href edit */}
        <Form.Item
          label="Ссылка"
          name={[globalField.name, 'topMenuLink', 'source']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'source']}
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {/* item.title edit */}
        <Form.Item
          label="Текст ссылки"
          name={[globalField.name, 'topMenuLink', 'content', 0, 'text', 'content']}
          fieldKey={[globalField.fieldKey, 'topMenuLink', 'content', 0, 'text', 'content']}
          style={{ margin: 0 }}
          labelCol={labelCol}
          rules={[{ required: true }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </Space>
    );
  }

  // it's menu item
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert message={`[Уровень ${menuLevel}] Переход в меню №${index + 1}`} type="info" />

      {/* item.title edit */}
      <Form.Item
        label="Текст ссылки"
        name={[globalField.name, 'topMenuItem', 'header', 0, 'text', 'content']}
        fieldKey={[globalField.fieldKey, 'topMenuItem', 'header', 0, 'text', 'content']}
        style={{ margin: 0 }}
        labelCol={labelCol}
        rules={[{ required: true }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {/* item.promoArea */}
      <Form.List name={[globalField.name, 'topMenuItem', 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <SnippetItemBlock
                key={field.key}
                disabled={disabled}
                onDelete={() => remove(field.name)}
                contentPath={[...itemsPath, globalField.fieldKey, 'topMenuItem', 'content', 0]}
              >
                <Alert message={`[Уровень ${menuLevel}] Промо-область`} type="info" />
              </SnippetItemBlock>
            ))}
            {/* only one promo area can be added */}
            {fields.length === 0 && (
              <AddSnippetButton
                add={add}
                isDisabled={disabled}
                label={`Добавить промо-область (${menuLevel})`}
                allowedSnippets={[SnippetName.TopMenuPromoArea]}
              />
            )}
          </>
        )}
      </Form.List>

      {/* item.subMenu */}
      <Collapse>
        <Collapse.Panel header={`Подменю уровень ${menuLevel + 1}`} key="1">
          <Form.List name={[globalField.name, 'topMenuItem', 'items']}>
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <TopMenuFlyoutItem
                    key={field.key}
                    disabled={disabled}
                    field={field}
                    index={index}
                    move={move}
                    remove={remove}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    itemsPath={[...itemsPath, globalField.fieldKey, 'topMenuItem', 'items']}
                    menuLevel={menuLevel + 1}
                  />
                ))}

                <Space direction="horizontal">
                  <Button
                    type="dashed"
                    disabled={disabled}
                    onClick={() => add(getInitialMenuLink())}
                    icon={<PlusOutlined />}
                  >
                    Добавить ссылку в меню ({menuLevel + 1})
                  </Button>
                  {/* max menu level is 3 */}
                  {menuLevel < 2 && (
                    <Button
                      type="dashed"
                      disabled={disabled}
                      onClick={() => add(getInitialMenuItem())}
                      icon={<PlusOutlined />}
                    >
                      Добавить переход в меню ({menuLevel + 1})
                    </Button>
                  )}
                </Space>
              </>
            )}
          </Form.List>
        </Collapse.Panel>
      </Collapse>
    </Space>
  );
};
