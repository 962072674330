import {
  blueprintNodeGenerator,
  ContentVolkswagenV1Node,
  SnippetName,
  SnippetPathByName,
} from '@cms/volkswagen-widgets';
import { filesApi } from '@console/api';
import { notification } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';

type UseLoadSnippet = {
  snippet?: ContentVolkswagenV1Node;
  isSnippetLoading: boolean;
  loadSnippet: (name: SnippetName) => void;
  setSnippet: (node: ContentVolkswagenV1Node) => void;
  resetState: () => void;
};

export function useLoadSnippet(): UseLoadSnippet {
  const [snippet, setSnippet] = React.useState<ContentVolkswagenV1Node>();
  const [path, setPath] = React.useState<string>();

  const { isLoading: isSnippetLoading } = useQuery(
    ['file', path],
    () =>
      filesApi.contentFilesV1FilesAPIGetFile(
        path ?? '', // filePath
      ),
    {
      enabled: !!path,
      onSuccess: (data) => {
        if (data.data.volkswagenV1Snippet) {
          const snippetBlueprint = blueprintNodeGenerator({
            source: data.data.volkswagenV1Snippet.path,
            content: data.data.volkswagenV1Snippet.content,
          });

          setSnippet(snippetBlueprint);
        } else {
          notification.error({
            message: `Получена неверная структура сниппета ${path}`,
            description: 'Path data.volkswagenV1Snippet not found',
          });
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (data: any) => {
        notification.error({
          message: `Ошибка получения сниппета по пути ${path}`,
          description: data?.response?.data.message,
        });
      },
    },
  );

  const loadSnippet = React.useCallback((snippetName: SnippetName) => {
    const snippetPath = SnippetPathByName[snippetName];
    setPath(snippetPath.substring(1));
  }, []);

  const resetState = React.useCallback(() => {
    setSnippet(undefined);
    setPath(undefined);
  }, []);

  return { snippet, isSnippetLoading, loadSnippet, resetState, setSnippet };
}
