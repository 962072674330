import { GoogleRpcStatus, UsersV1UsersAPIApi, UsersV1UserProps } from '@cms/volkswagen-widgets';
import { UsersV1User } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<UsersV1User>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { userName: string; body: UsersV1UserProps };

export type UseUpdateUserData = {
  updateUser: UseMutateFunction<Data, Error, Variables>;
  updateUserAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isUpdateLoading: boolean;
};

export function useUpdateUser(
  usersApi: UsersV1UsersAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseUpdateUserData {
  const queryClient = useQueryClient();

  const {
    mutate: updateUser,
    mutateAsync: updateUserAsync,
    isLoading: isUpdateLoading,
  } = useMutation<Data, Error, Variables>(({ body, userName }) => usersApi.usersV1UsersAPIUpdateUser(userName, body), {
    onSuccess: () => {
      if (isInvalidate) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (error) => {
      notification.error({
        message: 'Ошибка изменения пользователя',
        description: error?.response?.data.message,
      });
    },
  });

  return { updateUser, updateUserAsync, isUpdateLoading };
}
