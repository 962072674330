import { ScriptNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AttributesRadioGroup } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const commonScriptPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'script'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const ScriptEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { scriptPath } = React.useMemo(() => {
    const scriptPath = getPlaceholderedPath({
      node,
      commonPath: commonScriptPath,
    });

    return {
      scriptPath,
    };
  }, [node]);

  const disabled = scriptPath.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={scriptPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.Item label="Ссылка" name={[...scriptPath.path, 'source']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Тип загрузки" name={[...scriptPath.path, 'attributes']}>
        <AttributesRadioGroup
          possibleValues={[
            {
              label: 'async',
              value: 'async',
            },
            {
              label: 'defer',
              value: 'defer',
            },
            {
              label: 'По умолчанию',
              value: '',
            },
          ]}
          attributeName={ScriptNode.attributes.loadType}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item label="Режим" name={[...scriptPath.path, 'attributes']}>
        <AttributesRadioGroup
          possibleValues={[
            {
              label: 'dev',
              value: 'dev',
            },
            {
              label: 'prod',
              value: 'prod',
            },
          ]}
          attributeName={ScriptNode.attributes.mode}
          disabled={disabled}
        />
      </Form.Item>
    </Form>
  );
};
