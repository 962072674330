import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { useFile } from '@console/api';
import React from 'react';
import { PageFileSettingsModal, PageFileSettingsModalProps } from '../../../../containers';
import { SnippetContentModalPreview } from '../../../../containers/snippet-content-preview-modal';
import { useFilesContext } from '../../contexts';
import { FilesTable } from './components/files-table';

export const FilesTableContainer: React.FC = () => {
  const {
    breadcrumbs: { addBreadcrumb },
    selectedRows,
    setSelectedRows,
    setEditFolder,
    service: {
      models: { filesResponse },
      meta: { isListLoading, isListFetching, isUpdateLoading },
      operations: { updateFileAsync },
    },
  } = useFilesContext();
  const [currentPage, setCurrentPage] = React.useState<ContentFilesV1File>();
  const [currentSnippet, setCurrentSnippet] = React.useState<ContentFilesV1File>();

  const {
    models: { fileResponse: pageResponse },
    meta: { isFileLoading: isPageLoading },
  } = useFile({
    enabled: !!currentPage,
    path: `${currentPage?.volkswagenV1Page?.path ?? currentPage?.volkswagenV1PageBlueprint?.path}`,
  });

  const {
    models: { fileResponse: snippetResponse },
    meta: { isFileLoading: isSnippetLoading, isUpdateLoading: isSnippetUpdateLoading },
    operations: { updateFileAsync: updateSnippetAsync },
  } = useFile({
    enabled: !!currentSnippet,
    path: `${currentSnippet?.volkswagenV1Snippet?.path}`,
  });

  const files = filesResponse?.files ?? [];

  const handleUpdatePage: PageFileSettingsModalProps['updatePage'] = async ({ path, props, onSuccess }) => {
    await updateFileAsync(
      {
        filePath: path,
        body: {
          [currentPage?.volkswagenV1Page ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: {
            ...props,
          },
        },
      },
      { onSuccess },
    );
  };

  return (
    <>
      <FilesTable
        files={files}
        loading={isListLoading || isListFetching}
        addBreadcrumb={addBreadcrumb}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setEditFolder={setEditFolder}
        onPageSettingsClick={setCurrentPage}
        onSnippetViewClick={setCurrentSnippet}
        // setEditBlueprint={setEditBlueprint}
      />
      <PageFileSettingsModal
        pageData={pageResponse?.volkswagenV1Page || pageResponse?.volkswagenV1PageBlueprint}
        updatePage={handleUpdatePage}
        isLoading={isPageLoading}
        isUpdateLoading={isUpdateLoading}
        isVisible={!!currentPage}
        type={PageFileSettingsModal.type.Page}
        onCancel={() => setCurrentPage(undefined)}
        isBlueprint={!!pageResponse?.volkswagenV1PageBlueprint}
      />
      <SnippetContentModalPreview
        snippet={snippetResponse?.volkswagenV1Snippet}
        isLoading={isSnippetLoading}
        isUpdateLoading={isSnippetUpdateLoading}
        updateFileAsync={updateSnippetAsync}
        isVisible={!!currentSnippet}
        onCancel={() => setCurrentSnippet(undefined)}
      />
    </>
  );
};
