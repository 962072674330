import { ContentVolkswagenV1IFrameStyle, ContentVolkswagenV1ImageStyle } from '@cms/volkswagen-widgets';
import { Form, Input, Space, Table } from 'antd';
import React from 'react';

const BREAKPOINTS = ['0', '560', '960', '1280', '1600', '1920', '2560'];

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type StylesTableEditProps = {
  value?: ContentVolkswagenV1IFrameStyle[];
  onChange?: (value: ContentVolkswagenV1ImageStyle[]) => void;
  disabled: boolean;
};

export const StylesTableEdit: React.FC<StylesTableEditProps> = ({ value, onChange, disabled }) => {
  if (!value || !Array.isArray(value)) {
    throw new Error(`Invalid structure 'StylesTableEdit', not found styles`);
  }

  const breakpointStyles = React.useMemo(
    () =>
      BREAKPOINTS.map((breakpoint) => {
        const currentStyle = value.find((style) => style.layout === breakpoint);

        return {
          layout: breakpoint,
          width: currentStyle?.width,
          height: currentStyle?.height,
        };
      }),
    [value],
  );

  const handleEdit = (layout: string, editedStyle: Partial<ContentVolkswagenV1IFrameStyle>) => {
    if (onChange) {
      // trying to find an existing style
      const style = value.find((style) => style.layout === layout);

      // update style
      if (style) {
        onChange(
          value.map((style) =>
            style.layout === layout
              ? {
                  ...style,
                  ...editedStyle,
                }
              : style,
          ),
        );
      } else {
        // create style
        onChange([...value, { layout, ...editedStyle }]);
      }
    }
  };

  return (
    <Space>
      <Table
        dataSource={breakpointStyles}
        rowKey={(row) => row.layout}
        size="small"
        pagination={false}
        columns={[
          { title: 'Breakpoint', dataIndex: 'layout' },
          {
            title: 'Стиль',
            render: (row) => (
              <>
                <Form.Item label="Высота">
                  <Input
                    value={row.height}
                    disabled={disabled}
                    onChange={(e) =>
                      handleEdit(row.layout, {
                        height: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Ширина">
                  <Input
                    value={row.width}
                    disabled={disabled}
                    onChange={(e) =>
                      handleEdit(row.layout, {
                        width: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </>
            ),
          },
        ]}
      />
    </Space>
  );
};
