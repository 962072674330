import { ContentVolkswagenV1Node, GalleryNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Divider, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { MoodGalleryFirstItem, MoodGalleryItem } from './components';

const galleryPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'gallery'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapGalleryAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  galleryItem: GalleryNode.Item.generators.entity({
    content: [node],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.Image, SnippetName.Video];

export const MoodGalleryEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { galleryData } = React.useMemo(() => {
      const galleryData = getPlaceholderedPath({
        node,
        commonPath: galleryPath,
      });

      return { galleryData };
    }, [node]);

    const disabled = galleryData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={galleryData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            <Tooltip
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
            >
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>
        {/* // as NonNullable<FormListProps['rules']>[0]; */}
        <Form.List
          name={[formListFieldData.name, ...galleryData.path, 'items']}
          rules={[
            {
              validator: async (_, items) =>
                !items || items.length < 3
                  ? Promise.reject(new Error('Необходимо добавить минимум 2 элемента'))
                  : Promise.resolve(),
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <>
              {fields.map((field, index) => {
                // hardcode first GalleryNode.Item for richtext content
                if (index === 0) {
                  return (
                    <React.Fragment key={field.key}>
                      <MoodGalleryFirstItem disabled={disabled} field={field} />
                      <Divider orientation="right">Элементы (от 2 до 5)</Divider>
                    </React.Fragment>
                  );
                }

                return (
                  <MoodGalleryItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 2 && index < fields.length - 1}
                    isTopMovable={fields.length > 2 && index > 1}
                    contentPath={[...sectionContentPath, ...galleryData.path, 'items']}
                    allowedSnippets={allowedSnippets}
                  />
                );
              })}
              {fields.length < 6 && (
                <>
                  <AddSnippetButton
                    add={add}
                    mapBeforeAdd={mapGalleryAdd}
                    isDisabled={disabled}
                    label="Добавить элемент"
                    allowedSnippets={allowedSnippets}
                  />
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </>
          )}
        </Form.List>
      </>
    );
  },
  {
    disabledHorizontalBounds: true,
  },
);
