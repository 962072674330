import { CarouselNode, ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { HighlightCarouselItem } from './components/highlight-carousel-item';

const flexContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content', 0];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapCarouselAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  carouselItem: CarouselNode.Item.generators.entity({
    content: [node],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.Card];

export const HighlightCarouselEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { flexContentData, carouselItemsData } = React.useMemo(() => {
      const flexContentData = getPlaceholderedPath({
        node,
        commonPath: flexContentPath,
      });

      const carouselItemsData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 'carousel', 'items'],
      });

      return { flexContentData, carouselItemsData };
    }, [node]);

    const disabled = flexContentData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={flexContentData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек">
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>

        {/* cards edit */}
        <Form.List name={[formListFieldData.name, ...carouselItemsData.path]}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <HighlightCarouselItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  contentPath={[...sectionContentPath, ...carouselItemsData.path]}
                  allowedSnippets={allowedSnippets}
                />
              ))}
              <AddSnippetButton
                add={add}
                mapBeforeAdd={mapCarouselAdd}
                isDisabled={disabled}
                label="Добавить слайд"
                allowedSnippets={allowedSnippets}
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
