import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { useContentView } from 'src/pages/page-detail/contexts';

export enum SiblingComponentPosition {
  Above = 'Above',
  Below = 'Below',
}

type AddWidgetState =
  | {
      type: 'open';
      siblingComponentIndex: number;
      siblingComponentPosition: SiblingComponentPosition;
    }
  | { type: 'default' };

type UseAddWidgetState = {
  state: AddWidgetState;
  loading: boolean;
  createWidget: (node: ContentVolkswagenV1Widget) => Promise<unknown>;
  open: (siblingComponentIndex: number, siblingComponentPosition: SiblingComponentPosition) => void;
  close: () => void;
};

export function useAddWidgetState(): UseAddWidgetState {
  const [state, setState] = React.useState<AddWidgetState>({
    type: 'default',
  });

  const { loading, addNode } = useContentView();

  const open = (siblingComponentIndex: number, siblingComponentPosition: SiblingComponentPosition) =>
    setState({
      type: 'open',
      siblingComponentIndex,
      siblingComponentPosition,
    });
  const close = () => setState({ type: 'default' });

  const createWidget = (node: ContentVolkswagenV1Widget) => {
    if (state.type === 'open') {
      const componentIndex = state.siblingComponentIndex || 0;
      const index =
        state.siblingComponentPosition === SiblingComponentPosition.Below ? componentIndex + 1 : componentIndex;

      return addNode(index, node).finally(() => {
        close();
      });
    }

    return Promise.reject();
  };

  return {
    state,
    loading,
    createWidget,
    open,
    close,
  };
}
