import { ClearOutlined } from '@ant-design/icons';
import { ContentState, EditorState, Modifier } from 'draft-js';
import Immutable from 'immutable';
import React from 'react';
import { StyledControl } from '../../elements';
import { ControlsProps } from '../../types';
import { getSelectedBlocks } from '../../utils';
import { ControlButton } from './control-button';

export const Clear: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const handleClear = () => {
    setEditorState((prevState) => {
      const selection = prevState.getSelection();
      if (!selection.isCollapsed()) {
        const contentState = prevState.getCurrentContent();
        const selectedBlocks = getSelectedBlocks(editorState);

        const styles = selectedBlocks.reduce<string[]>((acc, block) => {
          const blockStyles = block
            .getCharacterList()
            .reduce(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (styles, c) => styles.union(c.getStyle()),
              Immutable.OrderedSet(),
            )
            .toJS();
          return [...acc, ...blockStyles];
        }, []);

        const removeStyles = styles.reduce((state, style) => {
          return Modifier.removeInlineStyle(state as ContentState, selection, style as string);
        }, contentState);

        const removeBlock = Modifier.setBlockType(removeStyles, selection, 'unstyled');

        return EditorState.push(prevState, removeBlock, 'change-inline-style');
      }

      return prevState;
    });
  };

  return (
    <StyledControl>
      <ControlButton
        title="Очистить"
        onToggle={handleClear}
        // disabled={selection.isCollapsed()}
      >
        <ClearOutlined />
      </ControlButton>
    </StyledControl>
  );
});

Clear.displayName = 'Clear';
