import { ContentVolkswagenV1Richtext, ParagraphNode } from '@cms/volkswagen-widgets';
import { convertToRaw, EditorState } from 'draft-js';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { entityDecorators } from '../components/controls/entities';
import { textSizeDecorators } from '../components/controls/inline/decorator';
import { CompositeDecorator } from '../composite-decorator';
import { Converter } from '../converter/types';

const decorator = new CompositeDecorator([...entityDecorators, ...textSizeDecorators]);

export const createDraftState = <
  T extends ContentVolkswagenV1Richtext['content'] = ContentVolkswagenV1Richtext['content'],
>(
  converter: Converter<T>,
  initialValue?: T,
): EditorState => {
  return initialValue
    ? EditorState.createWithContent(converter.from(initialValue), decorator)
    : EditorState.createEmpty(decorator);
};

export const useDraftState = (
  converter: Converter<ContentVolkswagenV1Richtext['content']>,
  initialValue?: ContentVolkswagenV1Richtext['content'],
  isSingleLine?: boolean,
): readonly [
  EditorState,
  React.Dispatch<React.SetStateAction<EditorState>>,
  ContentVolkswagenV1Richtext['content'] | undefined,
] => {
  const [convertedData, setConvertedData] = useState<ContentVolkswagenV1Richtext['content'] | undefined>(initialValue);

  // const isFlow = isFlowContent(convertedData || []);
  const isFlow = !isSingleLine;
  const value = isFlow ? convertedData : [ParagraphNode.generators.node({ content: convertedData })];

  const [editorState, setEditorState] = useState<EditorState>(() =>
    createDraftState<ContentVolkswagenV1Richtext['content']>(converter, value),
  );

  const [,] = useDebounce(
    () => {
      const nodes = converter.to(editorState.getCurrentContent());
      if (Array.isArray(nodes)) {
        setConvertedData(isFlow ? nodes : nodes[0]?.paragraph?.content ?? []);
      }
    },
    500,
    [editorState],
  );

  return [editorState, setEditorState, convertedData] as const;
};
