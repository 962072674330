/**
 * Declensions
 * http://vremenno.net/js/js-snippet-declension-of-nouns-and-adjectives/
 * @example getNoun(1, 'день', 'дня', 'дней')
 */
export const getNoun = (number: number, one: string, two: string, five: string): string => {
  number = Math.abs(number);
  number %= 100;
  if (number >= 5 && number <= 20) {
    return five;
  }
  number %= 10;
  if (number === 1) {
    return one;
  }
  if (number >= 2 && number <= 4) {
    return two;
  }
  return five;
};

/**
 * @example "/example-com/root/folder" -> "folder"
 * @example "" -> ""
 * @example "/" -> ""
 * @example "/pkw" -> "pkw"
 */
export const getLastPathParam = (path: string): string => {
  const parts = path.split('/');
  return parts[parts.length - 1];
};

/**
 * @example "/example-com/root/folder" -> "/example-com/root"
 */
export const removeLastPathParam = (path: string): string => {
  const paths = path.split('/');
  return paths.slice(0, paths.length - 1).join('/');
};

/**
 * @example 1 -> "01"
 * @example 22 -> "22"
 */
export const minTwoDigits = (n: number): string => (n < 10 ? '0' : '') + n;

export const strToKebabCase = (str = ''): string =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();
