import {
  ContentFilesV1File,
  ContentFilesV1Folder,
  ContentVolkswagenV1Page,
  ContentVolkswagenV1Snippet,
} from '@cms/volkswagen-widgets';
import React from 'react';
import { Breadcrumbs, MainLayout } from 'src/components';
import { useBreadcrumbs } from 'src/hooks';
import { useFiles } from '@console/api';
import {
  EditFolderModalContainer,
  EditPageModalContainer,
  EditSnippetModalContainer,
  FilesHeaderContainer,
  FilesTableContainer,
  MoveFolderModalContainer,
  MovePageModalContainer,
} from './containers';
import { CopyFolderModalContainer } from './containers/copy-folder-modal';
import { CopyPageModalContainer } from './containers/copy-page-modal';
import { FilesContextProvider } from './contexts';

export const FilesPage: React.FC = () => {
  const [selectedRows, setSelectedRows] = React.useState<ContentFilesV1File[]>([]);
  const resetSelectedRows = () => setSelectedRows([]);

  const breadcrumbs = useBreadcrumbs({ onChange: resetSelectedRows });

  const [editPage, setEditPage] = React.useState<ContentVolkswagenV1Page | boolean>(false);
  const [editFolder, setEditFolder] = React.useState<ContentFilesV1Folder | boolean>(false);
  const [editSnippet, setEditSnippet] = React.useState<ContentVolkswagenV1Snippet | boolean>(false);
  const [moveFolder, setMoveFolder] = React.useState<ContentFilesV1Folder>();
  const [movePage, setMovePage] = React.useState<ContentVolkswagenV1Page>();
  const [copyFolder, setCopyFolder] = React.useState<ContentFilesV1Folder>();
  const [copyPage, setCopyPage] = React.useState<ContentVolkswagenV1Page>();
  // const [editBlueprint, setEditBlueprint] = React.useState<
  //     SpacesBlueprint.Blueprint | boolean
  // >(false);

  const [search, setSearch] = React.useState<string>();

  const service = useFiles({
    enabled: true,
    path: breadcrumbs.path ? `/${breadcrumbs.path}/*` : '/*',
    types: [
      'files.v1.folder',
      'volkswagen.v1.page',
      'volkswagen.v1.snippet',
      'files.v1.folderBlueprint',
      'volkswagen.v1.pageBlueprint',
    ],
  });

  return (
    <FilesContextProvider
      value={{
        breadcrumbs,
        selectedRows,
        setSelectedRows,
        resetSelectedRows,
        editPage,
        setEditPage,
        editFolder,
        setEditFolder,
        editSnippet,
        setEditSnippet,
        // editBlueprint,
        // setEditBlueprint,
        search,
        setSearch,
        moveFolder,
        setMoveFolder,
        movePage,
        setMovePage,
        copyPage,
        setCopyPage,
        copyFolder,
        setCopyFolder,
        service,
      }}
    >
      <MainLayout $isStickyHeader header={<FilesHeaderContainer />}>
        <Breadcrumbs {...breadcrumbs} homeTitle="Файлы" />
        <FilesTableContainer />
        <MoveFolderModalContainer open={Boolean(moveFolder)} onClose={() => setMoveFolder(undefined)} />
        <MovePageModalContainer open={Boolean(movePage)} onClose={() => setMovePage(undefined)} />
        <CopyFolderModalContainer open={Boolean(copyFolder)} onClose={() => setCopyFolder(undefined)} />
        <CopyPageModalContainer open={Boolean(copyPage)} onClose={() => setCopyPage(undefined)} />
        <EditPageModalContainer open={Boolean(editPage)} onClose={() => setEditPage(false)} />
        <EditFolderModalContainer open={Boolean(editFolder)} onClose={() => setEditFolder(false)} />
        <EditSnippetModalContainer open={Boolean(editSnippet)} onClose={() => setEditSnippet(false)} />
        {/* <EditBlueprintModalContainer
                    open={Boolean(editBlueprint)}
                    onClose={() => setEditBlueprint(false)}
                /> */}
      </MainLayout>
    </FilesContextProvider>
  );
};
