import { ContentVolkswagenV1Node } from '@cms/volkswagen-widgets';
import { notification } from 'antd';

export type CopiedSnippetType = {
  node: ContentVolkswagenV1Node;
  path: string;
};

type UseCopySnippet = {
  handleCopySnippet: (node: ContentVolkswagenV1Node, path?: string) => void;
  handleGetSnippet: () => Promise<CopiedSnippetType | void>;
};

export const useCopySnippet = (): UseCopySnippet => {
  const handleCopySnippet: UseCopySnippet['handleCopySnippet'] = (node, path) => {
    navigator.clipboard
      .writeText(JSON.stringify({ node, path }))
      .then(() => notification.success({ message: 'Сниппет скопирован в буфер' }));
  };

  const handleGetSnippet = async () => {
    try {
      const valueJSON = await navigator.clipboard.readText();
      const value = JSON.parse(valueJSON);

      if (!value) {
        return notification.error({ message: 'Значение в буфере отсутствует' });
      }

      if (!value.node.blueprint) {
        return notification.error({ message: 'Скопированное значение не является сниппетом' });
      }

      return value as CopiedSnippetType;
    } catch (e) {
      return notification.error({ message: 'Ошибка при копировании сниппета' });
    }
  };

  return { handleCopySnippet, handleGetSnippet };
};
