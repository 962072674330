import { AccordionNode, ContentVolkswagenV1Node } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, RichtextTextNodesEditor, SnippetItemBlock } from '../custom-inputs';

export type CommonAccordionItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  accordionContentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

const mapAccordionAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  accordionItem: AccordionNode.Item.generators.entity({
    content: [node],
  }),
});

export const CommonAccordionItem: React.FC<CommonAccordionItemProps> & {
  mapper: typeof mapAccordionAdd;
} = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  accordionContentPath,
  allowedSnippets,
  disallowedSnippets,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...accordionContentPath, index, 'accordionItem', 'content', 0]}
      allowedSnippets={allowedSnippets}
      disallowedSnippets={disallowedSnippets}
    >
      <Form.Item
        label={`Заголовок аккордеон-итема #${index + 1}`}
        name={[field.name, 'accordionItem', 'header']}
        fieldKey={[field.fieldKey, 'accordionItem', 'header']}
        style={{ marginBottom: 0 }}
      >
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};

CommonAccordionItem.mapper = mapAccordionAdd;
