import { GlobalOutlined } from '@ant-design/icons';
import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';

export type SitesNameTableCellProps = {
  site: ContentSitesV1Site;
};

export const SitesNameTableCell: React.FC<SitesNameTableCellProps> = ({ site }) => {
  return (
    <Space size="middle">
      <GlobalOutlined />
      <Typography.Text>{site.name}</Typography.Text>
    </Space>
  );
};
