import { AttributesV1Attribute, ContentMediaV1Media } from '@cms/volkswagen-widgets';
import { getAttribute } from './entities';

export const getMimeType = (
  attributes: AttributesV1Attribute[] = [],
): {
  type: string;
  ext: string;
} => {
  const mime = getAttribute(attributes, 'content-type');

  const [type = '', ext = ''] = mime.split('/');

  return { type, ext };
};

export const isImage = (file: ContentMediaV1Media | undefined): boolean => {
  const mime = getMimeType(file?.attributes);

  return mime.type === 'image';
};

export const isPdf = (file: ContentMediaV1Media | undefined): boolean => {
  const mime = getMimeType(file?.attributes);

  return mime.ext === 'pdf';
};

export const isVideo = (file: ContentMediaV1Media | undefined): boolean => {
  const mime = getMimeType(file?.attributes);

  return mime.type === 'video';
};
