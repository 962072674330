import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import get from 'lodash/get';
import { NodeType } from './nodes';

export const PLACEHOLDER_KEY_PATH = 'PLACEHOLDER_KEY_PATH';

export type TreePath = Array<
  | typeof PLACEHOLDER_KEY_PATH
  | NodeType
  | number
  | 'content'
  | 'attributes'
  | 'properties'
  | 'themes'
  | 'items'
  | 'styles'
  | 'columns'
  | 'width'
  | 'side'
  | 'rows'
  | 'cells'
  | 'filters'
  | 'name'
  | 'source'
  | 'preview'
>;

const getPlaceholderPath = (
  commonPath: TreePath,
  placeholderContentIndex: number,
  doublePlaceholder: boolean,
): TreePath => {
  const placeholderPart: TreePath = [
    ...(doublePlaceholder ? (['placeholder', 'content', 0] as TreePath) : []),
    'placeholder',
    'content',
    placeholderContentIndex,
  ];

  const path = commonPath.map((key) => (key === PLACEHOLDER_KEY_PATH ? placeholderPart : key)).flat(1);

  return path;
};

type GetPlaceholderedPathParams = {
  /** Нода, в которой идет поиск по пути */
  node: ContentVolkswagenV1Widget;
  /** Эталонный путь с явно указанным местом возможного плэйсхолдера (`PLACEHOLDER_KEY_PATH`) */
  commonPath: TreePath;
  /** Индекс контента в плэйсхолдере */
  placeholderContentIndex?: number;
};

export type GetPlaceholderedPathResult = {
  path: TreePath;
  placeholdersCount: number;
};

export const getPlaceholderedPath = ({
  node,
  commonPath,
  placeholderContentIndex = 0,
}: GetPlaceholderedPathParams): GetPlaceholderedPathResult => {
  // THERE IS NO PLACEHOLDER CASE
  const path = commonPath.filter((key) => key !== PLACEHOLDER_KEY_PATH);
  if (get(node, path)) return { path, placeholdersCount: 0 };

  // THERE IS SINGLE PLACEHOLDER CASE
  const singlePlaceholderPath = getPlaceholderPath(commonPath, placeholderContentIndex, false);

  if (get(node, singlePlaceholderPath)) return { path: singlePlaceholderPath, placeholdersCount: 1 };

  // THERE IS DOUBLE PLACEHOLDER CASE
  const doublePlaceholderPath = getPlaceholderPath(commonPath, placeholderContentIndex, true);

  if (get(node, doublePlaceholderPath)) return { path: doublePlaceholderPath, placeholdersCount: 2 };

  // THERE IS NO CONTENT OR PLACEHOLDERS COUNT MORE THAN 2

  const errorMessage = `
        Invalid content structure.
        Not found content by path: "${commonPath.join('.')}"
        `;

  console.error(errorMessage, '\nPlaceholderContentIndex:', placeholderContentIndex, '\nNode:', node);

  throw new Error(errorMessage);
};
