import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { SnippetItemBlock } from '../../../custom-inputs';

export type ColumnEditorProps = {
  contentPath: TreePath;
  disabled: boolean;
};

export const ColumnEditor: React.FC<ColumnEditorProps> = ({ contentPath, disabled, children }) => (
  <SnippetItemBlock disabled={disabled} contentPath={contentPath}>
    {children}
  </SnippetItemBlock>
);
