import { Drawer } from 'antd';
import React from 'react';

type SitesFilterProps = {
  open: boolean;
  onClose: () => void;
};

const SitesFilterDrawer: React.FC<SitesFilterProps> = ({ open, onClose }) => {
  return (
    <Drawer title="Фильтры" placement="right" closable={true} onClose={onClose} visible={open}>
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Drawer>
  );
};

export default SitesFilterDrawer;
