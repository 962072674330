import { FilterOutlined } from '@ant-design/icons';
import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Input, Space, Typography } from 'antd';
import React from 'react';
import { useToggle } from 'src/hooks';
import { CreateSiteModalContainer } from '../../../create-site-modal';
import { SitesListHeaderControl, SitesListHeaderControlProps } from './components/control';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const StyledControlsWrapper = styled(Space)`
  line-height: 1;
`;

export type SitesListHeaderProps = SitesListHeaderControlProps & {
  selectedSites: ContentSitesV1Site[];
};

export const SitesListHeader: React.FC<SitesListHeaderProps> = ({ selectedSites, ...props }) => {
  const [createSiteModalIsOpen, { onToggle: openCreateSiteModal, offToggle: closeCreateSiteModal }] = useToggle();

  return (
    <>
      {selectedSites.length ? (
        <SitesListHeaderControl {...props} />
      ) : (
        <StyledWrapper>
          <Typography.Text>Список сайтов</Typography.Text>
          <StyledControlsWrapper>
            <Input.Search
              placeholder="Поиск по таблице"
              allowClear
              enterButton
              onSearch={() => null}
              style={{ width: 300 }}
            />
            <Button type="primary" onClick={openCreateSiteModal}>
              Добавить
            </Button>
            <Button icon={<FilterOutlined />} onClick={() => null} />
          </StyledControlsWrapper>
        </StyledWrapper>
      )}
      <CreateSiteModalContainer open={createSiteModalIsOpen} onClose={closeCreateSiteModal} />
    </>
  );
};
