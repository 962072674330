import { ContentFilesV1File, ContentFilesV1FilesAPIApi } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseGetFileData = {
  fileResponse: ContentFilesV1File | undefined;
  isFileLoading: boolean;
  isFileFetching: boolean;
};

export type GetFileParams = {
  enabled: boolean;
  path?: string;
};

export function useGetFile(
  filesApi: ContentFilesV1FilesAPIApi,
  queryKey: QueryKey,
  params: GetFileParams,
): UseGetFileData {
  const { enabled, path } = params;

  const {
    data: fileResponse,
    isLoading: isFileLoading,
    isFetching: isFileFetching,
  } = useQuery(
    queryKey,
    () =>
      filesApi.contentFilesV1FilesAPIGetFile(
        path ?? '', // filePath
        undefined, // fileRevision
      ),
    { enabled },
  );

  return {
    fileResponse: fileResponse?.data,
    isFileLoading,
    isFileFetching,
  };
}
