import { ContentVolkswagenV1Node, PaginationNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { withSection } from '../../hocs';
import { AddSnippetButton, AttributeInput, AttributesSelect } from '../../custom-inputs';
import { TeaserItem } from '../../node-editors';

const possibleContentViews = [
  { value: PaginationNode.contentView.List, label: 'Список' },
  { value: PaginationNode.contentView.ListPadding, label: 'Список (с отступом)' },
  { value: PaginationNode.contentView.GridTwo, label: 'Две колонки' },
  { value: PaginationNode.contentView.GridTwoPadding, label: 'Две колонки (с отступом)' },
  { value: PaginationNode.contentView.GridThree, label: 'Три колонки' },
];

const paginationPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'pagination'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapItemAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  paginationItem: PaginationNode.Item.generators.entity({
    content: [node],
  }),
});

export const TeaserCustomEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { paginationData } = React.useMemo(() => {
      const paginationData = getPlaceholderedPath({
        node,
        commonPath: paginationPath,
      });

      return { paginationData };
    }, [node]);

    const disabled = paginationData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={paginationData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            Редактируемое
          </Checkbox>
        </Form.Item>

        <Form.Item
          name={[formListFieldData.name, ...paginationData.path, 'attributes']}
          fieldKey={[formListFieldData.fieldKey, ...paginationData.path, 'attributes']}
          label="Вид контента"
        >
          <AttributesSelect
            attributeName={PaginationNode.attributes.contentView}
            disabled={disabled}
            possibleValues={possibleContentViews}
          />
        </Form.Item>

        <Form.Item
          name={[formListFieldData.name, ...paginationData.path, 'attributes']}
          fieldKey={[formListFieldData.fieldKey, ...paginationData.path, 'attributes']}
          label="Ссылка на все элементы"
        >
          <AttributeInput attributeName={PaginationNode.attributes.showAllHref} disabled={disabled} />
        </Form.Item>

        <Form.Item
          name={[formListFieldData.name, ...paginationData.path, 'size']}
          fieldKey={[formListFieldData.fieldKey, ...paginationData.path, 'size']}
          label="Начальное количество элементов"
        >
          <Input type="number" disabled={disabled} />
        </Form.Item>

        <Form.List name={[formListFieldData.name, ...paginationData.path, 'items']}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <TeaserItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  contentPath={[...sectionContentPath, ...paginationData.path, 'items']}
                />
              ))}
              <AddSnippetButton
                add={add}
                mapBeforeAdd={mapItemAdd}
                isDisabled={disabled}
                label="Добавить элемент-превью"
                noStyle
                allowedSnippets={[SnippetName.TeaserItem]}
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
