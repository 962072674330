import React from 'react';
import { Space, Typography } from 'antd';
import { styled } from '../../theme-provider';

const { Text } = Typography;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray1};
  padding: 0 24px;
  line-height: unset;

  &:hover {
    background: ${(props) => props.theme.colors.gray10};
  }
`;

// const LogoIcon = () => (
//   <svg
//     width="24"
//     height="14"
//     viewBox="0 0 24 14"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     style={{ display: 'block' }}
//   >
//     <path
//       d="M12.8136 13.3169V8.70561C12.0279 11.4326 9.51396 13.4274 6.53409 13.4274C2.92541 13.4274 0 10.502 0 6.89327C0 3.28459 2.92541 0.359178 6.53409 0.359178C9.51396 0.359178 12.0279 2.35391 12.8136 5.08093V0.299927H17.7174L17.72 0.307903C21.2173 0.477282 24 3.32287 24 6.8084C24 10.4029 21.0405 13.3169 17.3898 13.3169H12.8136Z"
//       fill="white"
//     />
//   </svg>
// );

const StyledText = styled(Text)`
  color: ${(props) => props.theme.colors.gray1};
`;

export const Logo: React.FC = React.memo(() => {
  return (
    <LogoWrapper>
      <Space align="center">
        {/*<LogoIcon />*/}
        <StyledText strong>CMS Volkswagen</StyledText>
      </Space>
    </LogoWrapper>
  );
});

Logo.displayName = 'Logo';
