import React from 'react';
import { css } from 'styled-components';
import { ControlsProps } from '../../../types';
import { placeholderStyles } from '../entities/placeholder-entity';
import { AlignBlock, alignStyles } from './align-block';
import { BlockquoteBlock, BlockquoteBlockType, blockquoteStyles } from './blockquote-block';
import { HeaderBlockType, HeaderBlock } from './header-block';
import { ThemeBlock, themeStyles } from './theme-block';
import { ListBlock, ListBlockType } from './list-block';

export type BlockType = HeaderBlockType | ListBlockType | BlockquoteBlockType | 'unstyled';

/**
 * Стилизация blocks в редакторе делается с помощью css классов
 * Здесь css классы из всех блоков собираются вместе
 * Подключаются в /elements.ts файле
 */
export const customBlockStyles = css`
  ${alignStyles}
  ${blockquoteStyles}
    ${themeStyles}
    ${placeholderStyles}
`;

export const BlockControls: React.FC<ControlsProps> = React.memo<ControlsProps>((props) => {
  return (
    <>
      <HeaderBlock {...props} />
      <AlignBlock {...props} />
      <ListBlock {...props} />
      <BlockquoteBlock {...props} />
      <ThemeBlock {...props} />
    </>
  );
});

BlockControls.displayName = 'BlockControls';
