import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { ContentMediaV1Media } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { getPathWithFileEndpoint } from '@console/api';
import { styled } from '@console/uikit';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { getFileAttribute, getFilePath } from 'src/utils/entities';
import { sortMediaFiles } from '../../../utils/files';
import { isPdf, isVideo } from '../../../utils/media';
import { FilesExtensionTableCell, FilesSizeTableCell, FilesTimeTableCell } from '../../table';
import { MediaStatus, Progresses, Statuses } from '../../../contexts/media-context';
import { MediaNameTableCell } from '../../table/media-name-table-cell';
import { MediaTableActions, MediaTableActionsProps } from '../../table';
import { VideoPreviewModal } from '../video-modal';

type TableFile = Pick<ContentFilesV1File, 'filesV1Folder' | 'mediaV1Media'>;

const StyledTableWrapper = styled.div`
  .row-media {
    &:hover {
      cursor: pointer;
    }
  }

  .row-active {
    background: ${(props) => props.theme.colors.gray3};
  }
`;

export type MediaTableProps = {
  files: TableFile[];
  progresses: Progresses;
  statuses: Statuses;
  loading: boolean;
  addBreadcrumb: MediaTableActionsProps['addBreadcrumb'];
  removeMedia: MediaTableActionsProps['removeFile'];
  path?: string;
  onSelectFile?: (path: string) => void;
};

export const MediaTable: React.FC<MediaTableProps> = ({
  files,
  progresses,
  loading,
  addBreadcrumb,
  removeMedia,
  path,
  onSelectFile,
}) => {
  const [previewMedia, setPreviewMedia] = useState<ContentMediaV1Media>();
  const sortedContents = useMemo(() => sortMediaFiles(files), [files]);
  const [currentPage, setCurrentPage] = useState<number>();

  useEffect(() => {
    setCurrentPage(1);
  }, [sortedContents]);

  useEffect(() => {
    if (previewMedia && isPdf(previewMedia)) {
      window.open(getPathWithFileEndpoint(previewMedia.path));
      setPreviewMedia(undefined);
    }
  }, [previewMedia]);

  const columns: ColumnsType<TableFile> = [
    {
      title: 'Название',
      key: 'name',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => (
        <MediaNameTableCell file={record} previewMedia={previewMedia} setPreviewMedia={setPreviewMedia} />
      ),
    },
    {
      title: 'Дата добавления',
      key: 'time',
      render: (_, record) => <FilesTimeTableCell file={record} />,
    },
    {
      title: 'Размер',
      key: 'size',
      render: (_, record) => <FilesSizeTableCell file={record} progresses={progresses} />,
    },
    {
      title: 'Формат',
      key: 'ext',
      render: (_, record) => <FilesExtensionTableCell file={record} />,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <MediaTableActions
          file={record}
          addBreadcrumb={addBreadcrumb}
          removeFile={removeMedia}
          setPreviewMedia={setPreviewMedia}
        />
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      <Table
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        rowKey={(row) => getFilePath(row)!}
        dataSource={sortedContents}
        columns={columns}
        rowClassName={(file, rowIndex) => {
          let className = '';
          if (file?.mediaV1Media?.path && onSelectFile) {
            className = 'row-media';
          }
          if (path && file?.mediaV1Media?.path === path) {
            className = className + ' row-active';
          }

          return className;
        }}
        onRow={(file, rowIndex) => ({
          onClick() {
            const mediaStatus = getFileAttribute(file, 'status');
            if (
              file?.mediaV1Media?.path &&
              (!mediaStatus || mediaStatus === `${MediaStatus.Uploaded}`) &&
              onSelectFile
            ) {
              onSelectFile(file.mediaV1Media.path);
            }
          },
        })}
        pagination={{
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
        sticky
        loading={loading}
      />
      <VideoPreviewModal
        open={isVideo(previewMedia)}
        onClose={() => setPreviewMedia(undefined)}
        src={getPathWithFileEndpoint(previewMedia?.path ?? '')}
      />
    </StyledTableWrapper>
  );
};
