import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1CarStockFilter } from '@cms/volkswagen-widgets';
import { Button, Form, Input, Select } from 'antd';
import omit from 'lodash/omit';
import React from 'react';
import { FormListItemContainer } from 'src/components/editors/custom-inputs';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type FilterEqualityInputProps = {
  value?: ContentVolkswagenV1CarStockFilter['filter'];
  onChange?: (value: ContentVolkswagenV1CarStockFilter['filter']) => void;
  handleError: (errorMessage?: string) => void;
  disabled: boolean;
  possibleAttributeNames?: { label: string; value: string }[];
};

export const FilterEqualityInput: React.FC<FilterEqualityInputProps> = ({
  value,
  onChange,
  handleError,
  disabled,
  possibleAttributeNames,
}) => {
  const [errorState, setErrorState] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (errorState.length > 0) return handleError('Наличие дублирующих фильтров недопустимо');

    return handleError();
  });

  if (!value || !onChange) return null;

  const attributesName = Object.keys(value);

  const handleAttributeNameChange = (prevName: string, name: string, index: number) => {
    const prevValue = value[prevName];

    if (name !== prevName && attributesName.includes(name)) {
      setErrorState([...errorState, index]);

      return;
    }

    setErrorState(errorState.filter((i) => i !== index));

    onChange({ ...omit(value, prevName), [name]: prevValue });
  };

  const handleAddAttribute = () => {
    onChange({ ...value, '': {} });
  };

  const handleArrayChange = (name: string, key: 'eq' | 'neq') => (arr: string[]) => {
    onChange({ ...value, [name]: { ...value[name], [key]: arr } });
  };

  const handleDeleteAttribute = (name: string, index: number) => {
    setErrorState(errorState.filter((_, i) => i !== index));
    onChange(omit(value, name));
  };

  return (
    <>
      {attributesName.map((name, index) => {
        const isNameError = errorState.includes(index);

        return (
          <FormListItemContainer disabled={disabled} key={index} onDelete={() => handleDeleteAttribute(name, index)}>
            <Form.Item
              label="Параметр"
              required
              validateStatus={isNameError ? 'error' : undefined}
              help={isNameError ? 'Данный аттрибут дублирован' : undefined}
            >
              {possibleAttributeNames ? (
                <Select value={name} onChange={(value) => handleAttributeNameChange(name, value, index)}>
                  {possibleAttributeNames.map((attributeName) => (
                    <Select.Option key={attributeName.value} value={attributeName.value}>
                      {attributeName.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  disabled={disabled}
                  defaultValue={name}
                  onBlur={(e) => handleAttributeNameChange(name, e.target.value, index)}
                />
              )}
            </Form.Item>
            <Form.Item label="Значения">
              <Select
                disabled={disabled}
                value={value[name].eq || []}
                mode="tags"
                onChange={handleArrayChange(name, 'eq')}
              />
            </Form.Item>
          </FormListItemContainer>
        );
      })}
      <Form.Item style={{ marginBottom: '0' }}>
        <Button type="dashed" disabled={disabled} onClick={handleAddAttribute} icon={<PlusOutlined />}>
          Добавить атрибут
        </Button>
      </Form.Item>
    </>
  );
};
