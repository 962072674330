import { ActivityV1Status } from '@cms/volkswagen-widgets';
import { VisibilityV1Status } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { useRedirects } from '@console/api';
import { Form } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { useSitesContext } from '../../contexts/sites-context';
import { EditRedirectFormValues, EditRedirectModal } from './components/edit-redirect-modal';

export type EditRedirectModalContainerProps = {
  open: boolean;
  initialValues?: EditRedirectFormValues;
  onClose: () => void;
};

export const EditRedirectModalContainer: React.FC<EditRedirectModalContainerProps> = ({
  open,
  initialValues,
  onClose,
}) => {
  const { currentSite } = useSitesContext();

  const [form] = Form.useForm<EditRedirectFormValues>();

  React.useEffect(() => {
    if (open) {
      if (initialValues) {
        form.setFieldsValue(initialValues);
      } else {
        form.resetFields();
        form.setFieldsValue({ weight: 100 });
      }
    }
  }, [open, initialValues, form]);

  const {
    meta: { isCreateLoading, isUpdateLoading },
    operations: { createRedirectAsync, updateRedirectAsync },
  } = useRedirects({ enabled: false, site: currentSite?.name || '' });

  const handleSubmit = (values: Omit<EditRedirectFormValues, 'name'>) => {
    if (initialValues) {
      updateRedirectAsync({
        name: initialValues.name,
        body: {
          ...values,
          name: initialValues.name,
          activity: { status: ActivityV1Status.Active },
          visibility: {
            status: VisibilityV1Status.Public,
          },
        },
      })
        .then(() => {
          onClose();
        })
        .catch(() => null);
    } else {
      createRedirectAsync({
        ...values,
        name: uuidv4(),
        activity: { status: ActivityV1Status.Active },
        visibility: {
          status: VisibilityV1Status.Public,
        },
      })
        .then(() => {
          onClose();
        })
        .catch(() => null);
    }
  };

  return (
    <EditRedirectModal
      open={open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
