import { snippetGenerators } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ValidationConst } from 'src/utils/validation';
import { AddEnrichedNodeButton } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { ContactsFocusLayerTab } from './components/contacts-focus-layer-tab';

const initialTab = snippetGenerators.ContactFocusLayer.tab({
  accordions: [],
  tabTitle: [],
  title: [],
});

const modalPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'modal'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const ContactsFocusLayerEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { modalData, titleRichtextData, tabsItemsData } = React.useMemo(() => {
    // modalName
    const modalData = getPlaceholderedPath({
      node,
      commonPath: modalPath,
    });
    // title
    const titleRichtextData = getPlaceholderedPath({
      node,
      commonPath: [...modalData.path, 'content', 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });

    // tabs
    const tabsItemsData = getPlaceholderedPath({
      node,
      commonPath: [...modalData.path, 'content', 0, 'flex', 'content', 1, 'tabs', 'items'],
    });

    return { modalData, titleRichtextData, tabsItemsData };
  }, [node]);

  const disabled = modalData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={modalData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек">
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      {/* modalName */}
      <Form.Item
        label="Имя формы"
        name={[...modalData.path, 'name']}
        rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {/* title */}
      <RichtextFormItem label="Заголовок" contentFieldName={titleRichtextData.path} disabled={disabled} />

      {/* contacts */}
      <Form.List name={tabsItemsData.path}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <ContactsFocusLayerTab
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={tabsItemsData.path}
              />
            ))}
            <AddEnrichedNodeButton add={add} nodeToEnrich={initialTab} isDisabled={disabled} label="Добавить таб" />
          </>
        )}
      </Form.List>
    </Form>
  );
};
