import { styled } from '@console/uikit';
import { Button } from 'antd';
import React from 'react';

const Container = styled.div`
  display: flex;
  justify-content: end;
`;

export type CustomFooterProps = {
  onOkClick: () => void;
  onCancelClick: () => void;
  isOkLoading: boolean;
};

export const CustomFooter: React.FC<CustomFooterProps> = ({ onCancelClick, onOkClick, isOkLoading }) => (
  <Container>
    {/*<Button type="text" icon={<DeleteFilled />} onClick={onDeleteClick} danger>*/}
    {/*  Удалить страницу*/}
    {/*</Button>*/}
    <div>
      <Button onClick={onCancelClick}>Отмена</Button>
      <Button type="primary" loading={isOkLoading} onClick={onOkClick}>
        Сохранить
      </Button>
    </div>
  </Container>
);
