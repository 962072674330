import { AttributesV1Attribute } from '@cms/volkswagen-widgets';
import { Input, InputProps, Select, SelectProps } from 'antd';
import React from 'react';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type AttributeInputProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  attributeName: string;
  disabled: boolean;
  /**
   * - `false` working with the first element of an array (`attribute.values[0]`)
   * - `true` working with the whole array (`attribute.values`)
   */
  multiply?: boolean;
  type?: InputProps['type'];
};

/**
 * Input looks for an attribute by name. Creates it when it is not null value.
 * Removes attribute if it is null value. Input works only with first attribute string value.
 * @example [{ name: 'attributeName', values: ['not null value'] }]
 */
export const AttributeInput: React.FC<AttributeInputProps> = ({
  value = [],
  onChange,
  attributeName,
  disabled,
  multiply = false,
  type,
}) => {
  const attribute = value.find(({ name }) => name === attributeName);
  const values = attribute?.values ?? [];
  const firstValue = values[0] ?? '';

  const handleChangeInput: InputProps['onChange'] = (event) => {
    if (onChange) {
      const newValue = value.filter(({ name }) => name !== attributeName);

      onChange([...newValue, ...(event.target.value ? [{ name: attributeName, values: [event.target.value] }] : [])]);
    }
  };

  const handleChangeSelect: SelectProps<string[]>['onChange'] = (newValues) => {
    if (onChange) {
      onChange(
        attribute
          ? value.map((attr) => (attr.name === attributeName ? { name: attributeName, values: newValues } : attr))
          : [...value, { name: attributeName, values: newValues }],
      );
    }
  };

  return multiply ? (
    <Select mode="tags" disabled={disabled} onChange={handleChangeSelect} value={values} />
  ) : (
    <Input value={firstValue} onChange={handleChangeInput} disabled={disabled} type={type} />
  );
};
