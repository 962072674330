import { DeleteFilled, PlusOutlined, EditFilled, EyeFilled, PlayCircleOutlined } from '@ant-design/icons';
import { d6 } from '@cms/volkswagen-widgets';
import { Button, Image, Space } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { MediaModalContainer, MediaModalContainerProps } from '../../../containers';
import { getPathWithFileEndpoint } from '@console/api';
import { styled } from '@console/uikit';
import { mediaFallback } from '../../common';
import { VideoPreviewModal } from '../../media/video-modal';

const { isAbsoluteUrl } = d6;

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type MediaPickerProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  mediaType?: MediaModalContainerProps['mediaType'];
};

const StyledUploadButton = styled(Button)`
  width: 200px;
  height: 200px;
`;

const StyledControls = styled.div`
  gap: 5px;
  display: flex;
  transition: all 0.3s;

  * {
    color: ${(props) => props.theme.colors.gray1};
  }
`;

const StyledImage = styled(Image)`
  width: 200px;
  height: 200px;
  object-fit: cover;

  & + .ant-image-mask {
    cursor: default;
  }
`;

const StyledControlsWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${StyledControls} {
    opacity: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);

    ${StyledControls} {
      opacity: 1;
    }
  }
`;

const StyledVideoWrapper = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  position: relative;
`;

const StyledPlayCircleOutlined = styled(PlayCircleOutlined)`
  color: #8c8c8c;
  font-size: 80px;
`;

export const MediaPicker: React.FC<MediaPickerProps> = ({ value, onChange, disabled, mediaType = 'image' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleSave = useCallback(
    (value: string) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const handleErrorImage = () => {
    onChange && onChange('');
  };

  const controls = useMemo(
    () => (
      <StyledControls>
        <Button disabled={disabled} type="text" shape="circle" icon={<EyeFilled />} onClick={() => setVisible(true)} />
        <Button
          disabled={disabled}
          type="text"
          shape="circle"
          icon={<EditFilled />}
          onClick={() => setIsModalOpen(true)}
        />
        <Button disabled={disabled} type="text" shape="circle" icon={<DeleteFilled />} onClick={() => handleSave('')} />
      </StyledControls>
    ),
    [disabled, handleSave],
  );

  return (
    <>
      {value ? (
        <Space align="start" direction="vertical">
          {mediaType === 'video' && (
            <>
              <StyledVideoWrapper>
                <StyledPlayCircleOutlined />
                <StyledControlsWrapper>{controls}</StyledControlsWrapper>
              </StyledVideoWrapper>
              <VideoPreviewModal
                open={visible}
                onClose={() => setVisible(false)}
                src={value && (isAbsoluteUrl(value) ? value : getPathWithFileEndpoint(value))}
              />
            </>
          )}
          {mediaType === 'image' && (
            <StyledImage
              src={isAbsoluteUrl(value) ? value : getPathWithFileEndpoint(value)}
              fallback={mediaFallback}
              onError={handleErrorImage}
              placeholder={
                <StyledControls>
                  <Button
                    disabled={disabled}
                    type="text"
                    shape="circle"
                    icon={<EyeFilled />}
                    onClick={() => setVisible(true)}
                  />
                  <Button
                    disabled={disabled}
                    type="text"
                    shape="circle"
                    icon={<EditFilled />}
                    onClick={() => setIsModalOpen(true)}
                  />
                  <Button
                    disabled={disabled}
                    type="text"
                    shape="circle"
                    icon={<DeleteFilled />}
                    onClick={() => handleSave('')}
                  />
                </StyledControls>
              }
              preview={{
                visible,
                onVisibleChange: (value) => {
                  if (value === false) {
                    setVisible(value);
                  }
                },
                mask: controls,
              }}
            />
          )}
        </Space>
      ) : (
        <StyledUploadButton type="dashed" block onClick={() => setIsModalOpen(true)} disabled={disabled}>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Добавить медиа</div>
        </StyledUploadButton>
      )}
      <MediaModalContainer
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        {...(isAbsoluteUrl(value || '') ? { url: value } : { path: value })}
        mediaType={mediaType}
      />
    </>
  );
};
