import { ContentSitesV1Site, ContentSitesV1SitesAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Site>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { siteName: string };
type Context = { prevGetSiteResponse?: ContentSitesV1Site };

export type UsePublishSiteData = {
  publishSite: UseMutateFunction<Data, Error, Variables>;
  publishSiteAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isPublishLoading: boolean;
};

export function usePublishSite(
  sitesApi: ContentSitesV1SitesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UsePublishSiteData {
  const queryClient = useQueryClient();

  const {
    mutate: publishSite,
    mutateAsync: publishSiteAsync,
    isLoading: isPublishLoading,
  } = useMutation<Data, Error, Variables, Context>(
    ({ siteName }) =>
      sitesApi.contentSitesV1SitesAPIPublishSite(
        siteName, // siteName: string,
        // siteRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка изменения сайта',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { publishSite, publishSiteAsync, isPublishLoading };
}
