import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';
import { getFilePath, getFileTitle } from 'src/utils/entities';
import { getFileIcon, StyledTitleContainer } from '../common';

type FilesNameTableCellProps = {
  file: ContentFilesV1File;
};

const getDescription = (file: ContentFilesV1File) => {
  return /*{getLastPathParam(getFilePath(file) || '')}*/ getFilePath(file) || '';
};

export const FilesTitleTableCell: React.FC<FilesNameTableCellProps> = ({ file }) => {
  const icon = getFileIcon(file);

  return (
    <Space size="middle">
      {icon}
      <StyledTitleContainer>
        <Typography.Text>{getFileTitle(file)}</Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 10 }}>
          {getDescription(file)}
        </Typography.Text>
      </StyledTitleContainer>
    </Space>
  );
};
