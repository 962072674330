import { PlusOutlined } from '@ant-design/icons';
import { SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Button, Form, Space } from 'antd';
import { AddSnippetButton, SnippetItemBlock } from 'src/components/editors/custom-inputs';
import { GetPlaceholderedPathResult, TreePath } from 'src/utils/content-path';
import { getInitialAdditionalLink, getInitialMainLink, getInitialMenuItem, getInitialMenuLink } from '../../generators';
import { TopMenuFlyoutItem } from '../top-menu-flyout-item';

type TopMenuTabStructureProps = {
  disabled: boolean;
  topMenuData: GetPlaceholderedPathResult;
};

export const TopMenuTabStructure: React.FC<TopMenuTabStructureProps> = ({ disabled, topMenuData }) => {
  const firstItemPath: TreePath = [...topMenuData.path, 'items', 0];

  return (
    <>
      {/* promoArea edit */}
      <Form.List name={[...firstItemPath, 'topMenuItem', 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <SnippetItemBlock
                key={field.key}
                disabled={disabled}
                onDelete={() => remove(field.name)}
                contentPath={[...firstItemPath, 'topMenuItem', 'content', 0]}
              >
                <Alert message="[Уровень 1] Промо-область" type="info" />
              </SnippetItemBlock>
            ))}
            {/* only one promo area can be added */}
            {fields.length === 0 && (
              <AddSnippetButton
                add={add}
                isDisabled={disabled}
                label="Добавить промо-область (1)"
                allowedSnippets={[SnippetName.TopMenuPromoArea]}
              />
            )}
          </>
        )}
      </Form.List>

      {/* items, mainLinks, additionalLinks edit */}
      <Form.List name={[...firstItemPath, 'topMenuItem', 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <TopMenuFlyoutItem
                key={field.key}
                disabled={disabled}
                field={field}
                index={index}
                move={move}
                remove={remove}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                itemsPath={[...firstItemPath, 'topMenuItem', 'items']}
                menuLevel={1}
              />
            ))}

            <Space direction="horizontal">
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(getInitialMenuLink())}
                icon={<PlusOutlined />}
              >
                Добавить ссылку в меню (1)
              </Button>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(getInitialMenuItem())}
                icon={<PlusOutlined />}
              >
                Добавить переход в меню (1)
              </Button>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(getInitialMainLink())}
                icon={<PlusOutlined />}
              >
                Добавить главную ссылку в нижний блок (1)
              </Button>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(getInitialAdditionalLink())}
                icon={<PlusOutlined />}
              >
                Добавить ссылку в нижний блок (1)
              </Button>
            </Space>
          </>
        )}
      </Form.List>
    </>
  );
};
