import { ContentSitesV1FilesAPIApi, ContentSitesV1ListFilesResponse } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseListContentData = {
  siteFilesResponse: ContentSitesV1ListFilesResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type ListSiteFilesParams = {
  onSuccessGet?: (sites: ContentSitesV1ListFilesResponse) => void;
  enabled: boolean;
  siteName?: string;
  path?: string[];
  kindEq?: string[];
};

export function useListSiteFiles(
  sitesApi: ContentSitesV1FilesAPIApi,
  queryKey: QueryKey,
  params: ListSiteFilesParams,
): UseListContentData {
  const { enabled, siteName, path, kindEq, onSuccessGet } = params;

  const {
    data: siteFilesResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      sitesApi
        .contentSitesV1FilesAPIListFiles(
          siteName ?? '', // siteName: string,
          undefined, // siteRevision?: string,
          undefined, // activityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // activityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // pageSize?: number,
          undefined, // pageToken?: string,
          path, // pathEq?: Array<string>,
          undefined, // pathNeq?: Array<string>,
          undefined, // _public?: boolean,
          undefined, // search?: string,
          undefined, // sort?: Array<'FILE_SORT_NONE' | 'FILE_SORT_PATH_ASC' | 'FILE_SORT_PATH_DESC' | 'FILE_SORT_TIME_ASC' | 'FILE_SORT_TIME_DESC' | 'FILE_SORT_TYPE_ASC' | 'FILE_SORT_TYPE_DESC'>,
          kindEq, // kindEq?: Arra
          // kindNeq?: Array<string>,
          // options?: any
        )
        .then((resp) => resp.data),
    {
      enabled,
      onSuccess: (sites) => {
        onSuccessGet && onSuccessGet(sites);
      },
    },
  );

  return {
    siteFilesResponse,
    isListLoading,
    isListFetching,
  };
}
