import {
  ContentBlock,
  ContentState,
  DraftEntityType,
  DraftInlineStyle,
  EditorState,
  EntityInstance,
  Modifier,
  RichUtils,
} from 'draft-js';

export const toggleInlineStyle = <T extends string>(style: T, editorState: EditorState): EditorState => {
  return RichUtils.toggleInlineStyle(editorState, style);
};

export const toggleInlineStyles = <T extends string>(styles: T[], editorState: EditorState): EditorState => {
  return styles.reduce((editorState, style) => {
    return RichUtils.toggleInlineStyle(editorState, style);
  }, editorState);
};

export const removeInlineStyles = <T extends string>(styles: T[], editorState: EditorState): EditorState => {
  const selection = editorState.getSelection();
  const nextContentState = styles.reduce<ContentState>((contentState, style) => {
    return Modifier.removeInlineStyle(contentState, selection, style);
  }, editorState.getCurrentContent());
  return EditorState.push(editorState, nextContentState, 'change-inline-style');
};

type FindEntityType = (type: DraftEntityType) => boolean;
type FindInlineType = (type: DraftInlineStyle, contentBlock: ContentBlock, contentState: ContentState) => boolean;

export const findEntities =
  <T extends string>(entityType: T | FindEntityType) =>
  (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        (typeof entityType === 'function'
          ? entityType(contentState.getEntity(entityKey).getType())
          : contentState.getEntity(entityKey).getType() === entityType)
      );
    }, callback);
  };

export const findInlines =
  <T extends string>(inlineStyle: T | FindInlineType) =>
  (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
    contentBlock.findStyleRanges((character) => {
      const style = character.getStyle();
      return typeof inlineStyle === 'function'
        ? inlineStyle(style, contentBlock, contentState)
        : !character.hasStyle(inlineStyle as T);
    }, callback);
  };

export const isSelectionContainOnlyEntity = (editorState: EditorState): boolean => {
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const endOffset = editorState.getSelection().getEndOffset();
  const contentState = editorState.getCurrentContent();
  const blockWithEntityAtBeginning = contentState.getBlockForKey(startKey);
  let isExist = true;
  for (let i = startOffset; i < endOffset; i += 1) {
    if (!blockWithEntityAtBeginning.getEntityAt(i)) {
      isExist = false;
      break;
    }
  }
  return isExist;
};

/**
 * Функция нужна для пробрасвания имен css классов
 */
export const getBlockStyle = (contentBlock: ContentBlock): string => {
  const blockData = contentBlock.getData().reduce((acc, value, key) => {
    return `${acc ? `${acc} ` : ''}${key}-${value}`;
  }, '');
  return `${contentBlock.getType()}${blockData ? ` ${blockData}` : ''}`;
};

export const getSelectedEntityKey = (editorState: EditorState): string => {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithEntityAtBeginning = contentState.getBlockForKey(startKey);
  return blockWithEntityAtBeginning.getEntityAt(startOffset);
};

export const getExistEntityByType = (
  editorState: EditorState,
  selectedEntityKey: string,
  entityType: string,
): EntityInstance | undefined => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();

  // isSelectionContainOnlyEntity(editorState)

  return selectedEntityKey &&
    contentState.getEntity(selectedEntityKey).getType() === entityType &&
    !selection.isCollapsed()
    ? contentState.getEntity(selectedEntityKey)
    : undefined;
};

export const getSelectedBlocks = (editorState: EditorState): ContentBlock[] => {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const isSameBlock = startKey === endKey;
  const startingBlock = contentState.getBlockForKey(startKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = startKey;

    while (blockKey !== endKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      if (nextBlock) {
        selectedBlocks.push(nextBlock);
        blockKey = nextBlock.getKey();
      }
    }
  }

  return selectedBlocks;
};
