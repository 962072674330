import { DeleteFilled, EditFilled, EyeFilled, RightOutlined } from '@ant-design/icons';
import { ContentFilesV1File, ContentFilesV1Folder } from '@cms/volkswagen-widgets';
import { ContentMediaV1Media } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { Button, Space, Modal, Typography, ButtonProps } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { getLastPathParam } from 'src/utils/string';
import { getFilePath } from '../../utils/entities';

const { confirm } = Modal;
const { Text } = Typography;

const StyledEditFilled = styled(EditFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledRightOutlined = styled(RightOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledEyeFilled = styled(EyeFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export type MediaTableActionsProps = {
  file: ContentFilesV1File;
  addBreadcrumb?: (breadcrumb: string) => void;
  onEditFolder?: (value: ContentFilesV1Folder) => void;
  removeFile?: (path: string) => void;
  setPreviewMedia?: (media: ContentMediaV1Media | undefined) => void;
  // onEditBlueprint: (value: SpacesBlueprint.Blueprint) => void;
};

export const MediaTableActions: React.FC<MediaTableActionsProps> = ({
  file,
  addBreadcrumb,
  onEditFolder,
  removeFile,
  setPreviewMedia,
}) => {
  const handleRemoveMedia: ButtonProps['onClick'] = (e) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: 'Удаление медиа',
      content: (
        <>
          <Text>Вы собираетесь удалить медиа «{getLastPathParam(getFilePath(file) || '')}».</Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить медиа?</Text>
        </>
      ),
      onOk() {
        if (removeFile) {
          return removeFile(file.mediaV1Media?.path || '');
        }
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  // const handleRemoveFolder = () => {
  //   confirm({
  //     title: 'Удаление медиа',
  //     content: (
  //       <>
  //         <Text>Вы собираетесь удалить папку «{getLastPathParam(getFilePath(file) || '')}».</Text>
  //         <br />
  //         <Text>Отменить это действие будет невозможно!</Text>
  //         <br />
  //         <br />
  //         <Text>Удалить папку?</Text>
  //       </>
  //     ),
  //     onOk() {
  //       if (removeFile) {
  //         return removeFile(file.filesV1Folder?.path || '');
  //       }
  //     },
  //     okText: 'Удалить',
  //     okType: 'danger',
  //     cancelText: 'Отменить',
  //   });
  // };

  return (
    <StyledActions>
      {file.filesV1Folder && (
        <>
          {onEditFolder && (
            <Button
              type="text"
              shape="circle"
              icon={<StyledEditFilled />}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onEditFolder(file.filesV1Folder!)}
            />
          )}
          {/*<Button type="text" shape="circle" onClick={handleRemoveFolder} icon={<StyledDeleteFilled />} />*/}
          {addBreadcrumb && (
            <Button
              type="text"
              shape="circle"
              onClick={() => addBreadcrumb(getLastPathParam(file.filesV1Folder?.path || ''))}
              icon={<StyledRightOutlined />}
            />
          )}
        </>
      )}
      {file.mediaV1Media && (
        <>
          {setPreviewMedia && (
            <Button
              type="text"
              shape="circle"
              icon={<StyledEyeFilled />}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPreviewMedia(file.mediaV1Media);
              }}
            />
          )}
          <Button type="text" shape="circle" onClick={handleRemoveMedia} icon={<StyledDeleteFilled />} />
        </>
      )}
    </StyledActions>
  );
};
