import {
  ContentSitesV1Robot,
  ContentSitesV1RobotProps,
  ContentSitesV1RobotsAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Robot>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = ContentSitesV1RobotProps;

export type UseCreateRobotData = {
  createRobot: UseMutateFunction<Data, Error, Variables>;
  createRobotAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateRobot(
  robotsApi: ContentSitesV1RobotsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseCreateRobotData {
  const queryClient = useQueryClient();

  const {
    mutate: createRobot,
    mutateAsync: createRobotAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>(
    (body) =>
      robotsApi.contentSitesV1RobotsAPICreateRobot(
        site, // siteName: string,
        body, // body: ContentSitesV1RobotProps,
        // siteRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка создания робота',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { createRobot, createRobotAsync, isCreateLoading };
}
