import { ContentFilesV1File, ContentSitesV1DomainKind } from '@cms/volkswagen-widgets';
import { useFile, useSiteFiles } from '@console/api';
import { notification } from 'antd';
import React from 'react';
import { PageFileSettingsModal, PageFileSettingsModalProps } from 'src/containers';
import { useBreadcrumbs } from 'src/hooks';
import { getFilePath } from 'src/utils/entities';
import { Breadcrumbs, MainLayout } from '../../../../components';
import { useSitesContext } from '../../contexts/sites-context';
import { useMainTabDomains } from '../main-tab-content/hooks/useMainTabDomains';
import StructureTabHeaderContainer from '../structure-tab-header';
import { StructureTable } from './components';

const StructureTabContentContainer: React.FC = () => {
  const [currentPageSettings, setCurrentPageSettings] = React.useState<ContentFilesV1File>();
  const { domains } = useMainTabDomains();

  const { currentSite } = useSitesContext();
  const [selectedFiles, setSelectedFiles] = React.useState<ContentFilesV1File[]>([]);
  const breadcrumbs = useBreadcrumbs({
    onChange: () => setSelectedFiles([]),
  });

  const {
    meta: { isListLoading, isPublishLoading },
    models: { siteFilesResponse },
    operations: { publishSiteFileAsync },
  } = useSiteFiles({
    siteName: currentSite?.name,
    enabled: Boolean(currentSite?.name),
    path: breadcrumbs.path ? [`/${breadcrumbs.path}/*`] : ['/*'],
    kindEq: [
      'files.v1.folder',
      'volkswagen.v1.page',
      'volkswagen.v1.snippet',
      'files.v1.folderBlueprint',
      'volkswagen.v1.pageBlueprint',
      'volkswagen.v1.snippet',
    ],
  });

  const fileService = useFile({
    enabled: !!currentPageSettings,
    path: `${currentSite?.path.substring(1)}${
      currentPageSettings?.volkswagenV1Page?.path ?? currentPageSettings?.volkswagenV1PageBlueprint?.path
    }`,
  });

  const handleClearSelection = () => setSelectedFiles([]);

  const handleAddBreadcrumb = (breadcrumb: string) => {
    breadcrumbs.addBreadcrumb(breadcrumb);
    handleClearSelection();
  };

  const handleLinkToDomain = (domainKind: ContentSitesV1DomainKind) => (file: ContentFilesV1File) => {
    const domain = domains.find((domain) => domain.kind === domainKind);

    if (!domain) return notification.warning({ message: 'Настройки домена отсутствуют' });

    window.open(`//${domain.host}${getFilePath(file)}`, '_blank');
  };

  if (!currentSite) {
    return null;
  }

  const handlePublishClick = () => {
    Promise.all(
      selectedFiles.map((file) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        publishSiteFileAsync({ siteName: currentSite.name, filePath: getFilePath(file)!.substring(1) }),
      ),
    ).then(() => notification.success({ message: 'Файлы успешно опубликованы' }));
  };

  const handlePageSettingsClick = (file: ContentFilesV1File) => setCurrentPageSettings(file);
  const handleClosePageSettingsModal = () => setCurrentPageSettings(undefined);

  const handleUpdatePage: PageFileSettingsModalProps['updatePage'] = async ({ path, props, onSuccess }) => {
    await fileService.operations.updateFileAsync(
      {
        filePath: path,
        body: {
          [currentPageSettings?.volkswagenV1Page ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: {
            ...props,
          },
        },
      },
      { onSuccess },
    );
  };

  return (
    <MainLayout
      header={
        <StructureTabHeaderContainer
          selectedFiles={selectedFiles}
          onClearSelection={handleClearSelection}
          onPublishClick={handlePublishClick}
          isPublishLoading={isPublishLoading}
          onSearch={() => null}
        />
      }
    >
      <Breadcrumbs {...breadcrumbs} homeTitle={currentSite?.name || ''} />
      <StructureTable
        files={siteFilesResponse?.files || []}
        loading={isListLoading}
        addBreadcrumb={handleAddBreadcrumb}
        selectedFiles={selectedFiles}
        onSelectedFilesChange={setSelectedFiles}
        onPageSettingsClick={handlePageSettingsClick}
        currentSite={currentSite}
        onMainDomainView={handleLinkToDomain(ContentSitesV1DomainKind.Main)}
        onPreviewDomainView={handleLinkToDomain(ContentSitesV1DomainKind.Preview)}
      />
      <PageFileSettingsModal
        pageData={
          fileService.models.fileResponse?.volkswagenV1Page ||
          fileService.models.fileResponse?.volkswagenV1PageBlueprint
        }
        updatePage={handleUpdatePage}
        isLoading={fileService.meta.isCreateLoading}
        isUpdateLoading={fileService.meta.isUpdateLoading}
        isVisible={!!currentPageSettings}
        type={PageFileSettingsModal.type.Publication}
        onCancel={handleClosePageSettingsModal}
        isBlueprint={!!fileService.models.fileResponse?.volkswagenV1PageBlueprint}
      />
    </MainLayout>
  );
};

export default StructureTabContentContainer;
