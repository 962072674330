import { d6, ModalsContextProvider } from '@cms/volkswagen-widgets';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from 'src/components/common';
import { useFile } from '@console/api';
import { useNodesToComponents } from '../../hooks/use-nodes-to-components';
import { GlobalStyle } from '../page-detail/global-styles';

const { IntegratorRoot, ThemeProvider } = d6;

export const PagePreview: React.FC = () => {
  const { path } = useParams<{ path: string }>();

  const {
    models: { fileResponse },
    meta: { isFileLoading },
  } = useFile({ enabled: true, path });
  const page = fileResponse?.volkswagenV1Page || fileResponse?.volkswagenV1PageBlueprint;

  const components = useNodesToComponents(page?.content);

  // loader
  if (isFileLoading) {
    return <CenteredSpinner />;
  }

  // 404
  if (!page) {
    return <div>Page "{path}" doesn't exist</div>;
  }

  return (
    <ModalsContextProvider value={{ openModals: [], closeModal: () => null }}>
      <GlobalStyle />
      <IntegratorRoot>
        <ThemeProvider theme="main">{components}</ThemeProvider>
      </IntegratorRoot>
    </ModalsContextProvider>
  );
};
