import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';

const commonImagePath: TreePath = [
  'blueprint',
  'content',
  0,
  'stack',
  'content',
  0,
  PLACEHOLDER_KEY_PATH,
  'box',
  'content',
  'image',
];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'stack', 'content', 0, 'placeholder'];

export const FullscreenStageEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const imageData = getPlaceholderedPath({
      node,
      commonPath: commonImagePath,
    });

    const disabled = imageData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={imageData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование контента">
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <ImageNodeInputs
          formListFieldData={formListFieldData}
          imagePath={imageData.path}
          disabled={disabled}
          required
        />

        <RichtextFormItem
          label="Контент"
          contentFieldName={[
            formListFieldData.name,
            'blueprint',
            'content',
            0,
            'stack',
            'content',
            1,
            'flex',
            'content',
            0,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ]}
          disabled={false}
        />
      </>
    );
  },
);
