import { VideoNode, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Row, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { MediaPicker, ImageStylesSelect, ThemeCheckbox, StyledImageStylesSelectItem } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { StatusArea } from '../../node-editors';

export const VideoEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const videoData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'video'],
  });
  const disabledVideoData = videoData.placeholdersCount === 0;

  const handlePlaceholderVideoChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          defaultChecked={videoData.placeholdersCount === 2}
          disabled={disabledVideoData}
          onChange={(e) => handlePlaceholderVideoChange(e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
          >
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label="Видео"
        name={[formListFieldData.name, ...videoData.path, 'source']}
        fieldKey={[formListFieldData.fieldKey, ...videoData.path, 'source']}
        rules={[{ required: true }]}
      >
        <MediaPicker disabled={disabledVideoData} mediaType="video" />
      </Form.Item>
      <StatusArea
        name={[...videoData.path, 'visibility']}
        formListFieldData={formListFieldData}
        checkboxProps={{
          truthyValue: VisibilityV1Status.Public,
          falsyValue: VisibilityV1Status.Private,
        }}
      >
        Видимость
      </StatusArea>
      <Form.Item
        label="Изображение-превью"
        name={[formListFieldData.name, ...videoData.path, 'poster']}
        fieldKey={[formListFieldData.fieldKey, ...videoData.path, 'poster']}
      >
        <MediaPicker disabled={disabledVideoData} />
      </Form.Item>
      <Form.Item label="Опции">
        <Row gutter={10}>
          <Form.Item name={[formListFieldData.name, ...videoData.path, 'themes']}>
            <ThemeCheckbox
              themeName={VideoNode.themes.AutoPlay}
              disabled={disabledVideoData}
              label="Автовоспроизведение"
            />
          </Form.Item>
          <Form.Item name={[formListFieldData.name, ...videoData.path, 'themes']}>
            <ThemeCheckbox
              themeName={VideoNode.themes.HideTimeLine}
              disabled={disabledVideoData}
              label="Без таймлайна"
            />
          </Form.Item>
          <Form.Item name={[formListFieldData.name, ...videoData.path, 'themes']}>
            <ThemeCheckbox themeName={VideoNode.themes.Loop} disabled={disabledVideoData} label="Зацикленное" />
          </Form.Item>
          <Form.Item name={[formListFieldData.name, ...videoData.path, 'themes']}>
            <ThemeCheckbox
              themeName={VideoNode.themes.SoundControl}
              disabled={disabledVideoData}
              label="Управление громкости"
            />
          </Form.Item>
        </Row>
      </Form.Item>
      <StyledImageStylesSelectItem
        label="Настройки отображения"
        name={[formListFieldData.name, ...videoData.path, 'styles']}
        fieldKey={[formListFieldData.fieldKey, ...videoData.path, 'styles']}
      >
        <ImageStylesSelect withoutSize disabled={disabledVideoData} />
      </StyledImageStylesSelectItem>
    </>
  );
});
