import { CopyOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Snippet } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { Button, Modal, notification, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { UseUpdateFileData } from '@console/api';

type SnippetContentPreviewModalProps = {
  onCancel: () => void;
  isVisible: boolean;
  snippet?: ContentVolkswagenV1Snippet;
  isLoading: boolean;
  isUpdateLoading: boolean;
  updateFileAsync: UseUpdateFileData['updateFileAsync'];
};

export const SnippetContentModalPreview: React.FC<SnippetContentPreviewModalProps> = ({
  onCancel,
  isVisible,
  snippet,
  updateFileAsync,
  isLoading,
  isUpdateLoading,
}) => {
  const [json, setJson] = useState('');

  useEffect(() => {
    setJson(JSON.stringify(snippet));
  }, [snippet]);

  const updateSnippet = () => {
    if (snippet) {
      const data: ContentVolkswagenV1Snippet = JSON.parse(json);
      updateFileAsync({
        filePath: snippet?.path.substring(1),
        body: {
          volkswagenV1Snippet: {
            ...snippet,
            content: data?.content,
          },
        },
      }).then(() => {
        notification.success({
          message: 'Изменения сохранены',
        });
      });
    }
  };

  const copyToClipboard = () => {
    const data = JSON.parse(json);
    const blueprint = {
      blueprint: {
        source: data?.path || '',
        content: data?.content || [],
      },
    };

    navigator.clipboard.writeText(JSON.stringify(blueprint, null, 4)).catch(() => null);
  };

  const handleChange: ReactJsonViewProps['onEdit'] = (edit) => {
    setJson(JSON.stringify(edit.updated_src));
  };

  return (
    <Modal visible={isVisible} width="80vw" onCancel={onCancel} footer={null} destroyOnClose>
      <Space style={{ marginBottom: 24 }}>
        <Button icon={<CopyOutlined />} type="dashed" loading={isUpdateLoading || isLoading} onClick={copyToClipboard}>
          Скопировать blueprint
        </Button>
        <Button key="submit" type="primary" loading={isUpdateLoading || isLoading} onClick={updateSnippet}>
          Обновить
        </Button>
      </Space>
      <Spin spinning={isLoading}>
        <ReactJson
          src={JSON.parse(json || '{}')}
          onEdit={handleChange}
          onAdd={handleChange}
          onDelete={handleChange}
          name={false}
        />
      </Spin>
    </Modal>
  );
};
