/** https://stackoverflow.com/questions/49401866/all-possible-keys-of-an-union-type */
export type KeysOfUnion<T> = T extends T ? keyof T : never;

export const PossibleColors = {
  'white-000': '#ffffff',
  'black-000': '#000000',
  'grey-100': '#DFE4E8',
  'grey-200': '#C2CACF',
  'grey-300': '#96A3A8',
  'grey-400': '#6A767D',
  'grey-500': '#3C484D',
  'blue-100': '#4CC7F4',
  'blue-200': '#00B0F0',
  'blue-350': '#0077CC',
  'blue-500': '#00437A',
  'blue-600': '#001E50',
  'blue-900': '#0040C5',
  'mint-100': '#00E6E6',
  'red-100': '#FF335C',
  'red-200': '#E4002C',
  'purple-100': '#5B08A4',
  'green-100': '#ADE5A1',
  'green-200': '#008437',
  'yellow-000': '#FFD100',
  transparent: 'transparent',
  'black-000-transparent-10': 'rgba(0, 0, 0, 0.1)',
  'black-000-transparent-50': 'rgba(0, 0, 0, 0.5)',
  'black-000-transparent-80': 'rgba(0, 0, 0, 0.8)',
  'grey-100-transparent-40': 'rgba(223, 228, 232, 0.4)',
  'grey-200-transparent-20': 'rgba(194, 202, 207, 0.2)',
  'white-000-transparent-20': 'rgba(255, 255, 255, 0.2)',
  'white-000-transparent-40': 'rgba(255, 255, 255, 0.4)',
  'white-000-transparent-98': 'rgba(255, 255, 255, 0.98)',
} as const;
