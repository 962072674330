import { ContentSitesV1ListRedirectsResponse } from '@cms/volkswagen-widgets';
import { redirectsApi } from '../../../const';
import { Service } from '../../../interfaces';
import { useCreateRedirect, UseCreateRedirectData } from './actions/useCreateRedirect';
import { GetRedirectsParams, useGetRedirects } from './actions/useGetRedirects';
import { useRemoveRedirect, UseRemoveRedirectData } from './actions/useRemoveRedirect';
import { useUpdateRedirect, UseUpdateRedirectData } from './actions/useUpdateRedirect';

type Models = {
  redirectsResponse: ContentSitesV1ListRedirectsResponse | undefined;
};

type Operations = {
  createRedirect: UseCreateRedirectData['createRedirect'];
  createRedirectAsync: UseCreateRedirectData['createRedirectAsync'];
  updateRedirect: UseUpdateRedirectData['updateRedirect'];
  updateRedirectAsync: UseUpdateRedirectData['updateRedirectAsync'];
  removeRedirect: UseRemoveRedirectData['removeRedirect'];
  removeRedirectAsync: UseRemoveRedirectData['removeRedirectAsync'];
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
  isRemoveLoading: boolean;
};

type Parameters = GetRedirectsParams;

export type RedirectsService = Service<Models, Operations, Meta>;

export const useRedirects = (params: Parameters): RedirectsService => {
  const { site } = params;
  const queryKey = ['redirects', site];

  const { isListFetching, isListLoading, redirectsResponse } = useGetRedirects(redirectsApi, queryKey, params);

  const { isCreateLoading, createRedirect, createRedirectAsync } = useCreateRedirect(redirectsApi, queryKey, site);
  const { isUpdateLoading, updateRedirect, updateRedirectAsync } = useUpdateRedirect(redirectsApi, queryKey, site);
  const { isRemoveLoading, removeRedirect, removeRedirectAsync } = useRemoveRedirect(redirectsApi, queryKey, site);

  return {
    models: {
      redirectsResponse,
    },
    operations: {
      createRedirect,
      createRedirectAsync,
      updateRedirect,
      updateRedirectAsync,
      removeRedirect,
      removeRedirectAsync,
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isUpdateLoading,
      isRemoveLoading,
    },
  };
};
