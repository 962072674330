import { ContentVolkswagenV1Node, GridNode, SnippetName, TabsNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { CommonTabsItem } from '../../node-editors';

const mapTabsAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  tabsItem: TabsNode.Tab.generators.entity({
    content: [
      GridNode.generators.node({
        themes: [GridNode.themes.themePaddingTop],
        styles: [
          {
            columns: [{ width: '100%' }],
          },
        ],
        content: [node],
      }),
    ],
    header: [],
  }),
});

const disallowedSnippets: AddSnippetButtonProps['disallowedSnippets'] = [
  SnippetName.FullscreenBanner,
  SnippetName.TabsSection,
  SnippetName.GalleryColumn,
  SnippetName.GallerySection,
  SnippetName.CopyAccordionSection,
];

export const TabsEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const themeData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'theme'],
  });

  const tabsData = getPlaceholderedPath({
    node,
    commonPath: [...themeData.path, 'content', 0, 'tabs'],
  });

  const disabled = themeData.placeholdersCount === 0;

  const handlePlaceholderChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          defaultChecked={themeData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => handlePlaceholderChange(e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Отступ"
        name={[formListFieldData.name, ...themeData.path, 'styles', 0, 'grid', 'padding', 'top']}
        fieldKey={[formListFieldData.key, ...themeData.path, 'styles', 0, 'grid', 'padding', 'top']}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.List name={[formListFieldData.name, ...tabsData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <CommonTabsItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                tabsContentPath={[...sectionContentPath, ...tabsData.path, 'items']}
                disallowedSnippets={disallowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapTabsAdd}
              isDisabled={disabled}
              label="Добавить таб-итем"
              disallowedSnippets={disallowedSnippets}
            />
          </>
        )}
      </Form.List>
    </>
  );
});
