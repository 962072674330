import { AutocompleteOptionItem } from '@console/uikit';
import { Alert, Button, Form, FormInstance, Modal, Spin } from 'antd';
import React from 'react';
import { PagePathFormItemContainer } from '../../../containers';

export type MoveFormValues = {
  path: AutocompleteOptionItem[];
};

export type MoveModalProps = {
  open: boolean;
  isLoadingSave: boolean;
  form: FormInstance<MoveFormValues>;
  title: string;
  fileTitle: string;
  isLoadingFetch?: boolean;
  isAllowMove?: boolean;
  onClose: () => void;
  onSubmit: (values: MoveFormValues) => void;
};

export const MoveModal: React.FC<MoveModalProps> = ({
  open,
  isLoadingSave,
  form,
  onClose,
  onSubmit,
  title,
  fileTitle,
  isAllowMove = true,
  isLoadingFetch,
}) => {
  return (
    <Modal
      title={`Переместить ${title} «${fileTitle}»`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" loading={isLoadingSave} onClick={form.submit}>
          Переместить
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Spin spinning={isLoadingFetch}>
          {!isAllowMove ? (
            <Alert description={`Нельзя переместить ${title} с содержимым`} type="warning" />
          ) : (
            <Form.Item
              name="path"
              label="в папку"
              rules={[{ required: true, message: 'Это обязательное поле' }]}
              initialValue={[]}
            >
              <PagePathFormItemContainer />
            </Form.Item>
          )}
        </Spin>
      </Form>
    </Modal>
  );
};
