import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { CommonAccordionItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const accordionPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'accordion'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.VacanciesGrid];

export const VacanciesAccordionsEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { accordionData } = React.useMemo(() => {
    const accordionData = getPlaceholderedPath({
      node,
      commonPath: accordionPath,
    });

    return { accordionData };
  }, [node]);

  const disabled = accordionData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={accordionData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.List name={[...accordionData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <CommonAccordionItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                accordionContentPath={[...accordionData.path, 'items']}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={CommonAccordionItem.mapper}
              isDisabled={disabled}
              label="Добавить аккордеон-итем"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
