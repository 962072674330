import { Col, Row, Form, Button, Typography, Radio } from 'antd';
import React, { useCallback, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { useEntity } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { DrawerFormEditor } from '../../../../../drawer-form-editor';
import { ControlButton } from '../../control-button';
import { StyledControl } from '../../../../elements';
import { KindAForm } from './kind-a-form';
import { KindButtonForm, StyledInlineFormItem } from './kind-button-form';
import { LinkEditProps, LinkEntityProps, LinkEntityType } from './types';
import { d6 } from '@cms/volkswagen-widgets';

export * from './types';
export * from './decorator';

const { useForm } = Form;

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} является обязательным параметром!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    url: '${label} - не валидное значение!',
  },
};

const defaultAValues: LinkEditProps = {
  tag: 'a',
  emphasis: 'none',
  content: '',
  href: '',
  theme: 'main',
  iconKind: undefined,
  iconPosition: 'start',
  iconVariant: 'default',
  iconFlow: 'block',
};

const defaultButtonValues: LinkEditProps = {
  tag: 'a',
  emphasis: 'primary',
  size: 'large',
  content: '',
  href: '',
  theme: 'main',
  iconKind: undefined,
  iconPosition: 'start',
  iconVariant: 'default',
  iconFlow: 'block',
  shallow: false,
};

type EditType = d6.CustomCTAProps;

export const LinkEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [createOrEditLink, removeLink, existLinkData, isSelectedEntity, selectedText] = useEntity<
    Omit<EditType, 'as' | 'onClick'>,
    LinkEntityType
  >(editorState, setEditorState, 'LINK');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [kind, setKind] = useState<'button' | 'a'>();
  const [form] = useForm<LinkEditProps>();

  const showModal = useCallback(() => {
    setIsModalVisible(true);
    const isButton = existLinkData?.emphasis === 'primary' || existLinkData?.emphasis === 'secondary';
    setKind(isButton ? 'button' : 'a');
    if (isButton) {
      form.setFieldsValue({
        ...defaultButtonValues,
        ...existLinkData,
        tag: 'a',
        content: selectedText,
      });
    } else {
      form.setFieldsValue({
        ...defaultAValues,
        ...existLinkData,
        tag: 'a',
        content: selectedText,
        rel: existLinkData
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (existLinkData as any).rel?.split(' ')
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsModalVisible, existLinkData, kind, selectedText]);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsModalVisible, form]);

  const handleSubmit = useCallback(
    (values: LinkEditProps) => {
      const { content, ...rest } = values;
      createOrEditLink(
        {
          ...rest,
          rel: values.rel && values.rel.length ? values.rel.join(' ') : '',
        } as LinkEntityProps,
        content,
      );
      hideModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsModalVisible, hideModal],
  );

  const handleRemove = useCallback(() => {
    removeLink();
    hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsModalVisible, hideModal]);

  const handleKindChange = useCallback(
    (event) => {
      setKind(event.target.value);
      const isButton = event.target.value === 'button';

      if (isButton) {
        form.setFieldsValue({
          ...defaultButtonValues,
          ...existLinkData,
          tag: 'a',
          content: selectedText,
        });
      } else {
        form.setFieldsValue({
          ...defaultAValues,
          ...existLinkData,
          tag: 'a',
          content: selectedText,
          rel: existLinkData
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (existLinkData as any).rel?.split(' ')
            : [],
        });
      }
    },
    [existLinkData, form, selectedText],
  );

  return (
    <StyledControl>
      <ControlButton title="Ссылка" isActive={Boolean(existLinkData)} onToggle={showModal}>
        <LinkOutlined />
      </ControlButton>
      <DrawerFormEditor
        title={existLinkData ? 'Редактирование ссылки' : 'Создание ссылки'}
        isVisible={isModalVisible}
        closeHandler={hideModal}
        buttons={[
          <Button key="closeButton" onClick={hideModal}>
            Закрыть
          </Button>,
          existLinkData ? (
            <Button key="removeButton" onClick={handleRemove} type="primary" htmlType="submit">
              Удалить ссылку
            </Button>
          ) : undefined,
          <Button key="submitButton" onClick={form.submit} type="primary" htmlType="submit">
            Сохранить изменения
          </Button>,
        ]}
      >
        <Row gutter={16}>
          <Col span={12}>
            <StyledInlineFormItem label={<Typography.Text type="secondary">Тип CTA</Typography.Text>}>
              <Radio.Group onChange={handleKindChange} value={kind}>
                <Radio value="button">Кнопка</Radio>
                <Radio value="a">Ссылка</Radio>
              </Radio.Group>
            </StyledInlineFormItem>
          </Col>
        </Row>
        <Form<LinkEditProps> layout="vertical" form={form} onFinish={handleSubmit} validateMessages={validateMessages}>
          {kind === 'button' ? (
            <KindButtonForm form={form} isContentField={!isSelectedEntity} />
          ) : (
            <KindAForm form={form} isContentField={!isSelectedEntity} />
          )}
        </Form>
      </DrawerFormEditor>
    </StyledControl>
  );
});

LinkEntity.displayName = 'LinkEntity';
