import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Form, Input, InputNumber, Select, Table } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDeleteButtonWrapper = styled.div`
  text-align: end;
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

export type EditRobotRulesTableProps = {
  fields: FormListFieldData[];
  add: FormListOperation['add'];
  remove: FormListOperation['remove'];
};

export const EditRobotRulesTable: React.FC<EditRobotRulesTableProps> = ({ fields, add, remove }) => {
  const columns: ColumnsType<FormListFieldData> = [
    {
      title: 'Правило',
      width: '30%',
      render: (_, field) => (
        <Form.Item
          name={[field.name, 'rule']}
          fieldKey={[field.fieldKey, 'rule']}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, заполните правило',
            },
          ]}
          initialValue="allow"
        >
          <Select>
            <Select.Option value="host">Host</Select.Option>
            <Select.Option value="sitemap">Sitemap</Select.Option>
            <Select.Option value="allow">Allow</Select.Option>
            <Select.Option value="dissalow">Disallow</Select.Option>
            <Select.Option value="crawlDelay">Crawl-delay</Select.Option>
            <Select.Option value="cleanParam">Clean-param</Select.Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Значение',
      render: (_, field) => (
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.rules[field.name]?.rule !== currentValues.rules[field.name]?.rule
          }
        >
          {({ getFieldValue }) => {
            const currentRule = getFieldValue(['rules', field.name, 'rule']);

            return (
              <>
                {currentRule === 'host' && (
                  <Form.Item
                    name={[field.name, 'host', 'url']}
                    fieldKey={[field.fieldKey, 'host', 'url']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, заполните URL',
                      },
                    ]}
                  >
                    <Input placeholder="Введите URL" />
                  </Form.Item>
                )}
                {currentRule === 'sitemap' && (
                  <Form.Item
                    name={[field.name, 'sitemap', 'url']}
                    fieldKey={[field.fieldKey, 'sitemap', 'url']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, заполните URL',
                      },
                    ]}
                  >
                    <Input placeholder="Введите URL" />
                  </Form.Item>
                )}
                {currentRule === 'allow' && (
                  <Form.Item
                    name={[field.name, 'allow', 'path']}
                    fieldKey={[field.fieldKey, 'allow', 'path']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, заполните путь',
                      },
                    ]}
                  >
                    <Input placeholder="Введите путь" />
                  </Form.Item>
                )}
                {currentRule === 'dissalow' && (
                  <Form.Item
                    name={[field.name, 'dissalow', 'path']}
                    fieldKey={[field.fieldKey, 'dissalow', 'path']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, заполните путь',
                      },
                    ]}
                  >
                    <Input placeholder="Введите путь" />
                  </Form.Item>
                )}
                {currentRule === 'crawlDelay' && (
                  <Form.Item
                    name={[field.name, 'crawlDelay', 'seconds']}
                    fieldKey={[field.fieldKey, 'crawlDelay', 'seconds']}
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, введите кол-во секунд',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder="Введите кол-во секунд" />
                  </Form.Item>
                )}
                {currentRule === 'cleanParam' && (
                  <>
                    <Form.Item
                      name={[field.name, 'cleanParam', 'path']}
                      fieldKey={[field.fieldKey, 'cleanParam', 'path']}
                      rules={[
                        {
                          required: true,
                          message: 'Пожалуйста, заполните путь',
                        },
                      ]}
                      style={{
                        display: 'inline-block',
                        width: 'calc(50% - 4px)',
                      }}
                    >
                      <Input placeholder="Введите путь" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'cleanParam', 'params']}
                      fieldKey={[field.fieldKey, 'cleanParam', 'params']}
                      rules={[
                        {
                          required: true,
                          message: 'Пожалуйста, заполните параметры',
                        },
                      ]}
                      style={{
                        display: 'inline-block',
                        width: 'calc(50% - 4px)',
                        marginLeft: '8px',
                      }}
                    >
                      <Select placeholder="Введите параметры" mode="tags" style={{ width: '100%' }} open={false} />
                    </Form.Item>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>
      ),
    },
    {
      title: '',
      width: '10%',
      render: (_, field) => (
        <StyledDeleteButtonWrapper>
          <Button type="text" shape="circle" icon={<StyledDeleteFilled />} onClick={() => remove(field.name)} />
        </StyledDeleteButtonWrapper>
      ),
    },
  ];

  return (
    <Table
      rowKey={(field) => field.fieldKey}
      dataSource={fields}
      columns={columns}
      pagination={false}
      title={() => (
        <StyledHeader>
          <Button type="link" onClick={() => add()} icon={<PlusOutlined />}>
            Добавить правило
          </Button>
        </StyledHeader>
      )}
    />
  );
};
