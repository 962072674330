import { ContentVolkswagenV1Widget, placeholderNodeGenerator, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { SnippetNameExtend, WidgetName } from '@console/api';
import { styled } from '@console/uikit';
import { Button, Drawer, Input, Space, Spin, Tabs } from 'antd';
import React, { useMemo, useState } from 'react';
import { getNodeId } from 'src/utils/nodes';
import { removeRootBlueprintId } from 'src/utils/snippets';
import { useCopySnippet, useLoadSnippet } from '../../../../hooks';
import { BlockPreview } from '../../../../pages/page-detail/components/block/block-preview';
import { WidgetEditorDrawer } from '../../widget-editor-drawer';
import { BlockPreviewType, BlocksGroup, BlocksGroupTitle, blocksPreviews } from '../data';
import { AddWidgetDrawerProps } from '../index';

const BlocksPreviewsWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, 318px);
`;

const getFilteredBlocks = (
  blocks: BlockPreviewType[],
  allowedSnippets: AddWidgetDrawerProps['allowedSnippets'],
  disallowedSnippets: AddWidgetDrawerProps['disallowedSnippets'],
  search: string,
) => {
  let filteredBlock: BlockPreviewType[] = blocks;

  if (allowedSnippets?.length) {
    filteredBlock = filteredBlock.filter((block) => allowedSnippets.includes(block.type));
  }

  if (disallowedSnippets?.length) {
    filteredBlock = filteredBlock.filter((block) => !disallowedSnippets.includes(block.type));
  }

  if (search) {
    filteredBlock = filteredBlock.filter(
      (block) =>
        block.type.toLowerCase().includes(search.toLowerCase()) ||
        block.title.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return filteredBlock;
};

export const MultiplyWidgetDrawer: React.FC<AddWidgetDrawerProps> = ({
  allowedSnippets,
  disallowedSnippets,
  loading,
  onClose,
  onSave,
  open,
}) => {
  const [search, setSearch] = React.useState('');
  const { snippet, isSnippetLoading, loadSnippet, resetState, setSnippet } = useLoadSnippet();
  const { handleGetSnippet } = useCopySnippet();
  const defaultActiveKey = useMemo(
    () =>
      Object.keys(blocksPreviews).find(
        (key) =>
          !!getFilteredBlocks(blocksPreviews[key as BlocksGroup], allowedSnippets, disallowedSnippets, '').length,
      ),
    [allowedSnippets, disallowedSnippets],
  );

  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const handleClose = () => {
    setSearch('');
    resetState();
    setActiveTab(defaultActiveKey);
    onClose();
  };

  const handleSaveWidget = (node: ContentVolkswagenV1Widget) => {
    onSave(node);
    setSearch('');
    onClose();
    resetState();
  };

  const handleSelectSnippet = (name: WidgetName) => {
    if (name === SnippetNameExtend.Placeholder) {
      return handleSaveWidget(placeholderNodeGenerator());
    }

    loadSnippet(name);
  };

  const handlePasteAsBlueprint = async () => {
    const snippet = await handleGetSnippet();

    if (!snippet) return;

    onSave({
      blueprint: {
        source: snippet.path,
        fragment: `#${getNodeId(snippet.node)}`,
        visibility: {
          status: VisibilityV1Status.Public,
        },
      },
    });
  };

  const handlePasteAsSnippet = async () => {
    const snippet = await handleGetSnippet();

    if (!snippet) return;

    setSnippet(removeRootBlueprintId(snippet.node));
  };

  return (
    <Drawer
      title="Добавление блока"
      placement="right"
      visible={open}
      width="90%"
      closable
      onClose={handleClose}
      bodyStyle={{ paddingLeft: 0 }}
      forceRender
      extra={
        <Input
          allowClear
          placeholder="Поиск блока"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300 }}
        />
      }
    >
      <Spin spinning={isSnippetLoading}>
        <Tabs
          tabPosition="left"
          style={{ minHeight: '100%' }}
          defaultActiveKey={defaultActiveKey}
          tabBarExtraContent={
            <Space direction="vertical">
              <Button onClick={handlePasteAsBlueprint} type="primary" block>
                Вставить как ярлык
              </Button>
              <Button onClick={handlePasteAsSnippet} type="primary" block>
                Вставить содержимое
              </Button>
            </Space>
          }
          tabBarStyle={{ display: 'block' }}
          activeKey={search ? BlocksGroup.All : activeTab}
          onChange={(key) => setActiveTab(key as BlocksGroup)}
        >
          {Object.keys(blocksPreviews).map((blockPreviewKey) => {
            const filteredBlocks = getFilteredBlocks(
              blocksPreviews[blockPreviewKey as BlocksGroup],
              allowedSnippets,
              disallowedSnippets,
              search,
            );

            return (
              <Tabs.TabPane
                tab={BlocksGroupTitle[blockPreviewKey as BlocksGroup]}
                disabled={Boolean(search) || !filteredBlocks.length}
                key={blockPreviewKey}
              >
                <BlocksPreviewsWrapper>
                  {filteredBlocks.map((blockPreview) => (
                    <BlockPreview
                      {...blockPreview}
                      type={blockPreview.type}
                      onClick={handleSelectSnippet}
                      key={blockPreview.type}
                    />
                  ))}
                </BlocksPreviewsWrapper>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Spin>

      <WidgetEditorDrawer
        node={snippet || null}
        loading={loading}
        title="Добавление блока"
        onClose={resetState}
        onSave={handleSaveWidget}
      />
    </Drawer>
  );
};
