import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React from 'react';

type FilesTypeTableCellProps = {
  file: ContentFilesV1File;
};

export const FilesTypeTableCell: React.FC<FilesTypeTableCellProps> = ({ file }) => {
  let type: string | null = null;

  if (file.filesV1Folder) {
    type = 'Папка';
  }

  if (file.volkswagenV1Page) {
    type = 'Страница';
  }

  if (file.volkswagenV1Snippet) {
    type = 'Сниппет';
  }

  if (file.filesV1FolderBlueprint) {
    type = `Ярлык папки «${file.filesV1FolderBlueprint.source}»`;
  }

  if (file.volkswagenV1PageBlueprint) {
    type = `Ярлык страницы «${file.volkswagenV1PageBlueprint.source}»`;
  }

  // if (file.blueprint) {
  //     type = 'Ссылка';
  // }

  // if (file.site) {
  //     type = 'Сайт';
  // }

  return <span>{type}</span>;
};
