import { RightOutlined } from '@ant-design/icons';
import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Space } from 'antd';
import React from 'react';

const StyledRightOutlined = styled(RightOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export type SitesActionsTableCellProps = {
  site: ContentSitesV1Site;
  setCurrentSite: (site: ContentSitesV1Site) => void;
};

export const SitesActionsTableCell: React.FC<SitesActionsTableCellProps> = ({ site, setCurrentSite }) => {
  return (
    <StyledActions>
      <Button type="text" shape="circle" onClick={() => setCurrentSite(site)} icon={<StyledRightOutlined />} />
    </StyledActions>
  );
};
