import { DraftStyleMap } from 'draft-js';
import React from 'react';
import { StyledControlsBlock } from '../../../elements';
import { ConfigType, useCheckConfig } from '../../../hooks';
import { ControlsProps } from '../../../types';
import { PlaceholderBlockType } from '../entities/placeholder-entity';
import { TextColorInline, TextColorInlineType, textColorStyleMap } from './text-color-inline';
import { TextSizeInline, TextSizeInlineType } from './text-size-inline';
import { TextStyleInline, TextStyleInlineType } from './text-style-inline';

export type InlineType = TextSizeInlineType | TextStyleInlineType | TextColorInlineType | PlaceholderBlockType;

/**
 * Стилизация inline элементов в редакторе делается с помощью DraftStyleMap
 * Здесь style maps из всех inline элементов собираются вместе
 * Подключаются в /index.tsx, как customStyleMap={customStyleMap}
 */
export const customStyleMap: DraftStyleMap = {
  ...textColorStyleMap,
};

export const InlineControls: React.FC<ControlsProps> = React.memo<ControlsProps>((props) => {
  const inlineConfig = useCheckConfig(ConfigType.TextStyle);
  const sizeConfig = useCheckConfig(ConfigType.TextSize);
  const colorConfig = useCheckConfig(ConfigType.TextColor);

  if (!inlineConfig && !sizeConfig && !colorConfig) {
    return null;
  }

  return (
    <StyledControlsBlock>
      <TextStyleInline {...props} />
      <TextSizeInline {...props} />
      <TextColorInline {...props} />
    </StyledControlsBlock>
  );
});

InlineControls.displayName = 'InlineControls';
