import { SnippetName } from '@cms/volkswagen-widgets';

export type Service<M, O, MT> = {
  operations: O;
  models: M;
  meta: MT;
};

export enum SnippetNameExtend {
  Placeholder = 'Placeholder',
}

export type WidgetName = SnippetName | SnippetNameExtend;
