import { PlusOutlined } from '@ant-design/icons';
import { ComparisonNode, ContentVolkswagenV1ComparisonProperty } from '@cms/volkswagen-widgets';
import { Button, Collapse, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, SnippetItemBlock } from '../../../custom-inputs';
import { NodePreview } from '../../../custom-inputs';
import { PropertiesItem, PropertiesItemProps } from './properties-item';

const makeInitialProp = (): ContentVolkswagenV1ComparisonProperty =>
  ComparisonNode.Property.generators.entity({
    title: '',
    category: '',
    content: [],
  });

export type ComparisonItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
  categories: PropertiesItemProps['categories'];
  properties: PropertiesItemProps['properties'];
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const ComparisonItem: React.FC<ComparisonItemProps> = ({
  field: comparisonItemField,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index: comparisonItemIndex,
  contentPath,
  categories,
  properties,
  allowedSnippets,
  disallowedSnippets,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(comparisonItemField.name)}
      onMoveTop={isTopMovable ? () => move(comparisonItemIndex, comparisonItemIndex - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(comparisonItemIndex, comparisonItemIndex + 1) : undefined}
      contentPath={[...contentPath, comparisonItemIndex, 'comparisonItem', 'content', 0]}
      allowedSnippets={allowedSnippets}
      disallowedSnippets={disallowedSnippets}
    >
      <div style={{ width: '20%' }}>
        <Form.Item name={[comparisonItemField.name, 'comparisonItem', 'content', 0]}>
          <NodePreview />
        </Form.Item>
      </div>

      <Form.List name={[comparisonItemField.name, 'comparisonItem', 'properties']}>
        {(fields, { add, remove, move }) => (
          <>
            <Collapse defaultActiveKey={['1']}>
              {fields.map((field, index) => (
                <Collapse.Panel header={`Параметр №${index + 1}`} key={field.fieldKey}>
                  <PropertiesItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    isEditParam={comparisonItemIndex === 0}
                    categories={categories}
                    properties={properties}
                    contentPath={[...contentPath, comparisonItemField.fieldKey, 'comparisonItem', 'properties']}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
            <Button
              type="dashed"
              disabled={disabled}
              onClick={() => add(makeInitialProp())}
              icon={<PlusOutlined />}
              block
            >
              Добавить параметр
            </Button>
          </>
        )}
      </Form.List>
    </SnippetItemBlock>
  );
};
