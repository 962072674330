import {
  blueprintNodeGenerator,
  ContentVolkswagenV1Node,
  ContentVolkswagenV1Page,
  ContentVolkswagenV1Placeholder,
  ContentVolkswagenV1Widget,
  snippetGenerators,
  SnippetName,
  SnippetNameByPath,
  SnippetPathByName,
  VisibilityV1Status,
} from '@cms/volkswagen-widgets';
import { Breakpoints } from '@cms/volkswagen-widgets/dist/d6';
import { filesApi } from '@console/api';
import { notification } from 'antd';
import jp from 'jsonpath';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import defaultsDeep from 'lodash/defaultsDeep';
import { TreePath } from './content-path';

export const initialSnippets: Record<SnippetName, ContentVolkswagenV1Node> = {
  [SnippetName.Bundles]: snippetGenerators.Bundles({
    bottomContent: [],
    cardHeaderHeight: {},
    cards: [],
  }),
  [SnippetName.BundlesCarFragment]: snippetGenerators.BundlesCarFragment({
    background: '',
    content: [],
    features: [],
    featuresTitle: '',
    src: '',
    title: [],
  }),
  [SnippetName.BundlesSection]: snippetGenerators.BundlesSection({
    bottomContent: [],
    cardHeaderHeight: {},
    cards: [],
  }),
  [SnippetName.Card]: snippetGenerators.Card({ content: [], imageSrc: '' }),
  [SnippetName.CarFragment]: snippetGenerators.CarFragment({
    background: '',
    content: [snippetGenerators.Richtext({ content: [] })],
    src: '',
    title: [],
  }),
  [SnippetName.CarStock]: snippetGenerators.CarStock({ tabs: [] }),
  [SnippetName.CarStockCarousel]: snippetGenerators.CarStockCarousel({
    tabs: [],
  }),
  [SnippetName.CarStockCarouselSection]: snippetGenerators.CarStockCarouselSection({ tabs: [] }),
  [SnippetName.CarStockSection]: snippetGenerators.CarStockSection({
    tabs: [],
  }),
  [SnippetName.Comparison]: snippetGenerators.Comparison({
    items: [],
    cardHeaderHeight: {},
  }),
  [SnippetName.ComparisonSection]: snippetGenerators.ComparisonSection({
    items: [],
    cardHeaderHeight: {},
  }),
  [SnippetName.ContactFocusLayer]: snippetGenerators.ContactFocusLayer.snippet({
    modalName: '',
    tabs: [],
    title: [],
  }),
  [SnippetName.ContactInfo]: snippetGenerators.ContactInfo({
    location: { mapUrl: '', title: '' },
    mail: { href: '', title: '' },
    phone: { href: '', title: '' },
    time: [],
  }),
  [SnippetName.Contacts]: snippetGenerators.Contacts({ tabs: [] }),
  [SnippetName.ContactsInteractionLayer]: snippetGenerators.ContactsInteractionLayer({
    contacts: [],
    modalName: '',
    title: [],
  }),
  [SnippetName.ContactsPreview]: snippetGenerators.ContactsPreview({
    image1: { src: '' },
    image2: { src: '' },
    content: [],
  }),
  [SnippetName.ContactsPreviewSection]: snippetGenerators.ContactsPreviewSection({
    image1: { src: '' },
    image2: { src: '' },
    content: [],
  }),
  [SnippetName.ContactsSection]: snippetGenerators.ContactsSection({
    tabs: [],
  }),
  [SnippetName.ContactsTab]: snippetGenerators.ContactsTab({
    aboutContent: [],
    accordions: [],
    ctaLinks: [],
    gallery: { items: [] },
    gridIcons: { items: [], title: [] },
    title: [],
  }),
  [SnippetName.CopyAccordion]: snippetGenerators.CopyAccordion({ items: [] }),
  [SnippetName.CopyAccordionSection]: snippetGenerators.CopyAccordionSection({
    items: [],
  }),
  [SnippetName.CopyColumns]: snippetGenerators.CopyColumns({
    column1: [],
    column2: [],
  }),
  [SnippetName.CopyColumnsSection]: snippetGenerators.CopyColumnsSection({
    column1: [],
    column2: [],
  }),
  [SnippetName.DisclaimerLayer]: snippetGenerators.DisclaimerLayer({
    content: [],
    modalName: '',
  }),
  [SnippetName.EditorialStage]: snippetGenerators.EditorialStage({
    src: '',
    content: [],
    halfHeight: false,
  }),
  [SnippetName.EditorialStageSection]: snippetGenerators.EditorialStageSection({
    src: '',
    content: [],
    halfHeight: false,
  }),
  [SnippetName.Engines]: snippetGenerators.Engines({ tabs: [] }),
  [SnippetName.EnginesSection]: snippetGenerators.EnginesSection({
    tabs: [],
  }),
  [SnippetName.EnginesTab]: snippetGenerators.EnginesTab({
    src: '',
    title: [],
    content: [],
    feature1: [],
    feature2: [],
    feature3: [],
  }),
  [SnippetName.FeatureCluster]: snippetGenerators.FeatureCluster({
    title: [],
    content: [],
    featureClusterTitle: [],
    items: [],
  }),
  [SnippetName.FeaturesIcons]: snippetGenerators.FeaturesIcons({
    column1: [],
    column2: [],
  }),
  [SnippetName.FeaturesIconsBanner]: snippetGenerators.FeaturesIconsBanner({
    mainColumn: { content: [], href: '', src: '' },
    secondaryColumn: [],
  }),
  [SnippetName.FeaturesIconsBannerSection]: snippetGenerators.FeaturesIconsBannerSection({
    mainColumn: { content: [], href: '', src: '' },
    secondaryColumn: [],
  }),
  [SnippetName.FeaturesIconsRow]: snippetGenerators.FeaturesIconsRow({
    content: [],
    href: '',
    src: '',
  }),
  [SnippetName.FeaturesIconsSection]: snippetGenerators.FeaturesIconsSection({
    column1: [],
    column2: [],
  }),
  [SnippetName.FeedbackItem]: snippetGenerators.FeedbackItem.generator({}),
  [SnippetName.Feedbacks]: snippetGenerators.Feedbacks({ items: [] }),
  [SnippetName.FeedbacksSection]: snippetGenerators.FeedbacksSection({
    items: [],
  }),
  [SnippetName.Footer]: snippetGenerators.Footer({
    columns: [],
    contacts: [],
    copyright: '',
    bottomNav: [],
    socialTitle: '',
  }),
  [SnippetName.FooterBottomNav]: snippetGenerators.FooterBottomNav({
    copyright: '',
    bottomNav: [],
  }),
  [SnippetName.FooterColumn]: snippetGenerators.FooterColumn({
    title: '',
    items: [],
  }),
  [SnippetName.FooterContacts]: snippetGenerators.FooterContacts({
    contacts: [],
  }),
  [SnippetName.FooterSocials]: snippetGenerators.FooterSocials({
    socialTitle: '',
  }),
  [SnippetName.Form]: snippetGenerators.Form({ name: '' }),
  [SnippetName.FormSection]: snippetGenerators.FormSection({ name: '' }),
  [SnippetName.FullscreenBanner]: snippetGenerators.FullscreenBanner.generator({
    src: '',
    title: [],
    content: [],
    halfHeight: false,
  }),
  [SnippetName.FullscreenStage]: snippetGenerators.FullscreenStage({
    content: [],
    src: '',
  }),
  [SnippetName.FullscreenStageSection]: snippetGenerators.FullscreenStageSection({ content: [], src: '' }),
  [SnippetName.Gallery]: snippetGenerators.Gallery({ items: [] }),
  [SnippetName.GalleryColumn]: snippetGenerators.GalleryColumn({ items: [] }),
  [SnippetName.GalleryColumnSection]: snippetGenerators.GalleryColumnSection({
    items: [],
  }),
  [SnippetName.GallerySection]: snippetGenerators.GallerySection({
    items: [],
  }),
  [SnippetName.GridIcons]: snippetGenerators.GridIcons({
    items: [],
    title: [],
  }),
  [SnippetName.GridIconsSection]: snippetGenerators.GridIconsSection({
    items: [],
    title: [],
  }),
  [SnippetName.HighlightCarousel]: snippetGenerators.HighlightCarousel({
    cards: [],
  }),
  [SnippetName.HighlightCarouselSection]: snippetGenerators.HighlightCarouselSection({
    cards: [],
  }),
  [SnippetName.HighlightFeature]: snippetGenerators.HighlightFeature({
    items: [],
  }),
  [SnippetName.HighlightFeatureSection]: snippetGenerators.HighlightFeatureSection({ items: [] }),
  [SnippetName.Iframe]: snippetGenerators.Iframe({ src: '', styles: [] }),
  [SnippetName.IframeSection]: snippetGenerators.IframeSection({
    src: '',
    styles: [],
  }),
  [SnippetName.Image]: snippetGenerators.Image({
    source: '',
    visibility: { status: VisibilityV1Status.Public },
  }),
  [SnippetName.ImageSection]: snippetGenerators.ImageSection({
    source: '',
    visibility: { status: VisibilityV1Status.Public },
  }),
  [SnippetName.Models]: snippetGenerators.Models({
    cardHeaderHeight: {},
    tabs: [],
  }),
  [SnippetName.ModelsCarousel]: snippetGenerators.ModelsCarousel({
    cardHeaderHeight: {},
    tabs: [],
  }),
  [SnippetName.ModelsCarouselCarFragment]: snippetGenerators.ModelsCarouselCarFragment({
    background: '',
    content: [snippetGenerators.Richtext({ content: [] })],
    src: '',
    title: [],
  }),
  [SnippetName.ModelsCarouselSection]: snippetGenerators.ModelsCarouselSection({
    cardHeaderHeight: {},
    tabs: [],
  }),
  [SnippetName.ModelsCarouselTab]: snippetGenerators.ModelsCarouselTab({
    bottomContent: [],
    cards: [],
  }),
  [SnippetName.ModelsSection]: snippetGenerators.ModelsSection({
    cardHeaderHeight: {},
    tabs: [],
  }),
  [SnippetName.ModelsTab]: snippetGenerators.ModelsTab({ cards: [] }),
  [SnippetName.MoodGallery]: snippetGenerators.MoodGallery({
    content: [],
    items: [],
  }),
  [SnippetName.MoodGallerySection]: snippetGenerators.MoodGallerySection({
    content: [],
    items: [],
  }),
  [SnippetName.PresentationTeaser]: snippetGenerators.PresentationTeaser({
    src: '',
    content: [],
  }),
  [SnippetName.PresentationTeaserSection]: snippetGenerators.PresentationTeaserSection({ src: '', content: [] }),
  [SnippetName.Prompt]: snippetGenerators.Prompt.generator({ content: [] }),
  [SnippetName.PromptImage]: snippetGenerators.PromptImage.generator({
    content: [],
  }),
  [SnippetName.PromptImageSection]: snippetGenerators.PromptImageSection.generator({ content: [] }),
  [SnippetName.PromptSection]: snippetGenerators.PromptSection.generator({
    content: [],
  }),
  [SnippetName.Quote]: snippetGenerators.Quote({ content: [], sign: [] }),
  [SnippetName.Richtext]: snippetGenerators.Richtext({ content: [] }),
  [SnippetName.Section]: snippetGenerators.Section.section({}),
  [SnippetName.Script]: snippetGenerators.Script({ src: '' }),
  [SnippetName.SideMenu]: snippetGenerators.SideMenu({ items: [] }),
  [SnippetName.SpecialSeries]: snippetGenerators.SpecialSeries({
    cards: [],
    cardHeaderHeight: {
      [Breakpoints.default]: '216px',
      [Breakpoints.b560]: '185px',
      [Breakpoints.b960]: '168px',
      [Breakpoints.b1280]: '216px',
      [Breakpoints.b1920]: '248px',
    },
  }),
  [SnippetName.SpecialSeriesSection]: snippetGenerators.SpecialSeriesSection({
    cards: [],
    cardHeaderHeight: {
      [Breakpoints.default]: '216px',
      [Breakpoints.b560]: '185px',
      [Breakpoints.b960]: '168px',
      [Breakpoints.b1280]: '216px',
      [Breakpoints.b1920]: '248px',
    },
  }),
  [SnippetName.Table]: snippetGenerators.Table.snippet({
    content: [],
    rows: [],
  }),
  [SnippetName.TableSection]: snippetGenerators.TableSection({
    content: [],
    rows: [],
  }),
  [SnippetName.TabLinks]: snippetGenerators.TabLinks({ tabs: [] }),
  [SnippetName.Tabs]: snippetGenerators.Tabs({ tabs: [] }),
  [SnippetName.TabsSection]: snippetGenerators.TabsSection({ tabs: [] }),
  [SnippetName.Team]: snippetGenerators.Team.snippet({ tabs: [] }),
  [SnippetName.TeamSection]: snippetGenerators.TeamSection({ tabs: [] }),
  [SnippetName.TeamGrid]: snippetGenerators.TeamGrid({ cards: [] }),
  [SnippetName.TeamItem]: snippetGenerators.TeamItem({
    src: '',
    content: [],
    href: '',
  }),
  [SnippetName.Text]: snippetGenerators.Text({ content: [] }),
  [SnippetName.TextSection]: snippetGenerators.TextSection({ content: [] }),
  [SnippetName.TopMenu]: snippetGenerators.TopMenu({
    additionalLinks: [],
    dealers: { items: [], modalHref: '' },
    items: [],
    mainLinks: [],
    inheritPromoArea: false,
  }),
  [SnippetName.TopMenuPromoArea]: snippetGenerators.TopMenuPromoArea({
    bottomContent: [],
    image: { href: '', src: '' },
    title: [],
  }),
  [SnippetName.Vacancies]: snippetGenerators.Vacancies({ tabs: [] }),
  [SnippetName.VacanciesAccordions]: snippetGenerators.VacanciesAccordions({
    accordions: [],
  }),
  [SnippetName.VacanciesGrid]: snippetGenerators.VacanciesGrid({
    columns: [],
    bottomContent: [],
  }),
  [SnippetName.VacanciesSection]: snippetGenerators.VacanciesSection({
    tabs: [],
  }),
  [SnippetName.Video]: snippetGenerators.Video({ source: '' }),
  [SnippetName.VideoSection]: snippetGenerators.VideoSection({ source: '' }),
  [SnippetName.TeaserItem]: snippetGenerators.TeaserItem({
    imageSrc: '',
    title: [],
    subTitle: [],
    content: [],
    href: '',
  }),
  [SnippetName.Teaser]: snippetGenerators.Teaser({}),
  [SnippetName.TeaserSection]: snippetGenerators.TeaserSection({}),
  [SnippetName.TeaserCustom]: snippetGenerators.TeaserCustom({ items: [] }),
  [SnippetName.TeaserCustomSection]: snippetGenerators.TeaserCustomSection({ items: [] }),
  [SnippetName.Breadcrumbs]: snippetGenerators.Breadcrumbs({ paddingTop: '16px', paddingBottom: '0px' }),
};

export function getSnippetPath(
  node: ContentVolkswagenV1Node | null,
  path: TreePath = [],
): {
  snippetPath?: string;
  treePath: TreePath;
} {
  if (!node) return { treePath: path };

  if (Object.values(SnippetPathByName).includes(node.blueprint?.source || ''))
    return {
      snippetPath: node.blueprint?.source,
      treePath: path,
    };

  if (node.blueprint?.content?.[0]?.blueprint)
    return getSnippetPath(node.blueprint?.content?.[0], [...path, 'blueprint', 'content', 0]);

  return { treePath: path };
}

export const removeRootBlueprintId = (source: ContentVolkswagenV1Widget): ContentVolkswagenV1Widget => {
  const paths = jp.nodes(source, '$..blueprint', 1);

  if (!paths.length) return source;

  const [{ path, value }] = paths;

  const copySource = cloneDeep(source);
  set(copySource, path.slice(1), omit(value, 'id'));

  return copySource;
};

export const removeIdsOnPage = (source: ContentVolkswagenV1Page): ContentVolkswagenV1Page => {
  const copyPage = cloneDeep(source);
  set(copyPage, 'volkswagenV1Page', omit(copyPage, 'id'));
  copyPage.content = copyPage.content?.map(removeRootBlueprintId);
  copyPage.preview = copyPage.preview?.map(removeRootBlueprintId);

  return copyPage;
};

// nodeName: keyof ContentVolkswagenV1Node
export const replaceNode = <T>(
  source: T,
  replacer?: (node: ContentVolkswagenV1Widget) => ContentVolkswagenV1Widget,
): T =>
  JSON.parse(
    JSON.stringify(source, (key, value) => {
      if (replacer && value && typeof value === 'object') {
        const result = replacer(value);
        return result ? result : value;
      }

      return value;
    }),
  );

export const findRootPlaceholder = (
  node: ContentVolkswagenV1Widget,
): { value?: ContentVolkswagenV1Placeholder; path?: string[] } => {
  const placeholders = jp.nodes(node, '$..placeholder', 1);

  return {
    value: placeholders[0]?.value as ContentVolkswagenV1Placeholder,
    path: placeholders[0]?.path.slice(1) as string[] | undefined,
  };
};

const loadSnippetAsync = async (name: SnippetName) => {
  const snippetPath = SnippetPathByName[name];
  const currentPath = snippetPath.substring(1);

  try {
    const response = await filesApi.contentFilesV1FilesAPIGetFile(
      currentPath, // filePath
    );

    if (response.data.volkswagenV1Snippet) {
      const snippetBlueprint = blueprintNodeGenerator({
        source: response.data.volkswagenV1Snippet.path,
        content: response.data.volkswagenV1Snippet.content,
      });

      return snippetBlueprint;
    } else {
      notification.error({
        message: `Получена неверная структура сниппета ${currentPath}`,
        description: 'Path data.volkswagenV1Snippet not found',
      });

      return Promise.reject();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    notification.error({
      message: `Ошибка получения сниппета по пути ${snippetPath}`,
      description: e?.response?.data.message,
    });

    return Promise.reject();
  }
};

// Add ids to all existing snippets in the custom node tree
export const addIds = async (node: ContentVolkswagenV1Node): Promise<ContentVolkswagenV1Node> => {
  // Search all blueprint and make GET query for each uniq snippet that doesn't have deep ids already
  const blueprintNodes = jp
    .nodes(node, '$..blueprint')
    .filter(({ value }) => jp.paths(value, '$..id').every((path) => path.includes('blueprint')));
  const uniqBlueprints = uniqBy(blueprintNodes, ({ value }) => value.source);

  const blueprints = await Promise.all(
    uniqBlueprints.map(({ value }) => loadSnippetAsync(SnippetNameByPath[value.source])),
  );

  const blueprintsMap = blueprints.reduce<Record<string, ContentVolkswagenV1Node>>(
    (acc, value) => ({ ...acc, [value.blueprint?.source || '']: value }),
    {},
  );

  // Merge custom node tree parts with blueprints by original snippet from DB
  // Only IDS merged
  const result = JSON.parse(
    JSON.stringify(node, (key, value) => {
      if (key !== 'blueprint') return value;

      const path = value.source;
      const snippet = blueprintsMap[path];

      if (!snippet) return value;

      const copy = cloneDeep(value);

      defaultsDeep(copy, snippet.blueprint);

      return copy;
    }),
  );

  return result;
};

type AddInitialSnippetArgs = {
  node: ContentVolkswagenV1Node;
  onStart?: () => void;
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: (node: ContentVolkswagenV1Node) => void;
};

export const addInitialSnippet = async ({
  node,
  onStart = noop,
  onError = noop,
  onSuccess = noop,
  onSettled = noop,
}: AddInitialSnippetArgs): Promise<void> => {
  try {
    onStart();
    const resultNode = await addIds(node);

    return onSuccess(resultNode);
  } catch {
    notification.error({ message: 'Не удалось добавить элемент' });
    onError();
  } finally {
    onSettled();
  }
};
