import { ContentSitesV1Robot, ContentSitesV1RobotsAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Robot>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveRobotData = {
  removeRobot: UseMutateFunction<Data, Error, Variables>;
  removeRobotAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveRobot(
  robotsApi: ContentSitesV1RobotsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseRemoveRobotData {
  const queryClient = useQueryClient();

  const {
    mutate: removeRobot,
    mutateAsync: removeRobotAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>(
    (name) =>
      robotsApi.contentSitesV1RobotsAPIDeleteRobot(
        site, // siteName: string,
        name, // robotName: string,
        // siteRevision?: string,
        // robotRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка удаления робота',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { removeRobot, removeRobotAsync, isRemoveLoading };
}
