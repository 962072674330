import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { ContentSitesV1Redirect } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

const { confirm } = Modal;
const { Text } = Typography;

const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledEditFilled = styled(EditFilled)`
  color: ${(props) => props.theme.colors.gray7};
  font-size: 20px;
`;

type RedirectsTableProps = {
  redirects: ContentSitesV1Redirect[];
  onRemoveRedirect: (name: string) => void;
  setEditRedirect: (value: ContentSitesV1Redirect | boolean) => void;
};

export const RedirectsTable: React.FC<RedirectsTableProps> = ({ redirects, onRemoveRedirect, setEditRedirect }) => {
  const handleDelete = (item: ContentSitesV1Redirect) => {
    confirm({
      title: 'Удаление редиректа',
      content: (
        <>
          <Text>Вы собираетесь удалить редиректа «{item.name}».</Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить редирект?</Text>
        </>
      ),
      onOk() {
        return onRemoveRedirect(item.name);
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  const columns: ColumnsType<ContentSitesV1Redirect> = [
    {
      title: 'Тип редиректа',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: 'URL старой страницы',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'URL новой страницы',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Приоритет',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: '',
      render: (_, record) => (
        <StyledActionsWrapper>
          <Space align="center">
            <Button type="link" icon={<StyledEditFilled />} onClick={() => setEditRedirect(record)} />
            <Button type="link" icon={<StyledDeleteFilled />} onClick={() => handleDelete(record)} />
          </Space>
        </StyledActionsWrapper>
      ),
    },
  ];

  return <Table rowKey={(redirect) => redirect.name} dataSource={redirects} columns={columns} pagination={false} />;
};
