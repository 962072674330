import { AutoComplete, Button, Form, Input, Modal } from 'antd';
import React from 'react';

export type CreateRobotFormValues = {
  name: string;
  agent: string;
};

export type CreateRobotModalProps = {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onCreateRobot: (values: CreateRobotFormValues) => Promise<unknown>;
};

export const CreateRobotModal: React.FC<CreateRobotModalProps> = ({ open, loading, onClose, onCreateRobot }) => {
  const [form] = Form.useForm<CreateRobotFormValues>();

  const handleFinish = (values: CreateRobotFormValues) => {
    onCreateRobot(values)
      .then(() => {
        form.resetFields();
        onClose();
      })
      .catch(() => null);
  };

  return (
    <Modal
      title="Создание группы"
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button loading={loading} key="submit" type="primary" onClick={form.submit}>
          Создать
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item label="Имя" name="name" rules={[{ required: true }]}>
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item label="Группа" name="agent" rules={[{ required: true }]}>
          <AutoComplete
            options={[{ value: 'YandexBot' }, { value: 'Googlebot' }, { value: '*' }]}
            style={{ width: '100%' }}
            placeholder="Введите группу"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
