import { Image, Typography } from 'antd';
import React from 'react';
import { WidgetName } from '@console/api';
import { styled } from '@console/uikit';
import { previews } from '../../../../components/drawers/add-widget-drawer/data/previews';

type BlockPreviewProps = {
  title: string;
  type: WidgetName;
  description: string;
  onClick: (name: WidgetName) => void;
};

const BlockPreviewWrapper = styled.div`
  padding: 16px;
  background: ${(props) => props.theme.colors.gray3};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.gray4};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const BlockPreview: React.FC<BlockPreviewProps> = ({ title, type, onClick }) => {
  return (
    <BlockPreviewWrapper onClick={() => onClick(type)}>
      <Image src={previews[type]} preview={false} />
      <ContentWrapper>
        <Typography.Text>{title}</Typography.Text>
        {/*<Typography.Text type="secondary">{description}</Typography.Text>*/}
      </ContentWrapper>
    </BlockPreviewWrapper>
  );
};
