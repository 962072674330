import { Form, Space } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../utils/content-path';
import { StatusCheckbox, StatusCheckboxProps, StatusTimeRange } from '../custom-inputs';

export type StatusAreaProps = {
  name: (TreePath[number] | string)[];
  formListFieldData?: FormListFieldData;
  checkboxProps: StatusCheckboxProps;
};

export const StatusArea: React.FC<StatusAreaProps> = ({ name, formListFieldData, checkboxProps, children }) => (
  <Form.Item>
    <Space direction="horizontal" size={24}>
      <Form.Item
        fieldKey={formListFieldData && [formListFieldData.fieldKey, ...name, 'status']}
        name={[...(formListFieldData ? [formListFieldData.name, ...name] : name), 'status']}
        noStyle
      >
        <StatusCheckbox {...checkboxProps}>{children}</StatusCheckbox>
      </Form.Item>
      <Form.Item
        fieldKey={formListFieldData && [formListFieldData.fieldKey, ...name, 'period']}
        name={[...(formListFieldData ? [formListFieldData.name, ...name] : name), 'period']}
        noStyle
      >
        <StatusTimeRange />
      </Form.Item>
    </Space>
  </Form.Item>
);
