import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React from 'react';
import { MediaView } from '../../../components/media/media-header';
import { UseMedia } from '../../../hooks/use-media';

type IMediaContext = UseMedia & {
  selectedRows: ContentFilesV1File[];
  setSelectedRows: (rows: ContentFilesV1File[]) => void;
  search: string | undefined;
  setSearch: (value: string) => void;
  sort: string;
  setSort: (value: string) => void;
  view: MediaView;
  setView: (value: MediaView) => void;
  openCreateFolderModal: () => void;
};

const MediaContext = React.createContext<IMediaContext>(null as unknown as IMediaContext);

export const MediaPageContextProvider = MediaContext.Provider;

export function useMediaPageContext(): IMediaContext {
  const context = React.useContext(MediaContext);
  if (context === null) {
    throw new Error('useMediaPageContext must be used within an MediaContextProvider');
  }
  return context;
}
