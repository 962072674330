import { createGlobalStyle } from 'styled-components';
import VWHeadWebBold from './fonts/VWHeadWeb-Bold.woff2';
import VWHeadWebBoldItalic from './fonts/VWHeadWeb-BoldItalic.woff2';
import VWHeadWebExtraBold from './fonts/VWHeadWeb-ExtraBold.woff2';
import VWHeadWebLight from './fonts/VWHeadWeb-Light.woff2';
import VWHeadWebRegular from './fonts/VWHeadWeb-Regular.woff2';
import VWHeadWebRegularItalic from './fonts/VWHeadWeb-RegularItalic.woff2';
import VWTextWebBold from './fonts/VWTextWeb-Bold.woff2';
import VWTextWebBoldItalic from './fonts/VWTextWeb-BoldItalic.woff2';
import VWTextWebLight from './fonts/VWTextWeb-Light.woff2';
import VWTextWebRegular from './fonts/VWTextWeb-Regular.woff2';
import VWTextWebRegularItalic from './fonts/VWTextWeb-RegularItalic.woff2';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'vw-head';
        font-weight: 400;
        src: url(${VWHeadWebRegular}) format('woff2');
    }
    @font-face {
        font-family: 'vw-head';
        font-weight: 400;
        font-style: italic;
        src: url(${VWHeadWebRegularItalic}) format('woff2');
    }
    @font-face {
        font-family: 'vw-head';
        font-weight: 700;
        src: url(${VWHeadWebBold}) format('woff2');
    }
    @font-face {
        font-family: 'vw-head';
        font-weight: 700;
        font-style: italic;
        src: url(${VWHeadWebBoldItalic}) format('woff2');
    }
    @font-face {
        font-family: 'vw-head';
        font-weight: 800;
        src: url(${VWHeadWebExtraBold}) format('woff2');
    }
    @font-face {
        font-family: 'vw-head';
        font-weight: 200;
        src: url(${VWHeadWebLight}) format('woff2');
    }

    @font-face {
        font-family: 'vw-text';
        font-weight: 400;
        src: url(${VWTextWebRegular}) format('woff2');
    }
    @font-face {
        font-family: 'vw-text';
        font-weight: 400;
        font-style: italic;
        src: url(${VWTextWebRegularItalic}) format('woff2');
    }
    @font-face {
        font-family: 'vw-text';
        font-weight: 700;
        src: url(${VWTextWebBold}) format('woff2');
    }
    @font-face {
        font-family: 'vw-text';
        font-weight: 700;
        font-style: italic;
        src: url(${VWTextWebBoldItalic}) format('woff2');
    }
    @font-face {
        font-family: 'vw-text';
        font-weight: 200;
        src: url(${VWTextWebLight}) format('woff2');
    }
`;
