import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { customBlockStyles } from './components/controls/blocks';

export const BlockStyledWrapper = styled.div(() => {
  return css`
    ${customBlockStyles};

    .ant-popover-inner-content {
      padding: 0 !important;
    }
  `;
});

export type StyledWrapperProps = { isFocus: boolean; isDisabled: boolean };

export const StyledWrapper = styled.div(() => {
  return css<StyledWrapperProps>`
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 2px;
    transition: all 0.3s;
    padding: 4px 11px;

    &:hover {
      ${(props) => !props.isDisabled && `border-color: #40a9ff;`}
    }

    ${(props) =>
      !props.isDisabled &&
      props.isFocus &&
      `
            box-shadow: 0 0 0 2px #1890ff33;
            border-color: #40a9ff;
        `}
  `;
});

export type StyledWrapperType = typeof StyledWrapper;

export const StyledDropdownMenu = styled(Menu)`
  max-height: 300px;
  overflow: auto;

  .ant-dropdown-menu-item {
    padding: 0;
  }
`;

export const StyledControl = styled.div<{ isDivider?: boolean }>`
  transition: all 0.3s;
  //padding: 0 6px;
  ${(props) =>
    props.isDivider &&
    `
        border-right: 1px solid #d9d9d9;
    `}

  &:hover {
    background: ${(props) => props.theme.colors.gray3};
  }
`;

export const StyledControlsBlock = styled.div`
  display: flex;
  border-right: 1px solid #d9d9d9;
`;

export const StyledDropdownControl = styled.div``;
