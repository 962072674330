import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';
import { ModelsItem } from './components/models-item';

const gridContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.CarFragment];

export const ModelsTabEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { gridContentData } = React.useMemo(() => {
    const gridContentData = getPlaceholderedPath({
      node,
      commonPath: gridContentPath,
    });

    return { gridContentData };
  }, [node]);

  const disabled = gridContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={gridContentData.path}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <ModelsItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={gridContentData.path}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={(node) => node}
              isDisabled={disabled}
              label="Добавить модель"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
