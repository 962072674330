import { ContentVolkswagenV1Widget, SnippetPathByName } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Alert } from 'antd';
import get from 'lodash/get';
import React, { useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { useCopySnippet } from 'src/hooks';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { useNodesToComponents } from '../../../../hooks/use-nodes-to-components';
import { SiblingComponentPosition } from '../../containers/content-view/hooks';
import { PlaceholderViewContainer } from '../../containers/placeholder-view';
import { useContentView } from '../../contexts';
import { BlockRoot } from './block-root';
import { AddControls } from './controls/add-controls';
import { AdditionalControls } from './controls/additional-controls';

const { ErrorBoundary } = Alert;

function isPositionAbsoluteNode(node: ContentVolkswagenV1Widget): boolean {
  return Boolean(
    node?.blueprint &&
      [SnippetPathByName.Script, SnippetPathByName.SideMenu, SnippetPathByName.TopMenu].includes(node.blueprint.source),
  );
}

function isModalNode(node: ContentVolkswagenV1Widget): boolean {
  return Boolean(
    node?.blueprint &&
      [
        SnippetPathByName.ContactFocusLayer,
        SnippetPathByName.ContactsInteractionLayer,
        SnippetPathByName.DisclaimerLayer,
      ].includes(node.blueprint.source),
  );
}

function getModalName(node: ContentVolkswagenV1Widget): string {
  const modalNameData = getPlaceholderedPath({
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'modal', 'name'],
    node,
  });

  return get(node, modalNameData.path);
}

const NotVisibleSnippetMock = styled.div`
  height: 150px;
  background: repeating-linear-gradient(-45deg, #ccc, #ccc 10px, #eee 10px, #eee 20px);
  padding: 16px;
`;

const SnippetWrapper = styled.div<{ source: string }>`
  min-height: 50px;
  position: relative;

  &:after {
    position: absolute;
    content: '${(props) => props.source}';
    top: 0;
    margin-top: 16px;
    margin-left: 16px;
  }
`;

const BlockElement: React.FC<{ node: ContentVolkswagenV1Widget; isBlockHovered: boolean }> = React.memo(
  ({ node, isBlockHovered }) => {
    const components = useNodesToComponents(node?.blueprint?.content);

    if (node.placeholder) {
      return <PlaceholderViewContainer placeholder={node.placeholder} isBlockHovered={isBlockHovered} />;
    }

    if (node.blueprint) {
      // modals
      if (isModalNode(node)) {
        return (
          <NotVisibleSnippetMock>
            {node.blueprint.source}
            {components}
          </NotVisibleSnippetMock>
        );
      }

      // absolute position blocks
      if (isPositionAbsoluteNode(node)) {
        return <NotVisibleSnippetMock>{node.blueprint.source}</NotVisibleSnippetMock>;
      }

      return <SnippetWrapper source={node.blueprint.source}>{components}</SnippetWrapper>;
    }

    return null;
  },
);

type BlockElementWrapperProps = {
  $isBlueprint: boolean;
  $isPlaceholder: boolean;
  $horizontalIndent: boolean;
};

const BlockElementWrapper = styled.div<BlockElementWrapperProps>`
  position: relative;

  ${(props) =>
    props.$isBlueprint &&
    `
        background: ${props.theme.colors.gray1};
        ${props.$horizontalIndent && 'margin: 0 24px;'}
    `}

  ${(props) =>
    props.$isPlaceholder &&
    `
        background: ${props.theme.colors.blue1};
        ${props.$horizontalIndent && 'padding: 0 24px;'}
    `}
`;

const HighlightOverlay = styled.div<{ $visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, ${(props) => props.theme.colors.blue6} 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(${(props) => props.theme.colors.blue6} 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, ${(props) => props.theme.colors.blue6} 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(${(props) => props.theme.colors.blue6} 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 40px 2px, 2px 40px;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.25s ease;
  z-index: 1;
`;

const InteractionOffLayer = styled.div`
  pointer-events: none;
  user-select: none;
`;

type BlockViewProps = {
  node: ContentVolkswagenV1Widget;
  rootContent: ContentVolkswagenV1Widget[];
  index: number;
  horizontalIndent: boolean;
  isEditable: boolean;
  onAddBlock: (siblingComponentIndex: number, siblingComponentPosition: SiblingComponentPosition) => void;
  onEditBlock: (index: number) => void;
  onRemoveBlock: (index: number) => void;
  onMoveBlock: (fromIndex: number, toIndex: number) => void;
  openModal: (name: string) => void;
};

export const BlockView: React.FC<BlockViewProps> = React.memo(
  ({
    node,
    rootContent,
    index,
    horizontalIndent,
    isEditable,
    onAddBlock,
    onEditBlock,
    onRemoveBlock,
    onMoveBlock,
    openModal,
  }) => {
    const { page } = useContentView();

    const isBlueprint = Boolean(node.blueprint);
    const isPlaceholder = Boolean(node.placeholder);

    const componentElementRef = useRef(null);
    const isBlockHovered = useHoverDirty(componentElementRef);
    const { handleCopySnippet } = useCopySnippet();

    const handleCopyClick = () => handleCopySnippet(node, page.path);
    const handleEditClick = () => onEditBlock(index);
    const handleAddBlockAbove = () => onAddBlock(index, SiblingComponentPosition.Above);
    const handleAddBlockBelow = () => onAddBlock(index, SiblingComponentPosition.Below);
    const handleRemove = () => onRemoveBlock(index);
    const handleMoveUpClick = () => {
      if (rootContent[index - 1]) {
        onMoveBlock(index, index - 1);
      }
    };
    const handleMoveDownClick = () => {
      if (rootContent[index + 1]) {
        onMoveBlock(index, index + 1);
      }
    };
    const openPreviewModal = isModalNode(node) ? () => openModal(getModalName(node)) : undefined;

    if (!node.placeholder && !node.blueprint) {
      return null;
    }

    return (
      <BlockElementWrapper
        ref={componentElementRef}
        $isBlueprint={isBlueprint}
        $isPlaceholder={isPlaceholder}
        $horizontalIndent={horizontalIndent}
      >
        <HighlightOverlay $visible={isBlockHovered} />
        {isEditable && (
          <AddControls
            visible={isBlockHovered}
            onAddBlockAbove={handleAddBlockAbove}
            onAddBlockBelow={handleAddBlockBelow}
          />
        )}
        <AdditionalControls
          showMoveActions={isEditable}
          visible={isBlockHovered}
          editVisible={isBlueprint}
          favoriteVisible={isBlueprint}
          onFavoriteClick={() => null}
          onEditClick={handleEditClick}
          onCopyClick={handleCopyClick}
          onRemoveClick={handleRemove}
          onMoveUpClick={handleMoveUpClick}
          onMoveDownClick={handleMoveDownClick}
          openPreviewModal={openPreviewModal}
        />
        <ErrorBoundary message="Ошибка при рендере сниппета" description="">
          {isPlaceholder && <BlockElement node={node} isBlockHovered={isBlockHovered} />}
          {isBlueprint && (
            <InteractionOffLayer>
              <BlockRoot>
                <BlockElement node={node} isBlockHovered={isBlockHovered} />
              </BlockRoot>
            </InteractionOffLayer>
          )}
        </ErrorBoundary>
      </BlockElementWrapper>
    );
  },
);
