import { PlusOutlined } from '@ant-design/icons';
import {
  ContentVolkswagenV1FlexOrientation,
  FlexNode,
  LinkNode,
  ParagraphNode,
  StrongNode,
  TextNode,
} from '@cms/volkswagen-widgets';
import { DesignTokenSizeEnum } from '@volkswagen-onehub/components-core';
import { Button, Checkbox, Col, Form, FormProps, Input, Row } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { FormListItemContainer } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const titlePath: TreePath = ['paragraph', 'content', 0, 'strong', 'content', 0, 'text', 'content'];

const linkPath: TreePath = ['paragraph', 'content', 0, 'link', 'source'];

const linkTitlePath: TreePath = ['paragraph', 'content', 0, 'link', 'content', 0, 'text', 'content'];

const emptyContact = FlexNode.generators.node({
  styles: [
    {
      orientation: ContentVolkswagenV1FlexOrientation.Column,
      spacing: {
        vertical: DesignTokenSizeEnum.static100,
      },
    },
  ],
  content: [
    ParagraphNode.generators.node({
      content: [
        StrongNode.generators.node({
          content: [
            TextNode.generators.node({
              attributes: [
                {
                  name: TextNode.attributes.appearance,
                  values: ['copy150'],
                },
              ],
              content: '',
            }),
          ],
        }),
      ],
    }),
    ParagraphNode.generators.node({
      content: [
        LinkNode.generators.node({
          source: '',
          attributes: [
            {
              name: LinkNode.attributes.emphasis,
              values: ['none'],
            },
          ],
          content: [
            TextNode.generators.node({
              attributes: [
                {
                  name: TextNode.attributes.appearance,
                  values: ['copy150'],
                },
                {
                  name: TextNode.attributes.color,
                  values: ['#001e50'],
                },
              ],
              content: '',
            }),
          ],
        }),
      ],
    }),
  ],
});

const emptyMap = ParagraphNode.generators.node({
  content: [
    LinkNode.generators.node({
      source: '',
      attributes: [
        {
          name: LinkNode.attributes.emphasis,
          values: ['none'],
        },
      ],
      content: [
        TextNode.generators.node({
          attributes: [
            {
              name: TextNode.attributes.appearance,
              values: ['copy150'],
            },
            {
              name: TextNode.attributes.color,
              values: ['#001e50'],
            },
          ],
          content: 'Построить маршрут',
        }),
      ],
    }),
  ],
});

export const FooterContactsEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });

    return { flexData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[...flexData.path, 'content', 1, 'flex', 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <FormListItemContainer disabled={disabled} onDelete={() => remove(index)} key={field.key}>
                <Form.List name={[field.name, 'flex', 'content']}>
                  {(richtextFields, { add: richtextAdd, remove: rcihtextRemove }) => (
                    <>
                      <Form.Item
                        label="Заголовок"
                        name={[richtextFields[0]?.name, ...titlePath]}
                        fieldKey={[richtextFields[0]?.fieldKey, ...titlePath]}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Телефон"
                            name={[richtextFields[1]?.name, ...linkTitlePath]}
                            fieldKey={[richtextFields[1]?.fieldKey, ...linkTitlePath]}
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <Input disabled={disabled} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Ссылка"
                            name={[richtextFields[1]?.name, ...linkPath]}
                            fieldKey={[richtextFields[1]?.fieldKey, ...linkPath]}
                          >
                            <Input disabled={disabled} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {richtextFields[2] ? (
                        <FormListItemContainer disabled={disabled} onDelete={() => rcihtextRemove(2)} key={field.key}>
                          <Form.Item
                            label="Ссылка"
                            name={[richtextFields[2]?.name, ...linkPath]}
                            fieldKey={[richtextFields[1]?.fieldKey, ...linkPath]}
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <Input disabled={disabled} />
                          </Form.Item>
                        </FormListItemContainer>
                      ) : (
                        <Form.Item
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <Button
                            type="dashed"
                            disabled={disabled}
                            onClick={() => richtextAdd(emptyMap)}
                            icon={<PlusOutlined />}
                            block
                          >
                            Добавить ссылку геопозоции
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>
              </FormListItemContainer>
            ))}
            <Form.Item>
              <Button type="dashed" disabled={disabled} onClick={() => add(emptyContact)} icon={<PlusOutlined />} block>
                Добавить ссылку
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
