import { AttributesV1Attribute } from '@cms/volkswagen-widgets';
import { Col, Row, Form, Input, InputProps } from 'antd';
import React from 'react';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type ButtonInputsProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  attributeName: string;
};

export const ButtonInputs: React.FC<ButtonInputsProps> = ({ value = [], onChange, attributeName }) => {
  const currentValue = value.find(({ name }) => name === attributeName);

  const handleValueChange: (index: number) => InputProps['onChange'] = (index) => (e) => {
    if (!currentValue) {
      const newValue = [
        ...value,
        {
          name: attributeName,
          values: new Array(2)
            .fill('')
            .map((attributeValue, valueIndex) => (valueIndex === index ? e.target.value : attributeValue)),
        },
      ];

      onChange && onChange(newValue);

      return;
    }

    const newValue = value?.map((attribute) =>
      attribute.name === attributeName
        ? {
            ...attribute,
            values: [
              ...(attribute.values?.slice(0, index) || []),
              e.target.value,
              ...(attribute.values?.slice(index + 1) || []),
            ],
          }
        : attribute,
    );

    onChange && onChange(newValue);
  };

  return (
    <Row gutter={16}>
      <Col>
        <Form.Item label="Текст">
          <Input value={currentValue?.values?.[0] || ''} onChange={handleValueChange(0)} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label="Ссылка">
          <Input value={currentValue?.values?.[1] || ''} onChange={handleValueChange(1)} />
        </Form.Item>
      </Col>
    </Row>
  );
};
