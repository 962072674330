import { ContentFilesV1File, ContentFilesV1Folder } from '@cms/volkswagen-widgets';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { getFilePath, getFileTitle } from 'src/utils/entities';
import {
  FilesTitleTableCell,
  FilesStatusTableCell,
  FilesTableActions,
  FilesTableActionsProps,
  FilesTimeTableCell,
  FilesTypeTableCell,
} from '../../../../../components';

function getSortWeight(file: ContentFilesV1File) {
  // if (file.blueprint?.folder) return 4;
  if (file.filesV1FolderBlueprint) return 3;
  if (file.filesV1Folder) return 3;
  // if (file.blueprint?.page) return 3;
  return 1;
}

export type FilesTableProps = {
  files: ContentFilesV1File[];
  loading: boolean;
  addBreadcrumb: (breadcrumb: string) => void;
  selectedRows: ContentFilesV1File[];
  setSelectedRows: (rows: ContentFilesV1File[]) => void;
  setEditFolder: (value: ContentFilesV1Folder | boolean) => void;
  onPageSettingsClick: FilesTableActionsProps['onPageSettingsClick'];
  onSnippetViewClick: FilesTableActionsProps['onSnippetViewClick'];
  // setEditBlueprint: (value: SpacesBlueprint.Blueprint | boolean) => void;
};

export const FilesTable: React.FC<FilesTableProps> = ({
  files,
  loading,
  addBreadcrumb,
  selectedRows,
  setSelectedRows,
  setEditFolder,
  onPageSettingsClick,
  onSnippetViewClick,
  // setEditBlueprint,
}) => {
  const columns: ColumnsType<ContentFilesV1File> = [
    {
      title: 'Название',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => <FilesTitleTableCell file={record} />,
    },
    {
      title: 'Статус',
      render: (_, record) => <FilesStatusTableCell file={record} />,
    },
    {
      title: 'Дата изменения',
      render: (_, record) => <FilesTimeTableCell file={record} />,
    },
    {
      title: 'Тип',
      render: (_, record) => <FilesTypeTableCell file={record} />,
    },
    {
      title: '',
      render: (_, record) => (
        <FilesTableActions
          file={record}
          addBreadcrumb={addBreadcrumb}
          onEditFolder={setEditFolder}
          onPageSettingsClick={onPageSettingsClick}
          onSnippetViewClick={onSnippetViewClick}
          // onEditBlueprint={setEditBlueprint}
        />
      ),
    },
  ];

  const rowSelection = React.useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedRowKeys: selectedRows.map((row) => getFilePath(row)!),
      onChange: (_: React.Key[], selectedRows: ContentFilesV1File[]) => {
        setSelectedRows(selectedRows.length ? [selectedRows[selectedRows.length - 1]] : selectedRows);
      },
    }),
    [selectedRows, setSelectedRows],
  );

  const sortedContents = files.slice().sort((a, b) => {
    const weightA = getSortWeight(a);
    const weightB = getSortWeight(b);

    if (weightA === weightB) {
      return (getFileTitle(a) ?? '').localeCompare(getFileTitle(b) ?? '');
    }

    return weightB - weightA;
  });

  return (
    <Table
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rowKey={(row) => getFilePath(row)!}
      dataSource={sortedContents}
      columns={columns}
      pagination={false}
      sticky={{ offsetHeader: 64 }}
      loading={loading}
      rowSelection={rowSelection}
    />
  );
};
