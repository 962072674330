import { getLastPathParam } from 'src/utils/string';
import { useSearch } from './useSearch';

const PATH_PARAM = 'path';

type UseBreadcrumbsParams = {
  onChange?: (path: string) => void;
  key?: string;
  defaultPath?: string;
};

export type UseBreadcrumbs = {
  breadcrumbs: string[];
  lastBreadcrumb: string;
  path: string;
  selectBreadcrumb: (index: number) => void;
  addBreadcrumb: (breadcrumb: string) => void;
  clearBreadcrumbs: () => void;
  backBreadcrumb: () => void;
};

export const useBreadcrumbs = ({ onChange, key = PATH_PARAM, defaultPath }: UseBreadcrumbsParams): UseBreadcrumbs => {
  const [path = '', setPath, clearPath] = useSearch(key, defaultPath);

  // const path = new URLSearchParams(location.search).get(key) || '';
  const lastBreadcrumb = getLastPathParam(path);
  const breadcrumbs = path.split('/');

  const clearBreadcrumbs = () => {
    clearPath();
    onChange && onChange('');
  };

  const selectBreadcrumb = (index: number) => {
    const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
    const newPath = newBreadcrumbs.join('/');
    setPath(newPath);
    onChange && onChange(newPath);
  };

  const backBreadcrumb = () => {
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
    const newPath = newBreadcrumbs.join('/');
    if (newBreadcrumbs.length) {
      setPath(newPath);
    } else {
      clearBreadcrumbs();
    }
    onChange && onChange(newPath);
  };

  const addBreadcrumb = (breadcrumb: string) => {
    const newPath = path ? path + '/' + breadcrumb : breadcrumb;
    setPath(newPath);
    onChange && onChange(newPath);
  };

  return {
    breadcrumbs,
    lastBreadcrumb,
    path,
    selectBreadcrumb,
    addBreadcrumb,
    clearBreadcrumbs,
    backBreadcrumb,
  };
};
