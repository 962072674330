import {
  ContentFilesV1File,
  ContentFilesV1FilesAPIApi,
  ContentFilesV1UpdateFileProps,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentFilesV1File>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { filePath: string; body: ContentFilesV1UpdateFileProps };
type Context = { prevGetFileResponse?: ContentFilesV1File };

export type UseUpdateFileData = {
  updateFile: UseMutateFunction<Data, Error, Variables>;
  updateFileAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isUpdateLoading: boolean;
};

export function useUpdateFile(
  filesApi: ContentFilesV1FilesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseUpdateFileData {
  const queryClient = useQueryClient();

  const {
    mutate: updateFile,
    mutateAsync: updateFileAsync,
    isLoading: isUpdateLoading,
  } = useMutation<Data, Error, Variables, Context>(
    ({ body, filePath }) => filesApi.contentFilesV1FilesAPIUpdateFile(filePath, body),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка изменения файла',
          description: error?.response?.data.message,
        });
      },
      // onMutate: async ({ body }) => {
      //     const newPage = body.volkswagenV1Page;

      //     // run optimistic update only on detail page update
      //     if (newPage) {
      //         // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      //         await queryClient.cancelQueries(queryKey);

      //         // Snapshot the previous value
      //         const prevGetFileResponse =
      //             queryClient.getQueryData<ContentFilesV1File>(queryKey);

      //         // Optimistically update to the new value
      //         queryClient.setQueryData<ContentFilesV1File | undefined>(
      //             queryKey,
      //             () => {
      //                 return {
      //                     volkswagenV1Page: {
      //                         version: { revision: '', time: '' },
      //                         ...newPage,
      //                     },
      //                 };
      //             },
      //         );

      //         // Return a context object with the snapshotted value
      //         return { prevGetFileResponse };
      //     }
      // },
      // onError: (error, _, context) => {
      //     if (context?.prevGetFileResponse) {
      //         queryClient.setQueryData(
      //             queryKey,
      //             context.prevGetFileResponse,
      //         );
      //     }

      //     notification.error({
      //         message: 'Ошибка изменения файла',
      //         description: error?.response?.data.message,
      //     });
      // },
      // onSettled: () => {
      //     queryClient.invalidateQueries(queryKey);
      // },
    },
  );

  return { updateFile, updateFileAsync, isUpdateLoading };
}
