import { styled } from '@console/uikit';
import { Spin } from 'antd';
import React from 'react';
import { MainLayout } from '../../../../components';
import { EditDomainModalContainer } from '../edit-domain-modal';
import { DomainTable } from './components/domain-table';
import { MainTabHeader } from './components/main-tab-header';
import { UpdateSiteForm } from './components/update-site-form';
import { useMainTabDomains } from './hooks/useMainTabDomains';
import { useMainTabSite } from './hooks/useMainTabSite';

const StyledFormWrapper = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  padding: 28px 0 8px;
`;

export const MainTabContentContainer: React.FC = () => {
  const { form, isUpdateLoading, onUpdateSite } = useMainTabSite();
  const { domains, editDomain, loading, onChangeDomainKind, onRemoveDomain, setEditDomain } = useMainTabDomains();

  return (
    <MainLayout header={<MainTabHeader onSubmit={form.submit} loading={isUpdateLoading} />}>
      <Spin spinning={isUpdateLoading}>
        <StyledFormWrapper>
          <UpdateSiteForm form={form} onSubmit={onUpdateSite} />
          <DomainTable
            domains={domains}
            loading={loading}
            setEditDomain={setEditDomain}
            onChangeDomainKind={onChangeDomainKind}
            onRemoveDomain={onRemoveDomain}
          />
        </StyledFormWrapper>
      </Spin>

      <EditDomainModalContainer
        open={editDomain !== false}
        onClose={() => setEditDomain(false)}
        initialValues={typeof editDomain === 'boolean' ? undefined : editDomain}
      />
    </MainLayout>
  );
};
