import { ContentVolkswagenV1Style } from '@cms/volkswagen-widgets';
import { InputNumber, Table } from 'antd';
import React from 'react';

const BREAKPOINTS = ['0', '560', '960', '1280', '1600', '1920', '2560'];

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type ThemeStylesHeightInputProps = {
  themeKey: 'box' | 'flex';
  value?: ContentVolkswagenV1Style[];
  onChange?: (value: ContentVolkswagenV1Style[]) => void;
  disabled: boolean;
};

export const ThemeStylesHeightInput: React.FC<ThemeStylesHeightInputProps> = ({
  value,
  themeKey,
  onChange,
  disabled,
}) => {
  if (!value || !Array.isArray(value)) {
    throw new Error(`Invalid structure 'ThemeStylesHeightInput', not found styles`);
  }

  const breakpointStyles = React.useMemo(
    () =>
      BREAKPOINTS.reduce(
        (acc, breakpoint) => {
          const height = value.find((style) => style[themeKey]?.layout === breakpoint)?.[themeKey]?.height;
          const pxValue = height && /^[0-9]+px$/.test(height) ? Number(height.substring(0, height.length - 2)) : 0;

          return {
            ...acc,
            [breakpoint]: pxValue || 0,
          };
        },
        { header: 'Высота' },
      ),
    [value, themeKey],
  );

  const handleEdit = (layout: string, height: number | null) => {
    if (onChange) {
      // trying to find an existing style
      const style = value.find((style) => style[themeKey]?.layout === layout);

      // update style
      if (style) {
        onChange(
          value.map((style) =>
            style[themeKey]?.layout === layout
              ? {
                  ...style,
                  [themeKey]: {
                    ...style[themeKey],
                    height:
                      // remove style if height is default value
                      height ? `${height}px` : undefined,
                  },
                }
              : style,
          ),
        );
      } else {
        // create style
        // create new layout style if height is not default value
        if (height) {
          onChange([...value, { [themeKey]: { height: `${height}px`, layout } }]);
        }
      }
    }
  };

  return (
    <Table
      dataSource={[breakpointStyles]}
      rowKey="header"
      size="small"
      pagination={false}
      columns={[
        { title: 'Разрешение', dataIndex: 'header' },
        ...BREAKPOINTS.map((breakpoint, index) => ({
          title:
            index === BREAKPOINTS.length - 1
              ? `от ${breakpoint}px`
              : `от ${breakpoint}px до ${Number(BREAKPOINTS[index + 1]) - 1}px`,
          dataIndex: breakpoint,
          render: (value: number) => (
            <InputNumber
              addonAfter="px"
              disabled={disabled}
              max={1000}
              min={0}
              onChange={(newValue) => handleEdit(breakpoint, newValue)}
              value={value}
            />
          ),
        })),
      ]}
    />
  );
};
