import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { CommonAccordionItem } from '../../node-editors';

const allowedSnippets: AddSnippetButtonProps['disallowedSnippets'] = [
  SnippetName.TextSection,
  SnippetName.CopyColumnsSection,
  SnippetName.ImageSection,
  SnippetName.VideoSection,
  SnippetName.TableSection,
  SnippetName.FormSection,
  SnippetName.IframeSection,
  SnippetName.GalleryColumnSection,
  // SnippetName.FocusTeaserSection,
];

export const CopyAccordionEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const accordionData = getPlaceholderedPath({
      node,
      commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'accordion'],
    });

    const disabledAccordionData = accordionData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={accordionData.placeholdersCount === 2}
            disabled={disabledAccordionData}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            Редактируемый аккордеон
          </Checkbox>
        </Form.Item>
        <Form.List name={[formListFieldData.name, ...accordionData.path, 'items']}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <CommonAccordionItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabledAccordionData}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  accordionContentPath={[...sectionContentPath, ...accordionData.path, 'items']}
                  allowedSnippets={allowedSnippets}
                />
              ))}
              <AddSnippetButton
                add={add}
                mapBeforeAdd={CommonAccordionItem.mapper}
                isDisabled={disabledAccordionData}
                label="Добавить аккордеон-итем"
                allowedSnippets={allowedSnippets}
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
