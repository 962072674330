import { ContentVolkswagenV1Richtext } from '@cms/volkswagen-widgets';
import { ContentState, convertToRaw, DraftBlockType, DraftInlineStyleType, RawDraftEntity } from 'draft-js';
import { ConvertToMdxConfig, FlowContent, RawDraftContentBlockWithCustomType, TextNode } from './types';
import {
  extractNode,
  getNodesFromEntityRanges,
  getNodesFromInlineStyleRanges,
  getNodesFromWrapInlineStyleRanges,
} from './utils';

const union = <
  S extends string = DraftInlineStyleType,
  B extends DraftBlockType = DraftBlockType,
  E extends RawDraftEntity = RawDraftEntity,
>(
  nodes: FlowContent[],
  config: ConvertToMdxConfig<S, B, E>,
) => {
  return nodes.reduce<FlowContent[]>((result, node) => {
    if (config.unionBlocks) {
      if (result[result.length - 1] && config.unionBlocks(node, result[result.length - 1])) {
        const [prev, type] = extractNode(result[result.length - 1]);
        const [current] = extractNode(node);

        if (
          'content' in prev &&
          prev.content &&
          Array.isArray(prev.content) &&
          prev.content.length &&
          'content' in current &&
          current.content &&
          Array.isArray(current.content) &&
          current.content.length
        ) {
          result[result.length - 1] = {
            ...result[result.length - 1],
            [type]: {
              ...result[result.length - 1][type],
              content: union([...prev.content, ...current.content], config),
            },
          };
        }

        if (
          'items' in prev &&
          prev.items &&
          Array.isArray(prev.items) &&
          prev.items.length &&
          'items' in current &&
          current.items &&
          Array.isArray(current.items) &&
          current.items.length
        ) {
          result[result.length - 1] = {
            ...result[result.length - 1],
            [type]: {
              ...result[result.length - 1][type],
              items: union([...prev.items, ...current.items], config),
            },
          };
        }

        return result;
      }

      return [...result, node] as FlowContent[];
    }

    return [...result, node];
  }, []);
};

export const convertTo =
  <
    S extends string = DraftInlineStyleType,
    B extends DraftBlockType = DraftBlockType,
    E extends RawDraftEntity = RawDraftEntity,
  >(
    config: ConvertToMdxConfig<S, B, E>,
  ) =>
  (contentState: ContentState): ContentVolkswagenV1Richtext['content'] => {
    const rawContent = convertToRaw(contentState);

    if (
      rawContent.blocks.length === 1 &&
      rawContent.blocks[0].type === 'unstyled' &&
      rawContent.blocks[0].text === ''
    ) {
      return [];
    }

    const preparedNodes = rawContent.blocks.reduce<FlowContent[]>((result, block) => {
      const items: TextNode[] = [config.plainTextTo(block.text) as TextNode];

      const wrapperInlineStyleRanges = block.inlineStyleRanges.filter(({ style }) =>
        config.styleToWrapper(style as S, []),
      );

      const children = getNodesFromWrapInlineStyleRanges(
        getNodesFromEntityRanges(
          getNodesFromInlineStyleRanges<S>(
            block.text && block.text.length ? items : [],
            block.inlineStyleRanges,
            config.styleTo,
          ),
          block.entityRanges,
          rawContent.entityMap,
          config.entityTo,
        ),
        wrapperInlineStyleRanges,
        config.styleToWrapper,
      );

      const node = config.blockTo(block as RawDraftContentBlockWithCustomType<B>, children as FlowContent[]);

      return [...result, ...(node ? [node] : [])];
    }, []);

    return union(preparedNodes, config);
  };
