import { d6 } from '@cms/volkswagen-widgets';
import { TextAppearance } from '@volkswagen-onehub/components-core';
import { DraftDecorator } from 'draft-js';
import React from 'react';
import { DecoratorComponentProps } from '../../../types';
import { findInlines } from '../../../utils';
import { inlineTextSizeList, TextSizeInlineType } from './text-size-inline';

const { StyledModifiedText, TextTag } = d6;

type TextSizeProps = { appearance: TextSizeInlineType };

const TextSize: React.FC<DecoratorComponentProps & TextSizeProps> = ({ children, appearance }) => {
  return (
    <StyledModifiedText tag={TextTag.span} appearance={appearance as unknown as TextAppearance}>
      {children}
    </StyledModifiedText>
  );
};

export const textSizeDecorators: DraftDecorator[] = inlineTextSizeList.map((appearance) => ({
  strategy: findInlines<string>((style) => Boolean(style.find((value = '') => appearance === value))),
  component: (props: DecoratorComponentProps) => <TextSize {...props} appearance={appearance} />,
}));
