import { ActivityV1Status, ContentSitesV1Robot, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { useRobots } from '@console/api';
import { Spin } from 'antd';
import React from 'react';
import { useToggle } from 'src/hooks';
import { MainLayout } from '../../../../components';
import { useSitesContext } from '../../contexts/sites-context';
import { CreateRobotFormValues, CreateRobotModal } from './components/create-robot-modal';
import { EditRobotRulesFormValues, EditRobotRulesModal } from './components/edit-robot-rules-modal';
import { RobotsTabHeader } from './components/robots-tab-header';
import { RobotsTable } from './components/robots-table';
import { RobotsTableHeader } from './components/robots-table-header';

export const RobotsTabContentContainer: React.FC = () => {
  const { currentSite } = useSitesContext();

  const [createRobotModalIsOpen, { onToggle: openCreateRobotModal, offToggle: closeCreateRobotModal }] = useToggle();

  const [editRobot, setEditRobot] = React.useState<ContentSitesV1Robot | null>(null);

  const {
    models: { robotsResponse },
    meta: { isListLoading, isListFetching, isCreateLoading, isUpdateLoading, isRemoveLoading },
    operations: { removeRobot, updateRobotAsync, createRobotAsync },
  } = useRobots({ enabled: true, site: currentSite?.name || '' });

  const robots = robotsResponse?.robots || [];

  const handleCreateRobot = async (values: CreateRobotFormValues) => {
    const robot = await createRobotAsync({
      name: values.name,
      agent: values.agent,
      activity: { status: ActivityV1Status.Active },
      visibility: { status: VisibilityV1Status.Public },
    });

    setEditRobot(robot.data);
  };

  const handleUpdateRobot = (robot: ContentSitesV1Robot, values: EditRobotRulesFormValues) => {
    return updateRobotAsync({
      name: robot.name,
      body: {
        ...robot,
        rules: values.rules.map((rule) => ({
          allow: rule.allow,
          cleanParam: rule.cleanParam,
          crawlDelay: rule.crawlDelay,
          host: rule.host,
          sitemap: rule.sitemap,
          disallow: rule.dissalow,
        })),
      },
    });
  };

  return (
    <MainLayout header={<RobotsTabHeader />}>
      <Spin spinning={isListLoading || isListFetching || isRemoveLoading}>
        <RobotsTableHeader openCreateRobotModal={openCreateRobotModal} />
        <RobotsTable robots={robots} setEditRobot={setEditRobot} onRemoveRobot={removeRobot} />
      </Spin>

      <CreateRobotModal
        loading={isCreateLoading}
        onClose={closeCreateRobotModal}
        onCreateRobot={handleCreateRobot}
        open={createRobotModalIsOpen}
      />

      <EditRobotRulesModal
        editRobot={editRobot}
        loading={isUpdateLoading}
        onClose={() => setEditRobot(null)}
        onUpdateRobot={handleUpdateRobot}
      />
    </MainLayout>
  );
};
