import {
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from 'draft-js';

import {
  ConvertFromMdxConfig,
  ConvertToMdxConfig,
  ContractContent,
  PhrasingContent,
  StaticPhrasingContent,
  TextNode,
} from './types';

import { AttributesV1Attribute } from '@cms/volkswagen-widgets';

const strForNonContentNode = ' ';

export const extractNode = <T extends ContractContent>(node: T): [Exclude<T[keyof T], undefined>, keyof T] => {
  if (!node) {
    return [] as unknown as [Exclude<T[keyof T], undefined>, keyof T];
  }

  const keys = Object.keys(node) as (keyof ContractContent)[];
  const type = keys.find((key) => key);
  return [node[type as keyof T], type] as [Exclude<T[keyof T], undefined>, keyof T];
};

const weight = (items: ContractContent[]): number => {
  return items.reduce((result, item) => {
    const [node] = extractNode(item);

    if (node && 'content' in node && typeof node.content === 'string') {
      return result + node.content.length;
    }
    if (node && 'content' in node && Array.isArray(node.content)) {
      return result + weight(node.content as ContractContent[]);
    }

    return result;
  }, 0);
};

const splitNodeByText = (item: TextNode, index: number): [TextNode | undefined, TextNode | undefined] => {
  const content = item.text.content || '';

  if (index < 1) {
    return [undefined, item];
  }

  if (index >= content.length) {
    return [item, undefined];
  }

  return [
    {
      ...item,
      text: {
        ...item.text,
        content: content.slice(0, index),
      },
    },
    {
      ...item,
      text: {
        ...item.text,
        content: content.slice(index),
      },
    },
  ];
};

const splitNodes = (items: ContractContent[], index: number): [ContractContent[], ContractContent[]] => {
  return items.reduce<{
    offset: number;
    nodes: [ContractContent[], ContractContent[]];
  }>(
    (result, item) => {
      const [node, type] = extractNode(item);

      if (node && 'content' in node && Array.isArray(node.content)) {
        const [left, right] = splitNodes(node.content as ContractContent[], index - result.offset);

        if (left && left.length) {
          result.nodes[0] = [
            ...result.nodes[0],
            ...(node?.content && node?.content.length
              ? [
                  {
                    [type]: {
                      ...item?.[type],
                      content: left,
                    },
                  },
                ]
              : left),
          ];
        }

        if (right && right.length) {
          result.nodes[1] = [
            ...result.nodes[1],
            ...(node?.content && node?.content.length
              ? [
                  {
                    [type]: {
                      ...item?.[type],
                      content: right,
                    },
                  },
                ]
              : right),
          ];
        }
        result.offset = result.offset + weight(left) + weight(right);
      }

      if (node && 'content' in node && typeof node.content === 'string') {
        const [left, right] = splitNodeByText(item as TextNode, index - result.offset);
        result.offset = result.offset + (left?.text.content?.length || 0) + (right?.text?.content?.length || 0);

        if (left) {
          result.nodes[0] = [...result.nodes[0], left];
        }
        if (right) {
          result.nodes[1] = [...result.nodes[1], right];
        }
      }

      return result;
    },
    {
      offset: 0,
      nodes: [[], []],
    },
  ).nodes;
};

const getStyledItems = <T>(
  nodes: ContractContent[],
  style: T,
  styleConverter: ConvertToMdxConfig<T, never, never>['styleTo'],
): ContractContent[] => {
  return nodes.map((item) => {
    return styleConverter(style as T, item as StaticPhrasingContent) as ContractContent;
  });
};

export const getNodesFromInlineStyleRanges = <S extends string = string>(
  items: ContractContent[],
  inlineStyleRanges: RawDraftInlineStyleRange[],
  styleConverter: ConvertToMdxConfig<S, never, never>['styleTo'],
): ContractContent[] => {
  return inlineStyleRanges.reduce<ContractContent[]>((result, range) => {
    const [left, tail] = splitNodes(result, range.offset);
    const [styled, right] = splitNodes(tail, range.length);
    const styledItems = getStyledItems(styled, range.style as S, styleConverter);

    return [...left, ...styledItems, ...right];
  }, items);
};

export const getNodesFromEntityRanges = <E extends RawDraftEntity = RawDraftEntity>(
  items: ContractContent[],
  entityRanges: RawDraftEntityRange[],
  entityMap: RawDraftContentState['entityMap'],
  entityConverter: ConvertToMdxConfig<never, never, E>['entityTo'],
): ContractContent[] => {
  return entityRanges.reduce<ContractContent[]>((result, range) => {
    const [left, tail] = splitNodes(result, range.offset);
    const [content, right] = splitNodes(tail, range.length);
    const currentEntity = entityMap[String(range.key)];

    const entity = entityConverter(currentEntity as E, content as StaticPhrasingContent[]) as Exclude<
      PhrasingContent,
      keyof StaticPhrasingContent
    >;

    return [...left, ...(entity ? [entity] : content), ...right];
  }, items);
};

export const getNodesFromWrapInlineStyleRanges = <S extends string = string>(
  items: ContractContent[],
  inlineStyleRanges: RawDraftInlineStyleRange[],
  styleConverter: ConvertToMdxConfig<S, never, never>['styleToWrapper'],
): ContractContent[] => {
  return inlineStyleRanges.reduce<ContractContent[]>((result, range) => {
    const [left, tail] = splitNodes(result, range.offset);
    const [content, right] = splitNodes(tail, range.length);

    const styled = styleConverter(range.style as S, content) as Exclude<PhrasingContent, keyof StaticPhrasingContent>;

    return [...left, styled, ...right];
  }, items);
};

export const extractText = (content: TextNode): string => {
  return typeof content.text.content === 'string' ? content.text.content : '';
};

export const extractTextFromNodes = (nodes: ContractContent[]): string => {
  return nodes.reduce((result, node) => {
    const [extractedNode] = extractNode(node);

    if (extractedNode && 'content' in extractedNode && typeof extractedNode.content === 'string') {
      return result + extractText(node as TextNode);
    }

    if (extractedNode && 'content' in extractedNode && Array.isArray(extractedNode.content)) {
      return result + extractTextFromNodes(extractedNode.content as ContractContent[]);
    }

    if (extractedNode && 'items' in extractedNode && Array.isArray(extractedNode.items)) {
      return result + extractTextFromNodes(extractedNode.items as ContractContent[]);
    }

    if (extractedNode && !('content' in extractedNode)) {
      return result + strForNonContentNode;
    }

    return result;
  }, '');
};

type RangesType = {
  inlineStyleRanges: RawDraftContentBlock['inlineStyleRanges'];
  entityRanges: RawDraftContentBlock['entityRanges'];
  entityMap: RawDraftContentState['entityMap'];
};

type ReduceRangesType = RangesType & {
  offset: number;
};

export const extractRangesFromNodes = <S = DraftInlineStyleType, E extends RawDraftEntity = RawDraftEntity>(
  nodes: ContractContent[],
  initialEntityMap: RawDraftContentState['entityMap'],
  styleConverter: ConvertFromMdxConfig<S, never, E>['styleFrom'],
  entityConverter: ConvertFromMdxConfig<S, never, E>['entityFrom'],
  initialOffset = 0,
): RangesType => {
  const { inlineStyleRanges, entityRanges, entityMap } = nodes.reduce<ReduceRangesType>(
    (result, node) => {
      const [extractedNode, type] = extractNode(node);

      if (typeof extractedNode === 'object') {
        if (extractedNode && 'content' in extractedNode && typeof extractedNode.content === 'string') {
          const style = styleConverter(node as TextNode);

          return {
            ...result,
            inlineStyleRanges: [
              ...result.inlineStyleRanges,
              ...(style
                ? Array.isArray(style)
                  ? style.map((styleItem) => ({
                      ...(styleItem as unknown as RawDraftInlineStyleRange),
                      offset: result.offset,
                      length: weight([node]),
                    }))
                  : [
                      {
                        ...(style as unknown as RawDraftInlineStyleRange),
                        offset: result.offset,
                        length: weight([node]),
                      },
                    ]
                : []),
            ],
            offset: result.offset + weight([node]),
          };
        }

        if (extractedNode && 'content' in extractedNode && Array.isArray(extractedNode.content)) {
          const style = styleConverter(node as StaticPhrasingContent);
          const entity = entityConverter(node as PhrasingContent);

          if (style) {
            return {
              ...result,
              inlineStyleRanges: [
                ...result.inlineStyleRanges,
                {
                  ...(style as unknown as RawDraftInlineStyleRange),
                  offset: result.offset,
                  length: weight(extractedNode.content as ContractContent[]),
                },
                ...extractRangesFromNodes(
                  extractedNode.content as ContractContent[],
                  result.entityMap,
                  styleConverter,
                  entityConverter,
                  result.offset,
                ).inlineStyleRanges,
              ],
              offset: result.offset + weight(extractedNode.content as ContractContent[]),
            };
          }

          if (entity) {
            const key = Object.keys(result.entityMap).length;

            return {
              ...result,
              inlineStyleRanges: [
                ...result.inlineStyleRanges,
                ...extractRangesFromNodes(
                  extractedNode?.content as ContractContent[],
                  result.entityMap,
                  styleConverter,
                  entityConverter,
                  result.offset,
                ).inlineStyleRanges,
              ],
              entityRanges: [
                ...result.entityRanges,
                {
                  key,
                  offset: result.offset,
                  length: weight(extractedNode.content as ContractContent[]),
                },
              ],
              entityMap: {
                ...result.entityMap,
                [key]: entity,
              },
              offset: result.offset + weight(extractedNode.content as ContractContent[]),
            };
          }

          const contentRanges = extractRangesFromNodes(
            extractedNode.content as ContractContent[],
            result.entityMap,
            styleConverter,
            entityConverter,
            result.offset,
          );
          return {
            ...result,
            inlineStyleRanges: [...result.inlineStyleRanges, ...contentRanges.inlineStyleRanges],
            entityRanges: [...result.entityRanges, ...contentRanges.entityRanges],
            entityMap: {
              ...result.entityMap,
              ...contentRanges.entityMap,
            },
            offset: result.offset + weight(extractedNode.content as ContractContent[]),
          };
        }

        if (extractedNode && !('content' in extractedNode)) {
          const style = styleConverter(node as StaticPhrasingContent);
          const entity = entityConverter(node as PhrasingContent);
          const fakeNodes = [
            {
              [type]: {
                content: strForNonContentNode,
              },
            },
          ];

          if (style) {
            return {
              ...result,
              inlineStyleRanges: [
                ...result.inlineStyleRanges,
                {
                  ...(style as unknown as RawDraftInlineStyleRange),
                  offset: result.offset,
                  length: weight(fakeNodes),
                },
              ],
              offset: result.offset + weight(fakeNodes as ContractContent[]),
            };
          }

          if (entity) {
            const key = Object.keys(result.entityMap).length;

            return {
              ...result,
              inlineStyleRanges: [...result.inlineStyleRanges],
              entityRanges: [
                ...result.entityRanges,
                {
                  key,
                  offset: result.offset,
                  length: weight(fakeNodes as ContractContent[]),
                },
              ],
              entityMap: {
                ...result.entityMap,
                [key]: entity,
              },
              offset: result.offset + weight(fakeNodes as ContractContent[]),
            };
          }

          return {
            ...result,
            inlineStyleRanges: [...result.inlineStyleRanges],
            offset: result.offset + weight(fakeNodes as ContractContent[]),
          };
        }
      }

      return result;
    },
    {
      offset: initialOffset,
      inlineStyleRanges: [],
      entityRanges: [],
      entityMap: initialEntityMap,
    },
  );

  return { inlineStyleRanges, entityRanges, entityMap };
};

export const modifyTextNode = (
  list: StaticPhrasingContent[],
  modifier: (item: TextNode) => TextNode,
): StaticPhrasingContent[] => {
  return list.map((item) => {
    const [node, type] = extractNode(item);

    if (node && 'content' in node && Array.isArray(node.content)) {
      return {
        [type]: {
          ...node,
          content: modifyTextNode(node.content as StaticPhrasingContent[], modifier),
        },
      };
    }

    if (node && 'content' in node && typeof node.content === 'string') {
      return modifier(item as TextNode);
    }

    return item;
  });
};

export const extractAttributesFromObject = (
  attr: Record<string, string | undefined>,
): { attributes: AttributesV1Attribute[] } => {
  const attrKeys = Object.keys(attr);
  const attributes = attrKeys.reduce<AttributesV1Attribute[]>((acc, name) => {
    if (name && attr[name]) {
      return [
        ...acc,
        {
          name: name
            .replace(/_/g, '-')
            .replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()),
          values: [attr[name] as string],
        },
      ];
    }

    return acc;
  }, []);

  return {
    ...(attributes.length ? { attributes } : { attributes: [] }),
  };
};

export const extractObjectFromAttributes = (
  attributes?: AttributesV1Attribute[],
): Record<string, string> | undefined => {
  if (!attributes) {
    return undefined;
  }

  return attributes.reduce<Record<string, string>>((acc, attr) => {
    if (attr.name) {
      return {
        ...acc,
        [attr.name]: attr && attr.values ? attr.values[0] : '',
      };
    }
    return acc;
  }, {});
};

export const isFlowContent = (nodes: ContractContent[]): boolean =>
  nodes.every((node) => Boolean(node.heading || node.list || node.paragraph || node.placeholder || node.quoting));
