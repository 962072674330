import { ImageNode, d6, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Form, Input, Collapse, Space } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../utils/content-path';
import { EditorContainer } from '@console/uikit';
import { ImageStylesSelect, MediaPicker, StyledImageStylesSelectItem } from '../custom-inputs';
import { AttributeSelect } from '../custom-inputs';
import { StatusArea } from '../node-editors';

const { Panel } = Collapse;
const { AspectRatio } = d6;

const StyledPanel = styled(Panel)`
  &&& .ant-collapse-header {
    padding: 0;
  }
`;

type ImageInputsProps = {
  formListFieldData?: FormListFieldData;
  imagePath: TreePath;
  disabled: boolean;
  required?: boolean;
  postFix?: string;
};

export const ImageNodeInputs: React.FC<ImageInputsProps> = ({
  formListFieldData,
  imagePath,
  postFix,
  disabled,
  required,
}) => {
  return (
    <EditorContainer label={`Редактирование изображения${postFix ? ` ${postFix}` : ''}`}>
      <Space align="start" size="large">
        <StyledImageStylesSelectItem
          label={`Изображение${postFix ? ` ${postFix}` : ''}`}
          fieldKey={formListFieldData && [formListFieldData.fieldKey, ...imagePath, 'source']}
          name={[...(formListFieldData ? [formListFieldData.name, ...imagePath] : imagePath), 'source']}
          rules={
            required
              ? [
                  {
                    required: true,
                    message: 'Пожалуйста, введите значение для обязательного поля.',
                  },
                ]
              : undefined
          }
        >
          <MediaPicker disabled={disabled} />
        </StyledImageStylesSelectItem>

        <div>
          <Form.Item
            label={`Alt${postFix ? ` ${postFix}` : ''}`}
            fieldKey={formListFieldData && [formListFieldData.fieldKey, ...imagePath, 'title']}
            name={[...(formListFieldData ? [formListFieldData.name, ...imagePath] : imagePath), 'title']}
            rules={
              required
                ? [
                    {
                      required: true,
                      message: 'Пожалуйста, введите значение для обязательного поля.',
                    },
                  ]
                : undefined
            }
          >
            <Input disabled={disabled} />
          </Form.Item>

          <StatusArea
            name={[...imagePath, 'visibility']}
            formListFieldData={formListFieldData}
            checkboxProps={{
              truthyValue: VisibilityV1Status.Public,
              falsyValue: VisibilityV1Status.Private,
            }}
          >
            Видимость{postFix ? ` ${postFix}` : ''}
          </StatusArea>
        </div>
        <Collapse ghost>
          <StyledPanel header={`Настройки изображения${postFix ? ` ${postFix}` : ''}`} key="1">
            <Form.Item
              fieldKey={formListFieldData && [formListFieldData.fieldKey, ...imagePath, 'styles']}
              name={[...(formListFieldData ? [formListFieldData.name, ...imagePath] : imagePath), 'styles']}
            >
              <ImageStylesSelect disabled={disabled} />
            </Form.Item>
            <Form.Item
              label="Соотношение сторон"
              fieldKey={formListFieldData && [formListFieldData.fieldKey, ...imagePath, 'attributes']}
              name={[...(formListFieldData ? [formListFieldData.name, ...imagePath] : imagePath), 'attributes']}
            >
              <AttributeSelect
                attributeName={ImageNode.attributes.aspectRatio}
                options={[
                  {
                    label: 'Не выбрано',
                    value: '',
                  },
                  ...Object.values(AspectRatio).map((item) => ({ label: item, value: item })),
                ]}
                disabled={disabled}
              />
            </Form.Item>
          </StyledPanel>
        </Collapse>
      </Space>
    </EditorContainer>
  );
};
