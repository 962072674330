import { ContentSitesV1Site, ContentSitesV1SitesAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Site>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveSiteData = {
  removeSite: UseMutateFunction<Data, Error, Variables>;
  removeSiteAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveSite(
  sitesApi: ContentSitesV1SitesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseRemoveSiteData {
  const queryClient = useQueryClient();

  const {
    mutate: removeSite,
    mutateAsync: removeSiteAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>(
    (name) =>
      sitesApi.contentSitesV1SitesAPIDeleteSite(
        name, // siteName: string,
        undefined, // siteRevision?: string,
      ),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка удаления файла',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { removeSite, removeSiteAsync, isRemoveLoading };
}
