import dayjs, { Dayjs } from 'dayjs';

const DEFAULT_FORMAT = 'DD.MM.YYYY';
export const SERVER_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const getDate = (time?: string): Dayjs => {
  return dayjs(time);
};

export const serverTimeToDefaultFormat = (time?: string): string => {
  return getDate(time).format(DEFAULT_FORMAT);
};

export const dateToServerTime = (time?: Dayjs): string | undefined => {
  return time?.format(SERVER_TIME_FORMAT);
};
