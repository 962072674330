import { PlusOutlined } from '@ant-design/icons';
import { ContentView, FiltersView, TeaserNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Collapse, Form, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AttributeInput, AttributesSelect, FilterEditor, ThemeCheckbox } from '../../custom-inputs';
import { withSection } from '../../hocs';

const possibleContentViews = [
  { value: ContentView.List, label: 'Список' },
  { value: ContentView.ListPadding, label: 'Список (с отступом)' },
  { value: ContentView.GridTwo, label: 'Две колонки' },
  { value: ContentView.GridTwoPadding, label: 'Две колонки (с отступом)' },
  { value: ContentView.GridThree, label: 'Три колонки' },
];

const possibleFiltersViews = [
  { value: FiltersView.None, label: 'Без фильтров' },
  { value: FiltersView.Select, label: 'Селект' },
  { value: FiltersView.Tabs, label: 'Табы' },
];

const teaserPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'teaser'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const TeaserEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { teaserData } = React.useMemo(() => {
    const teaserData = getPlaceholderedPath({
      node,
      commonPath: teaserPath,
    });

    return { teaserData };
  }, [node]);

  const disabled = teaserData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={teaserData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.Item name={[formListFieldData.name, ...teaserData.path, 'attributes']} label="Вид контента">
        <AttributesSelect
          attributeName={TeaserNode.attributes.contentView}
          disabled={disabled}
          possibleValues={possibleContentViews}
        />
      </Form.Item>

      <Form.Item name={[formListFieldData.name, ...teaserData.path, 'attributes']} label="Вид фильтров">
        <AttributesSelect
          attributeName={TeaserNode.attributes.filtersView}
          disabled={disabled}
          possibleValues={possibleFiltersViews}
        />
      </Form.Item>

      <Form.Item name={[formListFieldData.name, ...teaserData.path, 'attributes']} label="Ссылка на все элементы">
        <AttributeInput attributeName={TeaserNode.attributes.showAllHref} disabled={disabled} />
      </Form.Item>

      <Form.Item name={[formListFieldData.name, ...teaserData.path, 'themes']}>
        <ThemeCheckbox
          themeName={TeaserNode.themes.showAllFilter}
          label="Автогенерируемый таб-элемент 'Все'"
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item name={[formListFieldData.name, ...teaserData.path, 'size']} label="Начальное количество элементов">
        <Input type="number" disabled={disabled} />
      </Form.Item>

      <Form.List name={[formListFieldData.name, ...teaserData.path, 'filters']}>
        {(fields, { add, remove, move }) => (
          <>
            <Collapse defaultActiveKey={['0']}>
              {fields.map((field, index) => (
                <Collapse.Panel key={field.key} header={`Фильтр #${index}`}>
                  <FilterEditor
                    key={field.key}
                    disabled={disabled}
                    move={move}
                    remove={remove}
                    field={field}
                    index={index}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                  >
                    <Form.Item
                      name={[field.name, 'attributes']}
                      fieldKey={[field.fieldKey, 'attributes']}
                      label="Группа"
                    >
                      <AttributeInput attributeName={TeaserNode.attributes.groupTitle} disabled={disabled} />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'attributes']}
                      fieldKey={[field.fieldKey, 'attributes']}
                      label="Ccылка на все элементы"
                    >
                      <AttributeInput attributeName={TeaserNode.attributes.showAllHref} disabled={disabled} />
                    </Form.Item>
                  </FilterEditor>
                </Collapse.Panel>
              ))}
            </Collapse>
            <Form.Item>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(TeaserNode.filterGenerator.entity({}))}
                icon={<PlusOutlined />}
                block
              >
                Добавить фильтр
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
});
