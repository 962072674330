import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';

const flexContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const ContactsPreviewEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { flexContentData, firstImageData, secondImageData, richtextData } = React.useMemo(() => {
      const flexContentData = getPlaceholderedPath({
        node,
        commonPath: flexContentPath,
      });
      const firstImageData = getPlaceholderedPath({
        node,
        commonPath: [
          ...flexContentData.path,
          1,
          'gallery',
          'items',
          0,
          'galleryItem',
          'content',
          0,
          'box',
          'content',
          'image',
        ],
      });
      const secondImageData = getPlaceholderedPath({
        node,
        commonPath: [
          ...flexContentData.path,
          1,
          'gallery',
          'items',
          1,
          'galleryItem',
          'content',
          0,
          'box',
          'content',
          'image',
        ],
      });
      const richtextData = getPlaceholderedPath({
        node,
        commonPath: [...flexContentData.path, 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
      });

      return {
        flexContentData,
        firstImageData,
        secondImageData,
        richtextData,
      };
    }, [node]);

    const disabled = flexContentData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={flexContentData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек">
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <ImageNodeInputs
          formListFieldData={formListFieldData}
          imagePath={firstImageData.path}
          disabled={disabled}
          postFix="#1"
          required
        />
        <ImageNodeInputs
          formListFieldData={formListFieldData}
          imagePath={secondImageData.path}
          disabled={disabled}
          postFix="#2"
          required
        />

        <RichtextFormItem
          label="Контент"
          contentFieldName={[formListFieldData.name, ...richtextData.path]}
          disabled={disabled}
        />
      </>
    );
  },
);
