import React, { useCallback, useState } from 'react';

export enum ViewState {
  view = 'view',
  versionHistory = 'versionHistory',
  settings = 'settings',
  structure = 'structure',
  preview = 'preview',
  media = 'media',
}

export type PageDetailContextValue = {
  viewState: ViewState;
  setMainViewState: (state: ViewState) => void;
  setDefaultState: () => void;
};

export const PageDetailContext = React.createContext<PageDetailContextValue>(null as unknown as PageDetailContextValue);

export const PageDetailContextWrapper: React.FC = ({ children }) => {
  const [viewState, setViewState] = useState(ViewState.view);

  const setDefaultState = useCallback(() => {
    setViewState(ViewState.view);
  }, []);

  return (
    <PageDetailContext.Provider
      value={{
        viewState,
        setMainViewState: setViewState,
        setDefaultState,
      }}
    >
      {children}
    </PageDetailContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePageDetail = (state?: ViewState) => {
  const context = React.useContext(PageDetailContext);

  if (context === null) {
    throw new Error('usePageDetail must be used within an PageDetailContextWrapper');
  }

  return {
    ...context,
    isVisible: context.viewState === state,
  };
};
