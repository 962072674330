import { Col, Form, FormInstance, Input, InputProps, Row, Select } from 'antd';
import React from 'react';
import { EditorContainer } from '../../../../../../elements';
import { LinkEditProps } from '../types';

const { Option } = Select;

const SELECT_TARGET_OPTIONS = [
  { id: '_blank', name: 'blank' },
  { id: '_self', name: 'self' },
  { id: '_parent', name: 'parent' },
  { id: '_top', name: 'top' },
];

const SELECT_REL_OPTIONS = [
  { id: 'nofollow', name: 'nofollow' },
  { id: 'archives', name: 'archives' },
  { id: 'author', name: 'author' },
  { id: 'bookmark', name: 'bookmark' },
  { id: 'first', name: 'first' },
  { id: 'help', name: 'help' },
  { id: 'index', name: 'index' },
  { id: 'last', name: 'last' },
  { id: 'license', name: 'license' },
  { id: 'me', name: 'me' },
  { id: 'next', name: 'next' },
  { id: 'noreferrer', name: 'noreferrer' },
  { id: 'noopener', name: 'noopener' },
  { id: 'prefetch', name: 'prefetch' },
  { id: 'prev', name: 'prev' },
  { id: 'search', name: 'search' },
  { id: 'sidebar', name: 'sidebar' },
  { id: 'tag', name: 'tag' },
  { id: 'up', name: 'up' },
];

const UrlInput: React.FC<{ value?: string; onChange?: (value: string) => void }> = ({ value, onChange }) => {
  const prefixIndex = value?.indexOf(':') || -1;
  const prefix = prefixIndex >= 0 ? value?.slice(0, prefixIndex + 1) : '';
  const url = prefixIndex > 0 ? value?.slice(prefixIndex + 1) : value;

  const handleChangeUnit = (prefix: string) => {
    if (onChange) {
      onChange(`${prefix}${url}`);
    }
  };

  const handleChange: InputProps['onChange'] = (event) => {
    if (onChange) {
      onChange(`${prefix}${event.target.value}`);
    }
  };

  return (
    <Input
      value={url}
      onChange={handleChange}
      addonBefore={
        <Select defaultValue="" value={prefix} onChange={handleChangeUnit}>
          <Option value="">Без префикса</Option>
          <Option value="tel:">tel:</Option>
          <Option value="mailto:">mailto:</Option>
        </Select>
      }
    />
  );
};

export const LinkHrefInputs: React.FC<{ form: FormInstance<LinkEditProps> }> = ({ form }) => {
  return (
    <EditorContainer>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="href" label="url" rules={[{ required: true }]}>
            <UrlInput />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="rel" label="Параметр rel">
            <Select mode="multiple" showArrow>
              {SELECT_REL_OPTIONS.map((rel) => (
                <Option key={rel.id} value={rel.id}>
                  {rel.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="target" label="Параметр target">
            <Select>
              {SELECT_TARGET_OPTIONS.map((target) => (
                <Option key={target.id} value={target.id}>
                  {target.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </EditorContainer>
  );
};
