import { FilterOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Input, Space } from 'antd';
import React from 'react';

const StyledWrapper = styled(Space)`
  line-height: 1;
`;

type StructureTabControlProps = {
  onFilterOpen: () => void;
  onSearch: (value: string) => void;
  onCreateSite: () => void;
};

export const StructureTabControl: React.FC<StructureTabControlProps> = ({ onFilterOpen, onSearch, onCreateSite }) => {
  return (
    <StyledWrapper>
      <Input.Search placeholder="Поиск по таблице" allowClear enterButton onSearch={onSearch} style={{ width: 300 }} />
      <Button type="primary" onClick={onCreateSite}>
        Добавить
      </Button>
      <Button icon={<FilterOutlined />} onClick={onFilterOpen} />
    </StyledWrapper>
  );
};
