import { InfoCircleOutlined } from '@ant-design/icons';
import { d6 } from '@cms/volkswagen-widgets';
import { Button, Form, Input, InputNumber } from 'antd';
import React, { useCallback, useState } from 'react';
import { useEntity } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { DrawerFormEditor } from '../../../../../drawer-form-editor';
import { ControlButton } from '../../control-button';
import { StyledControl, StyledControlsBlock } from '../../../../elements';
import { DisclaimerEntityType } from './types';

export * from './types';
export * from './decorator';

const { Item, useForm } = Form;

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} является обязательным параметром!',
  types: {
    // eslint-disable-next-line no-template-curly-in-string
    url: '${label} - не валидное значение!',
  },
};

export const DisclaimerEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(
  ({ editorState, setEditorState }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [createOrEditDisclaimer, removeDisclaimer, existDisclaimerData, isSelectedText] = useEntity<
      d6.DisclaimerProps,
      DisclaimerEntityType
    >(editorState, setEditorState, 'DISCLAIMER', 'IMMUTABLE');
    const [form] = useForm<d6.DisclaimerProps>();

    const showModal = useCallback(() => {
      setIsModalVisible(true);
      if (existDisclaimerData) {
        form.setFieldsValue(existDisclaimerData);
      } else {
        form.resetFields();
      }
    }, [setIsModalVisible, form, existDisclaimerData]);

    const hideModal = useCallback(() => {
      setIsModalVisible(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsModalVisible, form]);

    const handleSubmit = useCallback(
      (values: d6.DisclaimerProps) => {
        createOrEditDisclaimer(values, ' ');
        hideModal();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setIsModalVisible, hideModal],
    );

    const handleRemove = useCallback(() => {
      removeDisclaimer();
      hideModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsModalVisible, hideModal]);

    return (
      <StyledControlsBlock>
        <StyledControl>
          <ControlButton
            title="Дисклеймер"
            isActive={Boolean(existDisclaimerData)}
            disabled={isSelectedText && !existDisclaimerData}
            onToggle={showModal}
          >
            <InfoCircleOutlined />
          </ControlButton>
          <DrawerFormEditor
            title={existDisclaimerData ? 'Редактирование дисклеймера' : 'Создание дисклеймера'}
            isVisible={isModalVisible}
            closeHandler={hideModal}
            buttons={[
              <Button key="closeButton" onClick={hideModal}>
                Закрыть
              </Button>,
              existDisclaimerData ? (
                <Button key="removeButton" onClick={handleRemove} type="primary" htmlType="submit">
                  Удалить дисклеймер
                </Button>
              ) : undefined,
              <Button key="submitButton" onClick={form.submit} type="primary" htmlType="submit">
                Сохранить изменения
              </Button>,
            ]}
          >
            <Form<d6.DisclaimerProps>
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              validateMessages={validateMessages}
            >
              <Item name="href" label="url" rules={[{ required: true }]}>
                <Input />
              </Item>
              <Item name="index" label="Индекс" rules={[{ required: true }]}>
                <InputNumber min={1} />
              </Item>
            </Form>
          </DrawerFormEditor>
        </StyledControl>
      </StyledControlsBlock>
    );
  },
);

DisclaimerEntity.displayName = 'DisclaimerEntity';
