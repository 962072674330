import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LinkNode, ParagraphNode, TextNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Form, FormProps, Input, Space, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AttributeInput } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const makeInitialTimeNode = () =>
  ParagraphNode.generators.node({
    content: [
      TextNode.generators.node({
        attributes: [
          {
            name: TextNode.attributes.appearance,
            values: ['label200'],
          },
        ],
        content: '',
      }),
    ],
  });

const gridContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const ContactInfoEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const {
    gridContentData,
    phoneLinkData,
    phoneTextData,
    mailLinkData,
    mailTextData,
    timeFlexData,
    locationTextData,
    locationLinkData,
  } = React.useMemo(() => {
    const gridContentData = getPlaceholderedPath({
      node,
      commonPath: gridContentPath,
    });
    // phone.id, phone.href
    const phoneLinkData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 1, 'link'],
    });
    // phone.title
    const phoneTextData = getPlaceholderedPath({
      node,
      commonPath: [...phoneLinkData.path, 'content', 0, 'text'],
    });
    // mail.href
    const mailLinkData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 3, 'link'],
    });
    // mail.title
    const mailTextData = getPlaceholderedPath({
      node,
      commonPath: [...mailLinkData.path, 'content', 0, 'text'],
    });
    // time
    const timeFlexData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 5, 'flex'],
    });
    // location
    const locationFlexData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 7, 'flex'],
    });
    // location.title
    const locationTextData = getPlaceholderedPath({
      node,
      commonPath: [...locationFlexData.path, 'content', 0, 'paragraph', 'content', 0, 'text'],
    });
    // location.mapUrl
    const locationLinkData = getPlaceholderedPath({
      node,
      commonPath: [...locationFlexData.path, 'content', 1, 'link'],
    });

    return {
      gridContentData,
      phoneLinkData,
      phoneTextData,
      mailLinkData,
      mailTextData,
      timeFlexData,
      locationTextData,
      locationLinkData,
    };
  }, [node]);

  const disabled = gridContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек">
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      {/* phone.id */}
      <Form.Item label="Идентификатор телефона" name={[...phoneLinkData.path, 'attributes']}>
        <AttributeInput attributeName={LinkNode.attributes.id} disabled={disabled} />
      </Form.Item>

      {/* phone.href */}
      <Form.Item label="Телефон ссылка" name={[...phoneLinkData.path, 'source']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* phone.title */}
      <Form.Item label="Телефон текст" name={[...phoneTextData.path, 'content']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* mail.href */}
      <Form.Item label="Почта ссылка" name={[...mailLinkData.path, 'source']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* mail.title */}
      <Form.Item label="Почта текст" name={[...mailTextData.path, 'content']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* time */}
      <Form.List name={[...timeFlexData.path, 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'paragraph', 'content', 0, 'text', 'content']}
                  fieldKey={[fieldKey, 'paragraph', 'content', 0, 'text', 'content']}
                  rules={[{ required: true }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(makeInitialTimeNode())}
                icon={<PlusOutlined />}
              >
                Добавить время
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      {/* location.mapUrl */}
      <Form.Item label="Локация ссылка" name={[...locationLinkData.path, 'source']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* location.mapUrl */}
      <Form.Item label="Локация текст" name={[...locationTextData.path, 'content']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>
    </Form>
  );
};
