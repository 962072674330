import { FormNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Input } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { withSection } from '../../hocs';
import { FormAttributeFormItem } from './components/form-attribute-form-item';

const formPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'form'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const FormEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const formData = getPlaceholderedPath({
    node,
    commonPath: formPath,
  });
  const disabled = formData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={formData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* form name edit */}
      <Form.Item
        label="Имя формы"
        name={[formListFieldData.name, ...formData.path, 'name']}
        fieldKey={[formListFieldData.fieldKey, ...formData.path, 'name']}
        rules={[{ required: true }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {/* attributes edit */}
      <FormAttributeFormItem
        attributeName={FormNode.attributes.dealers}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Дилер"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.model}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Модель"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.brand}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Бренд"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.requestConsent}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Ссылка на запрос соглашения"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.communicationConsent}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Ссылка на согласие для коммуникации"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.version}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Версия формы"
      />
      <FormAttributeFormItem
        attributeName={FormNode.attributes.basePath}
        disabled={disabled}
        formListFieldData={formListFieldData}
        formPath={formData.path}
        label="Путь до API формы"
      />
    </>
  );
});
