import { Button, Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { D6SizesInput, NodePreview } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { ColumnEditor, CopyColumnsWidthInput } from './components';
import { PlusOutlined } from '@ant-design/icons';

const StyledColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
  margin-bottom: 24px;
`;

const gridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const CopyColumnsEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const gridData = getPlaceholderedPath({
    node,
    commonPath: gridPath,
  });

  const disabled = gridData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек.`}
          >
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      {/* width1, width2 edit */}
      <Form.Item
        name={[formListFieldData.name, ...gridData.path, 'styles', 1, 'columns']}
        fieldKey={[formListFieldData.fieldKey, ...gridData.path, 'styles', 1, 'columns']}
        label="Соотношение колонок"
      >
        <CopyColumnsWidthInput disabled={disabled} />
      </Form.Item>

      {/* spacing edit */}
      <Form.Item
        name={[formListFieldData.name, ...gridData.path, 'styles', 1, 'spacing', 'horizontal']}
        fieldKey={[formListFieldData.fieldKey, ...gridData.path, 'styles', 1, 'spacing', 'horizontal']}
        label="Отступ между колонками"
      >
        <D6SizesInput disabled={disabled} />
      </Form.Item>

      <Form.List name={[formListFieldData.name, ...gridData.path, 'content']}>
        {(fields, { move }) => (
          <>
            <StyledColumnsContainer>
              {fields.map((field, index) => (
                <ColumnEditor
                  key={field.key}
                  disabled={disabled}
                  contentPath={[...sectionContentPath, ...gridData.path, 'content', index, 'flex', 'content', 0]}
                >
                  <Form.Item
                    name={[field.name, 'flex', 'content', 0]}
                    fieldKey={[field.fieldKey, 'flex', 'content', 0]}
                  >
                    <NodePreview />
                  </Form.Item>
                </ColumnEditor>
              ))}
            </StyledColumnsContainer>
            <Button type="dashed" disabled={disabled} onClick={() => move(0, 1)} icon={<PlusOutlined />} block>
              Поменять местами
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
});
