import { Button, Form, FormInstance, Input, Modal, Select } from 'antd';
import React from 'react';
import { ValidationConst } from 'src/utils/validation';

const { Option } = Select;

export type EditUserFormValues = {
  title: string;
  name: string;
  description: string;
  email: string;
  sites: string[];
};

export type EditUserModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<EditUserFormValues>;
  sitesOptions: {
    value: string;
    label: string;
  }[];
  isCreate: boolean;
  isEmptyContent?: boolean;
  onClose: () => void;
  onSubmit: (values: EditUserFormValues) => void;
};

export const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  loading,
  form,
  isCreate,
  onClose,
  onSubmit,
  sitesOptions,
}) => {
  return (
    <Modal
      title={`${isCreate ? 'Создание' : 'Редактирование'} пользователя`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Имя" name="name" rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}>
          <Input placeholder="Введите имя" disabled={!isCreate} />
        </Form.Item>
        <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
          <Input placeholder="Введите заголовок" />
        </Form.Item>
        <Form.Item label="Почта" name="email" rules={[{ required: true }]}>
          <Input placeholder="Введите email" />
        </Form.Item>
        <Form.Item label="Телефон" name="phone" rules={[{ required: true }]}>
          <Input placeholder="Введите телефон" />
        </Form.Item>
        <Form.Item label="Сайты" name="sites" rules={[{ required: true }]}>
          <Select mode="multiple" allowClear placeholder="Выберите сайты">
            <Option key="*" value="*">
              *
            </Option>
            {sitesOptions.map((site) => (
              <Option key={site.value} value={site.value}>
                {site.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
