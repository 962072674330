import React from 'react';
import baseStyled, {
  css as baseCSS,
  ThemedCssFunction,
  ThemedStyledInterface,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import { colorsTheme } from './colors';
import { ThemeDefinition } from './definitions';

export const mainTheme: ThemeDefinition = {
  colors: colorsTheme,
};

export const ThemeProvider: React.FC = ({ children }) => {
  return <StyledComponentsThemeProvider theme={mainTheme}>{children}</StyledComponentsThemeProvider>;
};

export const styled = baseStyled as ThemedStyledInterface<ThemeDefinition>;
export const css = baseCSS as ThemedCssFunction<ThemeDefinition>;
