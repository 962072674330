import { AttributesV1Attribute, d6, SectionNode, VisibilityV1Status } from '@cms/volkswagen-widgets';
import React from 'react';
import { styled } from '@console/uikit';
import { minTwoDigits } from 'src/utils/string';

const { ThemeProvider } = d6;

const Wrapper = styled.div`
  // mock grid variables
  ${Array.from({ length: 24 }, (_, i) => i + 1)
    .map((i) => `--size-grid0${minTwoDigits(i)}: calc(100% / 24 * ${i});`)
    .join('')}

  width: 100%;
  height: 100%;

  & * {
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.blue1};
`;

type PreviewBoundsProps = {
  value?: AttributesV1Attribute[];
};

export const PreviewBounds: React.FC<PreviewBoundsProps> = ({ value }) => {
  return (
    <Wrapper>
      <ThemeProvider theme="main">
        <SectionNode visibility={{ status: VisibilityV1Status.None }} attributes={value}>
          <Content />
        </SectionNode>
      </ThemeProvider>
    </Wrapper>
  );
};
