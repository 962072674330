import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const flexContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const TopMenuPromoAreaEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexContentData, titleData, imageHrefData, imageSrcData, bottomContentData } = React.useMemo(() => {
    // starting snippet point
    const flexContentData = getPlaceholderedPath({
      node,
      commonPath: flexContentPath,
    });
    // title
    const titleData = getPlaceholderedPath({
      node,
      commonPath: [...flexContentData.path, 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });
    // image.href
    const imageHrefData = getPlaceholderedPath({
      node,
      commonPath: [...flexContentData.path, 1, 'link'],
    });
    // image.src
    const imageSrcData = getPlaceholderedPath({
      node,
      commonPath: [...flexContentData.path, 1, 'link', 'content', 0, 'image'],
    });
    // bottomContent
    const bottomContentData = getPlaceholderedPath({
      node,
      commonPath: [...flexContentData.path, 2, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });

    return {
      flexContentData,
      titleData,
      imageHrefData,
      imageSrcData,
      bottomContentData,
    };
  }, [node]);

  const disabled = flexContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* title edit */}
      <RichtextFormItem label="Заголовок" contentFieldName={titleData.path} disabled={disabled} />

      {/* image.src edit */}
      <ImageNodeInputs imagePath={imageSrcData.path} disabled={disabled} required />

      {/* image.href edit */}
      <Form.Item label="Ссылка на изображении" name={[...imageHrefData.path, 'source']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* bottomContent edit */}
      <RichtextFormItem
        label="Контент под изображением"
        contentFieldName={bottomContentData.path}
        disabled={disabled}
      />
    </Form>
  );
};
