import { ContentSitesV1ListSitesResponse } from '@cms/volkswagen-widgets';
import { sitesApi, Service } from '../../../';
import { useCreateSite, UseCreateSiteData } from './actions/useCreateSite';
import { useRemoveSite, UseRemoveSiteData } from './actions/useDeleteSite';
import { ListSitesParams, useListSites } from './actions/useListSites';
import { usePublishSite, UsePublishSiteData } from './actions/usePublisheSite';
import { useUpdateSite, UseUpdateSiteData } from './actions/useUpdateSite';
import { useQueryClient } from 'react-query';

type Models = {
  sitesResponse: ContentSitesV1ListSitesResponse | undefined;
};

type Operations = {
  createSite: UseCreateSiteData['createSite'];
  createSiteAsync: UseCreateSiteData['createSiteAsync'];
  removeSite: UseRemoveSiteData['removeSite'];
  removeSiteAsync: UseRemoveSiteData['removeSiteAsync'];
  updateSite: UseUpdateSiteData['updateSite'];
  updateSiteAsync: UseUpdateSiteData['updateSiteAsync'];
  publishSite: UsePublishSiteData['publishSite'];
  publishSiteAsync: UsePublishSiteData['publishSiteAsync'];
  invalidateQueries(): void;
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isRemoveLoading: boolean;
  isUpdateLoading: boolean;
  isPublishLoading: boolean;
};

type Parameters = ListSitesParams & {
  isInvalidate?: boolean;
};

export type SitesService = Service<Models, Operations, Meta>;

export const useSites = (params: Parameters): SitesService => {
  const { path, isInvalidate } = params;
  const queryKey = ['sites', path];

  const queryClient = useQueryClient();

  const { isListFetching, isListLoading, sitesResponse } = useListSites(sitesApi, queryKey, params);

  const { createSite, createSiteAsync, isCreateLoading } = useCreateSite(sitesApi, queryKey, isInvalidate);
  const { isRemoveLoading, removeSite, removeSiteAsync } = useRemoveSite(sitesApi, queryKey, isInvalidate);
  const { isUpdateLoading, updateSite, updateSiteAsync } = useUpdateSite(sitesApi, queryKey, isInvalidate);
  const { isPublishLoading, publishSite, publishSiteAsync } = usePublishSite(sitesApi, queryKey, isInvalidate);

  return {
    models: {
      sitesResponse,
    },
    operations: {
      createSite,
      createSiteAsync,
      removeSite,
      removeSiteAsync,
      updateSite,
      updateSiteAsync,
      publishSite,
      publishSiteAsync,
      invalidateQueries() {
        queryClient.invalidateQueries(queryKey);
      },
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isRemoveLoading,
      isUpdateLoading,
      isPublishLoading,
    },
  };
};
