import { CarouselNode, ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, ThemeStylesHeightInput } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';
import { BundlesItem } from './components/bundles-item';

const themePath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'theme'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapCarouselAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  carouselItem: CarouselNode.Item.generators.entity({
    content: [node],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.BundlesCarFragment];

export const BundlesEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { themeData, cardsData, bottomContentData } = React.useMemo(() => {
    const themeData = getPlaceholderedPath({
      node,
      commonPath: themePath,
    });

    const bottomContentData = getPlaceholderedPath({
      node,
      commonPath: [
        ...themeData.path,
        'content',
        0,
        'flex',
        'content',
        1,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });

    const cardsData = getPlaceholderedPath({
      node,
      commonPath: [...themeData.path, 'content', 0, 'flex', 'content', 0, 'flex', 'content', 0, 'carousel', 'items'],
    });

    return { themeData, cardsData, bottomContentData };
  }, [node]);

  const disabled = themeData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={themeData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* cards height edit */}
      <Form.Item
        label="Высота изображений"
        fieldKey={[formListFieldData.fieldKey, ...themeData.path, 'styles']}
        name={[formListFieldData.name, ...themeData.path, 'styles']}
      >
        <ThemeStylesHeightInput disabled={disabled} themeKey="flex" />
      </Form.Item>

      {/* bottom content edit */}
      <RichtextFormItem
        label="Контент под каруселью"
        formListFieldData={formListFieldData}
        contentFieldName={bottomContentData.path}
        disabled={disabled}
      />

      <Form.List name={[formListFieldData.name, ...cardsData.path]}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <BundlesItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...sectionContentPath, ...cardsData.path]}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapCarouselAdd}
              isDisabled={disabled}
              label="Добавить модель"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </>
  );
});
