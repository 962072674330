import { PlusOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Typography } from 'antd';
import React from 'react';

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  padding: 24px;
`;

const StyledAddBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type RobotsTableHeaderProps = {
  openCreateRobotModal: () => void;
};

export const RobotsTableHeader: React.FC<RobotsTableHeaderProps> = ({ openCreateRobotModal }) => {
  return (
    <StyledContainer>
      <Typography.Title level={3}>Параметры индексации</Typography.Title>
      <StyledAddBlock>
        <Typography.Title level={5}>Добавьте группу</Typography.Title>
        <Button type="link" onClick={openCreateRobotModal} icon={<PlusOutlined />}>
          Добавить группу
        </Button>
      </StyledAddBlock>
    </StyledContainer>
  );
};
