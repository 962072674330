import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, RichtextTextNodesEditor } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { FeatureClusterItem } from './components';

const gridContentPath: TreePath = [
  'blueprint',
  'content',
  0,
  PLACEHOLDER_KEY_PATH,
  'flex',
  'content',
  0,
  'grid',
  'content',
];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.TextSection];

export const FeatureClusterEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { gridContentData, titleData, contentData, featureClusterData } = React.useMemo(() => {
    const gridContentData = getPlaceholderedPath({
      node,
      commonPath: gridContentPath,
    });
    const titleData = getPlaceholderedPath({
      node,
      commonPath: [
        ...gridContentData.path,
        0,
        'flex',
        'content',
        0,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });
    const contentData = getPlaceholderedPath({
      node,
      commonPath: [
        ...gridContentData.path,
        0,
        'flex',
        'content',
        1,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });
    const featureClusterData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 1, 'flex', 'content', 0, 'featureCluster'],
    });

    return {
      gridContentData,
      titleData,
      contentData,
      featureClusterData,
    };
  }, [node]);

  const disabled = gridContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <RichtextFormItem label="Заголовок" contentFieldName={titleData.path} disabled={disabled} />
      <RichtextFormItem label="Описание" contentFieldName={contentData.path} disabled={disabled} />
      <Form.Item label="Заголовок кластера" name={[...featureClusterData.path, 'header']}>
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>

      <Form.List name={[...featureClusterData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <FeatureClusterItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                featureClusterItem={[...featureClusterData.path, 'items']}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={FeatureClusterItem.mapper}
              isDisabled={disabled}
              label="Добавить итем"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
