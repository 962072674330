import { ContentVolkswagenV1Fit, ContentVolkswagenV1ImageStyle, ImageNode } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { InputNumber, Radio, Table, Select, Form } from 'antd';
import React, { useMemo, useState } from 'react';

const { Option } = Select;

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type ImageStylesSelectProps = {
  value?: ContentVolkswagenV1ImageStyle[];
  onChange?: (value: ContentVolkswagenV1ImageStyle[]) => void;
  withoutSize?: boolean;
  disabled: boolean;
};

export const StyledImageStylesSelectItem = styled(Form.Item)`
  flex-direction: column;
  align-items: start;
`;

const ImageSettingsSelectWrapper = styled('div')`
  display: flex;
`;

const InputSize: React.FC<{
  layout: string;
  size: string;
  handleEditSize: (layout: string, size: string) => void;
  disabled: boolean;
}> = ({ layout, size, handleEditSize, disabled }) => {
  const value = size?.slice(0, -2);
  const [unit, setUnit] = useState(size?.slice(-2));

  const handleChangeUnit = (unit: string) => {
    setUnit(unit);
    handleEditSize(layout, `${value}${unit}`);
  };

  const handleChange = (value: number) => {
    handleEditSize(layout, `${value}${unit}`);
  };

  return (
    <InputNumber
      type="number"
      value={Number(value)}
      min={0}
      addonAfter={
        <Select defaultValue="vw" value={unit} onChange={handleChangeUnit}>
          <Option value="vw">vw</Option>
          <Option value="px">px</Option>
        </Select>
      }
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export const ImageStylesSelect: React.FC<ImageStylesSelectProps> = ({ value, onChange, disabled, withoutSize }) => {
  if (!value || !Array.isArray(value)) {
    throw new Error(`Invalid structure 'ObjectFitSelect', not found styles`);
  }

  const settings = useMemo(() => value.map((val) => (!val.layout ? { ...val, layout: '0' } : val)), [value]);

  const breakpointStyles = React.useMemo(() => {
    return ImageNode.breakpoints.map((breakpoint) => ({
      layout: breakpoint,
      fit: settings.find((style) => style.layout === breakpoint)?.fit,
      size: settings.find((style) => style.layout === breakpoint)?.width,
    }));
  }, [settings]);

  const handleEditFit = (layout: string, fit: ContentVolkswagenV1Fit) => {
    if (onChange) {
      // trying to find an existing style
      const style = settings.find((style) => style.layout === layout);

      // update style
      if (style) {
        onChange(
          settings.map((style) =>
            style.layout === layout
              ? {
                  ...style,
                  fit,
                }
              : style,
          ),
        );
      } else {
        // create style
        // create new layout style if height is not default value
        if (fit) {
          onChange([...settings, { layout, fit }]);
        }
      }
    }
  };

  const handleEditSize = (layout: string, size: string) => {
    if (onChange) {
      // trying to find an existing style
      const style = settings.find((style) => style.layout === layout);

      // update style
      if (style) {
        onChange(
          settings.map((style) =>
            style.layout === layout
              ? {
                  ...style,
                  // TODO rename width to size in contracts
                  width: size,
                }
              : style,
          ),
        );
      } else {
        // create style
        // create new layout style if size is not default value
        if (size) {
          // TODO rename width to size in contracts
          onChange([...settings, { layout, width: size }]);
        }
      }
    }
  };

  return (
    <ImageSettingsSelectWrapper>
      <Table
        dataSource={breakpointStyles}
        rowKey={(row) => row.layout}
        size="small"
        pagination={false}
        columns={[
          { title: 'Breakpoint', render: (row) => `≥ ${row.layout}` },
          ...(withoutSize
            ? []
            : [
                {
                  title: 'Size (размер для srcSet)',
                  // TODO rename width to size in contracts
                  render: (row: { size: string; layout: string }) => (
                    <InputSize
                      size={row.size}
                      layout={row.layout}
                      handleEditSize={handleEditSize}
                      disabled={disabled}
                    />
                  ),
                },
              ]),
          {
            title: 'Fit',
            render: (row) => (
              <Radio.Group
                value={row.fit || ContentVolkswagenV1Fit.None}
                onChange={(event) => handleEditFit(row.layout, event.target.value)}
                disabled={disabled}
              >
                <Radio value={ContentVolkswagenV1Fit.Contain}>contain</Radio>
                <Radio value={ContentVolkswagenV1Fit.Cover}>cover</Radio>
                <Radio value={ContentVolkswagenV1Fit.Fill}>fill</Radio>
                <Radio value={ContentVolkswagenV1Fit.None}>Не задано</Radio>
              </Radio.Group>
            ),
          },
        ]}
      />
    </ImageSettingsSelectWrapper>
  );
};
