import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Form, notification } from 'antd';
import React from 'react';
import { getLastPathParam } from '../../../../utils/string';
import { useFilesContext } from '../../contexts';
import { EditPageFormValues, EditPageModal } from 'src/components/files';

export type EditPageModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const EditPageModalContainer: React.FC<EditPageModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<EditPageFormValues>();

  const {
    breadcrumbs: { path },
    editPage,
    service: {
      meta: { isCreateLoading, isUpdateLoading },
      operations: { createFileAsync, updateFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  React.useEffect(() => {
    if (open) {
      if (typeof editPage === 'boolean') {
        form.resetFields();
      } else {
        form.setFieldsValue({
          title: editPage.title,
          name: getLastPathParam(editPage.path),
        });
      }
    }
  }, [open, editPage, form]);

  const page = typeof editPage === 'boolean' ? null : editPage;

  const handleSubmit = (values: EditPageFormValues) => {
    (page
      ? updateFileAsync({
          filePath: page.path.substring(1),
          body: {
            volkswagenV1Page: {
              ...page,
              title: values.title,
              path: path ? `/${path}/${values.name}` : `/${values.name}`,
            },
          },
        })
      : createFileAsync({
          volkswagenV1Page: {
            path: path ? `/${path}/${values.name}` : `/${values.name}`,
            title: values.title,
            activity: { status: ActivityV1Status.Active },
            visibility: { status: VisibilityV1Status.Public },
          },
        })
    )
      .then(() => {
        notification.success({ message: page ? 'Страница успешно отредактирована' : 'Страница успешно создана' });
        onClose();
        resetSelectedRows();
      })
      .catch(() => null);
  };

  return (
    <EditPageModal
      open={open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      isCreate={!page}
      isEditName
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
