import { SnippetName } from '@cms/volkswagen-widgets';
import { WidgetName } from '@console/api';
import textSection from './text-section.png';
import copyAccordionSection from './copy-accordion-section.png';
import copyColumnsSection from './copy-columns-section.png';
import tableSection from './table-section.png';
import vacanciesSection from './vacancies-section.png';
import enginesSection from './engines-section.png';
import highlightCarouselSection from './highlight-carousel-section.png';
import specialSeriesSection from './special-series-section.png';
import gallerySection from './gallery-section.png';
import highlightFeatureSection from './highlight-feature-section.png';
import moodGallerySection from './mood-gallery-section.png';
import galleryColumnSection from './gallery-column-section.png';
import teaserSection from './teaser-section.png';
import imageSection from './image-section.png';
import featuresIconsSection from './features-icons-section.png';
import featuresIconsBannerSection from './features-icons-banner-section.png';
import featureCluster from './feature-cluster.png';
import videoSection from './video-section.png';
import editorialStageSection from './editorial-stage-section.png';
import promptSection from './prompt-section.png';
import promptImageSection from './prompt-section.png';
import fullscreenStageSection from './fullscreen-section.png';
import fullscreenBanner from './fullscreen-banner.png';
import presentationTeaserSection from './presentation-teaser-section.png';
import formSection from './form-section.png';
import iframeSection from './iframe-section.png';
import script from './script.png';
import modelsSection from './models-section.png';
import modelsCarouselSection from './models-carousel-section.png';
import modelsCarouselCarFragment from './models-carousel-section.png';
import bundlesSection from './bundles-section.png';
import comparisonSection from './comparison-section.png';
import carStockSection from './car-stock-section.png';
import carStockCarouselSection from './car-stock-carousel-section.png';
import tabLinks from './tab-links.png';
import tabsSection from './tabs-section.png';
import topMenu from './top-menu.png';
import footer from './footer.png';
import sideMenu from './side-menu.png';
import teamSection from './team-section.png';
import feedbacksSection from './feedbacks-section.png';
import contactsInteractionLayer from './contacts-interaction-layer.png';
import contactsPreviewSection from './contacts-preview-section.png';
import contactsSection from './contacts-section.png';
import gridIconsSection from './grid-icons-section.png';
import contactFocusLayer from './contact-focus-layer.png';

export const previews: Record<WidgetName | string, string> = {
  [SnippetName.TextSection]: textSection,
  [SnippetName.CopyAccordionSection]: copyAccordionSection,
  [SnippetName.CopyColumnsSection]: copyColumnsSection,
  [SnippetName.TableSection]: tableSection,
  [SnippetName.VacanciesGrid]: '',
  [SnippetName.VacanciesAccordions]: '',
  [SnippetName.VacanciesSection]: vacanciesSection,
  [SnippetName.EnginesSection]: enginesSection,
  [SnippetName.Card]: '',
  [SnippetName.HighlightCarouselSection]: highlightCarouselSection,
  [SnippetName.SpecialSeriesSection]: specialSeriesSection,
  [SnippetName.FeatureCluster]: featureCluster,
  [SnippetName.GallerySection]: gallerySection,
  [SnippetName.HighlightFeatureSection]: highlightFeatureSection,
  [SnippetName.MoodGallerySection]: moodGallerySection,
  [SnippetName.GalleryColumnSection]: galleryColumnSection,
  [SnippetName.TeaserSection]: teaserSection,
  [SnippetName.TeaserItem]: '',
  [SnippetName.TeaserCustom]: '',
  [SnippetName.TeaserCustomSection]: teaserSection,
  [SnippetName.ImageSection]: imageSection,
  [SnippetName.FeaturesIconsSection]: featuresIconsSection,
  [SnippetName.FeaturesIconsBannerSection]: featuresIconsBannerSection,
  [SnippetName.FeaturesIconsRow]: '',
  [SnippetName.VideoSection]: videoSection,
  [SnippetName.EditorialStageSection]: editorialStageSection,
  [SnippetName.PromptSection]: promptSection,
  [SnippetName.PromptImageSection]: promptImageSection,
  [SnippetName.FullscreenStageSection]: fullscreenStageSection,
  [SnippetName.FullscreenBanner]: fullscreenBanner,
  [SnippetName.PresentationTeaserSection]: presentationTeaserSection,
  [SnippetName.FormSection]: formSection,
  [SnippetName.IframeSection]: iframeSection,
  [SnippetName.Script]: script,
  [SnippetName.CarFragment]: '',
  [SnippetName.ModelsSection]: modelsSection,
  [SnippetName.ModelsCarouselSection]: modelsCarouselSection,
  [SnippetName.ModelsCarouselCarFragment]: modelsCarouselCarFragment,
  [SnippetName.ModelsCarouselTab]: '',
  [SnippetName.ModelsTab]: '',
  [SnippetName.BundlesCarFragment]: '',
  [SnippetName.BundlesSection]: bundlesSection,
  [SnippetName.ComparisonSection]: comparisonSection,
  [SnippetName.CarStockSection]: carStockSection,
  [SnippetName.CarStockCarouselSection]: carStockCarouselSection,
  [SnippetName.TabLinks]: tabLinks,
  [SnippetName.TabsSection]: tabsSection,
  [SnippetName.TopMenuPromoArea]: '',
  [SnippetName.TopMenu]: topMenu,
  [SnippetName.Footer]: footer,
  [SnippetName.DisclaimerLayer]: '',
  [SnippetName.SideMenu]: sideMenu,
  [SnippetName.TeamItem]: '',
  [SnippetName.FeedbacksSection]: feedbacksSection,
  [SnippetName.FeedbackItem]: '',
  [SnippetName.EnginesTab]: '',
  [SnippetName.Quote]: '',
  [SnippetName.Richtext]: '',
  [SnippetName.Image]: imageSection,
  [SnippetName.Video]: videoSection,
  [SnippetName.TeamGrid]: '',
  [SnippetName.FooterBottomNav]: '',
  [SnippetName.FooterSocials]: '',
  [SnippetName.FooterColumn]: '',
  [SnippetName.FooterContacts]: '',
  [SnippetName.ContactInfo]: '',
  [SnippetName.ContactsInteractionLayer]: contactsInteractionLayer,
  [SnippetName.ContactFocusLayer]: contactFocusLayer,
  [SnippetName.TeamSection]: teamSection,
  [SnippetName.GridIconsSection]: gridIconsSection,
  [SnippetName.ContactsTab]: '',
  [SnippetName.ContactsSection]: contactsSection,
  [SnippetName.ContactsPreviewSection]: contactsPreviewSection,
};
