import { CloudUploadOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Row, Space } from 'antd';
import React from 'react';

export type SitesListHeaderControlProps = {
  isPublishLoading: boolean;
  onClearSelection: () => void;
  onPublishClick: () => void;
  onRemoveClick: () => void;
};

const StyledRow = styled(Row)`
  height: 100%;
`;

export const SitesListHeaderControl: React.FC<SitesListHeaderControlProps> = ({
  isPublishLoading,
  onClearSelection,
  onPublishClick,
  onRemoveClick,
}) => {
  return (
    <StyledRow justify="space-between" align="middle">
      <Space>
        <Button icon={<CloudUploadOutlined />} type="default" onClick={onPublishClick} loading={isPublishLoading}>
          Опубликовать
        </Button>
        {/*<Button icon={<EditOutlined />} onClick={onRemoveClick}>*/}
        {/*  Редактировать*/}
        {/*</Button>*/}
        <Button icon={<DeleteOutlined />} onClick={onRemoveClick}>
          Удалить
        </Button>
      </Space>
      <Button icon={<UndoOutlined />} type="link" onClick={onClearSelection}>
        Сбросить выделение
      </Button>
    </StyledRow>
  );
};
