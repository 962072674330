import { ActivityV1Status } from '@cms/volkswagen-widgets';
import { useSites } from '@console/api';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import { EditUserFormValues, EditUserModal } from '../../components/edit-user-modal';
import { useUsersPageContext } from '../../contexts/users-context';

export type CreateUserModalContainerProps = {
  open: boolean;
  onClose: () => void;
  isCreate: boolean;
};

export const CreateUserModalContainer: React.FC<CreateUserModalContainerProps> = ({ open, onClose, isCreate }) => {
  const [form] = Form.useForm<EditUserFormValues>();

  const {
    editUser,
    service: {
      meta: { isCreateLoading, isUpdateLoading },
      operations: { createUserAsync, updateUserAsync },
    },
  } = useUsersPageContext();

  const service = useSites({
    enabled: open,
  });

  const {
    models: { sitesResponse },
  } = service;

  const sitesOptions =
    sitesResponse?.sites?.map((site) => ({
      value: site.name,
      label: site.title || site.name,
    })) || [];

  useEffect(() => {
    if (open) {
      if (typeof editUser === 'boolean') {
        form.resetFields();
      } else {
        form.setFieldsValue({
          ...editUser,
        });
      }
    }
  }, [open, editUser, form]);

  const user = typeof editUser === 'boolean' ? null : editUser;

  const handleSubmit = (values: EditUserFormValues) => {
    (user
      ? updateUserAsync({
          userName: user.name,
          body: {
            ...user,
            ...values,
          },
        })
      : createUserAsync({
          name: values.name,
          title: values.title,
          description: values.description,
          email: values.email,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          phone: values.phone,
          sites: values.sites,
          activity: { status: ActivityV1Status.Active },
        })
    ).then(() => {
      notification.success({
        message: user ? `Пользователь ${values.title} успешно обновлён` : `Создан новый пользователь ${values.title}`,
      });
      form.resetFields();
      onClose();
    });
  };

  return (
    <EditUserModal
      open={open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      sitesOptions={sitesOptions}
      onClose={onClose}
      onSubmit={handleSubmit}
      isCreate={isCreate}
    />
  );
};
