import { FontColorsOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { DraftStyleMap } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { StyledControl, StyledDropdownControl, StyledDropdownMenu } from '../../../elements';
import { ConfigType, useConfigByStyles, useInline } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';

const StyledIconWrapper = styled.span``;

export enum TextColorInlineType {
  'white-000' = '#ffffff',
  'black-000' = '#000000',
  'grey-100' = '#DFE4E8',
  'grey-200' = '#C2CACF',
  'grey-300' = '#96A3A8',
  'grey-400' = '#6A767D',
  'grey-500' = '#3C484D',
  'blue-100' = '#4CC7F4',
  'blue-200' = '#00B0F0',
  'blue-350' = '#0077CC',
  'blue-500' = '#00437A',
  'blue-600' = '#001E50',
  'blue-900' = '#0040C5',
  'mint-100' = '#00E6E6',
  'red-100' = '#FF335C',
  'red-200' = '#E4002C',
  'purple-100' = '#5B08A4',
  'green-100' = '#ADE5A1',
  'green-200' = '#008437',
  'yellow-000' = '#FFD100',
  transparent = 'transparent',
  'black-000-transparent-10' = 'rgba(0, 0, 0, 0.1)',
  'black-000-transparent-50' = 'rgba(0, 0, 0, 0.5)',
  'black-000-transparent-80' = 'rgba(0, 0, 0, 0.8)',
  'grey-100-transparent-40' = 'rgba(223, 228, 232, 0.4)',
  'grey-200-transparent-20' = 'rgba(194, 202, 207, 0.2)',
  'white-000-transparent-20' = 'rgba(255, 255, 255, 0.2)',
  'white-000-transparent-40' = 'rgba(255, 255, 255, 0.4)',
  'white-000-transparent-98' = 'rgba(255, 255, 255, 0.98)',
}

export type TextColorKeysInlineType = keyof typeof TextColorInlineType;

export const inlineTextColorList = Object.values<TextColorInlineType>(TextColorInlineType);

export const colorKeys: TextColorKeysInlineType[] = Object.keys(TextColorInlineType) as TextColorKeysInlineType[];

export const TEXT_COLOR_STYLES: StyleItem<TextColorInlineType>[] = colorKeys.map((color) => ({
  label: color,
  style: TextColorInlineType[color],
}));

export const textColorStyleMap: DraftStyleMap = {
  [TextColorInlineType['white-000']]: {
    color: '#ffffff',
  },
  [TextColorInlineType['black-000']]: {
    color: '#000000',
  },
  [TextColorInlineType['grey-100']]: { color: '#DFE4E8' },
  [TextColorInlineType['grey-200']]: { color: '#C2CACF' },
  [TextColorInlineType['grey-300']]: { color: '#96A3A8' },
  [TextColorInlineType['grey-400']]: { color: '#6A767D' },
  [TextColorInlineType['grey-500']]: { color: '#3C484D' },
  [TextColorInlineType['blue-100']]: { color: '#4CC7F4' },
  [TextColorInlineType['blue-200']]: { color: '#00B0F0' },
  [TextColorInlineType['blue-350']]: { color: '#0077CC' },
  [TextColorInlineType['blue-500']]: { color: '#00437A' },
  [TextColorInlineType['blue-600']]: { color: '#001E50' },
  [TextColorInlineType['blue-900']]: { color: '#0040C5' },
  [TextColorInlineType['mint-100']]: { color: '#00E6E6' },
  [TextColorInlineType['red-100']]: { color: '#FF335C' },
  [TextColorInlineType['red-200']]: { color: '#E4002C' },
  [TextColorInlineType['purple-100']]: { color: '#5B08A4' },
  [TextColorInlineType['green-100']]: { color: '#ADE5A1' },
  [TextColorInlineType['green-200']]: { color: '#008437' },
  [TextColorInlineType['yellow-000']]: { color: '#FFD100' },
  [TextColorInlineType['transparent']]: { color: 'transparent' },
  [TextColorInlineType['black-000-transparent-10']]: {
    color: 'rgba(0, 0, 0, 0.1)',
  },
  [TextColorInlineType['black-000-transparent-50']]: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  [TextColorInlineType['black-000-transparent-80']]: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
  [TextColorInlineType['grey-100-transparent-40']]: {
    color: 'rgba(223, 228, 232, 0.4)',
  },
  [TextColorInlineType['grey-200-transparent-20']]: {
    color: 'rgba(194, 202, 207, 0.2)',
  },
  [TextColorInlineType['white-000-transparent-20']]: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  [TextColorInlineType['white-000-transparent-40']]: {
    color: 'rgba(255, 255, 255, 0.4)',
  },
  [TextColorInlineType['white-000-transparent-98']]: {
    color: 'rgba(255, 255, 255, 0.98)',
  },
};

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
`;

const StyledColorView = styled.span<{ color: string }>`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid #949494;
`;

export const TextColorInline: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleTextColor, currentStyle] = useInline<TextColorInlineType>(
    editorState,
    setEditorState,
    inlineTextColorList,
  );

  const types = useConfigByStyles(ConfigType.TextColor, TEXT_COLOR_STYLES);

  const active = types.find(({ style }) => currentStyle.has(style));

  if (!types.length) {
    return null;
  }

  return (
    <StyledControl>
      <Dropdown
        trigger={['click']}
        overlay={
          <StyledDropdownMenu>
            {types.map(({ label, style, name }) => (
              <Menu.Item key={label}>
                <ControlButton title={name} isActive={currentStyle.has(style)} value={style} onToggle={toggleTextColor}>
                  <StyledLabel>
                    <StyledColorView color={style} />
                    {label}
                  </StyledLabel>
                </ControlButton>
              </Menu.Item>
            ))}
          </StyledDropdownMenu>
        }
      >
        <StyledDropdownControl>
          <ControlButton title="Цвет текста" isActive={!!active}>
            <StyledIconWrapper style={active ? textColorStyleMap[active.style] : undefined}>
              <FontColorsOutlined />
            </StyledIconWrapper>
          </ControlButton>
        </StyledDropdownControl>
      </Dropdown>
    </StyledControl>
  );
});

TextColorInline.displayName = 'TextColorInline';
