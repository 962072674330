import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { useFiles } from '@console/api';
import { Form } from 'antd';
import React from 'react';
import { EditFolderFormValues, EditFolderModal } from 'src/components/files';
import { getLastPathParam } from '../../../../utils/string';
import { useFilesContext } from '../../contexts';

export type EditFolderModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const EditFolderModalContainer: React.FC<EditFolderModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<EditFolderFormValues>();

  const {
    breadcrumbs: { path },
    editFolder,
    service: {
      meta: { isCreateLoading, isUpdateLoading },
      operations: { createFileAsync, updateFileAsync },
    },
  } = useFilesContext();

  const folder = typeof editFolder === 'boolean' ? null : editFolder;

  const filesService = useFiles({
    enabled: open && Boolean(folder),
    path: `${folder?.path}/*`,
  });

  const isExistContentFolder = Boolean(filesService.models.filesResponse?.result.total);
  const isExistContentLoading = filesService.meta.isListLoading;

  React.useEffect(() => {
    if (open) {
      if (typeof editFolder === 'boolean') {
        form.resetFields();
      } else {
        form.setFieldsValue({
          title: editFolder.title,
          name: getLastPathParam(editFolder.path),
        });
      }
    }
  }, [open, editFolder, form]);

  const handleSubmit = (values: EditFolderFormValues) => {
    (folder
      ? updateFileAsync({
          filePath: folder.path.substring(1),
          body: {
            filesV1Folder: {
              ...folder,
              title: values.title,
              path: path ? `/${path}/${values.name}` : `/${values.name}`,
            },
          },
        })
      : createFileAsync({
          filesV1Folder: {
            path: path ? `/${path}/${values.name}` : `/${values.name}`,
            title: values.title,
            activity: { status: ActivityV1Status.Active },
            visibility: { status: VisibilityV1Status.Public },
          },
        })
    )
      .then(() => {
        onClose();
      })
      .catch(() => null);
  };

  return (
    <EditFolderModal
      open={!isExistContentLoading && open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      isCreate={!folder}
      isEmptyContent={!isExistContentFolder}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
