import { NavigationMenuProps } from '@console/uikit/src/navigation-menu';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import { FilesPage, PageDetail, PagePreview, SitesPage, MediaPage } from 'src/pages';
import {
  getFilesUrl,
  getMediaUrl,
  getPageDetailUrl,
  getPagePreviewUrl,
  getSitesUrl,
  getUsersUrl,
} from 'src/utils/urls';
import { UsersPage } from '../pages/users';

import globalIcon from './icons/global.svg';
import fileIcon from './icons/file.svg';
import pictureIcon from './icons/picture.svg';
import teamIcon from './icons/team.svg';
// import profileIcon from './icons/profile.svg';

export type RouteItem = Omit<RouteProps, 'component'> & {
  name: string;
  path: string;
  component: React.ComponentType<Pick<RouteItem, 'routes' | 'redirect'>>;
  routes?: RouteItem[];
  redirect?: string;
};

export const privateRoutes: RouteItem[] = [
  {
    name: 'Список сайтов',
    path: getSitesUrl(),
    component: SitesPage,
    exact: true,
  },
  {
    name: 'Список файлов',
    path: getFilesUrl(),
    component: FilesPage,
    exact: true,
  },
  {
    name: 'Редактирование страницы',
    path: getPageDetailUrl(':path+'),
    exact: true,
    component: PageDetail,
  },
  {
    name: 'Предпросмотр страницы',
    path: getPagePreviewUrl(':path+'),
    exact: true,
    component: PagePreview,
  },
  {
    name: 'Медиа',
    path: getMediaUrl(),
    component: MediaPage,
    exact: true,
  },
  {
    name: 'Пользователи',
    path: getUsersUrl(),
    component: UsersPage,
    exact: true,
  },
];

export const navigationMenuRoutes: NavigationMenuProps['services'] = [
  {
    name: 'sites',
    title: 'Сайты',
    url: getSitesUrl(),
    icon: globalIcon,
  },
  {
    name: 'pages',
    title: 'Страницы',
    url: getFilesUrl(),
    icon: fileIcon,
  },
  {
    name: 'media',
    title: 'Медиа',
    url: getMediaUrl(),
    icon: pictureIcon,
  },
  // TODO: will be soon
  // {
  //   name: 'forms',
  //   title: 'Формы',
  //   url: '/forms',
  //   icon: profileIcon,
  // },
  {
    name: 'users',
    title: 'Пользователи',
    url: getUsersUrl(),
    icon: teamIcon,
  },
];

export const getCurrentService = (currentURL: string): NavigationMenuProps['services'][number] | undefined => {
  return navigationMenuRoutes.find(({ url }) => currentURL.indexOf(url) === 0);
};

export const defaultService: NavigationMenuProps['services'][number] = navigationMenuRoutes[0];
