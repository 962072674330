import { WidgetName } from '@console/api';

export type BlockPreviewType = {
  title: string;
  type: WidgetName;
  description: string;
};

export enum BlocksGroup {
  All = 'All',
  Texts = 'Texts',
  Galleries = 'Galleries',
  Sliders = 'Sliders',
  Banners = 'Banners',
  CTA = 'CTA',
  Tiles = 'Tiles',
  Placeholder = 'Placeholder',
  Models = 'Models',
  Navigation = 'Navigation',
  Modals = 'Modals',
  Service = 'Service',
  Items = 'Items',
  Contacts = 'Contacts',
}

export const BlocksGroupTitle: Record<BlocksGroup, string> = {
  [BlocksGroup.All]: 'Все',
  [BlocksGroup.Texts]: 'Текстовые блоки',
  [BlocksGroup.Galleries]: 'Галереи',
  [BlocksGroup.Sliders]: 'Слайдеры',
  [BlocksGroup.Banners]: 'Баннеры',
  [BlocksGroup.CTA]: 'CTA',
  [BlocksGroup.Tiles]: 'Плитки',
  [BlocksGroup.Placeholder]: 'Слот',
  [BlocksGroup.Models]: 'Модельный ряд',
  [BlocksGroup.Navigation]: 'Навигация',
  [BlocksGroup.Modals]: 'Модальные окна',
  [BlocksGroup.Contacts]: 'Контакты',
  [BlocksGroup.Items]: 'Айтемы',
  [BlocksGroup.Service]: 'Технические',
};
