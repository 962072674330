import { ContentVolkswagenV1TableRowNode } from '@cms/volkswagen-widgets';
import { Alert, Space } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { RichtextFormItem } from 'src/components/editors/node-editors';
import { TreePath } from 'src/utils/content-path';

const richTextPath: TreePath = ['content', 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'];

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type TableCellInputProps = {
  value?: ContentVolkswagenV1TableRowNode;
  onChange?: (value: ContentVolkswagenV1TableRowNode) => void;
  disabled: boolean;
  field: FormListFieldData;
  index: number;
};

export const TableCellInput: React.FC<TableCellInputProps> = ({ value, disabled, field, index }) => {
  if (value?.tableData) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`Ячейка (td) №${index + 1}`} type="info" />
        <RichtextFormItem
          label="Контент"
          noStyle
          formListFieldData={field}
          contentFieldName={['tableData', ...richTextPath]}
          disabled={disabled}
        />
      </Space>
    );
  }

  if (value?.tableHead) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`Главная ячейка (th) №${index + 1}`} type="info" />
        <RichtextFormItem
          label="Контент"
          noStyle
          formListFieldData={field}
          contentFieldName={['tableHead', ...richTextPath]}
          disabled={disabled}
        />
      </Space>
    );
  }

  return null;
};
