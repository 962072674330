import { PlusOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Typography } from 'antd';
import React from 'react';

const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  padding: 24px;
`;

const StyledAddBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type RedirectsTableHeaderProps = {
  onAddRule: () => void;
};

export const RedirectsTableHeader: React.FC<RedirectsTableHeaderProps> = ({ onAddRule }) => {
  return (
    <StyledContainer>
      <Typography.Title level={3}>Редактирование редиректов</Typography.Title>
      <StyledAddBlock>
        <Typography.Title level={5}>Добавьте правила для поисковых роботов</Typography.Title>
        <Button type="link" onClick={onAddRule} icon={<PlusOutlined />}>
          Добавить правило
        </Button>
      </StyledAddBlock>
    </StyledContainer>
  );
};
