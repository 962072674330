import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';

export type SitesPathTableCellProps = {
  site: ContentSitesV1Site;
};

export const SitesPathTableCell: React.FC<SitesPathTableCellProps> = ({ site }) => {
  return (
    <Space size="middle">
      <Typography.Text>{site.path}</Typography.Text>
    </Space>
  );
};
