import { FlexNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ThemeCheckbox } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';

export const TextEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const flexData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'],
  });
  const disabledFlexData = flexData.placeholdersCount === 0;

  const richTextPath: TreePath = [...flexData.path, 'content', 0, 'blueprint', 'content', 0, 'richtext'];

  const handlePlaceholderFlexChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <RichtextFormItem
        label="Контент"
        formListFieldData={formListFieldData}
        contentFieldName={richTextPath}
        disabled={disabledFlexData}
      />
      <Form.Item
        fieldKey={[formListFieldData.fieldKey, ...flexData.path, 'themes']}
        name={[formListFieldData.name, ...flexData.path, 'themes']}
      >
        <ThemeCheckbox label="Отступ слева у flex" themeName={FlexNode.themes.leftIndent} disabled={disabledFlexData} />
      </Form.Item>
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabledFlexData}
          onChange={(e) => handlePlaceholderFlexChange(e.target.checked)}
        >
          Редактируемое flex
        </Checkbox>
      </Form.Item>
    </>
  );
});
