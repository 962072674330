import {
  ContentSitesV1Robot,
  ContentSitesV1RobotProps,
  ContentSitesV1RobotsAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Robot>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { name: string; body: ContentSitesV1RobotProps };

export type UseUpdateRobotData = {
  updateRobot: UseMutateFunction<Data, Error, Variables>;
  updateRobotAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isUpdateLoading: boolean;
};

export function useUpdateRobot(
  robotsApi: ContentSitesV1RobotsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseUpdateRobotData {
  const queryClient = useQueryClient();

  const {
    mutate: updateRobot,
    mutateAsync: updateRobotAsync,
    isLoading: isUpdateLoading,
  } = useMutation<Data, Error, Variables>(
    ({ name, body }) =>
      robotsApi.contentSitesV1RobotsAPIUpdateRobot(
        site, // siteName: string,
        name, // robotName: string,
        body, // body: ContentSitesV1RobotProps,
        // siteRevision?: string,
        // robotRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка обновления робота',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { updateRobot, updateRobotAsync, isUpdateLoading };
}
