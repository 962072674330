import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Node, FlexNode, TableNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Divider, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ThemeCheckbox } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';
import { TableRow } from './components';

const placeholderContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const makeInitialTableRow = (): ContentVolkswagenV1Node => ({
  accordionItem: TableNode.Row.generators.entity({
    cells: [],
  }),
});

export const TableEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { flexData, tableData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: [...placeholderContentPath, 'flex'],
    });
    const tableData = getPlaceholderedPath({
      node,
      commonPath: [...placeholderContentPath, 'table'],
      placeholderContentIndex: 1,
    });

    return { flexData, tableData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* header edit */}
      <Divider orientation="right">Заголовок</Divider>
      <RichtextFormItem
        label="Контент"
        formListFieldData={formListFieldData}
        contentFieldName={[...flexData.path, 'content', 0, 'blueprint', 'content', 0, 'richtext']}
        disabled={disabled}
      />
      <Form.Item
        fieldKey={[formListFieldData.fieldKey, ...flexData.path, 'themes']}
        name={[formListFieldData.name, ...flexData.path, 'themes']}
      >
        <ThemeCheckbox label="Отступ слева у flex" themeName={FlexNode.themes.leftIndent} disabled={disabled} />
      </Form.Item>

      {/* table edit */}
      <Divider orientation="right">Строки</Divider>
      <Form.List name={[formListFieldData.name, ...tableData.path, 'rows']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <TableRow
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
              />
            ))}
            <Button
              type="dashed"
              disabled={disabled}
              onClick={() => add(makeInitialTableRow())}
              icon={<PlusOutlined />}
              block
            >
              Добавить строку
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
});
