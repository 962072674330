import { ContentVolkswagenV1Node, SnippetName, TabsNode } from '@cms/volkswagen-widgets';
import { Checkbox, Divider, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { EnginesItem } from './components/engines-item';

const tabsPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'tabs'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.EnginesTab];

const mapTabAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node =>
  TabsNode.Tab.generators.node({ content: [node], header: [] });

export const EnginesEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { tabsData } = React.useMemo(() => {
    const tabsData = getPlaceholderedPath({
      node,
      commonPath: tabsPath,
    });

    return { tabsData };
  }, [node]);

  const disabled = tabsData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={tabsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[formListFieldData.name, ...tabsData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            <Divider orientation="right">Табы</Divider>
            {fields.map((field, index) => (
              <EnginesItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...sectionContentPath, ...tabsData.path, 'items']}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapTabAdd}
              isDisabled={disabled}
              label="Добавить таб"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </>
  );
});
