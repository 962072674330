import { EditorState } from 'draft-js';
import React, { useEffect } from 'react';
import { contractConverter } from '../converter';
import { useDraftState } from '../hooks';
import { CommonProps, RichTextProps } from '../types';
import { ContentVolkswagenV1Richtext } from '@cms/volkswagen-widgets';

export type WithLocalState = CommonProps & {
  initialValue?: ContentVolkswagenV1Richtext['content'];
  onChange?: (mdx: ContentVolkswagenV1Richtext['content']) => void;
  enableReinitialize?: boolean;
};

export type WithExternalState = CommonProps & {
  value: EditorState;
  onChange: React.Dispatch<React.SetStateAction<EditorState>>;
};

export const withDraftState = (
  Component: React.ComponentType<RichTextProps>,
): React.FC<WithLocalState | WithExternalState> => {
  const WithLocalState: React.FC<WithLocalState> = React.memo(
    ({ initialValue, onChange, enableReinitialize = false, ...props }) => {
      const [editorState, setEditorState, convertedData] = useDraftState(
        contractConverter,
        initialValue,
        props.singleLine,
      );

      useEffect(() => {
        if (onChange && convertedData) {
          onChange(convertedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [convertedData]);

      return (
        <Component
          {...props}
          internalEditor={props.internalEditor ? props.internalEditor : withDraftState(Component)}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      );
    },
  );

  WithLocalState.displayName = `WithLocalState`;

  const StateWrapper: React.FC<WithLocalState | WithExternalState> = React.memo<WithLocalState | WithExternalState>(
    (props) => {
      if ('value' in props && props.value !== null && props.value !== undefined) {
        return (
          <Component
            {...props}
            internalEditor={props.internalEditor ? props.internalEditor : withDraftState(Component)}
            editorState={props.value}
            setEditorState={props.onChange}
          />
        );
      }

      if ('initialValue' in props) {
        return <WithLocalState {...props} />;
      }

      return null;
    },
  );

  StateWrapper.displayName = `StateWrapper`;
  return StateWrapper;
};
