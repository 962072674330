import { Drawer } from 'antd';
import React from 'react';

type FilesFilterProps = {
  open: boolean;
  onClose: () => void;
};

export const FilesFilter: React.FC<FilesFilterProps> = ({ open, onClose }) => {
  return (
    <Drawer title="Фильтры" placement="right" closable={true} onClose={onClose} visible={open}>
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Drawer>
  );
};
