import { SnippetName, SnippetPathByName } from '@cms/volkswagen-widgets';
import { Button, Form, FormInstance, Input, Modal, Select } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { initialSnippets } from 'src/utils/snippets';
import { getLastPathParam } from 'src/utils/string';
import { SnippetEditorInput } from './snippet-editor';

const StyledModal = styled(Modal)`
  & .ant-modal-footer {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }
`;

export type EditSnippetFormValues = {
  content: string;
  name: string;
  title: string;
};

export type EditSnippetModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<EditSnippetFormValues>;
  isCreate: boolean;
  onClose: () => void;
  onSubmit: (values: EditSnippetFormValues) => void;
};

export const EditSnippetModal: React.FC<EditSnippetModalProps> = ({
  open,
  loading,
  form,
  isCreate,
  onClose,
  onSubmit,
}) => {
  const handleTemplateChange = (name: SnippetName) => {
    const snippetPath = SnippetPathByName[name];
    const initialSnippet = initialSnippets[name];

    if (!initialSnippet || !snippetPath) return;

    form.setFieldsValue({
      content: JSON.stringify(initialSnippet.blueprint?.content?.[0]),
      name: getLastPathParam(snippetPath),
      title: name,
    });
  };

  return (
    <StyledModal
      title={`${isCreate ? 'Создание' : 'Редактирование'} сниппета`}
      visible={open}
      onCancel={onClose}
      width="80vw"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
          {isCreate ? 'Создать' : 'Сохранить'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Шаблон сниппета">
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Выберите шаблон"
            optionFilterProp="children"
            onChange={handleTemplateChange}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Object.keys(SnippetPathByName).map((name) => (
              <Select.Option key={name} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>

        <Form.Item label="Название в пути" name="name" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>

        <Form.Item label="Контент" name="content" rules={[{ required: true }]}>
          <SnippetEditorInput />
        </Form.Item>
      </Form>
    </StyledModal>
  );
};
