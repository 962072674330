import { Form, Input } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { SnippetItemBlock } from '../../../custom-inputs';

const textContentPath: TreePath = ['richtext', 'content', 1, 'paragraph', 'content', 0, 'text', 'content'];

export type BundlesFeatureProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
};

export const BundlesFeature: React.FC<BundlesFeatureProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item
        label={`Особенность #${index + 1}`}
        fieldKey={[field.fieldKey, ...textContentPath]}
        name={[field.name, ...textContentPath]}
        style={{ margin: 0 }}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};
