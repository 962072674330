import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';

export const ImageEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const imageData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'image'],
  });
  const disabledImageData = imageData.placeholdersCount === 0;

  const handlePlaceholderImageChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={imageData.placeholdersCount === 2}
          disabled={disabledImageData}
          onChange={(e) => handlePlaceholderImageChange(e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
          >
            Редактируемое изображение при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <ImageNodeInputs
        formListFieldData={formListFieldData}
        imagePath={imageData.path}
        disabled={disabledImageData}
        required
      />
    </>
  );
});
