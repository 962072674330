import React from 'react';
import { MediaCards } from '../../../components/media/media-cards';
import { useMediaPageContext } from '../contexts/media-context';

export const MediaCardsContainer: React.FC = () => {
  const {
    isListFetching,
    files,
    breadcrumbs: { addBreadcrumb },
    fileProgresses,
    removeFile,
  } = useMediaPageContext();

  return (
    <MediaCards
      files={files}
      progresses={fileProgresses}
      isLoading={isListFetching}
      addBreadcrumb={addBreadcrumb}
      removeFile={removeFile}
    />
  );
};
