import { styled } from '@console/uikit';
import React from 'react';
import { Typography, Tabs, Space } from 'antd';
import { MainLayout } from '../../../../components';

import { PublicationTabHeader, PublicationList } from './components';

const StyledContainer = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  padding: 16px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
`;

const mockData = [
  { title: 'Изменение Редиректов', url: '#' },
  { title: 'Изменения Robots.txt', url: '#' },
  { title: 'Именение Страницы Модельный ряд', url: '#' },
];

const mockHistory = new Array<{ title: string }[]>(3).fill(mockData.map(({ title }) => ({ title })));

export const PublicationTabContent: React.FC = () => {
  // TODO: Publication fetch

  return (
    <MainLayout header={<PublicationTabHeader onPublish={() => undefined} />}>
      <StyledContainer>
        <Typography.Title level={4}>Публикация</Typography.Title>
        <Tabs>
          <Tabs.TabPane tab="К публикации" key="publication">
            <PublicationList data={mockData} version="1.40.10.2" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="История" key="history">
            <StyledSpace direction="vertical" size="large">
              {mockHistory.map((data, index) => (
                <PublicationList
                  key={index}
                  data={data}
                  version="1.40.10.2"
                  createdBy={{
                    date: new Date(),
                    author: 'Иван Иванов (ivan.ivanov@gmail.com)',
                  }}
                />
              ))}
            </StyledSpace>
          </Tabs.TabPane>
        </Tabs>
      </StyledContainer>
    </MainLayout>
  );
};
