import { ContentVolkswagenV1FlexOrientation, DividerNode, FlexNode, snippetGenerators } from '@cms/volkswagen-widgets';
import { DesignTokenSizeEnum } from '@volkswagen-onehub/components-core';
import { Checkbox, Divider, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton, ContentButtonsInput } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { ColumnsEditor } from './components';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const initialDisclaimer = FlexNode.generators.node({
  styles: [
    {
      orientation: ContentVolkswagenV1FlexOrientation.Column,
      padding: {
        top: DesignTokenSizeEnum.static450,
      },
      spacing: {
        vertical: DesignTokenSizeEnum.static450,
      },
    },
  ],
  content: [
    DividerNode.generators.node({
      attributes: [
        {
          name: DividerNode.attributes.color,
          values: ['#dfe4e8'],
        },
        {
          name: DividerNode.attributes.size,
          values: ['2px'],
        },
      ],
    }),
    snippetGenerators.Richtext({ content: [] }),
  ],
});

export const FooterEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });

    return { flexData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[...flexData.path, 'content']}>
        {(fields, { add, remove }) => (
          <>
            <Divider orientation="right">Редактирование колонок</Divider>
            <ColumnsEditor
              name={[fields[1].name, 'grid', 'content']}
              contentPath={[...flexData.path, 'content', 1, 'grid', 'content']}
              disabled={disabled}
              stylesPath={[...flexData.path, 'content', 1, 'grid', 'styles']}
            />
            <ContentButtonsInput contentPath={[...flexData.path, 'content', 2]} hideReplaceButton label="Соц.сети" />
            <ContentButtonsInput
              contentPath={[...flexData.path, 'content', 3]}
              hideReplaceButton
              label="Дополнительные ссылки и копирайт"
            />
            {fields[4] ? (
              <RichtextFormItem
                label="Дисклеймер"
                formListFieldData={fields[4]}
                contentFieldName={['flex', 'content', 1, 'blueprint', 'content', 0, 'richtext']}
                disabled={disabled}
                onRemove={() => remove(fields[4].name)}
              />
            ) : (
              <AddEnrichedNodeButton
                add={add}
                nodeToEnrich={initialDisclaimer}
                isDisabled={disabled}
                label="Добавить дисклеймер"
              />
            )}
          </>
        )}
      </Form.List>
    </Form>
  );
};
