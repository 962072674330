import React from 'react';

export enum MediaStatus {
  Selected,
  Uploading,
  Uploaded,
  Error,
  Wait,
}

export type Statuses = { [id: string]: MediaStatus };
export type Progresses = { [id: string]: string };

export type MediaContextType = {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  fileStatuses: Statuses;
  setStatuses: React.Dispatch<React.SetStateAction<Statuses>>;
  fileProgresses: Progresses;
  setProgresses: React.Dispatch<React.SetStateAction<Progresses>>;
};

export type UseMediaContextType = Pick<MediaContextType, 'isModal' | 'fileStatuses' | 'fileProgresses'> & {
  isUploading: boolean;
  showModal: () => void;
  closeModal: () => void;
  setFileStatuses(statuses: Statuses): void;
  setFileProgresses(progresses: Progresses): void;
};

const MediaContext = React.createContext<MediaContextType>(null as unknown as MediaContextType);

export const MediaContextProvider: React.FC = ({ children }) => {
  const [isModal, setIsModal] = React.useState<boolean>(false);
  const [fileStatuses, setStatuses] = React.useState<Statuses>({});
  const [fileProgresses, setProgresses] = React.useState<Progresses>({});

  return (
    <MediaContext.Provider
      value={{
        isModal,
        setIsModal,
        fileStatuses,
        setStatuses,
        fileProgresses,
        setProgresses,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

export function useMediaContext(): UseMediaContextType {
  const context = React.useContext(MediaContext);
  const { isModal, fileStatuses, fileProgresses, setStatuses, setProgresses, setIsModal } = context;

  if (context === null) {
    throw new Error('useMediaContext must be used within an MediaContextProvider');
  }

  return {
    isModal,
    isUploading: Boolean(Object.values(fileStatuses).filter((status) => status === MediaStatus.Uploading).length),
    fileStatuses,
    fileProgresses,
    showModal() {
      setIsModal(true);
    },
    closeModal() {
      // setFiles((prevState) =>
      //     prevState.filter(
      //         (file) => fileStatuses[file.uid] !== MediaStatus.Uploaded,
      //     ),
      // );
      setIsModal(false);
    },
    setFileStatuses(statuses: Statuses) {
      setStatuses((prevState) => ({
        ...prevState,
        ...statuses,
      }));
    },
    setFileProgresses(progresses: Progresses) {
      setProgresses((prevState) => ({
        ...prevState,
        ...progresses,
      }));
    },
  };
}
