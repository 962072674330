import { styled } from '@console/uikit';

export const StyledTabHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:first-child {
    width: 100%;
  }

  > *:last-child {
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
