import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { useState } from 'react';
import { TreePath } from 'src/utils/content-path';

enum PlaceholderActionType {
  add = 'add',
  remove = 'remove',
}
type PlaceholderActions = Record<string, PlaceholderActionType>;

type UsePlaceholderActions = {
  onPlaceholderChange: (path: TreePath, isEnabled: boolean) => void;
  mutateNodePlaceholders: (clone: ContentVolkswagenV1Widget) => void;
};

export const usePlaceholderActions = (): UsePlaceholderActions => {
  const [placeholderActions, setPlaceholderActions] = useState<PlaceholderActions>({});

  const onPlaceholderChange = (path: TreePath, isEnabled: boolean) => {
    const jsonPath = path.join('.');

    if (placeholderActions[jsonPath]) return setPlaceholderActions((prev) => omit(prev, jsonPath));

    return setPlaceholderActions((prev) => ({
      ...prev,
      [jsonPath]: isEnabled ? PlaceholderActionType.add : PlaceholderActionType.remove,
    }));
  };

  /** Mutating a node with adding/removing placeholders */
  const mutateNodePlaceholders = (clone: ContentVolkswagenV1Widget) => {
    // Сначала находим ссылки на все объекты placeholder-ов,
    // т.к. добавление/удаление placeholder-а меняет путь следующего
    // действия с placeholder-ом
    Object.entries(placeholderActions)
      .map(([path, type]) => ({
        type,
        placeholder: get(clone, path),
      }))
      .forEach(({ placeholder, type }) => {
        if (type === PlaceholderActionType.add) {
          placeholder.content = [{ placeholder: { content: placeholder.content } }];
        }

        if (type === PlaceholderActionType.remove) {
          placeholder.content = placeholder.content[0].placeholder.content;
        }
      });
  };

  return {
    onPlaceholderChange,
    mutateNodePlaceholders,
  };
};
