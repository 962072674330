import { PictureOutlined } from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DrawerFormEditor } from '../../../../../drawer-form-editor';
import { DataType, useEntityControls, useEntity } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { ControlButton } from '../../control-button';
import { StyledControl, StyledControlsBlock } from '../../../../elements';
import { colorKeys, TextColorInlineType, TextColorKeysInlineType } from '../../inline/text-color-inline';
import { getIconComponent } from './decorator';
import { IconEntityType, IconProps, IconVariant } from './types';
import { d6 } from '@cms/volkswagen-widgets';

const { Icons } = d6;

const { Item, useForm } = Form;
const { Option } = Select;

export * from './types';
export * from './decorator';

export type IconType = keyof typeof Icons;
export const ICONS: IconType[] = Object.keys(Icons) as IconType[];

const StyledOptionBox = styled.span<Pick<IconProps, 'color'>>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};

  > *:first-child {
    margin-right: 5px;
  }
`;

export const variantOptions: { label: string; value: IconVariant }[] = [
  {
    label: 'Маленькая',
    value: 'small',
  },
  {
    label: 'Стандартная',
    value: 'default',
  },
  {
    label: 'Большая',
    value: 'large',
  },
];

export const useVariants = (kind: string): { label: string; value: IconVariant }[] => {
  const { availableVariants } = getIconComponent(kind);

  return useMemo(
    () => (kind ? variantOptions.filter((variant) => availableVariants.includes(variant.value)) : variantOptions),
    [availableVariants, kind],
  );
};

export const IconEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [currentKind, setCurrentKind] = useState<string>('');
  const icons = useEntityControls<IconType[]>(DataType.Icon, ICONS);
  const iconColors = useEntityControls<TextColorKeysInlineType[]>(DataType.IconColor, colorKeys);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createIcon, removeIcon, existIcon] = useEntity<IconProps, IconEntityType>(
    editorState,
    setEditorState,
    'ICON',
    'IMMUTABLE',
  );
  const [form] = useForm<IconProps>();

  const showModal = useCallback(() => {
    setIsModalVisible(true);
    if (existIcon) {
      form.setFieldsValue(existIcon);
    } else {
      form.resetFields();
    }
  }, [existIcon, form]);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsModalVisible, form]);

  const handleSubmit = useCallback(
    ({ kind, variant, color }: IconProps) => {
      createIcon(
        {
          kind,
          variant,
          color,
        },
        ' ',
      );
      hideModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsModalVisible, hideModal],
  );

  const filteredVariantOptions = useVariants(currentKind);

  const handleKindChange = useCallback(
    (value) => {
      form.setFieldsValue({ kind: value });
      const values = form.getFieldsValue();
      const { availableVariants } = getIconComponent(value);

      if (values.variant && !availableVariants.includes(values.variant)) {
        form.setFieldsValue({ variant: undefined });
      }
      setCurrentKind(value);
    },
    [form],
  );

  const handleRemove = useCallback(() => {
    removeIcon();
    hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsModalVisible, hideModal]);

  return (
    <StyledControlsBlock>
      <StyledControl>
        <ControlButton title="Иконка" isActive={Boolean(existIcon)} onToggle={showModal}>
          <PictureOutlined />
        </ControlButton>
        <DrawerFormEditor
          title={existIcon ? 'Редактирование иконки' : 'Добавление иконки'}
          isVisible={isModalVisible}
          closeHandler={hideModal}
          buttons={[
            <Button key="closeButton" onClick={hideModal}>
              Закрыть
            </Button>,
            existIcon ? (
              <Button key="removeButton" onClick={handleRemove} type="primary" htmlType="submit">
                Удалить иконку
              </Button>
            ) : undefined,
            <Button key="submitButton" onClick={form.submit} type="primary" htmlType="submit">
              Сохранить изменения
            </Button>,
          ]}
        >
          <Form<IconProps> layout="vertical" form={form} onFinish={handleSubmit}>
            <Item name="kind" label="Иконка" required>
              <Select onChange={handleKindChange} allowClear>
                {icons.map((icon) => {
                  const { IconComponent } = getIconComponent(icon);

                  if (!IconComponent) {
                    return null;
                  }

                  return (
                    <Option key={icon} value={icon}>
                      <StyledOptionBox>
                        <IconComponent />
                        {icon}
                      </StyledOptionBox>
                    </Option>
                  );
                })}
              </Select>
            </Item>
            <Item label="Размер" name="variant" required initialValue="default">
              <Select>
                {filteredVariantOptions.map((variant) => (
                  <Option value={variant.value}>{variant.label}</Option>
                ))}
              </Select>
            </Item>
            <Item name="color" label="Цвет">
              <Select>
                {iconColors.map((color) => {
                  return (
                    <Option key={color} value={TextColorInlineType[color]}>
                      <StyledOptionBox color={TextColorInlineType[color]}>{color}</StyledOptionBox>
                    </Option>
                  );
                })}
              </Select>
            </Item>
          </Form>
        </DrawerFormEditor>
      </StyledControl>
    </StyledControlsBlock>
  );
});

IconEntity.displayName = 'VerticalDividerEntity';
