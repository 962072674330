import { ConfigType, DataType } from '@console/uikit';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, SnippetItemBlock } from '../../../custom-inputs';
import { RichtextFormItem } from '../../../node-editors';

export type ContactsTabCTALinkProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const ContactsTabCTALink: React.FC<ContactsTabCTALinkProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
  allowedSnippets,
}) => {
  return (
    <SnippetItemBlock
      contentPath={[...contentPath, index]}
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      allowedSnippets={allowedSnippets}
      isHiddenEditing={true}
    >
      <RichtextFormItem
        label={`Иконка №${index + 1}`}
        formListFieldData={field}
        contentFieldName={['blueprint', 'content', 0, 'richtext']}
        disabled={disabled}
        controlsConfig={{
          [ConfigType.Align]: [],
          [ConfigType.Blockquote]: [],
          [ConfigType.Heading]: [],
          [ConfigType.List]: [],
        }}
        entityControlsConfig={{
          [DataType.Disclaimer]: false,
        }}
      />
    </SnippetItemBlock>
  );
};
