import { Select, SelectProps, Spin, Tag } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const { Option } = Select;

export type AutocompleteOptionItem = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

export type AutocompleteProps = Omit<SelectProps<SelectValue>, 'onChange' | 'options'> & {
  values: AutocompleteOptionItem[];
  options: AutocompleteOptionItem[];
  loadingOptions: boolean;
  onChange: (values: AutocompleteOptionItem[]) => void;
};

export const generateOptions = (path: string): AutocompleteOptionItem[] => {
  return path.split('/').map((item) => ({
    label: item,
    value: item,
  }));
};

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix)
    + .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
    &::before {
      content: '/';
      padding: 0 4px;
    }
  }
`;

const SpinWrapper = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const tagRender =
  (values: AutocompleteOptionItem[], loadingOptions: boolean): ((props: CustomTagProps) => React.ReactElement) =>
  (props) => {
    const { value, closable, onClose } = props;
    const prevent = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const option = useMemo(
      () => values.find((o) => o.value === value),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [values, value],
    );

    return (
      <Tag
        onMouseDown={prevent}
        closable={closable}
        onClose={loadingOptions ? prevent : onClose}
        icon={option?.icon}
        style={{ marginRight: 0 }}
      >
        {option?.label || value}
      </Tag>
    );
  };

export const Autocomplete: React.FC<AutocompleteProps> = React.memo<AutocompleteProps>(
  ({ values, options, loadingOptions, onChange, ...selectProps }) => {
    const handleSelect: SelectProps<SelectValue>['onSelect'] = (value) => {
      const option = options.find((o) => o.value === value);
      option && onChange([...values, option]);
    };

    const handleDeselect: SelectProps<SelectValue>['onDeselect'] = (value) => {
      const index = values.findIndex((o) => o.value === value);
      onChange(values.slice(0, index));
    };

    return (
      <StyledSelect
        mode="multiple"
        value={values.map((v) => v.value)}
        loading={loadingOptions}
        onSelect={handleSelect}
        onDeselect={handleDeselect}
        style={{ width: '100%' }}
        tagRender={tagRender(values, loadingOptions)}
        dropdownRender={(menu) =>
          loadingOptions ? (
            <SpinWrapper>
              <Spin />
            </SpinWrapper>
          ) : (
            menu
          )
        }
        {...selectProps}
      >
        {options.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </StyledSelect>
    );
  },
);

Autocomplete.displayName = 'Autocomplete';
