import { UsersV1User } from '@cms/volkswagen-widgets';
import { UsersService } from '@console/api/src/modules/user';
import React from 'react';

export enum UsersTabs {
  UserList,
  GroupList,
}

type UsersContextType = {
  tab: UsersTabs;
  setTab: (user: UsersTabs) => void;
  selectedUsers: UsersV1User[];
  setSelectedUsers: (value: UsersV1User[]) => void;
  service: UsersService;

  editUser: UsersV1User | boolean;
  setEditUser: (value: UsersV1User | boolean) => void;
};

const UsersContext = React.createContext<UsersContextType>(null as unknown as UsersContextType);

export const UsersPageContextProvider = UsersContext.Provider;

export function useUsersPageContext(): UsersContextType {
  const context = React.useContext(UsersContext);
  if (context === null) {
    throw new Error('useUsersPageContext must be used within an UsersPageContextProvider');
  }

  return context;
}
