import {
  Configuration,
  ContentFilesV1FilesAPIApi,
  ContentSitesV1DomainsAPIApi,
  ContentSitesV1RedirectsAPIApi,
  ContentSitesV1RobotsAPIApi,
  ContentSitesV1SitesAPIApi,
  ContentSitesV1FilesAPIApi,
  UsersV1UsersAPIApi,
} from '@cms/volkswagen-widgets';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || window.location.origin;

export const getPathWithFileEndpoint = (path: string): string => `${API_ENDPOINT}/v1/files${path}`;

const configuration = new Configuration({ basePath: API_ENDPOINT });
export const filesApi = new ContentFilesV1FilesAPIApi(configuration);
export const sitesApi = new ContentSitesV1SitesAPIApi(configuration);
export const sitesFilesApi = new ContentSitesV1FilesAPIApi(configuration);
export const domainsApi = new ContentSitesV1DomainsAPIApi(configuration);
export const redirectsApi = new ContentSitesV1RedirectsAPIApi(configuration);
export const robotsApi = new ContentSitesV1RobotsAPIApi(configuration);
export const usersApi = new UsersV1UsersAPIApi(configuration);

export const keycloakConfig = {
  realm: process.env.REACT_APP_AUTH_REALM,
  url: process.env.REACT_APP_AUTH_URL,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  scope: process.env.REACT_APP_AUTH_SCOPE,
  onLoad: 'login-required',
  useDangerCookies: window.location.origin.includes('localhost'),
  extraQueryParams: [['themeid', 'volkswagen']],
};
