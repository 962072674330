import { Layout as AntLayout } from 'antd';
import { styled } from '@console/uikit';
import React from 'react';

export type LayoutProps = {
  header: React.ReactNode;
  $isStickyHeader?: boolean;
};

const StyledLayout = styled(AntLayout)`
  min-height: 100vh;
`;

const StyledLayoutHeader = styled(AntLayout.Header)<Pick<LayoutProps, '$isStickyHeader'>>`
  background: ${(props) => props.theme.colors.gray1};
  ${(props) =>
    props.$isStickyHeader &&
    `
    position: sticky;
    z-index: 4;
    top: 0;
  `}
`;

const StyledLayoutContent = styled(AntLayout.Content)`
  padding: 24px;
`;

export const MainLayout: React.FC<LayoutProps> = ({ $isStickyHeader, header, children }) => {
  return (
    <StyledLayout>
      <StyledLayoutHeader $isStickyHeader={$isStickyHeader}>{header}</StyledLayoutHeader>
      <StyledLayoutContent>{children}</StyledLayoutContent>
    </StyledLayout>
  );
};
