import { ContentVolkswagenV1Node, d6, mapNodesToComponents } from '@cms/volkswagen-widgets';
import { getPathWithFileEndpoint } from '@console/api';
import React, { useMemo } from 'react';
import { replaceNode } from '../utils/snippets';
const { isAbsoluteUrl } = d6;

/**
 * Enrich image nodes. Image source requires file endpoint
 */
const enrichImageNodes = (
  nodes: ContentVolkswagenV1Node | ContentVolkswagenV1Node[] | undefined,
): ContentVolkswagenV1Node | ContentVolkswagenV1Node[] | undefined => {
  return replaceNode(nodes, (node) => {
    if (Object.keys(node).includes('image') && node?.image?.source) {
      const newNode = JSON.parse(JSON.stringify(node));

      newNode.image.source = isAbsoluteUrl(newNode.image.source)
        ? newNode.image.source
        : getPathWithFileEndpoint(newNode.image.source);

      return newNode;
    }

    if (Object.keys(node).includes('video') && (node?.video?.source || node?.video?.poster)) {
      const newNode = JSON.parse(JSON.stringify(node));

      if (node?.video?.source) {
        newNode.video.source = isAbsoluteUrl(newNode.video.source)
          ? newNode.video.source
          : getPathWithFileEndpoint(newNode.video.source);
      }

      if (node?.video?.poster) {
        newNode.video.poster = isAbsoluteUrl(newNode.video.poster)
          ? newNode.video.poster
          : getPathWithFileEndpoint(newNode.video.poster);
      }

      return newNode;
    }

    return node;
  });
};

export const useNodesToComponents = (
  nodes: ContentVolkswagenV1Node | ContentVolkswagenV1Node[] | undefined,
): React.ReactNode => {
  return useMemo(() => {
    if (nodes) {
      const enrichNodes = enrichImageNodes(nodes);
      return mapNodesToComponents(enrichNodes);
    }

    return null;
  }, [nodes]);
};
