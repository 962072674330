import { BreadcrumbsNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AttributeInput } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const BreadcrumbsEditor: React.FC<NodeEditorProps> = ({ node, onSave, formName }) => {
  const breadcrumbsData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'breadcrumbs'],
  });
  const disabled = breadcrumbsData.placeholdersCount === 0;

  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={breadcrumbsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
          >
            Редактируемые отступы при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item label="Отступ сверху" name={[...breadcrumbsData.path, 'attributes']}>
        <AttributeInput attributeName={BreadcrumbsNode.attributes.paddingTop} disabled={disabled} />
      </Form.Item>

      <Form.Item label="Отступ снизу" name={[...breadcrumbsData.path, 'attributes']}>
        <AttributeInput attributeName={BreadcrumbsNode.attributes.paddingBottom} disabled={disabled} />
      </Form.Item>
    </Form>
  );
};
