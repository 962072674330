import { DownOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Space, Typography } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { MediaFile, useUploader } from '../../../../../hooks';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledControlsWrapper = styled(Space)`
  line-height: 1;
`;

type FilesHeaderProps = {
  onSearch: (value: string) => void;
  onFilterOpen: () => void;
  onCreatePage: () => void;
  onCreateFolder: () => void;
  onCreateSnippet: () => void;
  onCreateMedia: (files: MediaFile[]) => void;
};

export const FilesHeader: React.FC<FilesHeaderProps> = ({
  onSearch,
  onFilterOpen,
  onCreateFolder,
  onCreatePage,
  onCreateMedia,
  onCreateSnippet,
}) => {
  const handleClick = useUploader(onCreateMedia);

  const menu = (
    <Menu>
      <Menu.Item key="page" onClick={onCreatePage}>
        Добавить страницу
      </Menu.Item>
      <Menu.Item key="folder" onClick={onCreateFolder}>
        Добавить папку
      </Menu.Item>
      <Menu.Item key="snippet" onClick={onCreateSnippet}>
        Добавить сниппет
      </Menu.Item>
      <Menu.Item key="media" onClick={handleClick}>
        Добавить файл
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledWrapper>
      <Typography.Text>Список файлов</Typography.Text>
      <StyledControlsWrapper>
        <Input.Search placeholder="Поиск файлов" allowClear enterButton onSearch={onSearch} style={{ width: 300 }} />
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="primary">
            Добавить <DownOutlined />
          </Button>
        </Dropdown>
        <Button icon={<FilterOutlined />} onClick={onFilterOpen} />
      </StyledControlsWrapper>
    </StyledWrapper>
  );
};
