import { ContentSitesV1ListSitesResponse, ContentSitesV1SitesAPIApi } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseListContentData = {
  sitesResponse: ContentSitesV1ListSitesResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type ListSitesParams = {
  onSuccessGet?: (sites: ContentSitesV1ListSitesResponse) => void;
  enabled: boolean;
  path?: string;
};

export function useListSites(
  sitesApi: ContentSitesV1SitesAPIApi,
  queryKey: QueryKey,
  params: ListSitesParams,
): UseListContentData {
  const { enabled, path, onSuccessGet } = params;

  const {
    data: sitesResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      sitesApi
        .contentSitesV1SitesAPIListSites(
          undefined, // activityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // activityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // hostEq?: Array<string>,
          undefined, // hostNeq?: Array<string>,
          undefined, // nameEq?: Array<string>,
          undefined, // nameNeq?: Array<string>,
          undefined, // pageSize?: number,
          undefined, // pageToken?: string,
          path ? [path] : undefined, // pathEq?: Array<string>,
          undefined, // pathNeq?: Array<string>,
          undefined, // search?: string,
          undefined, // sort?: Array<'SITE_SORT_NONE' | 'SITE_SORT_NAME_ASC' | 'SITE_SORT_NAME_DESC' | 'SITE_SORT_TIME_ASC' | 'SITE_SORT_TIME_DESC'>,
          undefined, // visibilityEq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
          undefined, // visibilityNeq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
        )
        .then((resp) => resp.data),
    {
      enabled,
      onSuccess: (sites) => {
        onSuccessGet && onSuccessGet(sites);
      },
    },
  );

  return {
    sitesResponse,
    isListLoading,
    isListFetching,
  };
}
