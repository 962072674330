import { ContentFilesV1File, ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Table, TableProps } from 'antd';
import React from 'react';
import { FilesTitleTableCell, FilesTimeTableCell, FilesTypeTableCell } from 'src/components/table';
import { UseBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import { getFilePath, getFileTitle } from 'src/utils/entities';
import { StructureTableActionsCell } from './structure-table-actions-cell';

function getSortWeight(file: ContentFilesV1File) {
  if (file.filesV1Folder) return 2;
  return 1;
}

export type StructureTableProps = {
  files: ContentFilesV1File[];
  loading: boolean;
  addBreadcrumb: UseBreadcrumbs['addBreadcrumb'];
  selectedFiles: ContentFilesV1File[];
  onSelectedFilesChange: (files: ContentFilesV1File[]) => void;
  onPageSettingsClick: (file: ContentFilesV1File) => void;
  onMainDomainView: (file: ContentFilesV1File) => void;
  onPreviewDomainView: (file: ContentFilesV1File) => void;
  currentSite: ContentSitesV1Site;
};

export const StructureTable: React.FC<StructureTableProps> = ({
  files,
  loading,
  selectedFiles,
  onSelectedFilesChange,
  onPageSettingsClick,
  onMainDomainView,
  onPreviewDomainView,
  addBreadcrumb,
  currentSite,
}) => {
  const columns: TableProps<ContentFilesV1File>['columns'] = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <FilesTitleTableCell file={record} />,
      width: '50%',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'time',
      key: 'time',
      render: (_, record) => <FilesTimeTableCell file={record} />,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <FilesTypeTableCell file={record} />,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <StructureTableActionsCell
          file={record}
          currentSite={currentSite}
          addBreadcrumb={addBreadcrumb}
          onPageSettingsClick={onPageSettingsClick}
          onMainDomainView={onMainDomainView}
          onPreviewDomainView={onPreviewDomainView}
        />
      ),
    },
  ];

  const sortedContents = files.slice().sort((a, b) => {
    const weightA = getSortWeight(a);
    const weightB = getSortWeight(b);

    if (weightA === weightB) {
      return (getFileTitle(a) ?? '').localeCompare(getFileTitle(b) ?? '');
    }

    return weightB - weightA;
  });

  const rowSelection = React.useMemo(
    () => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedRowKeys: selectedFiles.map((row) => getFilePath(row)!),
      onChange: (_: React.Key[], selectedRows: ContentFilesV1File[]) => onSelectedFilesChange(selectedRows),
    }),
    [selectedFiles, onSelectedFilesChange],
  );

  return (
    <Table
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rowKey={(row) => getFilePath(row)!}
      dataSource={sortedContents}
      columns={columns}
      pagination={false}
      sticky
      loading={loading}
      rowSelection={rowSelection}
    />
  );
};
