import { encodedIcons, ImageNode, LinkNode } from '@cms/volkswagen-widgets';
import { Checkbox, Col, Form, FormProps, Input, Row } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton, AttributesSelect, FormListItemContainer } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const emptyLink = LinkNode.generators.node({
  source: '',
  attributes: [
    {
      name: LinkNode.attributes.emphasis,
      values: ['none'],
    },
  ],
  content: [
    ImageNode.generators.node({
      attributes: [],
    }),
  ],
});

export const FooterSocialsEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });

    return { flexData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.Item
        label="Заголовок"
        name={[
          ...flexData.path,
          'content',
          0,
          'richtext',
          'content',
          0,
          'paragraph',
          'content',
          0,
          'strong',
          'content',
          0,
          'text',
          'content',
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.List name={[...flexData.path, 'content', 1, 'flex', 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <FormListItemContainer disabled={disabled} onDelete={() => remove(index)} key={field.key}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Ссылка"
                      name={[field.name, 'link', 'source']}
                      fieldKey={[field.fieldKey, 'link', 'source']}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <Input disabled={disabled} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Иконка"
                      name={[field.name, 'link', 'content', 0, 'image', 'attributes']}
                      fieldKey={[field.fieldKey, 'link', 'content', 0, 'image', 'attributes']}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <AttributesSelect
                        possibleValues={[
                          {
                            label: 'ВКонтакте',
                            value: encodedIcons.VK,
                          },
                          {
                            label: 'Одноклассники',
                            value: encodedIcons.OK,
                          },
                          {
                            label: 'Instagram',
                            value: encodedIcons.Instagram,
                          },
                          {
                            label: 'Facebook',
                            value: encodedIcons.Facebook,
                          },
                          {
                            label: 'YouTube',
                            value: encodedIcons.YouTube,
                          },
                        ]}
                        attributeName={ImageNode.attributes.encoded}
                        disabled={disabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </FormListItemContainer>
            ))}
            <Form.Item>
              <AddEnrichedNodeButton add={add} nodeToEnrich={emptyLink} isDisabled={disabled} label="Добавить ссылку" />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
