import { Spin } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';

const StyledContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredSpinner: React.FC = () => (
  <StyledContainer>
    <Spin size="large" />
  </StyledContainer>
);
