import { useFiles } from '@console/api';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import { MoveFormValues, MoveModal } from '../../../../components/files/move-modal';
import { getLastPathParam } from '../../../../utils/string';
import { useFilesContext } from '../../contexts';

export type MoveFolderModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const MoveFolderModalContainer: React.FC<MoveFolderModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<MoveFormValues>();

  const {
    moveFolder,
    service: {
      meta: { isUpdateLoading },
      operations: { updateFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  const filesService = useFiles({
    enabled: open && Boolean(moveFolder),
    path: `${moveFolder?.path}/*`,
  });

  const isExistContentFolder = Boolean(filesService.models.filesResponse?.result.total);
  const isExistContentLoading = filesService.meta.isListLoading;

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open, moveFolder, form]);

  const handleSubmit = (values: MoveFormValues) => {
    if (moveFolder) {
      const newFolderPath = `/${values.path.map((p) => p.value).join('/')}`;
      const path = `${newFolderPath}/${getLastPathParam(moveFolder.path)}`;
      updateFileAsync({
        filePath: moveFolder.path.substring(1),
        body: {
          filesV1Folder: {
            ...moveFolder,
            path,
          },
        },
      })
        .then(() => {
          onClose();
          notification.success({
            message: `Папка перемещена в ${newFolderPath}`,
          });
          resetSelectedRows();
        })
        .catch(() => null);
    }
  };

  return (
    <MoveModal
      open={open}
      isLoadingSave={isUpdateLoading}
      isLoadingFetch={isExistContentLoading}
      form={form}
      title="папку"
      fileTitle={moveFolder?.title || ''}
      isAllowMove={!isExistContentFolder}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
