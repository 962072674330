import { SnippetName } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListOperation } from 'antd/lib/form/FormList';
import { FormInstance } from 'rc-field-form';
import React from 'react';
import { AddSnippetButton, SnippetItemBlock } from 'src/components/editors/custom-inputs';
import { TreePath } from 'src/utils/content-path';

export type ColumnsEditorProps = {
  name: TreePath;
  contentPath: TreePath;
  stylesPath: TreePath;
  disabled: boolean;
};

export const ColumnsEditor: React.FC<ColumnsEditorProps> = ({ name, contentPath, stylesPath, disabled }) => {
  const handleDelete =
    (index: number, fieldsLength: number, setFields: FormInstance['setFields'], remove: FormListOperation['remove']) =>
    () => {
      setFields([
        {
          name: [...stylesPath, 1, 'columns'],
          value: new Array(fieldsLength - 1).fill({ width: '1fr' }),
        },
      ]);

      remove(index);
    };

  const handleAdd =
    (
      fieldsLength: number,
      add: FormListOperation['add'],
      setFields: FormInstance['setFields'],
    ): FormListOperation['add'] =>
    (defaultValue, insertIndex) => {
      setFields([
        {
          name: [...stylesPath, 1, 'columns'],
          value: new Array(fieldsLength + 1).fill({ width: '1fr' }),
        },
      ]);

      add(defaultValue, insertIndex);
    };

  return (
    <Form.List name={name}>
      {(fields, { add, remove, move }) => (
        <Form.Item shouldUpdate>
          {({ setFields }) => (
            <>
              {fields.map((field, index) => (
                <SnippetItemBlock
                  disabled={disabled}
                  onDelete={handleDelete(index, fields.length, setFields, remove)}
                  onMoveTop={fields.length > 0 && index > 0 ? () => move(index, index - 1) : undefined}
                  onMoveBottom={
                    fields.length > 0 && index < fields.length - 1 ? () => move(index, index + 1) : undefined
                  }
                  contentPath={[...contentPath, index]}
                  allowedSnippets={[SnippetName.FooterColumn, SnippetName.FooterContacts]}
                >
                  <div style={{ height: 30 }}>Колонка №{index + 1}</div>
                </SnippetItemBlock>
              ))}
              <AddSnippetButton
                add={handleAdd(fields.length, add, setFields)}
                isDisabled={disabled}
                label="Добавить колонку"
                allowedSnippets={[SnippetName.FooterColumn, SnippetName.FooterContacts]}
              />
            </>
          )}
        </Form.Item>
      )}
    </Form.List>
  );
};
