import { ContentVolkswagenV1PageProps } from '@cms/volkswagen-widgets';
import { ContentVolkswagenV1PageBlueprintProps } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { Form, Input, Modal, notification, Spin, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { getLastPathParam, removeLastPathParam } from '../../utils/string';
import { CustomFooter, MainSettingsTabContent, PreviewSettingsTabContent } from './components';

const PageSettingsType = {
  Page: 'Page',
  Publication: 'Publication',
} as const;

type UpdatePageArgs = {
  path: string;
  props: ContentVolkswagenV1PageProps | ContentVolkswagenV1PageBlueprintProps;
  onSuccess: () => void;
};

export type PageFileSettingsModalProps = {
  pageData: ContentVolkswagenV1PageProps | undefined;
  updatePage: (args: UpdatePageArgs) => void;
  isLoading: boolean;
  isUpdateLoading: boolean;
  isVisible: boolean;
  isBlueprint: boolean;
  type: typeof PageSettingsType[keyof typeof PageSettingsType];
  onCancel: () => void;
};

export const PageFileSettingsModal: React.FC<PageFileSettingsModalProps> & { type: typeof PageSettingsType } = ({
  pageData,
  isLoading,
  isUpdateLoading,
  isVisible,
  type,
  updatePage,
  onCancel,
  isBlueprint,
}) => {
  const [form] = Form.useForm<ContentVolkswagenV1PageProps & { name: string }>();

  useEffect(() => {
    if (isVisible) {
      if (pageData) {
        form.setFieldsValue({ ...pageData, name: getLastPathParam(pageData.path) });
      } else {
        form.resetFields();
      }
    }
  }, [isVisible, pageData, form]);

  const handleFormSubmit = () => {
    if (pageData) {
      form.validateFields().then(({ name, ...values }) => {
        const path = removeLastPathParam(pageData.path.substring(1));
        updatePage({
          path: pageData.path.substring(1),
          props: {
            ...pageData,
            ...values,
            path: path ? `/${path}/${name}` : `/${name}`,
          },
          onSuccess: () => {
            notification.success({
              message: 'Страница успешно изменена',
              duration: 2,
            });
            onCancel();
          },
        });
      });

      return true;
    }
  };

  return (
    <Modal
      title="Настройка страницы"
      visible={isVisible}
      onCancel={onCancel}
      onOk={handleFormSubmit}
      width={1200}
      okText="Сохранить"
      cancelText="Закрыть"
      destroyOnClose
      footer={<CustomFooter onCancelClick={onCancel} onOkClick={handleFormSubmit} isOkLoading={isUpdateLoading} />}
    >
      <Spin spinning={isLoading}>
        <Form name="page-settings-form" form={form} layout="vertical">
          {/* HACK FOR INITIALIZE PREVIEW FIELD */}
          <Form.Item name="preview" noStyle>
            <Input hidden />
          </Form.Item>
          <Tabs defaultActiveKey="main">
            <Tabs.TabPane tab="Основные" key="main">
              <MainSettingsTabContent
                isPageMode={type === PageSettingsType.Page}
                isEmptyContent={pageData?.content?.length === 0}
              />
            </Tabs.TabPane>
            {type === PageSettingsType.Page && (
              <Tabs.TabPane tab="Превью" key="preview">
                <Form.Item shouldUpdate>
                  {({ getFieldValue, setFields }) => (
                    <PreviewSettingsTabContent
                      getFieldValue={getFieldValue}
                      setFields={setFields}
                      isBlueprint={isBlueprint}
                    />
                  )}
                </Form.Item>
              </Tabs.TabPane>
            )}
          </Tabs>
        </Form>
      </Spin>
    </Modal>
  );
};

PageFileSettingsModal.type = PageSettingsType;
