import { styled } from '@console/uikit';
import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { MainLayout } from '../../../../components';
import { SitemapTabHeader } from './components/sitemap-tab-header';

const StyledContainer = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  padding: 16px;
`;

const StyledSitemapFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const StyledWrapper = styled.div`
  margin-top: 14px;
`;

export const SitemapTabContentContainer: React.FC = () => {
  const [form] = Form.useForm();

  return (
    <MainLayout header={<SitemapTabHeader onSubmit={form.submit} loading={false} />}>
      <StyledContainer>
        <Typography.Title level={4}>Карта сайта</Typography.Title>
        <StyledSitemapFileContainer>
          <Typography.Text>Смотреть опубликованный файл Sitemap.xml</Typography.Text>
          <Button style={{ padding: 0 }} type="link" href="https//vw-dealer/sitemap.xml">
            https//vw-dealer/sitemap.xml
          </Button>
        </StyledSitemapFileContainer>
        <StyledWrapper>
          <Form form={form} name="sitemap" layout="vertical">
            <Form.Item label="Название сайта" name="title" rules={[{ required: true }]}>
              <Input.TextArea rows={6} />
            </Form.Item>
          </Form>
        </StyledWrapper>
      </StyledContainer>
    </MainLayout>
  );
};
