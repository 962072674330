import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { filesApi, Service } from '../../../';
import { useCreateFile, UseCreateFileData } from './actions/useCreateFile';
import { useRemoveFile, UseRemoveFileData } from './actions/useDeleteFile';
import { GetFileParams, useGetFile } from './actions/useGetFile';
import { useUpdateFile, UseUpdateFileData } from './actions/useUpdateFile';

type Models = {
  fileResponse: ContentFilesV1File | undefined;
};

type Operations = {
  createFile: UseCreateFileData['createFile'];
  createFileAsync: UseCreateFileData['createFileAsync'];
  removeFile: UseRemoveFileData['removeFile'];
  removeFileAsync: UseRemoveFileData['removeFileAsync'];
  updateFile: UseUpdateFileData['updateFile'];
  updateFileAsync: UseUpdateFileData['updateFileAsync'];
};

type Meta = {
  isFileLoading: boolean;
  isFileFetching: boolean;
  isCreateLoading: boolean;
  isRemoveLoading: boolean;
  isUpdateLoading: boolean;
};

type Parameters = GetFileParams;

export type FileService = Service<Models, Operations, Meta>;

export const useFile = (params: Parameters): FileService => {
  const { path } = params;
  const queryKey = ['file', path];

  const { isFileFetching, isFileLoading, fileResponse } = useGetFile(filesApi, queryKey, params);

  const { createFile, createFileAsync, isCreateLoading } = useCreateFile(filesApi, queryKey);
  const { isRemoveLoading, removeFile, removeFileAsync } = useRemoveFile(filesApi, queryKey);
  const { isUpdateLoading, updateFile, updateFileAsync } = useUpdateFile(filesApi, queryKey);

  return {
    models: {
      fileResponse,
    },
    operations: {
      createFile,
      createFileAsync,
      removeFile,
      removeFileAsync,
      updateFile,
      updateFileAsync,
    },
    meta: {
      isFileLoading,
      isFileFetching,
      isCreateLoading,
      isRemoveLoading,
      isUpdateLoading,
    },
  };
};
