import { Button, Form, FormInstance, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { PagePathFormItemContainer } from 'src/containers';
import { AutocompleteOptionItem } from '@console/uikit';
import { ValidationConst } from '../../../utils/validation';

export type CopyFormValues = {
  title: string;
  name: string;
  path: AutocompleteOptionItem[];
  isFullCopy: boolean;
};

export type CopyModalProps = {
  form: FormInstance<CopyFormValues>;
  open: boolean;
  title: string;
  fileTitle: string;
  isLoadingSave: boolean;
  isLoadingFetch?: boolean;
  isFullCopyAvailable?: boolean;
  onClose: () => void;
  onSubmit: (values: CopyFormValues) => void;
};

export const CopyModal: React.FC<CopyModalProps> = ({
  form,
  open,
  title,
  fileTitle,
  isLoadingSave,
  isLoadingFetch,
  isFullCopyAvailable,
  onClose,
  onSubmit,
}) => {
  const [isFullCopy, setIsFullCopy] = useState(false);

  const onFinish = (values: CopyFormValues) => {
    onSubmit({ ...values, isFullCopy });
  };

  return (
    <Modal
      title={`Скопировать ${title} «${fileTitle}»`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        ...(isFullCopyAvailable
          ? [
              <Button
                key="submit"
                type="primary"
                loading={isLoadingSave}
                onClick={() => {
                  setIsFullCopy(true);
                  form.submit();
                }}
              >
                Копировать
              </Button>,
            ]
          : []),
        <Button
          key="submit-fullCopy"
          type="primary"
          loading={isLoadingSave}
          onClick={() => {
            setIsFullCopy(false);
            form.submit();
          }}
        >
          Копировать как ярлык
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Spin spinning={isLoadingFetch}>
          <Form.Item label="Имя" name="name" rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}>
            <Input placeholder="Введите имя" />
          </Form.Item>

          <Form.Item label="Заголовок" name="title" rules={[{ required: true }]}>
            <Input placeholder="Введите заголовок" />
          </Form.Item>

          <Form.Item
            name="path"
            label="Скопировать в папку"
            rules={[{ required: true, message: 'Это обязательное поле' }]}
            initialValue={[]}
          >
            <PagePathFormItemContainer />
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};
