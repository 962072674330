import { ContentVolkswagenV1Placeholder, ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { ContentViewContextProvider, useContentView } from '../../contexts';
import { ContentViewContainer } from '../content-view';

type PlaceholderViewContainerProps = {
  placeholder: ContentVolkswagenV1Placeholder;
  isBlockHovered: boolean;
};

export const PlaceholderViewContainer: React.FC<PlaceholderViewContainerProps> = ({ placeholder, isBlockHovered }) => {
  const {
    rootContent,
    updateNode: updateNodeRoot,
    loading,
    service,
    page,
    isBlueprintPage: isBlueprint,
  } = useContentView();
  const {
    operations: { updateFileAsync },
  } = service;

  const placeholderIndex = rootContent.findIndex((node) => node.placeholder?.id === placeholder.id);

  if (placeholderIndex === -1) {
    return null;
  }

  const addNode = (index: number, node: ContentVolkswagenV1Widget) => {
    let newPlaceholderContent: ContentVolkswagenV1Widget[];

    if (placeholder.content) {
      const copy = placeholder.content.slice();
      copy.splice(index, 0, node);
      newPlaceholderContent = copy;
    } else {
      newPlaceholderContent = [node];
    }

    const newPageContent = rootContent.slice();
    newPageContent[placeholderIndex] = {
      placeholder: { ...placeholder, content: newPlaceholderContent },
    };

    return updateFileAsync({
      // page has a path like "/folder/page"
      // request requires the form "folder/page"
      filePath: page.path.substring(1),
      body: {
        [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content: newPageContent },
      },
    });
  };

  const moveNode = (fromIndex: number, toIndex: number) => {
    if (placeholder.content) {
      const newPlaceholderContent = placeholder.content.slice();
      const widget = placeholder.content[fromIndex];
      newPlaceholderContent.splice(fromIndex, 1);
      newPlaceholderContent.splice(toIndex, 0, widget);

      const newPageContent = rootContent.slice();
      newPageContent[placeholderIndex] = {
        placeholder: {
          ...placeholder,
          content: newPlaceholderContent,
        },
      };

      return updateFileAsync({
        // page has a path like "/folder/page"
        // request requires the form "folder/page"
        filePath: page.path.substring(1),
        body: {
          [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content: newPageContent },
        },
      });
    }

    return Promise.reject("placeholder.content is empty, can't move node");
  };

  const removeNode = (index: number) => {
    if (placeholder.content) {
      const newPlaceholderContent = placeholder.content.slice();
      newPlaceholderContent.splice(index, 1);

      const newPageContent = rootContent.slice();
      newPageContent[placeholderIndex] = {
        placeholder: {
          ...placeholder,
          content: newPlaceholderContent,
        },
      };

      return updateFileAsync({
        // page has a path like "/folder/page"
        // request requires the form "folder/page"
        filePath: page.path.substring(1),
        body: {
          [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content: newPageContent },
        },
      });
    }

    return Promise.reject("placeholder.content is empty, can't remove node");
  };

  const updateNode = (node: ContentVolkswagenV1Widget, index: number) => {
    return updateNodeRoot(node, index, `.content.${placeholderIndex}`);
  };

  return (
    <ContentViewContextProvider
      value={{
        rootType: 'placeholder',
        rootContent: placeholder.content ?? [],
        isRootHovered: isBlockHovered,
        isBlueprintPage: isBlueprint,

        page,
        service,
        addNode,
        updateNode,
        moveNode,
        removeNode,
        loading,
      }}
    >
      <ContentViewContainer />
    </ContentViewContextProvider>
  );
};
