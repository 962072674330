import { Checkbox, CheckboxProps } from 'antd';
import React from 'react';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type StatusCheckboxProps = {
  value?: string;
  onChange?: (value: string) => void;
  truthyValue: string;
  falsyValue: string;
};

export const StatusCheckbox: React.FC<StatusCheckboxProps> = ({
  value,
  onChange,
  truthyValue,
  falsyValue,
  children,
}) => {
  const handleChange: CheckboxProps['onChange'] = (event) =>
    onChange && onChange(event.target.checked ? truthyValue : falsyValue);

  return (
    <Checkbox checked={value === truthyValue} onChange={handleChange}>
      {children}
    </Checkbox>
  );
};
