import { EditFilled, EyeFilled, RightOutlined, SettingOutlined } from '@ant-design/icons';
import { ContentFilesV1File, ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { getLastPathParam } from 'src/utils/string';
import { getPageDetailUrl } from 'src/utils/urls';

const StyledEditFilled = styled(EditFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledRightOutlined = styled(RightOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledEyeFilled = styled(EyeFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledSettingOutlined = styled(SettingOutlined)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export type StructureTableActionsCellProps = {
  file: ContentFilesV1File;
  currentSite: ContentSitesV1Site;
  addBreadcrumb: (breadcrumb: string) => void;
  onPageSettingsClick: (file: ContentFilesV1File) => void;
  onMainDomainView: (file: ContentFilesV1File) => void;
  onPreviewDomainView: (file: ContentFilesV1File) => void;
};

export const StructureTableActionsCell: React.FC<StructureTableActionsCellProps> = ({
  file,
  currentSite,
  addBreadcrumb,
  onPageSettingsClick,
  onMainDomainView,
  onPreviewDomainView,
}) => {
  return (
    <StyledActions>
      {(file.volkswagenV1Page || file.volkswagenV1PageBlueprint) && (
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="page" onClick={() => onMainDomainView(file)}>
                  Опубликованная версия
                </Menu.Item>
                <Menu.Item key="page" onClick={() => onPreviewDomainView(file)}>
                  Тестовая версия
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button type="text" shape="circle" icon={<StyledEyeFilled />} />
          </Dropdown>
          <Button
            type="text"
            shape="circle"
            icon={<StyledEditFilled />}
            onClick={() =>
              window.open(
                getPageDetailUrl(
                  (
                    `${currentSite.path}${file.volkswagenV1Page?.path || file.volkswagenV1PageBlueprint?.path}` || ''
                  ).substring(1),
                ),
                '_blank',
              )
            }
          />
          <Button
            type="text"
            shape="circle"
            icon={<StyledSettingOutlined />}
            onClick={() => onPageSettingsClick(file)}
          />
        </>
      )}
      {file.filesV1Folder && (
        <>
          <Button
            type="text"
            shape="circle"
            onClick={() => addBreadcrumb(getLastPathParam(file.filesV1Folder?.path || ''))}
            icon={<StyledRightOutlined />}
          />
        </>
      )}
    </StyledActions>
  );
};
