import { Button, Space, Upload } from 'antd';
import React from 'react';
import { SitesControl } from 'src/pages/sites/components/sites-control';
import { StyledTabHeaderWrapper } from '../../../components/elements';
import { ParsedRedirectsModalContainer } from '../../parsed-redirects-modal';
import { ParsingStatus, useParseRedirectsCsv } from '../hooks';

export const RedirectsTabHeader: React.FC = () => {
  const { parsingState, handleParseCsvFile, handleResetState, handleRedirectStatusChange } = useParseRedirectsCsv();

  return (
    <StyledTabHeaderWrapper>
      <SitesControl />
      <Space>
        <Upload showUploadList={false} beforeUpload={handleParseCsvFile}>
          <Button loading={parsingState.status === ParsingStatus.loading}>Загрузить .CSV</Button>
        </Upload>
      </Space>
      <ParsedRedirectsModalContainer
        isOpen={parsingState.status === ParsingStatus.success}
        redirects={parsingState.parsedRedirects}
        onClose={handleResetState}
        onRedirectCreated={handleRedirectStatusChange}
      />
    </StyledTabHeaderWrapper>
  );
};
