import { useFile } from '@console/api';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import { MoveFormValues, MoveModal } from '../../../../components/files/move-modal';
import { getLastPathParam } from '../../../../utils/string';
import { useFilesContext } from '../../contexts';

export type MovePageModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const MovePageModalContainer: React.FC<MovePageModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<MoveFormValues>();

  const {
    movePage,
    service: {
      meta: { isUpdateLoading },
      operations: { updateFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open, form]);

  const {
    models: { fileResponse },
    meta: { isFileLoading },
  } = useFile({
    enabled: open && Boolean(movePage),
    path: `${movePage?.path}`,
  });

  const page = fileResponse?.volkswagenV1Page;

  const handleSubmit = (values: MoveFormValues) => {
    if (page) {
      const newFolderPath = `/${values.path.map((p) => p.value).join('/')}`;
      const path = `${newFolderPath}/${getLastPathParam(page.path)}`;

      updateFileAsync({
        filePath: page.path.substring(1),
        body: {
          volkswagenV1Page: {
            ...page,
            path,
          },
        },
      })
        .then(() => {
          notification.success({ message: `Страница перемещена в ${newFolderPath}` });
          onClose();
          resetSelectedRows();
        })
        .catch(() => null);
    }
  };

  return (
    <MoveModal
      open={open}
      isLoadingSave={isUpdateLoading}
      isLoadingFetch={isFileLoading}
      form={form}
      title="страницу"
      fileTitle={page?.title || ''}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
