import {
  ContentSitesV1SiteProps,
  ContentSitesV1Site,
  ContentSitesV1SitesAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Site>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { siteName: string; body: ContentSitesV1SiteProps };
type Context = { prevGetSiteResponse?: ContentSitesV1Site };

export type UseUpdateSiteData = {
  updateSite: UseMutateFunction<Data, Error, Variables>;
  updateSiteAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isUpdateLoading: boolean;
};

export function useUpdateSite(
  sitesApi: ContentSitesV1SitesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseUpdateSiteData {
  const queryClient = useQueryClient();

  const {
    mutate: updateSite,
    mutateAsync: updateSiteAsync,
    isLoading: isUpdateLoading,
  } = useMutation<Data, Error, Variables, Context>(
    ({ body, siteName }) => sitesApi.contentSitesV1SitesAPIUpdateSite(siteName, body),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
        notification.success({
          message: 'Изменения сохранены',
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка изменения сайта',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { updateSite, updateSiteAsync, isUpdateLoading };
}
