import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Input, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';
import { FeaturesIconsItem } from '../features-icons-editor/components/features-icons-item';

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.FeaturesIconsRow];

export const FeaturesIconsBannerEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { gridData, mainColumnLinkData, mainColumnImageData, mainColumnRichtextData, secondaryColumnData } =
      React.useMemo(() => {
        const gridData = getPlaceholderedPath({
          node,
          commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'],
        });

        const mainColumnLinkData = getPlaceholderedPath({
          node,
          commonPath: [...gridData.path, 'content', 0, 'flex', 'content', 0, 'link'],
        });
        const mainColumnImageData = getPlaceholderedPath({
          node,
          commonPath: [...mainColumnLinkData.path, 'content', 0, 'image'],
        });
        const mainColumnRichtextData = getPlaceholderedPath({
          node,
          commonPath: [
            ...gridData.path,
            'content',
            0,
            'flex',
            'content',
            1,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ],
        });

        const secondaryColumnData = getPlaceholderedPath({
          node,
          commonPath: [...gridData.path, 'content', 1, 'flex', 'content'],
        });

        return {
          gridData,
          mainColumnLinkData,
          mainColumnImageData,
          mainColumnRichtextData,
          secondaryColumnData,
        };
      }, [node]);

    const disabled = gridData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={gridData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            <Tooltip
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
            >
              Редактируемые настройки блока
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <EditorContainer label="Первая колонка">
          {/* mainColumn.href edit */}
          <Form.Item
            label="Ссылка"
            fieldKey={[formListFieldData.fieldKey, ...mainColumnLinkData.path, 'source']}
            name={[formListFieldData.name, ...mainColumnLinkData.path, 'source']}
          >
            <Input disabled={disabled} />
          </Form.Item>
          {/* mainColumn.src edit */}
          <ImageNodeInputs
            formListFieldData={formListFieldData}
            imagePath={mainColumnImageData.path}
            disabled={disabled}
            required
          />

          {/* mainColumn.href content */}
          <RichtextFormItem
            label="Контент"
            formListFieldData={formListFieldData}
            contentFieldName={mainColumnRichtextData.path}
            disabled={disabled}
          />
        </EditorContainer>
        <EditorContainer label="Вторая колонка">
          {/* column2 edit */}
          <Form.List name={[formListFieldData.name, ...secondaryColumnData.path]}>
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <FeaturesIconsItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    contentPath={[...sectionContentPath, ...secondaryColumnData.path]}
                    allowedSnippets={allowedSnippets}
                  />
                ))}
                <AddSnippetButton
                  add={add}
                  mapBeforeAdd={(node) => node}
                  isDisabled={disabled}
                  label="Добавить элемент"
                  allowedSnippets={allowedSnippets}
                />
              </>
            )}
          </Form.List>
        </EditorContainer>
      </>
    );
  },
);
