import { Form, FormProps, Input, Tabs } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';
import { TopMenuTabCommon, TopMenuTabStructure, TopMenuTabTopBar } from './components';

const topMenuPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'header', 'content', 0, 'topMenu'];

export const TopMenuEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { topMenuData } = React.useMemo(() => {
    const topMenuData = getPlaceholderedPath({
      node,
      commonPath: topMenuPath,
    });

    return { topMenuData };
  }, [node]);

  const disabled = topMenuData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Tabs tabPosition="left">
        <Tabs.TabPane tab="Общие настройки" key="1">
          {/* logo.href, logo.title, stageHeight, dealers.modalHref, inheritPromoArea, dealers.items  edit */}
          <TopMenuTabCommon disabled={disabled} onPlaceholderChange={onPlaceholderChange} topMenuData={topMenuData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Toп-бар" key="2">
          {/* tabs, sideIcons edit */}
          <TopMenuTabTopBar disabled={disabled} topMenuData={topMenuData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Структура меню" key="3">
          {/* items, mainLinks, additionalLinks edit */}
          <TopMenuTabStructure disabled={disabled} topMenuData={topMenuData} />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};
