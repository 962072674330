import { DeleteOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Richtext } from '@cms/volkswagen-widgets';
import { Button, Form, ColProps } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { RichTextEditorProps, styled } from '@console/uikit';
import { TreePath } from 'src/utils/content-path';
import { RichTextContentMapper } from './components/richtext-content-mapper';

export type RichtextFormItemProps = Pick<
  RichTextEditorProps,
  'controlsConfig' | 'entityControlsConfig' | 'singleLine'
> & {
  value?: ContentVolkswagenV1Richtext;
  onChange?: (value: string) => void;
  contentFieldName: TreePath;
  disabled: boolean;
  formListFieldData?: FormListFieldData;
  label?: string;
  labelCol?: ColProps;
  noStyle?: boolean;
  onRemove?: () => void;
};

const StyledFormItem = styled(Form.Item)`
  flex-direction: column;
  align-items: start;

  .ant-form-item-control {
    width: 100%;
  }
`;

export const RichtextFormItem: React.FC<RichtextFormItemProps> = ({
  contentFieldName,
  controlsConfig,
  disabled,
  entityControlsConfig,
  formListFieldData,
  label,
  labelCol,
  noStyle = false,
  onRemove,
}) => (
  <>
    <StyledFormItem
      label={label}
      noStyle={noStyle}
      labelCol={labelCol}
      fieldKey={formListFieldData && [formListFieldData.fieldKey, ...contentFieldName]}
      name={[...(formListFieldData ? [formListFieldData.name, ...contentFieldName] : contentFieldName)]}
    >
      <RichTextContentMapper
        disabled={disabled}
        name={formListFieldData ? [formListFieldData.name, ...contentFieldName] : contentFieldName}
        controlsConfig={controlsConfig}
        entityControlsConfig={entityControlsConfig}
      />
    </StyledFormItem>
    {onRemove && (
      <Form.Item>
        <Button type="dashed" onClick={onRemove} block icon={<DeleteOutlined />}>
          Удалить {label?.toLocaleLowerCase()}
        </Button>
      </Form.Item>
    )}
  </>
);
