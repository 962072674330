import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ValidationConst } from 'src/utils/validation';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const modalPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'modal'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const DisclaimerLayerEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { modalData, richtextData } = React.useMemo(() => {
    const modalData = getPlaceholderedPath({
      node,
      commonPath: modalPath,
    });
    const richtextData = getPlaceholderedPath({
      node,
      commonPath: [...modalData.path, 'content', 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });

    return { modalData, richtextData };
  }, [node]);

  const disabled = modalData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={modalData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.Item
        label="Имя формы"
        name={[...modalData.path, 'name']}
        rules={[{ required: true }, { pattern: ValidationConst.lLettersNumbers }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <RichtextFormItem label="Контент" contentFieldName={richtextData.path} disabled={disabled} />
    </Form>
  );
};
