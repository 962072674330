import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { getFileTitle } from './entities';

type MediaFile = Pick<ContentFilesV1File, 'filesV1Folder' | 'mediaV1Media'>;

function getSortWeight(file: MediaFile) {
  if (file.filesV1Folder) return 3;

  return 1;
}

export const sortMediaFiles = (files: MediaFile[]): MediaFile[] =>
  files.slice().sort((a, b) => {
    const weightA = getSortWeight(a);
    const weightB = getSortWeight(b);

    if (weightA === weightB) {
      return (getFileTitle(a) ?? '').localeCompare(getFileTitle(b) ?? '');
    }

    return weightB - weightA;
  });
