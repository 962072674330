import { VisibilityV1Period } from '@cms/volkswagen-widgets';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import React from 'react';
import { dateToServerTime, getDate } from '../../../utils/date';

const RangePicker: React.FC<RangePickerProps<Dayjs>> = ({ ...props }) => (
  <DatePicker.RangePicker {...(props as RangePickerProps<moment.Moment>)} />
);

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type StatusTimeRangeProps = {
  value?: VisibilityV1Period;
  onChange?: (value: { start: string | undefined; end: string | undefined }) => void;
};

export const StatusTimeRange: React.FC<StatusTimeRangeProps> = ({ value, onChange }) => {
  const handleChange: RangePickerProps<Dayjs>['onChange'] = (dates) => {
    onChange &&
      onChange({
        start: dateToServerTime(dates?.[0] ?? undefined),
        end: dateToServerTime(dates?.[1] ?? undefined),
      });
  };

  return (
    <RangePicker
      allowEmpty={[true, true]}
      value={[value?.start ? getDate(value.start) : null, value?.end ? getDate(value.end) : null]}
      onChange={handleChange}
    />
  );
};
