import { ContentVolkswagenV1Node, SnippetName, TabsNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { CommonTabsItem } from '../../node-editors';

const mapTabsAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  tabsItem: TabsNode.Tab.generators.entity({
    content: [node],
    header: [],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.VacanciesAccordions];

export const VacanciesEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const tabsData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'tabs'],
  });

  const disabled = tabsData.placeholdersCount === 0;

  const handlePlaceholderChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          defaultChecked={tabsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => handlePlaceholderChange(e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.List name={[formListFieldData.name, ...tabsData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <CommonTabsItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                tabsContentPath={[...sectionContentPath, ...tabsData.path, 'items']}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapTabsAdd}
              isDisabled={disabled}
              label="Добавить таб-итем"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
    </>
  );
});
