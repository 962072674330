import { PlusOutlined } from '@ant-design/icons';
import { CarStockNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Divider, Form } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { FilterEditor } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { ButtonInputs } from './components/button-inputs';

export const CarStockEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const carStockData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'carStock'],
  });
  const disabled = carStockData.placeholdersCount === 0;

  const handlePlaceholderChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          defaultChecked={carStockData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => handlePlaceholderChange(e.target.checked)}
        >
          Редактируемые фильтры
        </Checkbox>
      </Form.Item>

      <Divider orientation="right">Редактирование карточки-плейсхолдера</Divider>
      <Form.Item name={[formListFieldData.name, ...carStockData.path, 'attributes']} label="Основная кнопка">
        <ButtonInputs attributeName={CarStockNode.attributes.emptyPrimaryButtonSettings} />
      </Form.Item>
      <Form.Item name={[formListFieldData.name, ...carStockData.path, 'attributes']} label="Дополнительная кнопка">
        <ButtonInputs attributeName={CarStockNode.attributes.emptySecondaryButtonSettings} />
      </Form.Item>

      <Divider orientation="right">Редактирование фильтров</Divider>
      <Form.List name={[formListFieldData.name, ...carStockData.path, 'filters']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <FilterEditor
                key={field.key}
                disabled={disabled}
                move={move}
                remove={remove}
                field={field}
                index={index}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                possibleAttributeNames={[
                  { label: 'Дилер', value: 'dealer' },
                  { label: 'Комплектация', value: 'model' },
                ]}
              />
            ))}
            <Form.Item>
              <Button
                type="dashed"
                disabled={disabled}
                onClick={() => add(CarStockNode.Filter.generators.entity({}))}
                icon={<PlusOutlined />}
                block
              >
                Добавить фильтр
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
});
