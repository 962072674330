import React from 'react';
import { MediaHeader } from '../../../components/media/media-header';
import { useUploader } from '../../../hooks';
import { useMediaPageContext } from '../contexts/media-context';

export const MediaHeaderContainer: React.FC = () => {
  const { sort, view, setSearch, setSort, setView, uploadFiles, openCreateFolderModal } = useMediaPageContext();

  const handleUploadClick = useUploader(uploadFiles);

  return (
    <MediaHeader
      view={view}
      sort={sort}
      onUploadClick={handleUploadClick}
      setSearch={setSearch}
      setView={setView}
      setSort={setSort}
      openCreateFolderModal={openCreateFolderModal}
    />
  );
};
