import { ComparisonNode, ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Divider, Form } from 'antd';
import React, { useMemo } from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, AttributeInput, ThemeStylesHeightInput } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { ComparisonItem, PropertiesItemProps } from './component';

const mapItemAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node =>
  ComparisonNode.Item.generators.node({ content: [node], properties: [] });

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.CarFragment];

export const ComparisonEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath, getFieldValue }) => {
    const themeData = getPlaceholderedPath({
      node,
      commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'theme'],
    });

    const disabled = themeData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    const comparisonItems: Pick<ContentVolkswagenV1Node, 'comparisonItem'>[] = useMemo(
      () => getFieldValue([...sectionContentPath, ...themeData.path, 'content', 0, 'comparison', 'items']),
      [getFieldValue, sectionContentPath, themeData.path],
    );

    const { categories, properties } = useMemo(() => {
      return comparisonItems?.reduce<{
        categories: PropertiesItemProps['categories'];
        properties: PropertiesItemProps['properties'];
      }>(
        (result, item) => {
          const { categories, properties } = item?.comparisonItem?.properties?.reduce(
            (acc, prop) => {
              const categoryAttr = prop?.attributes?.find(
                (attr) => attr.name === ComparisonNode.const.COMPARISON_PROPERTY_CATEGORY,
              );
              const propAttr = prop?.attributes?.find(
                (attr) => attr.name === ComparisonNode.const.COMPARISON_PROPERTY_KEY,
              );

              const categoryKey = categoryAttr && categoryAttr.values ? categoryAttr.values[0] : '';

              const propKey = propAttr && propAttr.values ? propAttr.values[0] : '';

              return {
                categories: {
                  ...acc.categories,
                  [categoryKey]: prop?.category,
                },
                properties: {
                  ...acc.properties,
                  [propKey]: prop?.title,
                },
              };
            },
            {
              categories: {},
              properties: {},
            },
          ) || {
            categories: {},
            properties: {},
          };

          return {
            categories: {
              ...result.categories,
              ...categories,
            },
            properties: {
              ...result.properties,
              ...properties,
            },
          };
        },
        {
          categories: {},
          properties: {},
        },
      );
    }, [comparisonItems]);

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={themeData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            Редактируемое
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Высота"
          fieldKey={[formListFieldData.fieldKey, ...themeData.path, 'styles']}
          name={[formListFieldData.name, ...themeData.path, 'styles']}
        >
          <ThemeStylesHeightInput disabled={disabled} themeKey="flex" />
        </Form.Item>

        <Form.Item
          label="Отступ"
          name={[formListFieldData.name, ...themeData.path, 'content', 0, 'comparison', 'attributes']}
        >
          <AttributeInput attributeName={ComparisonNode.attributes.stickyTop} disabled={disabled} type="number" />
        </Form.Item>

        <Form.List name={[formListFieldData.name, ...themeData.path, 'content', 0, 'comparison', 'items']}>
          {(fields, { add, remove, move }) => (
            <>
              <Divider orientation="right">Комплектации</Divider>
              {fields.map((field, index) => (
                <ComparisonItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  categories={categories}
                  properties={properties}
                  contentPath={[...sectionContentPath, ...themeData.path, 'content', 0, 'comparison', 'items']}
                  allowedSnippets={allowedSnippets}
                />
              ))}
              <AddSnippetButton
                add={add}
                mapBeforeAdd={mapItemAdd}
                isDisabled={disabled}
                label="Добавить комплектацию"
                allowedSnippets={allowedSnippets}
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
