import {
  ContentSitesV1Domain,
  ContentSitesV1DomainProps,
  ContentSitesV1DomainsAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Domain>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = ContentSitesV1DomainProps;

export type UseCreateDomainData = {
  createDomain: UseMutateFunction<Data, Error, Variables>;
  createDomainAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateDomain(
  domainsApi: ContentSitesV1DomainsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseCreateDomainData {
  const queryClient = useQueryClient();

  const {
    mutate: createDomain,
    mutateAsync: createDomainAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>(
    (body) =>
      domainsApi.contentSitesV1DomainsAPICreateDomain(
        site, // siteName: string,
        body, // body: ContentSitesV1DomainProps,
        undefined, // siteRevision?: string,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка создания домена',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { createDomain, createDomainAsync, isCreateLoading };
}
