import { Col, Form, FormInstance, Radio, Row, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { EditorContainer } from '../../../../../../elements';
import { getIconComponent, ICONS, useVariants } from '../../icon-entity';
import { LinkEditProps } from '../types';

const { Option } = Select;

const StyledOptionBox = styled.span`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 5px;
  }
`;

export const LinkIconInputs: React.FC<{ form: FormInstance<LinkEditProps> }> = ({ form }) => {
  const [currentKind, setCurrentKind] = useState<string>(form.getFieldValue('iconKind'));
  const filteredVariantOptions = useVariants(currentKind);

  const handleKindChange = useCallback(
    (value) => {
      form.setFieldsValue({ iconKind: value });
      const values = form.getFieldsValue();
      const { availableVariants } = getIconComponent(value);

      if (values.iconVariant && !availableVariants.includes(values.iconVariant)) {
        form.setFieldsValue({ iconVariant: undefined });
      }
      setCurrentKind(value);
    },
    [form],
  );

  return (
    <EditorContainer>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="iconKind" label="Иконка">
            <Select placeholder="Тип иконки" value={currentKind} onChange={handleKindChange}>
              <Option value="">Без иконки</Option>
              {ICONS.map((icon) => {
                const { IconComponent } = getIconComponent(icon);

                if (!IconComponent) {
                  return null;
                }

                return (
                  <Option key={icon} value={icon}>
                    <StyledOptionBox>
                      <IconComponent />
                      {icon}
                    </StyledOptionBox>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="iconVariant" label="Размер">
            <Select>
              {filteredVariantOptions.map((variant) => (
                <Option value={variant.value}>{variant.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="iconPosition" label="Позиция">
            <Radio.Group>
              <Radio value="start">В начале</Radio>
              <Radio value="end">В конце</Radio>
              <Radio value="top">Сверху</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="iconFlow" label="Вид">
            <Radio.Group>
              <Radio value="block">Блочная иконка</Radio>
              <Radio value="inline">Строчная иконка</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </EditorContainer>
  );
};
