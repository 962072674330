import { AccordionNode, snippetGenerators, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddEnrichedNodeButton } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { AccordionItem } from './components';

const initialAccordion = {
  accordionItem: AccordionNode.Item.generators.entity({
    content: [snippetGenerators.Richtext({ content: [] })],
  }),
};

export const HighlightFeatureEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const featureData = getPlaceholderedPath({
      node,
      commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content', 0, 'accordion'],
    });

    const disabledFeatureData = featureData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={featureData.placeholdersCount === 2}
            disabled={disabledFeatureData}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            <Tooltip
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
            >
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <Form.List name={[formListFieldData.name, ...featureData.path, 'items']}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <AccordionItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabledFeatureData}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  accordionContentPath={[...sectionContentPath, ...featureData.path, 'items']}
                  disallowedSnippets={[SnippetName.HighlightFeature]}
                />
              ))}
              <AddEnrichedNodeButton
                add={add}
                nodeToEnrich={initialAccordion}
                isDisabled={disabledFeatureData}
                label="Добавить аккордеон"
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
