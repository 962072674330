import { ContentSitesV1Redirect, ContentSitesV1RedirectsAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Redirect>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveRedirectData = {
  removeRedirect: UseMutateFunction<Data, Error, Variables>;
  removeRedirectAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveRedirect(
  redirectsApi: ContentSitesV1RedirectsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseRemoveRedirectData {
  const queryClient = useQueryClient();

  const {
    mutate: removeRedirect,
    mutateAsync: removeRedirectAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>(
    (name) =>
      redirectsApi.contentSitesV1RedirectsAPIDeleteRedirect(
        site, // siteName: string,
        name, // redirectName: string,
        undefined, // siteRevision?: string,
        undefined, // redirectRevision?: string,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка удаления редиректа',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { removeRedirect, removeRedirectAsync, isRemoveLoading };
}
