import { CarouselNode, ContentVolkswagenV1Node } from '@cms/volkswagen-widgets';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, SnippetItemBlock } from '../../../custom-inputs';

export type CarouselItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  carouselContentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

const mapCarouselItem = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  carouselItem: CarouselNode.Item.generators.entity({
    content: [node],
  }),
});

export const CarouselItem: React.FC<CarouselItemProps> & {
  mapper: typeof mapCarouselItem;
} = ({ field, disabled, move, remove, isBottomMovable, isTopMovable, index, carouselContentPath, allowedSnippets }) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...carouselContentPath, index, 'carouselItem', 'content', 0]}
      allowedSnippets={allowedSnippets}
    >
      <span>Контент #{index}</span>
    </SnippetItemBlock>
  );
};

CarouselItem.mapper = mapCarouselItem;
