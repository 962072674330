import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Node, ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { Button, Form } from 'antd';
import { FormListOperation } from 'antd/lib/form/FormList';
import React, { useState } from 'react';
import { addInitialSnippet } from 'src/utils/snippets';

export type AddEnrichedNodeButtonProps = {
  add: FormListOperation['add'];
  nodeToEnrich: ContentVolkswagenV1Node;
  isDisabled: boolean;
  label: string;
  noStyle?: boolean;
};

export const AddEnrichedNodeButton: React.FC<AddEnrichedNodeButtonProps> = ({
  add,
  isDisabled,
  label,
  noStyle = false,
  nodeToEnrich,
}) => {
  const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);
  const [resultSnippet, setResultSnippet] = useState<ContentVolkswagenV1Widget>();

  const handleOnClick = () => {
    addInitialSnippet({
      node: nodeToEnrich,
      onStart: () => setIsLoadingSnippet(true),
      onSuccess: (node) => {
        setResultSnippet(node);
        setIsLoadingSnippet(false);
      },
      onError: () => setIsLoadingSnippet(false),
    });
  };

  React.useEffect(() => {
    if (resultSnippet) {
      add(resultSnippet);
      setResultSnippet(undefined);
    }
  }, [resultSnippet, add]);

  return (
    <Form.Item noStyle={noStyle}>
      <Button
        type="dashed"
        disabled={isDisabled}
        onClick={handleOnClick}
        icon={<PlusOutlined />}
        block
        loading={isLoadingSnippet}
      >
        {label}
      </Button>
    </Form.Item>
  );
};
