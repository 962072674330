import { keycloakConfig } from '@console/api';
import { useEffect } from 'react';
import * as React from 'react';
import { useCookies } from 'react-cookie';
import { useKeycloak } from 'use-keycloak';

const AuthPage: React.FC = React.memo(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _, removeCookie] = useCookies(['fromPath']);
  const authData = useKeycloak(keycloakConfig);

  useEffect(() => {
    if (authData.authToken) {
      const href = cookies?.fromPath ?? '/';
      removeCookie('fromPath');
      window.location.href = href;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.authToken]);

  return null;
});

export default AuthPage;
