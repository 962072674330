import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Space, Typography } from 'antd';
import React from 'react';

export type SitesTitleTableCellProps = {
  site: ContentSitesV1Site;
};

export const SitesTitleTableCell: React.FC<SitesTitleTableCellProps> = ({ site }) => {
  return (
    <Space size="middle">
      <Typography.Text>{site.title}</Typography.Text>
    </Space>
  );
};
