import { DraftDecorator } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { DecoratorComponentProps } from '../../../../types';
import { findEntities } from '../../../../utils';
import { DisclaimerEntityType } from './types';
import { d6 } from '@cms/volkswagen-widgets';

const StyledDisclaimerWrapper = styled.span`
  position: relative;
  svg {
    margin-right: -2px;
  }

  > span {
    //visibility: hidden;
  }
`;

const StyledDisclaimerIcon = styled.span`
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid #001e50;
  border-radius: 50%;
  color: #001e50;
  position: relative;

  > span {
    position: absolute;
    left: 2px;
    top: -1px;
    font-size: 10px;
    line-height: 12px;
  }
`;

const Disclaimer: React.FC<DecoratorComponentProps> = (props) => {
  const linkProps: d6.DisclaimerProps = props.contentState.getEntity(props.entityKey).getData();
  return (
    <StyledDisclaimerWrapper>
      {linkProps.index && typeof linkProps.index === 'number' ? (
        <StyledDisclaimerIcon>
          <span>{linkProps.index}</span>
        </StyledDisclaimerIcon>
      ) : (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.33 6H6.66V9H5.33V6Z" fill="#001E50" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM1.35 6C1.35 8.56812 3.43188 10.65 6 10.65C8.56584 10.6445 10.6445 8.56584 10.65 6C10.65 3.43188 8.56812 1.35 6 1.35C3.43188 1.35 1.35 3.43188 1.35 6Z"
            fill="#001E50"
          />
          <path
            d="M6 4.75C6.41421 4.75 6.75 4.41421 6.75 4C6.75 3.58579 6.41421 3.25 6 3.25C5.58579 3.25 5.25 3.58579 5.25 4C5.25 4.41421 5.58579 4.75 6 4.75Z"
            fill="#001E50"
          />
        </svg>
      )}
      <span>{props.children}</span>
    </StyledDisclaimerWrapper>
  );
};

export const disclaimerDecorator: DraftDecorator = {
  strategy: findEntities<DisclaimerEntityType>('DISCLAIMER'),
  component: Disclaimer,
};
