import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Node, ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { Button, Form } from 'antd';
import { FormListOperation } from 'antd/lib/form/FormList';
import React, { useState } from 'react';
import { AddWidgetDrawer, AddWidgetDrawerProps } from 'src/components/drawers';
import { addInitialSnippet } from 'src/utils/snippets';

export type AddSnippetButtonProps = {
  add: FormListOperation['add'];
  mapBeforeAdd?: (node: ContentVolkswagenV1Node) => ContentVolkswagenV1Node;
  isDisabled: boolean;
  label: string;
  noStyle?: boolean;
} & Pick<AddWidgetDrawerProps, 'allowedSnippets' | 'disallowedSnippets'>;

export const AddSnippetButton: React.FC<AddSnippetButtonProps> = ({
  add,
  isDisabled,
  label,
  allowedSnippets,
  disallowedSnippets,
  noStyle = false,
  mapBeforeAdd = (value) => value,
}) => {
  const [isLoadingSnippet, setIsLoadingSnippet] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [resultSnippet, setResultSnippet] = useState<ContentVolkswagenV1Widget>();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setIsLoadingSnippet(false);
    setResultSnippet(undefined);
  };

  const handleOnSaveDrawer: AddWidgetDrawerProps['onSave'] = (node) => {
    addInitialSnippet({
      node: mapBeforeAdd(node),
      onStart: () => setIsLoadingSnippet(true),
      onSuccess: (node) => {
        setResultSnippet(node);
        setIsLoadingSnippet(false);
      },
      onError: () => setIsLoadingSnippet(false),
    });
  };

  React.useEffect(() => {
    if (resultSnippet) {
      handleCloseDrawer();
      add(resultSnippet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSnippet]);

  return (
    <Form.Item noStyle={noStyle}>
      <Button
        type="dashed"
        disabled={isDisabled}
        onClick={handleToggleDrawer}
        icon={<PlusOutlined />}
        block
        loading={isLoadingSnippet}
      >
        {label}
      </Button>

      <AddWidgetDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        onSave={handleOnSaveDrawer}
        loading={isLoadingSnippet}
        allowedSnippets={allowedSnippets}
        disallowedSnippets={disallowedSnippets}
      />
    </Form.Item>
  );
};
