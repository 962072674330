import React from 'react';
import { MediaTable } from '../../../components/media/media-table';
import { useMediaPageContext } from '../contexts/media-context';

export const MediaTableContainer: React.FC = () => {
  const {
    isListFetching,
    files,
    breadcrumbs: { addBreadcrumb },
    fileProgresses,
    fileStatuses,
    removeFile,
  } = useMediaPageContext();

  return (
    <MediaTable
      files={files}
      loading={isListFetching}
      addBreadcrumb={addBreadcrumb}
      progresses={fileProgresses}
      statuses={fileStatuses}
      removeMedia={removeFile}
    />
  );
};
