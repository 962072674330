import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SideMenuNode } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Checkbox, Col, Form, FormProps, Input, Row } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { PossibleColors } from 'src/utils/types';
import { AttributesSelect, IconSelect, RichtextTextNodesEditor } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const StyledColorBox = styled.span<{ $color: string }>`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background: ${(props) => props.$color};
`;

const sideMenuPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'sideMenu'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const colorsOptions = Object.keys(PossibleColors).map((colorName) => {
  const colorValue = PossibleColors[colorName as keyof typeof PossibleColors];

  return {
    label: (
      <>
        <StyledColorBox $color={colorValue} />
        {colorName}
      </>
    ),
    value: colorValue,
  };
});

export const SideMenuEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { sideMenuData } = React.useMemo(() => {
    const sideMenuData = getPlaceholderedPath({
      node,
      commonPath: sideMenuPath,
    });

    return { sideMenuData };
  }, [node]);

  const disabled = sideMenuData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={sideMenuData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Row align="middle" gutter={8} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <Form.Item label="Задний фон" name={[...sideMenuData.path, 'attributes']}>
            <AttributesSelect
              attributeName={SideMenuNode.attributes.background}
              possibleValues={[
                {
                  label: 'По умолчанию',
                  value: AttributesSelect.defaultValue,
                },
                ...colorsOptions,
              ]}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Цвет иконок" name={[...sideMenuData.path, 'attributes']}>
            <AttributesSelect
              attributeName={SideMenuNode.attributes.color}
              possibleValues={[
                {
                  label: 'По умолчанию',
                  value: AttributesSelect.defaultValue,
                },
                ...colorsOptions,
              ]}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.List name={[...sideMenuData.path, 'items']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Row key={key} align="middle" gutter={8} style={{ marginBottom: '16px' }}>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    label="Ссылка"
                    name={[name, 'sideMenuLink', 'source']}
                    fieldKey={[fieldKey, 'sideMenuLink', 'source']}
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true }]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    {...restField}
                    label="Иконка"
                    name={[name, 'sideMenuLink', 'icon']}
                    style={{ marginBottom: 0 }}
                    fieldKey={[fieldKey, 'sideMenuLink', 'icon']}
                    rules={[{ required: true }]}
                  >
                    <IconSelect disabled={disabled} isRequired />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Заголовок"
                    name={[name, 'sideMenuLink', 'content']}
                    style={{ marginBottom: 0 }}
                    fieldKey={[fieldKey, 'sideMenuLink', 'content']}
                  >
                    <RichtextTextNodesEditor disabled={disabled} />
                  </Form.Item>
                </Col>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Row>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add(
                    SideMenuNode.Link.generators.node({
                      source: '',
                      content: [],
                      icon: '',
                    }),
                  )
                }
                block
                icon={<PlusOutlined />}
              >
                Добавить элемент
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
