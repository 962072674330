import { CarouselNode, ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { ConfigType, DataType } from '@console/uikit';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { ModelsCarouselItem } from './components/models-carousel-item';

const mapCarouselAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  carouselItem: CarouselNode.Item.generators.entity({
    content: [node],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.ModelsCarouselCarFragment];

export const ModelsCarouselTabEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { carouselItemsData } = React.useMemo(() => {
    const carouselItemsData = getPlaceholderedPath({
      node,
      commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content', 0, 'carousel', 'items'],
    });

    return { carouselItemsData };
  }, [node]);

  const disabled = carouselItemsData.placeholdersCount === 0;

  const handlePlaceholderChange = (checked: boolean) => {
    onPlaceholderChange(['blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item>
        <Checkbox
          defaultChecked={carouselItemsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => handlePlaceholderChange(e.target.checked)}
        >
          Редактируемая галерея
        </Checkbox>
      </Form.Item>

      <Form.List name={carouselItemsData.path}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <ModelsCarouselItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={carouselItemsData.path}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapCarouselAdd}
              isDisabled={disabled}
              label="Добавить слайд"
              allowedSnippets={allowedSnippets}
            />
          </>
        )}
      </Form.List>
      <RichtextFormItem
        label="Контент под слайдами"
        contentFieldName={[
          'blueprint',
          'content',
          0,
          'placeholder',
          'content',
          1,
          'flex',
          'content',
          0,
          'blueprint',
          'content',
          0,
          'richtext',
        ]}
        controlsConfig={{
          [ConfigType.List]: [],
          [ConfigType.Heading]: [],
          [ConfigType.Blockquote]: [],
          [ConfigType.Align]: [],
        }}
        entityControlsConfig={{
          [DataType.Disclaimer]: false,
        }}
        disabled={false}
      />
    </Form>
  );
};
