import { UsersV1ListUsersResponse } from '@cms/volkswagen-widgets';
import { usersApi, Service } from '../../../';
import { useCreateUser, UseCreateUserData } from './actions/useCreateUser';
import { useRemoveUser, UseRemoveUserData } from './actions/useRemoveUser';
import { ListUsersParams, useListUsers } from './actions/useListUsers';
import { useUpdateUser, UseUpdateUserData } from './actions/useUpdateUser';
import { useQueryClient } from 'react-query';

export * from './actions/useUpdateUser';
export * from './actions/useCreateUser';
export * from './actions/useRemoveUser';
export * from './actions/useGetUser';

type Models = {
  usersResponse: UsersV1ListUsersResponse | undefined;
};

type Operations = {
  createUser: UseCreateUserData['createUser'];
  createUserAsync: UseCreateUserData['createUserAsync'];
  removeUser: UseRemoveUserData['removeUser'];
  removeUserAsync: UseRemoveUserData['removeUserAsync'];
  updateUser: UseUpdateUserData['updateUser'];
  updateUserAsync: UseUpdateUserData['updateUserAsync'];
  invalidateQueries(): void;
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isRemoveLoading: boolean;
  isUpdateLoading: boolean;
};

type Parameters = ListUsersParams & {
  isInvalidate?: boolean;
};

export type UsersService = Service<Models, Operations, Meta>;

export const useUsers = (params: Parameters): UsersService => {
  const { name, isInvalidate } = params;
  const queryKey = ['users', name];

  const queryClient = useQueryClient();

  const { isListFetching, isListLoading, usersResponse } = useListUsers(usersApi, queryKey, params);
  const { createUser, createUserAsync, isCreateLoading } = useCreateUser(usersApi, queryKey, isInvalidate);
  const { isRemoveLoading, removeUser, removeUserAsync } = useRemoveUser(usersApi, queryKey, isInvalidate);
  const { isUpdateLoading, updateUser, updateUserAsync } = useUpdateUser(usersApi, queryKey, isInvalidate);

  return {
    models: {
      usersResponse,
    },
    operations: {
      createUser,
      createUserAsync,
      removeUser,
      removeUserAsync,
      updateUser,
      updateUserAsync,
      invalidateQueries() {
        queryClient.invalidateQueries(queryKey);
      },
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isRemoveLoading,
      isUpdateLoading,
    },
  };
};
