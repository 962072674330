import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const commonGridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const EnginesTabEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const gridPath = getPlaceholderedPath({
    node,
    commonPath: commonGridPath,
  });

  const disabled = gridPath.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <EditorContainer label="Первая колонка">
        <RichtextFormItem
          label="Особенность #1"
          contentFieldName={[
            ...gridPath.path,
            'content',
            0,
            'flex',
            'content',
            1,
            'flex',
            'content',
            0,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ]}
          disabled={disabled}
        />

        <RichtextFormItem
          label="Особенность #2"
          contentFieldName={[
            ...gridPath.path,
            'content',
            0,
            'flex',
            'content',
            1,
            'flex',
            'content',
            2,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ]}
          disabled={disabled}
        />

        <RichtextFormItem
          label="Особенность #3"
          contentFieldName={[
            ...gridPath.path,
            'content',
            0,
            'flex',
            'content',
            1,
            'flex',
            'content',
            4,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ]}
          disabled={disabled}
        />

        <ImageNodeInputs
          imagePath={[...gridPath.path, 'content', 0, 'flex', 'content', 0, 'box', 'content', 'image']}
          disabled={disabled}
          required
        />
      </EditorContainer>
      <EditorContainer label="Вторая колонка">
        <RichtextFormItem
          label="Контент"
          contentFieldName={[
            ...gridPath.path,
            'content',
            1,
            'flex',
            'content',
            0,
            'blueprint',
            'content',
            0,
            'richtext',
          ]}
          disabled={disabled}
        />
      </EditorContainer>
    </Form>
  );
};
