import { Button } from 'antd';
import React from 'react';
import { SitesControl } from 'src/pages/sites/components/sites-control';
import { StyledTabHeaderWrapper } from '../../../components/elements';

type MainTabHeaderProps = {
  onSubmit: () => void;
  loading: boolean;
};

export const SitemapTabHeader: React.FC<MainTabHeaderProps> = ({ loading, onSubmit }) => {
  return (
    <StyledTabHeaderWrapper>
      <SitesControl />
      <Button type="primary" htmlType="submit" onClick={onSubmit} loading={loading}>
        Сохранить
      </Button>
    </StyledTabHeaderWrapper>
  );
};
