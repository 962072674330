import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import React from 'react';
import { css } from 'styled-components';
import { ConfigType, useBlockMeta, useConfigByStyles } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';
import { StyledControl, StyledControlsBlock } from '../../../elements';

export enum AlignBlockData {
  left = 'left',
  center = 'center',
  right = 'right',
}

const ALIGN_TYPES: StyleItem<AlignBlockData>[] = [
  {
    label: 'Align left',
    style: AlignBlockData.left,
    icon: <AlignLeftOutlined />,
    name: 'Выравнивание по левому краю',
  },
  {
    label: 'Align center',
    style: AlignBlockData.center,
    icon: <AlignCenterOutlined />,
    name: 'Выравнивание по центру',
  },
  {
    label: 'Align right',
    style: AlignBlockData.right,
    icon: <AlignRightOutlined />,
    name: 'Выравнивание по правому краю',
  },
];

export const ALIGN_KEY = 'align';

export const alignStyles = css`
  .${ALIGN_KEY}-${AlignBlockData.left} {
    text-align: left;
  }
  .${ALIGN_KEY}-${AlignBlockData.center} {
    text-align: center;
  }
  .${ALIGN_KEY}-${AlignBlockData.right} {
    text-align: right;
  }
`;

export const AlignBlock: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleData, currentBlockData] = useBlockMeta<AlignBlockData>(editorState, setEditorState, ALIGN_KEY);

  const types = useConfigByStyles(ConfigType.Align, ALIGN_TYPES);

  if (!types.length) {
    return null;
  }

  return (
    <StyledControlsBlock>
      {types.map(({ label, style: blockDataValue, name, icon }) => (
        <StyledControl key={label}>
          <ControlButton
            title={name}
            isActive={currentBlockData === blockDataValue}
            value={blockDataValue}
            onToggle={toggleData}
          >
            {icon}
          </ControlButton>
        </StyledControl>
      ))}
    </StyledControlsBlock>
  );
});

AlignBlock.displayName = 'AlignBlock';
