import { keycloakConfig } from '@console/api';
import { ProfileType } from '@console/uikit';
import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useKeycloak } from 'use-keycloak';
import { MediaContextProvider } from '../contexts/media-context';
import { useRequestInterceptorServiceWorker } from '../hooks/use-request-interceptor-service-worker';
import AuthPage from '../pages/auth';
import { NotFoundPage } from '../pages/not-found';
import { defaultService, privateRoutes } from './routes';
import { Menu } from './components/menu';

const PrivateRoutes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['fromPath']);
  const authData = useKeycloak({
    ...keycloakConfig,
    redirectUri: `${window.location.origin}/auth/token`,
  });
  const [profile, setProfile] = useState<ProfileType>();
  const [requestInterceptor] = useRequestInterceptorServiceWorker();

  useEffect(() => {
    if (authData.authToken) {
      requestInterceptor(async (headers) => {
        const token = await authData.getRefreshedToken();
        if (!token) {
          return Promise.reject('Auth token not found');
        }

        headers['Authorization'] = `Bearer ${token}`;

        return headers;
      });

      setProfile({
        name: authData?.tokenParsed?.name ?? '',
        preferredUsername: authData?.tokenParsed?.preferred_username ?? '',
        familyName: authData?.tokenParsed?.family_name ?? '',
        email: authData?.tokenParsed?.email ?? '',
        givenName: authData?.tokenParsed?.given_name ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.authToken]);

  if (!profile) {
    setCookie('fromPath', `${window.location.href}`);

    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin spinning={true} size="large" />
      </div>
    );
  }

  return (
    <>
      <Menu profile={profile} logout={authData.logout} />
      <Route exact path="/" render={() => <Redirect to={defaultService.url} />} />
      <MediaContextProvider>
        <Switch>
          {privateRoutes.map(({ component: Component, routes, redirect, ...props }) => (
            <Route key={props.path} {...props}>
              <Component routes={routes} redirect={redirect} />
            </Route>
          ))}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </MediaContextProvider>
    </>
  );
};

export const RoutesView: FC = React.memo(() => {
  return (
    <Router>
      <Switch>
        <Route path="/auth/token" component={AuthPage} exact />
        <Route path="*" component={PrivateRoutes} />
      </Switch>
    </Router>
  );
});

RoutesView.displayName = 'RoutesView';
