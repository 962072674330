import { Form, Input } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { FormListItemContainer } from 'src/components/editors/custom-inputs';
import { FilterEqualityInput } from './filter-equality-input';

export type FilterEditorProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  possibleAttributeNames?: { label: string; value: string }[];
};

// TODO: CHANGE EQUALITY INPUTS LOGIC
export const FilterEditor: React.FC<FilterEditorProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  children,
  possibleAttributeNames,
}) => {
  const [equalityError, setEqualityError] = React.useState<string>();

  return (
    <FormListItemContainer
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item
        label={`Заголовок фильтра #${index + 1}`}
        name={[field.name, 'title']}
        fieldKey={[field.fieldKey, 'title']}
      >
        <Input disabled={disabled} />
      </Form.Item>
      {children}
      <Form.Item
        label="Фильтры"
        name={[field.name, 'filter']}
        fieldKey={[field.fieldKey, 'filter']}
        style={{ marginBottom: 0 }}
        rules={[
          {
            validator: (_, filter) => {
              if (!Object.keys(filter).filter(Boolean).length) {
                return Promise.reject(new Error('Добавьте как минимум один фильтр'));
              }

              return Promise.resolve();
            },
          },
          {
            validator: () => {
              if (equalityError) {
                return Promise.reject(new Error(equalityError));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <FilterEqualityInput
          handleError={setEqualityError}
          disabled={disabled}
          possibleAttributeNames={possibleAttributeNames}
        />
      </Form.Item>
    </FormListItemContainer>
  );
};
