import { useContext } from 'react';
import { AlignBlockData } from '../components/controls/blocks/align-block';
import { BlockquoteBlockType } from '../components/controls/blocks/blockquote-block';
import { HeaderBlockType } from '../components/controls/blocks/header-block';
import { ListBlockType } from '../components/controls/blocks/list-block';
import { DisclaimerDataItem } from '../components/controls/entities/disclaimer-entity';
import { IconType } from '../components/controls/entities/icon-entity';
import { PhoneProps } from '../components/controls/entities/phone-entity';
import { TextColorInlineType, TextColorKeysInlineType } from '../components/controls/inline/text-color-inline';
import { TextSizeInlineType } from '../components/controls/inline/text-size-inline';
import { TextStyleInlineType } from '../components/controls/inline/text-style-inline';
import { StyleItem } from '../types';
import { ControlsContext } from './controls-context';

export { TextSizeInlineType, TextColorInlineType };
export type { TextStyleInlineType };

export enum ConfigType {
  Heading = 'heading',
  Align = 'align',
  Blockquote = 'blockquote',
  List = 'list',
  TextColor = 'textColor',
  TextSize = 'textSize',
  TextStyle = 'textStyle',
}

export enum DataType {
  Link = 'link',
  Disclaimer = 'disclaimer',
  Phone = 'phone',
  Placeholder = 'placeholder',
  Icon = 'icon',
  IconColor = 'iconColor',
}

export type EntityControlsConfig = {
  [DataType.Link]?: boolean;
  [DataType.Disclaimer]?: boolean | DisclaimerDataItem[];
  [DataType.Phone]?: boolean | PhoneProps[];
  [DataType.Placeholder]?: boolean;
  [DataType.Icon]?: boolean | IconType[];
  [DataType.IconColor]?: boolean | TextColorKeysInlineType[];
};

export type ControlsConfig = {
  [ConfigType.Heading]?: HeaderBlockType[];
  [ConfigType.Align]?: AlignBlockData[];
  [ConfigType.List]?: ListBlockType[];
  [ConfigType.Blockquote]?: BlockquoteBlockType[];
  [ConfigType.TextColor]?: TextColorInlineType[];
  [ConfigType.TextSize]?: TextSizeInlineType[];
  [ConfigType.TextStyle]?: TextStyleInlineType[];
};

export type PresetsStylesConfig = {
  [ConfigType.TextColor]?: TextColorInlineType;
  [ConfigType.TextSize]?: TextSizeInlineType;
  [ConfigType.TextStyle]?: TextStyleInlineType[];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useConfig = (type: ConfigType) => {
  const controls = useContext(ControlsContext);
  return controls && controls.config ? controls.config[type] : undefined;
};

export const useCheckConfig = (type: ConfigType): boolean => {
  const currentConfig = useConfig(type);
  return currentConfig ? Boolean(currentConfig.length) : true;
};

export const useConfigByStyles = <T,>(type: ConfigType, styles: StyleItem<T>[]): StyleItem<T>[] => {
  const currentConfig = useConfig(type);
  return currentConfig && styles ? styles.filter((item) => currentConfig.includes(item.style as never)) : styles;
};

export const useEntityControls = <T,>(type: DataType, defaultData: T): T => {
  const controls = useContext(ControlsContext);
  const controlData = controls && controls.entityConfig && controls.entityConfig[type];
  return (typeof controlData === 'undefined' ? defaultData : controlData) as T;
};
