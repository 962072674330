import { DraftDecorator } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { DecoratorComponentProps } from '../../../../types';
import { findEntities } from '../../../../utils';
import { VerticalDividerProps } from './index';
import { VerticalDividerEntityType } from './types';

const StyledDividerWrapper = styled.span<VerticalDividerProps>`
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: ${(props) => props.color};
  }
`;

const Divider: React.FC<DecoratorComponentProps> = (props) => {
  const dividerProps: VerticalDividerProps = props.contentState.getEntity(props.entityKey).getData();
  return (
    <StyledDividerWrapper {...dividerProps}>
      <span>{props.children}</span>
    </StyledDividerWrapper>
  );
};

export const dividerDecorator: DraftDecorator = {
  strategy: findEntities<VerticalDividerEntityType>('VERTICAL_DIVIDER'),
  component: Divider,
};
