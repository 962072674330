import { DraftDecorator } from 'draft-js';
import React from 'react';
import { DecoratorComponentProps } from '../../../../types';
import { findEntities } from '../../../../utils';
import { PhoneEntityType, PhoneProps } from './types';

const Phone: React.FC<DecoratorComponentProps> = (props) => {
  const linkProps: PhoneProps = props.contentState.getEntity(props.entityKey).getData();
  return <a href={linkProps.phone}>{props.children}</a>;
};

export const phoneDecorator: DraftDecorator = {
  strategy: findEntities<PhoneEntityType>('TEXTPHONE'),
  component: Phone,
};
