import { ContentSitesV1DomainsAPIApi, ContentSitesV1ListDomainsResponse } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseGetDomainsData = {
  domainsResponse: ContentSitesV1ListDomainsResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type GetDomainsParams = {
  site: string;
  enabled: boolean;
};

export function useGetDomains(
  domainsApi: ContentSitesV1DomainsAPIApi,
  queryKey: QueryKey,
  params: GetDomainsParams,
): UseGetDomainsData {
  const { site, enabled } = params;

  const {
    data: domainsResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      domainsApi
        .contentSitesV1DomainsAPIListDomains(
          site, // siteName: string,
          undefined, // siteRevision?: string,
          undefined, // activityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // activityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // kindEq?: Array<'DOMAIN_KIND_NONE' | 'DOMAIN_KIND_MAIN' | 'DOMAIN_KIND_PREVIEW' | 'DOMAIN_KIND_MIRROR'>,
          undefined, // kindNeq?: Array<'DOMAIN_KIND_NONE' | 'DOMAIN_KIND_MAIN' | 'DOMAIN_KIND_PREVIEW' | 'DOMAIN_KIND_MIRROR'>,
          undefined, // nameEq?: Array<string>,
          undefined, // nameNeq?: Array<string>,
          undefined, // pageSize?: number,
          undefined, // pageToken?: string,
          undefined, // search?: string,
          undefined, // sort?: Array<'DOMAIN_SORT_NONE' | 'DOMAIN_SORT_NAME_ASC' | 'DOMAIN_SORT_NAME_DESC' | 'DOMAIN_SORT_TIME_ASC' | 'DOMAIN_SORT_TIME_DESC' | 'DOMAIN_SORT_KIND_ASC' | 'DOMAIN_SORT_KIND_DESC'>,
          undefined, // statusEq?: Array<'DOMAIN_KIND_NONE' | 'DOMAIN_KIND_MAIN' | 'DOMAIN_KIND_PREVIEW' | 'DOMAIN_KIND_MIRROR'>,
          undefined, // statusNeq?: Array<'DOMAIN_KIND_NONE' | 'DOMAIN_KIND_MAIN' | 'DOMAIN_KIND_PREVIEW' | 'DOMAIN_KIND_MIRROR'>,
        )
        .then((resp) => resp.data),
    { enabled },
  );

  return {
    domainsResponse,
    isListLoading,
    isListFetching,
  };
}
