import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { EditorBySnippetPathname } from 'src/components/editors/snippet-editors';
import { DrawerFormEditor } from '@console/uikit';
import { getSnippetPath } from 'src/utils/snippets';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

type WidgetEditorDrawerProps = {
  /** editable node */
  node: ContentVolkswagenV1Widget | null;
  /** editor title */
  title: string;
  /** is editor state loading */
  loading: boolean;
  /** on close editor handler */
  onClose: () => void;
  /** on save editor handler */
  onSave: (node: ContentVolkswagenV1Widget) => void;
};

export const WidgetEditorDrawer: React.FC<WidgetEditorDrawerProps> = ({ loading, node, onClose, onSave, title }) => {
  const { snippetPath, treePath } = React.useMemo(() => getSnippetPath(node), [node]);
  const nodeForEdit = React.useMemo(() => (treePath.length > 0 ? get(node, treePath) : node), [node, treePath]);

  const isVisible = Boolean(snippetPath);
  const Editor = snippetPath && EditorBySnippetPathname[snippetPath];

  const handleSave = React.useCallback(
    (newNode: ContentVolkswagenV1Widget) => {
      if (treePath.length > 0) {
        const cloneNode = set(cloneDeep(node) || {}, treePath, newNode);

        return onSave(cloneNode);
      }

      onSave(newNode);
    },
    [node, treePath, onSave],
  );

  return (
    <DrawerFormEditor
      title={`${title} ${snippetPath}`}
      isVisible={isVisible}
      closeHandler={onClose}
      loading={loading}
      formName={snippetPath || ''}
    >
      {node && Editor && <Editor node={nodeForEdit} onSave={handleSave} formName={snippetPath} />}
    </DrawerFormEditor>
  );
};
