import { ContentFilesV1ListFilesResponse } from '@cms/volkswagen-widgets';
import { filesApi, Service } from '../../../';
import { useCreateFile, UseCreateFileData } from './actions/useCreateFile';
import { useRemoveFile, UseRemoveFileData } from './actions/useDeleteFile';
import { ListFilesParams, useListFiles } from './actions/useListFiles';
import { useUpdateFile, UseUpdateFileData } from './actions/useUpdateFile';
import { useQueryClient } from 'react-query';

export * from './actions/useUpdateFile';
export * from './actions/useCreateFile';
export * from './actions/useDeleteFile';
export * from './actions/useGetFile';

type Models = {
  filesResponse: ContentFilesV1ListFilesResponse | undefined;
};

type Operations = {
  createFile: UseCreateFileData['createFile'];
  createFileAsync: UseCreateFileData['createFileAsync'];
  removeFile: UseRemoveFileData['removeFile'];
  removeFileAsync: UseRemoveFileData['removeFileAsync'];
  updateFile: UseUpdateFileData['updateFile'];
  updateFileAsync: UseUpdateFileData['updateFileAsync'];
  invalidateQueries(): void;
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isRemoveLoading: boolean;
  isUpdateLoading: boolean;
};

type Parameters = ListFilesParams & {
  isInvalidate?: boolean;
};

export type FilesService = Service<Models, Operations, Meta>;

export const useFiles = (params: Parameters): FilesService => {
  const { path, types, isInvalidate } = params;
  const queryKey = ['files', path, types];

  const queryClient = useQueryClient();

  const { isListFetching, isListLoading, filesResponse } = useListFiles(filesApi, queryKey, params);
  const { createFile, createFileAsync, isCreateLoading } = useCreateFile(filesApi, queryKey, isInvalidate);
  const { isRemoveLoading, removeFile, removeFileAsync } = useRemoveFile(filesApi, queryKey, isInvalidate);
  const { isUpdateLoading, updateFile, updateFileAsync } = useUpdateFile(filesApi, queryKey, isInvalidate);

  return {
    models: {
      filesResponse,
    },
    operations: {
      createFile,
      createFileAsync,
      removeFile,
      removeFileAsync,
      updateFile,
      updateFileAsync,
      invalidateQueries() {
        queryClient.invalidateQueries(queryKey);
      },
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isRemoveLoading,
      isUpdateLoading,
    },
  };
};
