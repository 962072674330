import { VideoNode, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input, Row, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageStylesSelect, ThemeCheckbox, MediaPicker, StyledImageStylesSelectItem } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { StatusArea } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const VideoSnippetEditor: React.FC<NodeEditorProps> = ({ node, onSave, formName }) => {
  const videoData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'video'],
  });
  const disabled = videoData.placeholdersCount === 0;

  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item>
        <Checkbox
          defaultChecked={videoData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
          >
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <Form.Item
        label="Видео"
        name={[...videoData.path, 'source']}
        fieldKey={[...videoData.path, 'source']}
        rules={[{ required: true }]}
      >
        <MediaPicker disabled={disabled} mediaType="video" />
      </Form.Item>
      <StatusArea
        name={[...videoData.path, 'visibility']}
        checkboxProps={{
          truthyValue: VisibilityV1Status.Public,
          falsyValue: VisibilityV1Status.Private,
        }}
      >
        Видимость
      </StatusArea>
      <Form.Item
        label="Изображение-превью"
        name={[...videoData.path, 'poster']}
        fieldKey={[...videoData.path, 'poster']}
      >
        <MediaPicker disabled={disabled} />
      </Form.Item>
      <Form.Item label="Опции плеера">
        <Row gutter={10}>
          <Form.Item name={[...videoData.path, 'themes']}>
            <ThemeCheckbox themeName={VideoNode.themes.AutoPlay} disabled={disabled} label="Автовоспроизведение" />
          </Form.Item>
          <Form.Item name={[...videoData.path, 'themes']}>
            <ThemeCheckbox themeName={VideoNode.themes.HideTimeLine} disabled={disabled} label="Без таймлайна" />
          </Form.Item>
          <Form.Item name={[...videoData.path, 'themes']}>
            <ThemeCheckbox themeName={VideoNode.themes.Loop} disabled={disabled} label="Зацикленное" />
          </Form.Item>
          <Form.Item name={[...videoData.path, 'themes']}>
            <ThemeCheckbox themeName={VideoNode.themes.SoundControl} disabled={disabled} label="Управление громкости" />
          </Form.Item>
        </Row>
      </Form.Item>
      <StyledImageStylesSelectItem
        label="Настройки отображения"
        name={[...videoData.path, 'styles']}
        fieldKey={[...videoData.path, 'styles']}
      >
        <ImageStylesSelect withoutSize disabled={disabled} />
      </StyledImageStylesSelectItem>
    </Form>
  );
};
