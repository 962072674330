import { BellOutlined, QuestionCircleOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { d6 } from '@cms/volkswagen-widgets';
import { NavigationMenu, NavigationMenuProps } from '@console/uikit';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isPreviewPage } from '../../utils/urls';
import { getCurrentService, navigationMenuRoutes } from '../routes';

const { isAbsoluteUrl } = d6;

export const Menu: FC<{ profile: NavigationMenuProps['profile']; logout: NavigationMenuProps['logout'] }> = ({
  profile,
  logout,
}) => {
  const location = useLocation();
  const currentService = getCurrentService(location.pathname);

  if (isPreviewPage(location.pathname)) return null;

  return (
    <NavigationMenu
      services={navigationMenuRoutes}
      currentServiceName={currentService?.name}
      currentAppName="cms"
      profile={profile}
      logout={logout}
      links={[
        {
          name: '',
          title: 'Список пользователей',
          url: '#',
          icon: UsergroupAddOutlined,
        },
        {
          name: '',
          title: 'Сервис уведомлений',
          url: '#',
          icon: BellOutlined,
        },
        {
          name: '',
          title: 'Служба поддержки',
          url: '#',
          icon: QuestionCircleOutlined,
        },
      ]}
      link={({ href, children }) => {
        return isAbsoluteUrl(href) ? (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
          <Link to={href}>{children}</Link>
        );
      }}
    />
  );
};
