import { ApartmentOutlined, EyeOutlined, HistoryOutlined, SettingOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Page } from '@cms/volkswagen-widgets';
import { Button, PageHeader, Space, Typography } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@console/uikit';
import { removeLastPathParam } from 'src/utils/string';
import { getFilesUrl, getPagePreviewUrl } from 'src/utils/urls';
import { usePageDetail, ViewState } from '../contexts';

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type PageDetailHeaderProps = {
  page: ContentVolkswagenV1Page;
};

export const PageDetailHeader: React.FC<PageDetailHeaderProps> = ({ page }) => {
  const history = useHistory();
  const { setMainViewState } = usePageDetail();

  const handleHistoryClick = () => setMainViewState(ViewState.versionHistory);
  const handleSettingsClick = () => setMainViewState(ViewState.settings);
  const handleStructureClick = () => setMainViewState(ViewState.structure);
  const handlePreviewClick = () => window.open(getPagePreviewUrl(page.path.substring(1)));
  const handleBack = () => history.push(`${getFilesUrl()}?path=${removeLastPathParam(page.path).substring(1)}`);

  return (
    <PageHeader
      title={
        <StyledTitleContainer>
          <Typography.Text style={{ lineHeight: 1 }}>{page.title}</Typography.Text>
          <Typography.Text type="secondary" style={{ fontSize: 10, lineHeight: 1.5 }}>
            {page.path}
          </Typography.Text>
        </StyledTitleContainer>
      }
      onBack={handleBack}
      extra={
        <Space size="middle">
          <Button icon={<HistoryOutlined />} onClick={handleHistoryClick} />
          <Button icon={<EyeOutlined />} onClick={handlePreviewClick} />
          <Button icon={<ApartmentOutlined />} onClick={handleStructureClick} />
          <Button icon={<SettingOutlined />} onClick={handleSettingsClick} />
        </Space>
      }
    />
  );
};
