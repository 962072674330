import {
  ContentSitesV1SiteProps,
  ContentSitesV1Site,
  GoogleRpcStatus,
  ContentSitesV1SitesAPIApi,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Site>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = ContentSitesV1SiteProps;

export type UseCreateSiteData = {
  createSite: UseMutateFunction<Data, Error, Variables>;
  createSiteAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateSite(
  sitesApi: ContentSitesV1SitesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseCreateSiteData {
  const queryClient = useQueryClient();

  const {
    mutate: createSite,
    mutateAsync: createSiteAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>(
    (body) =>
      sitesApi.contentSitesV1SitesAPICreateSite(
        body, // body: ContentSitesV1SiteProps,
      ),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка создания сайта',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { createSite, createSiteAsync, isCreateLoading };
}
