import { ContentVolkswagenV1Richtext, ContentVolkswagenV1TextNode, d6 } from '@cms/volkswagen-widgets';
import React, { useMemo } from 'react';
import { ControlsConfig, DataType, EntityControlsConfig, PresetsStylesConfig, styled } from '@console/uikit';
import { TreePath } from '../../../../../../utils/content-path';
import { mapRichTextNodesToComponents } from './mapper';

const { IntegratorRoot, ThemeProvider } = d6;

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type RichTextContentEditorProps = {
  value?: ContentVolkswagenV1Richtext;
  onChange?: (value: ContentVolkswagenV1TextNode[]) => void;
  presetsStyles?: PresetsStylesConfig;
  controlsConfig?: ControlsConfig;
  entityControlsConfig?: EntityControlsConfig;
  availableIcons?: EntityControlsConfig['icon'];
  availableIconColors?: EntityControlsConfig['iconColor'];
  disabled: boolean;
  name: TreePath;
};

const StyledRichTextWrapper = styled.div<{ isDisabled: boolean }>`
  padding: 4px 11px;
  border: 1px solid ${(props) => props.theme.colors.gray5};
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    ${(props) => !props.isDisabled && `border-color: #40a9ff;`}
  }

  ${(props) =>
    props.isDisabled &&
    `
        color: rgba(0,0,0,.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        box-shadow: none;
        cursor: not-allowed;

        * {
          opacity: 0.75;
        }
  `}
`;

export const RichTextContentMapper: React.FC<RichTextContentEditorProps> = React.memo(
  ({ value: node, name, ...props }) => {
    const components = useMemo(
      () =>
        node
          ? mapRichTextNodesToComponents(node.content, name, {
              presetsStyles: props.presetsStyles,
              controlsConfig: props.controlsConfig,
              entityControlsConfig: {
                ...props.entityControlsConfig,
                ...(props.availableIcons ? { [DataType.Icon]: props.availableIcons } : {}),
                ...(props.availableIconColors ? { [DataType.IconColor]: props.availableIconColors } : {}),
              },
              disabled: props.disabled,
            })
          : [],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [name, node?.content],
    );

    if (!node) {
      console.warn(`Invalid structure RichTextContentMapper, not found content`);

      return null;
    }

    return (
      <StyledRichTextWrapper isDisabled={props.disabled}>
        <IntegratorRoot>
          <ThemeProvider theme="main">{components}</ThemeProvider>
        </IntegratorRoot>
      </StyledRichTextWrapper>
    );
  },
);
