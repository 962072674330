import { Form } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { AttributeInput } from 'src/components/editors/custom-inputs';
import { TreePath } from 'src/utils/content-path';

type FormAttributeFormItemProps = {
  attributeName: string;
  disabled: boolean;
  formListFieldData: FormListFieldData;
  formPath: TreePath;
  label: string;
};

export const FormAttributeFormItem: React.FC<FormAttributeFormItemProps> = ({
  attributeName,
  disabled,
  formListFieldData,
  formPath,
  label,
}) => (
  <Form.Item
    label={label}
    name={[formListFieldData.name, ...formPath, 'attributes']}
    fieldKey={[formListFieldData.fieldKey, ...formPath, 'attributes']}
  >
    <AttributeInput attributeName={attributeName} disabled={disabled} />
  </Form.Item>
);
