import { TopMenuNode } from '@cms/volkswagen-widgets';
import { Checkbox, ColProps, Form } from 'antd';
import { AttributeInput, ThemeCheckbox } from 'src/components/editors/custom-inputs';
import { GetPlaceholderedPathResult, TreePath } from 'src/utils/content-path';

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];
const labelCol: ColProps = { span: 5 };

type TopMenuTabCommonProps = {
  disabled: boolean;
  onPlaceholderChange: (path: TreePath, isEnabled: boolean) => void;
  topMenuData: GetPlaceholderedPathResult;
};

export const TopMenuTabCommon: React.FC<TopMenuTabCommonProps> = ({ disabled, onPlaceholderChange, topMenuData }) => {
  return (
    <>
      {/* placeholder edit */}
      <Form.Item wrapperCol={{ offset: labelCol.span }}>
        <Checkbox
          defaultChecked={topMenuData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* logo.href edit */}
      <Form.Item label="Ссылка на логотипе" labelCol={labelCol} name={[...topMenuData.path, 'attributes']}>
        <AttributeInput attributeName={TopMenuNode.attributes.logoHref} disabled={disabled} />
      </Form.Item>

      {/* logo.title edit */}
      <Form.Item label="Подпись к логотипу" labelCol={labelCol} name={[...topMenuData.path, 'attributes']}>
        <AttributeInput attributeName={TopMenuNode.attributes.logoTitle} disabled={disabled} />
      </Form.Item>

      {/* stageHeight edit */}
      <Form.Item label="Высота стейджа" labelCol={labelCol} name={[...topMenuData.path, 'attributes']}>
        <AttributeInput attributeName={TopMenuNode.attributes.stageHeight} disabled={disabled} />
      </Form.Item>

      {/* dealers.modalHref edit */}
      <Form.Item
        label="Ссылка на модальное окно контактов дилера"
        labelCol={labelCol}
        name={[...topMenuData.path, 'attributes']}
      >
        <AttributeInput attributeName={TopMenuNode.attributes.dealersHref} disabled={disabled} />
      </Form.Item>

      {/* dealers.items edit */}
      <Form.Item
        label="Список дилеров"
        labelCol={labelCol}
        name={[...topMenuData.path, 'items', 0, 'topMenuItem', 'attributes']}
      >
        <AttributeInput attributeName={TopMenuNode.Item.attributes.dealers} disabled={disabled} multiply />
      </Form.Item>

      {/* inheritPromoArea edit */}
      <Form.Item name={[...topMenuData.path, 'themes']} wrapperCol={{ offset: labelCol.span }}>
        <ThemeCheckbox
          label="Наследовать промо-область в меню"
          themeName={TopMenuNode.themes.inheritPromoArea}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};
