import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import React from 'react';
import { useSitesContext } from 'src/pages/sites/contexts/sites-context';
import { UpdateSiteFormValues } from '../components/update-site-form';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMainTabSite() {
  const {
    currentSite,
    service: {
      operations: { updateSite },
      meta: { isUpdateLoading },
    },
  } = useSitesContext();

  const [form] = Form.useForm<UpdateSiteFormValues>();

  React.useEffect(() => {
    // discard the first slash
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, ...pathChunks] = currentSite?.path.split('/') || [];

    form.setFieldsValue({
      title: currentSite?.title,
      path: pathChunks.map((c) => ({ label: c, value: c })),
    });
  }, [form, currentSite]);

  const onUpdateSite = (values: UpdateSiteFormValues) => {
    updateSite({
      siteName: currentSite?.name || '',
      body: {
        ...values,
        name: currentSite?.name || '',
        path: '/' + values.path.map((p) => p.value).join('/'),
        activity: { status: ActivityV1Status.Active },
        visibility: { status: VisibilityV1Status.Public },
      },
    });
  };

  return { onUpdateSite, isUpdateLoading, form };
}
