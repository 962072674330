import { ContentVolkswagenV1Widget, SnippetName, SnippetPathByName } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../../../utils/content-path';
import { AddSnippetButtonProps, SnippetItemBlock } from '../../../custom-inputs';
import { NodePreview } from '../../../custom-inputs';

export type TextOrQuoteProps = {
  field: FormListFieldData;
  remove: FormListOperation['remove'];
  disabled: boolean;
  index: number;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const ImageOrQuote: React.FC<TextOrQuoteProps> = ({ field, disabled, remove, index, contentPath }) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => {
        const node: ContentVolkswagenV1Widget = getFieldValue([...contentPath, index]);

        const isQuote = node.blueprint?.source === SnippetPathByName.Quote;

        const allowedSnippets = [isQuote ? SnippetName.Richtext : SnippetName.Quote];

        return (
          <SnippetItemBlock
            disabled={disabled}
            onDelete={() => remove(field.name)}
            contentPath={isQuote ? [...contentPath, index] : [...contentPath, index, 'box', 'content']}
            allowedSnippets={allowedSnippets}
          >
            <NodePreview value={node} />
          </SnippetItemBlock>
        );
      }}
    </Form.Item>
  );
};
