import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React from 'react';
import { getMimeType } from '../../utils/media';

type FilesTypeTableCellProps = {
  file: ContentFilesV1File;
};

export const FilesExtensionTableCell: React.FC<FilesTypeTableCellProps> = ({ file }) => {
  const mime = getMimeType(file.mediaV1Media?.attributes);

  return <span>{mime.ext.toUpperCase()}</span>;
};
