import { ContentVolkswagenV1Widget, SnippetName } from '@cms/volkswagen-widgets';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../../../../../../utils/content-path';
import { SnippetItemBlock } from '../../../../../../custom-inputs';
import { NodePreview } from '../../../../../../custom-inputs';

export type GalleryColumnQuoteProps = {
  node: ContentVolkswagenV1Widget;
  field: FormListFieldData;
  remove: FormListOperation['remove'];
  disabled: boolean;
  index: number;
  contentPath: TreePath;
};

export const GalleryColumnQuote: React.FC<GalleryColumnQuoteProps> = ({
  node,
  field,
  disabled,
  remove,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      contentPath={[...contentPath, index]}
      allowedSnippets={[SnippetName.Richtext]}
    >
      <NodePreview value={node} />
    </SnippetItemBlock>
  );
};
