import { notification } from 'antd';
import React from 'react';
import { useUsersPageContext } from '../../contexts/users-context';
import { UsersTable } from './components/table';

export const UsersTableContainer: React.FC = () => {
  const {
    selectedUsers,
    setSelectedUsers,
    setEditUser,
    service: {
      models: { usersResponse },
      meta: { isListLoading, isListFetching },
      operations: { removeUserAsync },
    },
  } = useUsersPageContext();

  const users = usersResponse?.users ?? [];

  const handleRemoveUser = (name: string) =>
    removeUserAsync(name).then(() => {
      notification.error({
        message: 'Пользователь успешно удалён',
      });
    });

  return (
    <>
      <UsersTable
        users={users}
        loading={isListLoading || isListFetching}
        selectedRows={selectedUsers}
        setSelectedRows={setSelectedUsers}
        onEditUser={setEditUser}
        onRemoveUser={handleRemoveUser}
      />
    </>
  );
};
