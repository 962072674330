import { nodeHasTheme } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';

const ActiveMarginBlock = styled.div<{ active: boolean }>`
  ${(props) => props.active && 'height: 20%;'}
`;

type PreviewMarginProps = {
  value?: string[];
  themeName: string;
};

export const PreviewMargin: React.FC<PreviewMarginProps> = ({ value, themeName }) => {
  return <ActiveMarginBlock active={nodeHasTheme(value, themeName)} />;
};
