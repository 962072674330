import { ContentSitesV1ListRobotsResponse, ContentSitesV1RobotsAPIApi } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseGetRobotsData = {
  robotsResponse: ContentSitesV1ListRobotsResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type GetRobotsParams = {
  site: string;
  enabled: boolean;
};

export function useGetRobots(
  robotsApi: ContentSitesV1RobotsAPIApi,
  queryKey: QueryKey,
  params: GetRobotsParams,
): UseGetRobotsData {
  const { site, enabled } = params;

  const {
    data: robotsResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      robotsApi
        .contentSitesV1RobotsAPIListRobots(
          site, // siteName: string,
          undefined, // siteRevision?: string,
          undefined, // activityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // activityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // nameEq?: Array<string>,
          undefined, // nameNeq?: Array<string>,
          undefined, // pageSize?: number,
          undefined, // pageToken?: string,
          undefined, // search?: string,
          undefined, // sort?: Array<'ROBOT_SORT_NONE' | 'ROBOT_SORT_NAME_ASC' | 'ROBOT_SORT_NAME_DESC' | 'ROBOT_SORT_TIME_ASC' | 'ROBOT_SORT_TIME_DESC'>,
          undefined, // visibilityEq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
          undefined, // visibilityNeq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
        )
        .then((resp) => resp.data),
    { enabled },
  );

  return {
    robotsResponse,
    isListLoading,
    isListFetching,
  };
}
