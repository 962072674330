import { useCallback, useState } from 'react';

type UseToggle = readonly [
  boolean,
  {
    readonly onToggle: () => void;
    readonly offToggle: () => void;
    readonly switchToggle: () => void;
  },
];

/**
 * Toggle
 * @example const [toggle, { onToggle, offToggle, switchToggle }] = useToggle();
 */
export const useToggle = (defaultState = false): UseToggle => {
  const [toggle, setToggle] = useState(defaultState);

  const switchToggle = useCallback(() => setToggle((state) => !state), []);
  const onToggle = useCallback(() => setToggle(true), []);
  const offToggle = useCallback(() => setToggle(false), []);

  return [toggle, { onToggle, offToggle, switchToggle }] as const;
};
