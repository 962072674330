import { styled } from '@console/uikit';

export const EditorContainer = styled.div<{ label?: string }>`
  border: 1px solid ${(props) => props.theme.colors.gray5};
  padding: 16px;
  position: relative;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    top: -6px;
    left: 16px;
    content: '${(props) => props.label}';
    background-color: white;
    padding: 0 8px;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;
