import React, { useContext } from 'react';
import styled from 'styled-components';
import { ControlsContext } from '../../hooks/controls-context';
import { ControlsProps } from '../../types';
import { BlockControls } from './blocks';
import { Clear } from './clear';
import { EntityControls } from './entities';
import { InlineControls } from './inline';

export const StyledControlsWrapper = styled.div<{ isDisabled?: boolean }>`
  padding: 8px 6px;
  display: flex;
  ${(props) => props.isDisabled && `pointer-events: none;`}
`;

const StyledControls = styled.div`
  display: flex;
  align-items: center;
`;

const StyledClearControls = styled(StyledControls)`
  width: 100%;
  justify-content: flex-end;
`;

export const Controls: React.FC<ControlsProps> = React.memo<ControlsProps>((props) => {
  const controls = useContext(ControlsContext);

  return (
    <StyledControlsWrapper isDisabled={Boolean(props.disabled)} onMouseDown={(e) => e.preventDefault()}>
      <StyledControls onMouseDown={(e) => e.stopPropagation()}>
        <BlockControls {...props} />
      </StyledControls>
      <StyledControls onMouseDown={(e) => e.stopPropagation()}>
        <InlineControls {...props} />
      </StyledControls>
      <StyledControls onMouseDown={(e) => e.stopPropagation()}>
        <EntityControls {...props} />
      </StyledControls>
      {controls.isClearButton && (
        <StyledClearControls onMouseDown={(e) => e.stopPropagation()}>
          <Clear {...props} />
        </StyledClearControls>
      )}
    </StyledControlsWrapper>
  );
});

Controls.displayName = 'Controls';
