import { DeleteOutlined, DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { styled } from '@console/uikit';

const ControlsWrapper = styled.div`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Container = styled.div<{ disabled: boolean }>`
  border: 1px dashed ${(props) => props.theme.colors.gray5};
  padding: 16px 8px;
  margin-bottom: 8px;
  position: relative;

  &:hover {
    ${(props) => !props.disabled && `border-color: ${props.theme.colors.blue5}`}
  }

  &:hover > ${ControlsWrapper} {
    display: block;
  }

  ${(props) =>
    props.disabled &&
    `
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.65);
        cursor: not-allowed;
    }`}
`;

type FormListItemContainerProps = {
  disabled: boolean;
  onDelete?: () => void;
  onMoveTop?: () => void;
  onMoveBottom?: () => void;
};

export const FormListItemContainer: React.FC<FormListItemContainerProps> = ({
  children,
  disabled,
  onDelete,
  onMoveBottom,
  onMoveTop,
}) => {
  return (
    <Container disabled={disabled}>
      {children}
      <ControlsWrapper>
        <Space>
          {onMoveTop && (
            <Button size="small" type="primary" shape="circle" onClick={onMoveTop} icon={<UpCircleOutlined />} />
          )}
          {onMoveBottom && (
            <Button size="small" type="primary" shape="circle" onClick={onMoveBottom} icon={<DownCircleOutlined />} />
          )}
          {onDelete && (
            <Button size="small" type="primary" shape="circle" onClick={onDelete} icon={<DeleteOutlined />} />
          )}
        </Space>
      </ControlsWrapper>
    </Container>
  );
};
