import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { SnippetItemBlock } from '../../../custom-inputs';
import { TopMenuFlyoutItemInput } from './top-menu-flyout-item-input';

export type TopMenuFlyoutItemProps = {
  disabled: boolean;
  field: FormListFieldData;
  index: number;
  isBottomMovable: boolean;
  isTopMovable: boolean;
  itemsPath: TreePath;
  menuLevel: number;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
};

export const TopMenuFlyoutItem: React.FC<TopMenuFlyoutItemProps> = ({
  disabled,
  field,
  index,
  isBottomMovable,
  isTopMovable,
  itemsPath,
  menuLevel,
  move,
  remove,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item noStyle style={{ margin: 0 }} name={[field.name]} fieldKey={[field.fieldKey]}>
        <TopMenuFlyoutItemInput
          disabled={disabled}
          field={field}
          index={index}
          itemsPath={itemsPath}
          menuLevel={menuLevel}
        />
      </Form.Item>
    </SnippetItemBlock>
  );
};
