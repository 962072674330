import { Checkbox, Form, Input } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { withSection } from '../../hocs';
import { StylesTableEdit } from './components';

export const IframeEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const boxData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'box'],
  });
  const disabled = boxData.placeholdersCount === 0;

  const handlePlaceholderChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item
        label="Ссылка"
        name={[formListFieldData.name, ...boxData.path, 'content', 'iframe', 'source']}
        fieldKey={[formListFieldData.fieldKey, ...boxData.path, 'content', 'iframe', 'source']}
        rules={[{ required: true }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        label="Стили"
        name={[formListFieldData.name, ...boxData.path, 'styles']}
        fieldKey={[formListFieldData.fieldKey, ...boxData.path, 'styles']}
      >
        <StylesTableEdit disabled={disabled} />
      </Form.Item>
      <Form.Item>
        <Checkbox
          defaultChecked={boxData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => handlePlaceholderChange(e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
    </>
  );
});
