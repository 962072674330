import { SnippetName } from '@cms/volkswagen-widgets';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from '../../../../../../../../utils/content-path';
import { SnippetItemBlock } from '../../../../../../custom-inputs';
import { RichtextFormItem } from '../../../../../../node-editors';

export type GalleryColumnTextProps = {
  field: FormListFieldData;
  remove: FormListOperation['remove'];
  disabled: boolean;
  index: number;
  contentPath: TreePath;
};

export const GalleryColumnText: React.FC<GalleryColumnTextProps> = ({
  field,
  disabled,
  remove,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      contentPath={[...contentPath, index]}
      allowedSnippets={[SnippetName.Quote]}
    >
      <RichtextFormItem
        formListFieldData={field}
        contentFieldName={['blueprint', 'content', 0, 'richtext']}
        disabled={disabled}
      />
    </SnippetItemBlock>
  );
};
