import uid from 'lodash/uniqueId';

export type MediaFile = {
  uid: string;
  originFileObj: File;
};

export const useUploader = (fileHandler: (files: MediaFile[]) => void) => {
  return (): void => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('multiple', 'true');
    fileInput.setAttribute('accept', 'image/*,video/*');
    fileInput.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      const files = Array.from(target?.files as FileList);
      const mediaFiles = files.map<MediaFile>((file) => {
        return {
          uid: uid(),
          originFileObj: file,
        };
      });
      fileHandler(mediaFiles);
      fileInput.remove();
    });
    fileInput.click();
  };
};
