import { ListFilesParams } from '@console/api/src/modules/file/service/actions/useListFiles';
import { useFiles } from '@console/api';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ActivityV1Status,
  ContentMediaV1Media,
  ContentFilesV1File,
  VisibilityV1Status,
  AttributesV1Attribute,
} from '@cms/volkswagen-widgets';
import { MediaStatus, Progresses, Statuses, useMediaContext } from '../../contexts/media-context';
import { strToKebabCase } from '../../utils/string';
import { useBreadcrumbs, UseBreadcrumbs } from '../useBreadcrumbs';
import { MediaFile } from '../useUploader';

type Media = MediaFile & {
  path: string;
};

type UseMediaParams = {
  enabled?: boolean;
  key?: string;
  search?: ListFilesParams['search'];
  sort?: ListFilesParams['sort'];
  defaultPath?: string;
};

export type UseMedia = {
  breadcrumbs: UseBreadcrumbs;
  files: Pick<ContentFilesV1File, 'filesV1Folder' | 'mediaV1Media'>[];
  isListFetching: boolean;
  isCreateLoading: boolean;
  fileStatuses: Statuses;
  fileProgresses: Progresses;
  uploadFiles(files: MediaFile[]): void;
  createFolder(name: string, title?: string): Promise<void>;
  removeFile(path: string): Promise<void>;
};

const sliceFile = (file: File, chunkSize = 1024 * 1024): Blob[] => {
  const result = [];

  for (let start = 0; start < file.size; start += chunkSize) {
    const chunk = file.slice(start, start + chunkSize);
    result.push(chunk);
  }

  return result;
};

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

const mergeAttributes = (
  attributes: AttributesV1Attribute[] | undefined,
  name: string,
  value: string,
): AttributesV1Attribute[] => {
  return (attributes ?? []).map((attr) => {
    if (attr.name === name) {
      return {
        ...attr,
        values: [value],
      };
    }

    return attr;
  });
};

export const useMedia = ({
  key = 'mediaPath',
  enabled = true,
  search,
  sort,
  defaultPath,
}: UseMediaParams): UseMedia => {
  const [queue, setQueue] = useState<Media[]>([]);
  const breadcrumbs = useBreadcrumbs({ key, defaultPath });
  const { fileStatuses, setFileStatuses, fileProgresses, setFileProgresses, isUploading } = useMediaContext();

  const {
    models: { filesResponse },
    meta: { isListFetching, isCreateLoading },
    operations: { createFileAsync, updateFileAsync, removeFileAsync, invalidateQueries },
  } = useFiles({
    enabled,
    path: breadcrumbs.path ? `/${breadcrumbs.path}/*` : '/*',
    isInvalidate: false,
    types: ['files.v1.folder', 'media.v1.media'],
    search,
    sort,
    size: 500, // TODO temporary
  });

  const removeFile = useCallback(
    async (path: string) => {
      try {
        await removeFileAsync(path.substring(1));
      } catch (_) {}
      invalidateQueries();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeFileAsync],
  );

  const uploadFile = useCallback(
    async (file: Media) => {
      const fileName = strToKebabCase(file.originFileObj.name);
      const filePath = file.path ? `/${file.path}/${fileName}` : `/${fileName}`;
      let updatedMedia: ContentMediaV1Media | undefined;

      try {
        setFileStatuses({
          [filePath]: MediaStatus.Uploading,
        });

        const chunks = sliceFile(file.originFileObj);

        const arrayOfSize = chunks.map((_) => '');
        await createFileAsync({
          mediaV1Media: {
            activity: { status: ActivityV1Status.Active },
            visibility: { status: VisibilityV1Status.Public },
            attributes: [
              {
                name: 'content-type',
                values: [file.originFileObj.type],
              },
              {
                name: 'status',
                values: [`${MediaStatus.Uploading}`],
              },
            ],
            path: filePath,
            title: file.originFileObj.name,
            content: arrayOfSize,
          },
        });
        setFileProgresses({
          [filePath]: '0',
        });

        invalidateQueries();

        for await (const [index, chunk] of chunks.entries()) {
          const base64Chunk = await blobToBase64(chunk);
          const base64ChunkWithoutTags = base64Chunk.substring(base64Chunk.indexOf(',') + 1);

          const { data } = await updateFileAsync({
            filePath: filePath.substring(1),
            body: {
              mediaV1Fragment: {
                fragment: `.content.${index}`,
                content: base64ChunkWithoutTags,
              },
            },
          });

          updatedMedia = data.mediaV1Media;

          setFileProgresses({
            [filePath]: `${(index + 1) / chunks.length}`,
          });
        }

        if (updatedMedia) {
          const attributes = mergeAttributes(updatedMedia?.attributes, 'status', `${MediaStatus.Uploaded}`);

          await updateFileAsync({
            filePath,
            body: {
              mediaV1Media: {
                ...updatedMedia,
                attributes,
              },
            },
          });
        }

        setFileStatuses({
          [filePath]: MediaStatus.Uploaded,
        });

        invalidateQueries();
      } catch (e) {
        setFileStatuses({
          [filePath]: MediaStatus.Error,
        });

        if (updatedMedia) {
          const attributes = mergeAttributes(updatedMedia?.attributes, 'status', `${MediaStatus.Error}`);

          await updateFileAsync({
            filePath,
            body: {
              mediaV1Media: {
                ...updatedMedia,
                attributes,
              },
            },
          });

          invalidateQueries();
        }

        throw Error();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFileStatuses, createFileAsync, setFileProgresses, updateFileAsync, fileProgresses],
  );

  const createFolder = useCallback(
    async (name: string, title?: string) => {
      await createFileAsync({
        filesV1Folder: {
          path: breadcrumbs.path ? `/${breadcrumbs.path}/${name}` : `/${name}`,
          title: title,
          activity: { status: ActivityV1Status.Active },
          visibility: { status: VisibilityV1Status.Public },
        },
      });
      invalidateQueries();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadcrumbs.path, createFileAsync],
  );

  const uploadFiles = (selectedFiles: MediaFile[]) => {
    setQueue((prevState) => [
      ...prevState,
      ...selectedFiles.map((file) => ({
        ...file,
        path: breadcrumbs.path,
      })),
    ]);
  };

  const upload = useCallback(async () => {
    const [head, ...tail] = queue;
    setQueue(tail);
    try {
      await uploadFile(head);
    } catch (e) {}
  }, [queue, uploadFile]);

  useEffect(() => {
    if (!isUploading && queue.length) {
      upload();
    }
  }, [isUploading, queue, upload]);

  useEffect(() => {
    if (search) {
      invalidateQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (sort) {
      invalidateQueries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    const progressesPaths = Object.keys(fileProgresses);
    const uploadingPath = progressesPaths.find((progressPath) => Number(fileProgresses[progressPath]) < 1);
    const isUploading = !!uploadingPath;

    if (isUploading) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [fileProgresses]);

  const filteredFiles = useMemo(() => {
    if (filesResponse?.files) {
      return search
        ? filesResponse?.files?.filter((file) => {
            return file?.mediaV1Media?.path?.includes(search) || file?.mediaV1Media?.title?.includes(search);
          })
        : filesResponse?.files;
    }

    return [];
  }, [filesResponse?.files, search]);

  return {
    breadcrumbs,
    files: filteredFiles,
    isListFetching,
    isCreateLoading,
    uploadFiles,
    fileStatuses,
    fileProgresses,
    createFolder,
    removeFile,
  };
};
