import { ContentFilesV1File, ContentFilesV1FilesAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentFilesV1File>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveFileData = {
  removeFile: UseMutateFunction<Data, Error, Variables>;
  removeFileAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveFile(
  filesApi: ContentFilesV1FilesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseRemoveFileData {
  const queryClient = useQueryClient();

  const {
    mutate: removeFile,
    mutateAsync: removeFileAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>(
    (path) =>
      filesApi.contentFilesV1FilesAPIDeleteFile(
        path, // filePath
        undefined, // fileRevision
      ),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка удаления файла',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { removeFile, removeFileAsync, isRemoveLoading };
}
