import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const gridPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, 'placeholder'];

export const ModelsCarouselCarFragmentEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { mainGridData, contentRichtextData, backgroundData, titleRichtextData, srcImageData } = React.useMemo(() => {
    const mainGridData = getPlaceholderedPath({
      node,
      commonPath: gridPath,
    });
    // content
    const contentRichtextData = getPlaceholderedPath({
      node,
      commonPath: [...mainGridData.path, 'content', 1, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });
    // background
    const backgroundData = getPlaceholderedPath({
      node,
      commonPath: [...mainGridData.path, 'content', 0, 'flex', 'content', 0, 'box', 'content', 'background'],
    });
    const boxGridContentData = getPlaceholderedPath({
      node,
      commonPath: [...backgroundData.path, 'content', 'grid', 'content'],
    });
    // title
    const titleRichtextData = getPlaceholderedPath({
      node,
      commonPath: [...boxGridContentData.path, 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });
    // src
    const srcImageData = getPlaceholderedPath({
      node,
      commonPath: [...boxGridContentData.path, 2, 'flex', 'content', 0, 'box', 'content', 'image'],
    });

    return {
      mainGridData,
      contentRichtextData,
      backgroundData,
      titleRichtextData,
      srcImageData,
    };
  }, [node]);

  const disabled = mainGridData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={mainGridData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <RichtextFormItem label="Заголовок" contentFieldName={titleRichtextData.path} disabled={disabled} />

      <RichtextFormItem label="Контент" contentFieldName={contentRichtextData.path} disabled={disabled} />

      <ImageNodeInputs imagePath={srcImageData.path} disabled={disabled} required />

      <Form.Item label="Цвет фона" name={[...backgroundData.path, 'styles', 0, 'color']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>
    </Form>
  );
};
