import { DraftDecorator } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { DecoratorComponentProps } from '../../../../types';
import { findInlines } from '../../../../utils';
import { PLACEHOLDER_KEY, PlaceholderBlockType } from './types';

const StyledPlaceholderWrapper = styled.span`
  border: 1px dashed #91d5ff;
`;

const Placeholder: React.FC<DecoratorComponentProps> = ({ children, ...props }) => {
  return <StyledPlaceholderWrapper {...props}>{children}</StyledPlaceholderWrapper>;
};

export const placeholderDecorator: DraftDecorator = {
  strategy: findInlines<PlaceholderBlockType>((style) => {
    return Boolean(style.find((value = '') => value.includes(PLACEHOLDER_KEY)));
  }),
  component: Placeholder,
};
