import { snippetGenerators, SnippetName, TabsNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddEnrichedNodeButton, AddSnippetButtonProps, ThemeStylesHeightInput } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { ModelsCarouselTabItem } from './components';

const initialTab = TabsNode.Tab.generators.node({
  content: [snippetGenerators.ModelsCarouselTab({ cards: [], bottomContent: [] })],
  header: [],
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.ModelsCarouselTab];

export const ModelsCarouselEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { themeData, tabsData } = React.useMemo(() => {
      const themeData = getPlaceholderedPath({
        node,
        commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'theme'],
      });

      const tabsData = getPlaceholderedPath({
        node,
        commonPath: [...themeData.path, 'content', 0, 'tabs'],
      });

      return { themeData, tabsData };
    }, [node]);

    const disabled = themeData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={themeData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            Редактируемый
          </Checkbox>
        </Form.Item>

        <Form.Item
          label="Высота изображений"
          fieldKey={[formListFieldData.fieldKey, ...themeData.path, 'styles']}
          name={[formListFieldData.name, ...themeData.path, 'styles']}
        >
          <ThemeStylesHeightInput disabled={disabled} themeKey="flex" />
        </Form.Item>

        <Form.List name={[formListFieldData.name, ...tabsData.path, 'items']}>
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <ModelsCarouselTabItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  contentPath={[...sectionContentPath, ...tabsData.path, 'items']}
                  allowedSnippets={allowedSnippets}
                />
              ))}
              <AddEnrichedNodeButton add={add} nodeToEnrich={initialTab} isDisabled={disabled} label="Добавить таб" />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
