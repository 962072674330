import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import React from 'react';
import { MainLayout } from 'src/components';
import { useSitesContext } from '../../contexts/sites-context';
import { SitesListHeader } from './components/sites-list-header';
import { SitesTable } from './components';
import { useDeleteSites } from './hooks/use-delete-sites';

export const SitesListContentContainer: React.FC = () => {
  const [selectedSites, setSelectedSites] = React.useState<ContentSitesV1Site[]>([]);
  const {
    sites,
    setCurrentSite,
    service: {
      meta: { isListLoading, isPublishLoading },
      operations: { publishSiteAsync, removeSiteAsync },
    },
  } = useSitesContext();

  const handleClearSelection = () => setSelectedSites([]);
  const handlePublishSites = () => {
    Promise.all(selectedSites.map((site) => publishSiteAsync({ siteName: site.name }))).then(() => {
      handleClearSelection();
      notification.success({ message: 'Сайты успешно опубликованы' });
    });
  };

  const handleDeleteSites = () => {
    return Promise.all(selectedSites.map((site) => removeSiteAsync(site.name))).then(() => handleClearSelection());
  };

  const { showDeleteConfirm } = useDeleteSites(selectedSites, handleDeleteSites);

  return (
    <MainLayout
      header={
        <SitesListHeader
          selectedSites={selectedSites}
          onClearSelection={handleClearSelection}
          onPublishClick={handlePublishSites}
          onRemoveClick={showDeleteConfirm}
          isPublishLoading={isPublishLoading}
        />
      }
    >
      <SitesTable
        isLoading={isListLoading}
        sites={sites}
        setCurrentSite={setCurrentSite}
        selectedSites={selectedSites}
        onSelectSitesChange={setSelectedSites}
      />
    </MainLayout>
  );
};
