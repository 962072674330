import { AccordionNode, ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButton, RichtextTextNodesEditor, SnippetItemBlock } from '../../../custom-inputs';
import { TeamTabAccordionItem } from './team-tab-accordion-item';

const mapAccordionAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  accordionItem: AccordionNode.Item.generators.entity({
    header: [],
    content: [node],
  }),
});

export type TeamTabItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
};

export const TeamTabItem: React.FC<TeamTabItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Alert message={`Таб №${index + 1}`} type="info" style={{ marginBottom: 12 }} />

      <Form.Item
        label="Заголовок таба"
        name={[field.name, 'tabsItem', 'header']}
        fieldKey={[field.fieldKey, 'tabsItem', 'header']}
      >
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>

      {/* tab.accordions */}
      <Form.List name={[field.name, 'tabsItem', 'content', 0, 'accordion', 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <TeamTabAccordionItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...contentPath, field.fieldKey, 'tabsItem', 'content', 0, 'accordion', 'items']}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapAccordionAdd}
              isDisabled={disabled}
              label="Добавить аккордион"
              noStyle
              allowedSnippets={[SnippetName.TeamGrid]}
            />
          </>
        )}
      </Form.List>
    </SnippetItemBlock>
  );
};
