import { FormListFieldData } from 'antd/lib/form/FormList';
import { Button, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { TreePath } from 'src/utils/content-path';
import { ConfigType, DataType } from '@console/uikit';
import { RichtextFormItem } from './richtext-form-item';

const richTextPath: TreePath = ['grid', 'content', 0, 'blueprint', 'content', 0, 'richtext'];

export type HeadlineEditorProps = {
  formListFieldData: FormListFieldData;
  disabled: boolean;
  onRemove: () => void;
};

export const HeadlineEditor: React.FC<HeadlineEditorProps> = ({ formListFieldData, disabled, onRemove }) => {
  return (
    <>
      <RichtextFormItem
        label="Заголовок"
        formListFieldData={formListFieldData}
        contentFieldName={richTextPath}
        disabled={disabled}
        controlsConfig={{
          [ConfigType.Blockquote]: [],
          [ConfigType.List]: [],
        }}
        entityControlsConfig={{
          [DataType.Disclaimer]: false,
          [DataType.Icon]: false,
          [DataType.Link]: false,
        }}
      />
      <Form.Item>
        <Button type="dashed" onClick={onRemove} block icon={<DeleteOutlined />}>
          Удалить заголовок
        </Button>
      </Form.Item>
    </>
  );
};
