import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import { AddSnippetButton } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { FeaturesIconsItem } from './components/features-icons-item';

export const FeaturesIconsEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { gridData, flexColumn1Data, flexColumn2Data } = React.useMemo(() => {
      const gridData = getPlaceholderedPath({
        node,
        commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'],
      });

      const flexColumn1Data = getPlaceholderedPath({
        node,
        commonPath: [...gridData.path, 'content', 0, 'flex', 'content'],
      });

      const flexColumn2Data = getPlaceholderedPath({
        node,
        commonPath: [...gridData.path, 'content', 1, 'flex', 'content'],
      });

      return { gridData, flexColumn1Data, flexColumn2Data };
    }, [node]);

    const disabled = gridData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={gridData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            <Tooltip
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
            >
              Редактируемые настройки блока
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <EditorContainer label="Первая колонка">
          {/* column1 edit */}
          <Form.List name={[formListFieldData.name, ...flexColumn1Data.path]}>
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <FeaturesIconsItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    contentPath={[...sectionContentPath, ...flexColumn1Data.path]}
                  />
                ))}
                <AddSnippetButton
                  add={add}
                  mapBeforeAdd={(node) => node}
                  isDisabled={disabled}
                  label="Добавить элемент"
                  allowedSnippets={[SnippetName.FeaturesIconsRow]}
                />
              </>
            )}
          </Form.List>
        </EditorContainer>
        <EditorContainer label="Вторая колонка">
          <Form.List name={[formListFieldData.name, ...flexColumn2Data.path]}>
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <FeaturesIconsItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    contentPath={[...sectionContentPath, ...flexColumn2Data.path]}
                  />
                ))}
                <AddSnippetButton
                  add={add}
                  mapBeforeAdd={(node) => node}
                  isDisabled={disabled}
                  label="Добавить элемент"
                  allowedSnippets={[SnippetName.FeaturesIconsRow]}
                />
              </>
            )}
          </Form.List>
        </EditorContainer>
      </>
    );
  },
);
