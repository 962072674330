import React from 'react';
import { GlobalStyles } from './styles';

export const IntegratorRoot: React.FC = React.memo(({ children }) => {
  return (
    <>
      <GlobalStyles />
      {children}
    </>
  );
});

IntegratorRoot.displayName = 'IntegratorRoot';
