import React from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type SnippetEditorInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export const SnippetEditorInput: React.FC<SnippetEditorInputProps> = ({ value, onChange }) => {
  const handleChange: ReactJsonViewProps['onEdit'] = (edit) => {
    onChange && onChange(JSON.stringify(edit.updated_src));
  };

  return (
    <ReactJson
      src={JSON.parse(value || '{}')}
      onEdit={handleChange}
      onAdd={handleChange}
      onDelete={handleChange}
      name={false}
    />
  );
};
