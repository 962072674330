import { snippetGenerators, TableNode } from '@cms/volkswagen-widgets';
import { Alert, Form, Space } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { AddEnrichedNodeButton, SnippetItemBlock, ThemeCheckbox } from '../../../custom-inputs';
import { TableCell } from './table-cell';

export type TableRowProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
};

export const TableRow: React.FC<TableRowProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
}) => (
  <SnippetItemBlock
    disabled={disabled}
    onDelete={() => remove(field.name)}
    onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
    onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
  >
    <Alert message={`Строка (tr) №${index + 1}`} type="info" />

    <Form.Item
      name={[field.name, 'tableRow', 'themes']}
      fieldKey={[field.fieldKey, 'tableRow', 'themes']}
      style={{ marginBottom: 0 }}
    >
      <ThemeCheckbox label="Строка в заголовке таблицы" themeName={TableNode.Row.themes.head} disabled={disabled} />
    </Form.Item>

    <Form.List name={[field.name, 'tableRow', 'cells']}>
      {(fields, { add, remove, move }) => (
        <>
          {fields.map((field, index) => (
            <TableCell
              key={field.key}
              move={move}
              remove={remove}
              index={index}
              field={field}
              disabled={disabled}
              isBottomMovable={fields.length > 1 && index < fields.length - 1}
              isTopMovable={fields.length > 1 && index > 0}
            />
          ))}

          <Space direction="horizontal">
            <AddEnrichedNodeButton
              add={add}
              nodeToEnrich={snippetGenerators.Table.head([])}
              isDisabled={disabled}
              label="Добавить главную ячейку (th)"
            />
            <AddEnrichedNodeButton
              add={add}
              nodeToEnrich={snippetGenerators.Table.data([])}
              isDisabled={disabled}
              label="Добавить ячейку (td)"
            />
          </Space>
        </>
      )}
    </Form.List>
  </SnippetItemBlock>
);
