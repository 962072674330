import { Alert, ConfigProvider } from 'antd';
import 'antd/dist/antd.min.css';
import ruRU from 'antd/lib/locale/ru_RU';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntegratorRoot, ThemeProvider } from '@console/uikit';
import reportWebVitals from './reportWebVitals';
import { RoutesView } from './routing';
import { CookiesProvider } from 'react-cookie';

const { ErrorBoundary } = Alert;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <IntegratorRoot>
      <ConfigProvider locale={ruRU}>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <CookiesProvider>
              <ErrorBoundary>
                <RoutesView />
                {/* By default, React Query Devtools are not included in production bundles */}
                <ReactQueryDevtools />
              </ErrorBoundary>
            </CookiesProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </IntegratorRoot>
  );
};

if (process.env.REACT_APP_MSW_ENABLED === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
