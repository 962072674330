import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { UsersV1User } from '@cms/volkswagen-widgets';
import { Button, Space, Modal, Typography, ButtonProps } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';

const { confirm } = Modal;
const { Text } = Typography;

const StyledEditFilled = styled(EditFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledActions = styled(Space)`
  display: flex;
  justify-content: flex-end;
`;

export type ActionTableCellProps = {
  user: UsersV1User;
  onEdit?: (value: UsersV1User) => void;
  onRemove?: (name: string) => void;
};

export const ActionTableCell: React.FC<ActionTableCellProps> = ({ user, onEdit, onRemove }) => {
  const handleRemoveMedia: ButtonProps['onClick'] = (e) => {
    e.preventDefault();
    e.stopPropagation();
    confirm({
      title: 'Удаление медиа',
      content: (
        <>
          <Text>
            Вы собираетесь удалить пользователя «{user.title || ''}, E-mail {user.email}».
          </Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить?</Text>
        </>
      ),
      onOk() {
        if (onRemove) {
          return onRemove(user.name || '');
        }
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  return (
    <StyledActions>
      {onEdit && <Button type="text" shape="circle" icon={<StyledEditFilled />} onClick={() => onEdit(user)} />}
      {onRemove && <Button type="text" shape="circle" icon={<StyledDeleteFilled />} onClick={handleRemoveMedia} />}
    </StyledActions>
  );
};
