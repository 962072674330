import { ArrowLeftOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Menu, MenuProps, Select, Space } from 'antd';
import React from 'react';
import { SiteTabs, useSitesContext } from '../contexts/sites-context';

const { Option } = Select;

const StyledSpace = styled(Space)`
  > *:first-child {
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
  }

  > *:last-child {
    width: 100%;
  }
`;

export const SitesControl: React.FC = () => {
  const { tab, setTab, currentSite, setCurrentSite, sites } = useSitesContext();

  const handleTabChange: MenuProps['onClick'] = (e) => {
    setTab(e.key as SiteTabs);
  };

  const handleBackClick = () => setCurrentSite();

  return (
    <StyledSpace size="large">
      <Space>
        <Button icon={<ArrowLeftOutlined />} type="text" onClick={handleBackClick} style={{ paddingLeft: 0 }}>
          К списку
        </Button>
        <Select
          value={currentSite?.name}
          style={{ width: 200 }}
          onChange={(siteName) => {
            const selectedSite = sites.find((site) => site.name === siteName);
            if (selectedSite) {
              setCurrentSite(selectedSite);
            }
          }}
        >
          {sites.map((site) => (
            <Option key={site.name} value={site.name}>
              {site.title}
            </Option>
          ))}
        </Select>
      </Space>
      <Menu style={{ width: '100%' }} onClick={handleTabChange} selectedKeys={[tab]} mode="horizontal">
        <Menu.Item key={SiteTabs.Structure}>Структура</Menu.Item>
        {/* <Menu.Item key={SiteTabs.Publication}>Публикация</Menu.Item> */}
        <Menu.Item key={SiteTabs.Main}>Основные</Menu.Item>
        <Menu.Item key={SiteTabs.Redirects}>Редиректы</Menu.Item>
        <Menu.Item key={SiteTabs.Robots}>Robots.txt</Menu.Item>
        {/*<Menu.Item key={SiteTabs.Sitemap}>Sitemap</Menu.Item>*/}
      </Menu>
    </StyledSpace>
  );
};
