import { ActivityV1Status } from '@cms/volkswagen-widgets';
import { Tag, TagProps } from 'antd';
import React from 'react';

const mapColorByStatus: Record<ActivityV1Status, TagProps['color']> = {
  [ActivityV1Status.Active]: 'green',
  [ActivityV1Status.Archived]: 'purple',
  [ActivityV1Status.Disabled]: 'red',
  [ActivityV1Status.None]: 'cyan',
};

const mapTitleByStatus: Record<ActivityV1Status, string> = {
  [ActivityV1Status.Active]: 'Active',
  [ActivityV1Status.Archived]: 'Archived',
  [ActivityV1Status.Disabled]: 'Disabled',
  [ActivityV1Status.None]: 'None',
};

type StatusTagProps = {
  status: ActivityV1Status;
};

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  return <Tag color={mapColorByStatus[status]}>{mapTitleByStatus[status]}</Tag>;
};
