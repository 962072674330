import { CopyFilled, DeleteFilled, DownSquareFilled, EditFilled, EyeFilled, UpSquareFilled } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Space } from 'antd';
import React from 'react';

const AdditionalControlsWrapper = styled.div<{ $visible: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.blue6};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  z-index: 2;
  transition: all 0.25s ease;

  .anticon {
    cursor: pointer;
  }

  & .anticon:hover {
    color: ${(props) => props.theme.colors.blue7};
  }
`;

type AdditionalControlsProps = {
  visible: boolean;
  editVisible: boolean;
  favoriteVisible: boolean;
  showMoveActions: boolean;
  onFavoriteClick: () => void;
  onEditClick: () => void;
  onCopyClick: () => void;
  onRemoveClick: () => void;
  onMoveUpClick: () => void;
  onMoveDownClick: () => void;
  openPreviewModal?: () => void;
};

export const AdditionalControls: React.FC<AdditionalControlsProps> = ({
  visible,
  editVisible,
  showMoveActions,
  // favoriteVisible,
  // onFavoriteClick,
  onEditClick,
  onCopyClick,
  onRemoveClick,
  onMoveUpClick,
  onMoveDownClick,
  openPreviewModal,
}) => {
  return (
    <AdditionalControlsWrapper $visible={visible}>
      <Space size="middle">
        {openPreviewModal && <EyeFilled onClick={openPreviewModal} />}
        {/* {favoriteVisible && <StarFilled onClick={onFavoriteClick} />} */}
        {editVisible && <EditFilled onClick={onEditClick} />}
        <CopyFilled onClick={onCopyClick} />
        {showMoveActions && (
          <>
            <DeleteFilled onClick={onRemoveClick} />
            <UpSquareFilled onClick={onMoveUpClick} />
            <DownSquareFilled onClick={onMoveDownClick} />
          </>
        )}
      </Space>
    </AdditionalControlsWrapper>
  );
};
