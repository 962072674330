import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { useFile } from '@console/api';
import { styled } from '@console/uikit';
import { notification, Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CenteredSpinner } from 'src/components/common';
import { NodeKeys } from 'src/utils/nodes';
import { findRootPlaceholder } from 'src/utils/snippets';
import { PageDetailHeader } from './components/page-detail-header';
import { StructureDrawer } from './components/structure-drawer';
import { ContentViewContainer } from './containers/content-view';
import { PageSettingsModalContainer } from './containers/page-settings-modal';
import { VersionHistoryDrawerContainer } from './containers/version-history-drawer';
import { ContentViewContextProvider, PageDetailContextWrapper } from './contexts';
import { GlobalStyle } from './global-styles';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageDetail: React.FC = () => {
  const { path } = useParams<{ path: string }>();
  // use service
  const service = useFile({ enabled: true, path });

  const {
    models: { fileResponse },
    meta: { isFileLoading, isFileFetching, isUpdateLoading },
    operations: { updateFileAsync },
  } = service;

  const page = fileResponse?.volkswagenV1Page || fileResponse?.volkswagenV1PageBlueprint;
  const isBlueprint = !!fileResponse?.volkswagenV1PageBlueprint;

  // loader
  if (isFileLoading) {
    return <CenteredSpinner />;
  }

  // 404
  if (!page) {
    return <div>Page "{path}" doesn't exist</div>;
  }

  // widget operations

  const addNode = (index: number, node: ContentVolkswagenV1Widget) => {
    let content: ContentVolkswagenV1Widget[];

    if (page.content) {
      const newContent = page.content.slice();
      newContent.splice(index, 0, node);
      content = newContent;
    } else {
      content = [node];
    }

    return updateFileAsync({
      filePath: path,
      body: { [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content } },
    });
  };

  const updateNode = (node: ContentVolkswagenV1Widget, index: number, prefixPath = '') => {
    const { value: placeholder, path: placeholderPath } = findRootPlaceholder(node);

    if (!placeholder || !placeholderPath)
      return Promise.resolve(notification.error({ message: 'Не удалось обновить сниппет' }));

    const nodes = Object.values(NodeKeys) as string[];
    const clearedPath = placeholderPath?.filter((value) => !nodes.includes(value));

    return updateFileAsync({
      filePath: path,
      body: {
        volkswagenV1Fragment: {
          fragment: `${prefixPath}.content.${index}.` + clearedPath.join('.'),
          content: { placeholder },
        },
      },
    });
  };

  const moveNode = (fromIndex: number, toIndex: number) => {
    if (page.content) {
      const newContent = page.content.slice();
      const widget = page.content[fromIndex];
      newContent.splice(fromIndex, 1);
      newContent.splice(toIndex, 0, widget);

      return updateFileAsync({
        filePath: path,
        body: { [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content: newContent } },
      });
    }

    return Promise.reject("page.content is empty, can't move node");
  };

  const removeNode = (index: number) => {
    if (page.content) {
      const newContent = page.content.slice();
      newContent.splice(index, 1);

      return updateFileAsync({
        filePath: path,
        body: { [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: { ...page, content: newContent } },
      });
    }

    return Promise.reject("page.content is empty, can't remove node");
  };

  return (
    <PageDetailContextWrapper>
      <StyledWrapper>
        <PageDetailHeader page={page} />
        <Spin spinning={isFileFetching || isUpdateLoading} size="large" tip="Loading...">
          <ContentViewContextProvider
            value={{
              rootType: 'page',
              rootContent: page.content ?? [],
              isRootHovered: false,
              isBlueprintPage: isBlueprint,

              page,
              service,
              addNode,
              updateNode,
              moveNode,
              removeNode,
              loading: isUpdateLoading,
            }}
          >
            <ContentViewContainer />
          </ContentViewContextProvider>
        </Spin>

        {/* modals/drawers */}
        <VersionHistoryDrawerContainer />
        {/* -------Temporary created as separate page-------- */}
        {/* <PreviewModalContainer /> */}
        <StructureDrawer />
        <PageSettingsModalContainer fileService={service} />
        <GlobalStyle />
      </StyledWrapper>
    </PageDetailContextWrapper>
  );
};
