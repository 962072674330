import { ContentVolkswagenV1Node, GalleryNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { GalleryItem } from './components';

const mapGalleryAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  galleryItem: GalleryNode.Item.generators.entity({
    content: [node],
    header: [],
  }),
});

const disallowedSnippets: AddSnippetButtonProps['disallowedSnippets'] = [
  SnippetName.CopyAccordionSection,
  SnippetName.GallerySection,
  SnippetName.GalleryColumn,
  SnippetName.FullscreenBanner,
];

export const GalleryEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const galleryData = getPlaceholderedPath({
    node,
    commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'gallery'],
  });
  const disabledGalleryData = galleryData.placeholdersCount === 0;

  const handlePlaceholderGalleryChange = (checked: boolean) => {
    onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          defaultChecked={galleryData.placeholdersCount === 2}
          disabled={disabledGalleryData}
          onChange={(e) => handlePlaceholderGalleryChange(e.target.checked)}
        >
          Редактируемая галлерея
        </Checkbox>
      </Form.Item>
      <Form.List name={[formListFieldData.name, ...galleryData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <GalleryItem
                key={field.fieldKey}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabledGalleryData}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                galleryContentPath={[...sectionContentPath, ...galleryData.path, 'items']}
                disallowedSnippets={disallowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapGalleryAdd}
              isDisabled={disabledGalleryData}
              label="Добавить медиа"
              allowedSnippets={[SnippetName.Image, SnippetName.Video]}
            />
          </>
        )}
      </Form.List>
    </>
  );
});
