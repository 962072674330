import React from 'react';
import { usePageDetail, ViewState } from '../../contexts';
import { VersionHistoryDrawer } from './components/version-history-drawer';

export type VersionHistoryData = {
  id: string;
  date: string;
  author: string;
};

const versionHistoryMock: VersionHistoryData[] = [
  {
    id: '1',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
  {
    id: '2',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
  {
    id: '3',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
  {
    id: '4',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
  {
    id: '5',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
  {
    id: '6',
    date: '14.02.2020 14:35',
    author: '(alexeliz@gmail.com)',
  },
];

export const VersionHistoryDrawerContainer: React.FC = () => {
  const { isVisible, setDefaultState } = usePageDetail(ViewState.versionHistory);

  const handleCreateCopy = (id: string) => {
    // eslint-disable-next-line no-console
    console.log('Create copy:', id);
  };

  const handleRollback = (id: string) => {
    // eslint-disable-next-line no-console
    console.log('Rollback:', id);
  };

  return (
    <VersionHistoryDrawer
      isOpen={isVisible}
      data={versionHistoryMock}
      onCreateCopy={handleCreateCopy}
      onRollback={handleRollback}
      onClose={setDefaultState}
    />
  );
};
