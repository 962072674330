import { snippetGenerators } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Input, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { TeamTabItem } from './components';

const tabsPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex', 'content', 0, 'tabs'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const initialTab = snippetGenerators.Team.tab({ title: [], accordions: [] });

export const TeamEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { tabsData } = React.useMemo(() => {
    const tabsData = getPlaceholderedPath({
      node,
      commonPath: tabsPath,
    });

    return { tabsData };
  }, [node]);

  const disabled = tabsData.placeholdersCount === 0;

  return (
    <>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={tabsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование настроек">
            Редактируемые настройки при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>
      <Form.List name={[formListFieldData.name, ...tabsData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <TeamTabItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...sectionContentPath, ...tabsData.path, 'items']}
              />
            ))}
            <AddEnrichedNodeButton add={add} nodeToEnrich={initialTab} isDisabled={disabled} label="Добавить таб" />
          </>
        )}
      </Form.List>
    </>
  );
});
