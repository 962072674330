import { GoogleRpcStatus, ContentSitesV1FilesAPIApi, ContentFilesV1File } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentFilesV1File>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = { siteName: string; filePath: string };

export type UsePublishSiteData = {
  publishSiteFile: UseMutateFunction<Data, Error, Variables>;
  publishSiteFileAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isPublishLoading: boolean;
};

export function usePublishSite(
  sitesApi: ContentSitesV1FilesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UsePublishSiteData {
  const queryClient = useQueryClient();

  const {
    mutate: publishSiteFile,
    mutateAsync: publishSiteFileAsync,
    isLoading: isPublishLoading,
  } = useMutation<Data, Error, Variables>(
    ({ siteName, filePath }) =>
      sitesApi.contentSitesV1FilesAPIPublishFile(
        siteName, // siteName: string,
        filePath, // filePath: string,
        // siteRevision?: string,
        // fileRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        if (isInvalidate) {
          queryClient.invalidateQueries(queryKey);
        }
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка изменения сайта',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { publishSiteFile, publishSiteFileAsync, isPublishLoading };
}
