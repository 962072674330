import { Col, Input, Row, Form, Radio, FormInstance, Checkbox } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { LinkHrefInputs } from './components/link-href-inputs';
import { LinkIconInputs } from './components/link-Icon-inputs';
import { LinkEditProps } from './types';

export const StyledInlineFormItem = styled(Form.Item)`
  &&& {
    display: flex;
    flex-direction: row;

    > * + * {
      margin-left: 15px;
    }

    > * {
      display: flex;
      padding: 0;
    }
  }
`;

export const KindAForm: React.FC<{
  form: FormInstance<LinkEditProps>;
  isContentField: boolean;
}> = React.memo(({ form, isContentField }) => {
  return (
    <>
      <LinkHrefInputs form={form} />
      <Row gutter={16}>
        <Col span={12}>
          <StyledInlineFormItem name="emphasis" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value="none">Без подчёркивания</Radio>
              <Radio value="tertiary">С подчёркиванием</Radio>
            </Radio.Group>
          </StyledInlineFormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <StyledInlineFormItem name="shallow" label="Модальное окно" valuePropName="checked">
            <Checkbox />
          </StyledInlineFormItem>
        </Col>
      </Row>
      <LinkIconInputs form={form} />
      {isContentField && (
        <Form.Item name="content" label="Текст" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      )}
    </>
  );
});

KindAForm.displayName = 'KindAForm';
