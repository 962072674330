import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';

const galleryPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'gallery'];

export const PresentationTeaserEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const galleryData = getPlaceholderedPath({
      node,
      commonPath: galleryPath,
    });

    const imagePath: TreePath = [...galleryData.path, 'items', 0, 'galleryItem', 'content', 0, 'image'];

    const richTextData = getPlaceholderedPath({
      node,
      commonPath: [
        ...galleryData.path,
        'items',
        1,
        'galleryItem',
        'content',
        0,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });

    const disabled = galleryData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={galleryData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            <Tooltip
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
            >
              Редактируемое изображение при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>

        <ImageNodeInputs formListFieldData={formListFieldData} imagePath={imagePath} disabled={disabled} required />

        <RichtextFormItem
          label="Контент"
          contentFieldName={[formListFieldData.name, ...richTextData.path]}
          disabled={disabled}
        />
      </>
    );
  },
);
