import { ContentVolkswagenV1Node, PaginationNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Divider, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { FeedbackItem } from './components/feedback-item';

const paginationPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'pagination'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapBeforeAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  paginationItem: PaginationNode.Item.generators.entity({
    content: [node],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.FeedbackItem];

export const FeedbacksEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { paginationData } = React.useMemo(() => {
    const paginationData = getPlaceholderedPath({
      node,
      commonPath: paginationPath,
    });

    return { paginationData };
  }, [node]);

  const disabled = paginationData.placeholdersCount === 0;

  return (
    <>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={paginationData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List
        name={[formListFieldData.name, ...paginationData.path, 'items']}
        rules={[
          {
            validator: async (rule, items) => {
              if (!items || items.length === 0) {
                return Promise.reject(new Error('Необходимо добавить минимум 1 элемент'));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove, move }, { errors }) => (
          <>
            <Divider orientation="right">Отзывы(минимум 1)</Divider>
            {fields.map((field, index) => (
              <FeedbackItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...sectionContentPath, ...paginationData.path, 'items']}
                allowedSnippets={allowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapBeforeAdd}
              isDisabled={disabled}
              label="Добавить отзыв"
              allowedSnippets={allowedSnippets}
            />
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </>
  );
});
