import React from 'react';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import SVG from 'react-inlinesvg';
import { AppsNavigation } from './components/apps-navigation';
import { Logo } from './components/logo';
import { Profile } from './components/profile';
import { AppName, ProfileType, ServiceType } from './types';

export * from './types';

const { Header } = Layout;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: 50px;
  line-height: 50px;
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledLogoWrapper = styled.div`
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
`;

const StyledMenuWrapper = styled.div`
  width: 100%;
`;

const RightSide = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 16px;
  padding-right: 24px;
`;

export type NavigationMenuProps = {
  currentServiceName?: string;
  currentAppName: AppName;
  services: ServiceType[];
  links?: ServiceType[];
  profile: ProfileType;
  link?: React.ComponentType<{ href: string }>;
  logout: () => void;
};

export const NavigationMenu: React.FC<NavigationMenuProps> = React.memo<NavigationMenuProps>(
  ({ currentServiceName, services, links, profile, logout, link: LinkComponent }) => {
    const Link = LinkComponent || 'a';

    return (
      <StyledHeader>
        <LeftSide>
          <StyledLogoWrapper>
            <Link href="/">
              <Logo />
            </Link>
          </StyledLogoWrapper>
          <StyledMenuWrapper>
            <Menu theme="dark" mode="horizontal" selectedKeys={currentServiceName ? [currentServiceName] : []}>
              {services.map(({ name, icon, url, title }) => {
                const iconNode =
                  icon && typeof icon === 'string' ? <Icon component={() => <SVG src={icon} />} /> : null;
                return (
                  <Menu.Item icon={iconNode} key={name}>
                    <Link href={url}>{title}</Link>
                  </Menu.Item>
                );
              })}
            </Menu>
          </StyledMenuWrapper>
        </LeftSide>
        <RightSide>
          {links && <AppsNavigation links={links} linkComponent={Link} />}
          <Profile profile={profile} logout={logout} />
        </RightSide>
      </StyledHeader>
    );
  },
);

NavigationMenu.displayName = 'NavigationMenu';
