import { Alert, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { styled } from '@console/uikit';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, NodePreview, SnippetItemBlock } from '../../../custom-inputs';

const StyledPreviewContainer = styled.div`
  width: 25%;
  border: 1px solid ${(props) => props.theme.colors.blue3};
  padding: 8px;
`;

export type ModelsItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const ModelsItem: React.FC<ModelsItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
  allowedSnippets,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...contentPath, index]}
      allowedSnippets={allowedSnippets}
    >
      <Alert message={`Модель №${index + 1}`} type="info" style={{ marginBottom: 12 }} />
      <StyledPreviewContainer>
        <Form.Item name={[field.name]}>
          <NodePreview />
        </Form.Item>
      </StyledPreviewContainer>
    </SnippetItemBlock>
  );
};
