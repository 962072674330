import { useEffect } from 'react';

type ListenerType = { type: string; handler: (headers: Record<string, string>) => Promise<Record<string, string>> };

type UseRequestInterceptorServiceWorkerType = [(handler: ListenerType['handler']) => void];

const listeners: ListenerType[] = [];

const FILE_NAME = 'request-interceptor-service-worker.js';

export function useRequestInterceptorServiceWorker(): UseRequestInterceptorServiceWorkerType {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register(`${window.location.origin}/${FILE_NAME}`).catch((err) => console.error(err));
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { type, payload } = event.data;
        const port = event.ports[0];

        const listener = listeners.find((listener) => listener.type === type);
        if (listener) {
          listener
            .handler(payload)
            .then((result) => {
              port.postMessage({ result });
            })
            .catch((error) => {
              port.postMessage({ error: `Failed to ${type}: ${error}` });
            });
        } else {
          port.postMessage({ result: '' });
        }
      });
    }
  }, []);

  return [
    (handler) => {
      listeners.push({
        type: 'RUN_INTERCEPTOR',
        handler,
      });
    },
  ];
}
