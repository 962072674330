import { ComparisonNode } from '@cms/volkswagen-widgets';
import { Form, Input } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';

import {
  AddSnippetButtonProps,
  AttributeInput,
  AttributesRadioGroup,
  RichtextTextNodesEditor,
  SnippetItemBlock,
} from '../../../custom-inputs';
import { AttributeAutocomplete } from '../../../custom-inputs/attribute-autocomplete';
import { AttributeSelect } from '../../../custom-inputs/attribute-select';

export type PropertiesItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  isEditParam: boolean;
  categories: Record<string, string>;
  properties: Record<string, string>;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const PropertiesItem: React.FC<PropertiesItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  isEditParam,
  categories,
  properties,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item
        label={isEditParam ? `Ключ категории` : `Категория`}
        name={[field.name, 'attributes']}
        fieldKey={[field.fieldKey, 'attributes']}
        rules={[{ required: true }]}
      >
        {isEditParam ? (
          <AttributeAutocomplete
            attributeName={ComparisonNode.const.COMPARISON_PROPERTY_CATEGORY}
            valueName={[...contentPath, index, 'category']}
            disabled={disabled}
            options={Object.keys(categories).map((key) => ({
              value: key,
              label: categories[key],
            }))}
          />
        ) : (
          <AttributeSelect
            attributeName={ComparisonNode.const.COMPARISON_PROPERTY_CATEGORY}
            valueName={[...contentPath, index, 'category']}
            options={Object.keys(categories).map((key) => ({
              value: key,
              label: categories[key],
            }))}
            disabled={disabled}
          />
        )}
      </Form.Item>

      <Form.Item
        label={`Название категории`}
        name={[field.name, 'category']}
        fieldKey={[field.fieldKey, 'category']}
        rules={[{ required: true }]}
        hidden={!isEditParam}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label={isEditParam ? `Ключ параметра` : `Параметр`}
        name={[field.name, 'attributes']}
        fieldKey={[field.fieldKey, 'attributes']}
        rules={[{ required: true }]}
      >
        {isEditParam ? (
          <AttributeInput attributeName={ComparisonNode.const.COMPARISON_PROPERTY_KEY} disabled={disabled} />
        ) : (
          <AttributeSelect
            attributeName={ComparisonNode.const.COMPARISON_PROPERTY_KEY}
            valueName={[...contentPath, index, 'title']}
            options={Object.keys(properties).map((key) => ({
              value: key,
              label: properties[key],
            }))}
            disabled={disabled}
          />
        )}
      </Form.Item>

      <Form.Item
        label={`Название параметра`}
        name={[field.name, 'title']}
        fieldKey={[field.fieldKey, 'title']}
        rules={[{ required: true }]}
        hidden={!isEditParam}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label={`Наличие`}
        name={[field.name, 'attributes']}
        fieldKey={[field.fieldKey, 'attributes']}
        rules={[{ required: true }]}
      >
        <AttributesRadioGroup
          possibleValues={[
            {
              value: 'on',
              label: 'Есть',
            },
            {
              value: 'off',
              label: 'Нет',
            },
          ]}
          attributeName={ComparisonNode.const.COMPARISON_PROPERTY_VALUE}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item label={`Значение`} name={[field.name, 'content']} fieldKey={[field.fieldKey, 'content']}>
        <RichtextTextNodesEditor
          disabled={disabled}
          availableIcons={['Close', 'Checkmark']}
          availableIconColors={['grey-300', 'blue-200']}
        />
      </Form.Item>
    </SnippetItemBlock>
  );
};
