import { Popover } from 'antd';
import React from 'react';
// import {
//     UsergroupAddOutlined,
//     BellOutlined,
//     QuestionCircleOutlined,
// } from '@ant-design/icons';
import styled from 'styled-components';
import { ServiceType } from '../types';

const StyledAppsNavigation = styled.div`
  display: flex;
`;

const StyledNavItem = styled.div`
  width: 40px;
  font-size: 20px;
  text-align: center;
  margin: 0 5px;

  a {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;

    color: ${(props) => props.theme.colors.gray6};

    &:hover {
      color: ${(props) => props.theme.colors.gray1};
    }
  }
`;

type AppsNavigationProps = {
  links: ServiceType[];
  linkComponent: React.ComponentType<{ href: string }> | 'a';
};

export const AppsNavigation: React.FC<AppsNavigationProps> = React.memo<AppsNavigationProps>(
  ({ links, linkComponent: Link }) => {
    return (
      <StyledAppsNavigation>
        {links.map(({ title, url, icon: Icon }) => (
          <Popover key={title} trigger="hover" content={title}>
            <StyledNavItem>
              <Link href={url}>{Icon && <Icon />}</Link>
            </StyledNavItem>
          </Popover>
        ))}
      </StyledAppsNavigation>
    );
  },
);

AppsNavigation.displayName = 'AppsNavigation';
