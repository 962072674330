import { ContentVolkswagenV1Align, snippetGenerators } from '@cms/volkswagen-widgets';
import { ContentVolkswagenV1FlexStyle, ContentVolkswagenV1GradientStyle } from '@cms/volkswagen-widgets/dist/core';
import { Checkbox, Form, Input, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { CustomCheckbox } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';

const commonGradientPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'gradient'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const PromptEditor = withSection(({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
  const { gradientPath, richTextPath } = React.useMemo(() => {
    const gradientPath = getPlaceholderedPath({
      node,
      commonPath: commonGradientPath,
    });

    const richTextPath = getPlaceholderedPath({
      node,
      commonPath: [
        ...gradientPath.path,
        'content',
        'flex',
        'content',
        0,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });

    return {
      gradientPath,
      richTextPath,
    };
  }, [node]);

  const disabled = gradientPath.placeholdersCount === 0;

  return (
    <>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gradientPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
        >
          <Tooltip
            title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Настроек блока"`}
          >
            Редактируемое изображение при наследовании
          </Tooltip>
        </Checkbox>
      </Form.Item>

      <RichtextFormItem
        label="Контент"
        contentFieldName={[formListFieldData.name, ...richTextPath.path]}
        disabled={disabled}
      />

      <Form.Item
        name={[formListFieldData.name, ...gradientPath.path, 'styles']}
        fieldKey={[formListFieldData.fieldKey, ...gradientPath.path, 'styles']}
      >
        <CustomCheckbox<ContentVolkswagenV1GradientStyle[]>
          disabled={disabled}
          label="Градиент"
          check={(value) => Boolean(value?.length)}
          checkedValue={snippetGenerators.Prompt.helpers.getGradientStyles(true)}
          uncheckedValue={snippetGenerators.Prompt.helpers.getGradientStyles(false)}
        />
      </Form.Item>

      <Form.Item
        name={[formListFieldData.name, ...gradientPath.path, 'content', 'flex', 'content', 0, 'flex', 'styles']}
        fieldKey={[formListFieldData.fieldKey, ...gradientPath.path, 'content', 'flex', 'content', 0, 'flex', 'styles']}
      >
        <CustomCheckbox<ContentVolkswagenV1FlexStyle[]>
          disabled={disabled}
          label="Контент справа"
          check={(value) => {
            return Boolean(
              value?.find((item: ContentVolkswagenV1FlexStyle) => item.align === ContentVolkswagenV1Align.CenterLeft),
            );
          }}
          checkedValue={snippetGenerators.Prompt.helpers.getFlexStyles(true)}
          uncheckedValue={snippetGenerators.Prompt.helpers.getFlexStyles(false)}
        />
      </Form.Item>

      <Form.Item
        label="Задать высоту вручную"
        name={[formListFieldData.name, ...gradientPath.path, 'content', 'flex', 'styles', 0, 'height']}
        fieldKey={[formListFieldData.fieldKey, ...gradientPath.path, 'content', 'flex', 'styles', 0, 'height']}
        tooltip="Если значение поля не задано, то высота автоматически определяется в зависимости от объема контента. Высоту можно задавать в процентах высоты экрана (vh) и пикселях (px)"
        rules={[{ pattern: /\d+(vh|px)/ }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </>
  );
});
