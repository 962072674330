import { DraftDecorator } from 'draft-js';
import React from 'react';
import { DataType, useEntityControls } from '../../../hooks';
import { ControlsProps } from '../../../types';
import { disclaimerDecorator, DisclaimerEntity, RawDraftDisclaimer } from './disclaimer-entity';
import { iconDecorator, IconEntity, RawDraftIcon } from './icon-entity';
import { linkDecorator, LinkEntity, RawDraftLink } from './link-entity';
import { phoneDecorator, RawDraftPhone } from './phone-entity';
import { placeholderDecorator } from './placeholder-entity/decorator';
import { PlaceholderEntity } from './placeholder-entity';

import { dividerDecorator, RawDraftVerticalDivider } from './verical-divider-entity';

export type RawEntityType = RawDraftLink | RawDraftDisclaimer | RawDraftPhone | RawDraftVerticalDivider | RawDraftIcon;

/**
 * Стилизация entities в самом редакторе делается с помощью декораторов
 * Здесь декораторы из всех entity собираются в массив
 * Подключаются в /index.tsx, как const decorator = new CompositeDecorator(entityDecorators);
 */
export const entityDecorators: DraftDecorator[] = [
  linkDecorator,
  disclaimerDecorator,
  phoneDecorator,
  dividerDecorator,
  iconDecorator,
  placeholderDecorator,
];

export const EntityControls: React.FC<ControlsProps> = React.memo<ControlsProps>((props) => {
  // const phoneData = useControlsData<[]>(DataType.Phone, []);
  const linkData = useEntityControls(DataType.Link, true);
  const disclaimerData = useEntityControls(DataType.Disclaimer, true);
  const placeholderData = useEntityControls(DataType.Placeholder, true);
  const iconData = useEntityControls(DataType.Icon, true);

  return (
    <>
      {linkData && <LinkEntity {...props} />}
      {disclaimerData && <DisclaimerEntity {...props} />}
      {/*{phoneData && <PhoneEntity {...props} />}*/}
      {iconData && <IconEntity {...props} />}
      {placeholderData && <PlaceholderEntity {...props} />}
    </>
  );
});

EntityControls.displayName = 'EntityControls';
