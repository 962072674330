import { UsersV1User } from '@cms/volkswagen-widgets';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { ActionTableCell, ActionTableCellProps } from './actions-table-cell';
import { EmailTableCell } from './email-table-cell';
import { NameTableCell } from './name-table-cell';
import { PhoneTableCell } from './phone-table-cell';
import { SitesTableCell } from './sites-table-cell';
import { TimeTableCell } from './time-table-cell';

export type UsersTableProps = {
  users: UsersV1User[];
  loading: boolean;
  selectedRows: UsersV1User[];
  setSelectedRows: (rows: UsersV1User[]) => void;
  onEditUser: ActionTableCellProps['onEdit'];
  onRemoveUser: ActionTableCellProps['onRemove'];
};

export const UsersTable: React.FC<UsersTableProps> = ({ users, loading, onEditUser, onRemoveUser }) => {
  const columns: ColumnsType<UsersV1User> = [
    {
      title: 'Имя',
      // https://github.com/yannickcr/eslint-plugin-react/issues/2751
      // Project `eslint-plugin-react` version: 7.26.1
      // CRA `eslint-plugin-react` version: 7.22.0
      render: (_, record) => <NameTableCell user={record} />,
    },
    {
      title: 'E-mail',
      render: (_, record) => <EmailTableCell user={record} />,
    },
    {
      title: 'Телефон',
      render: (_, record) => <PhoneTableCell user={record} />,
    },
    {
      title: 'Дата изменения',
      render: (_, record) => <TimeTableCell user={record} />,
    },
    {
      title: 'Сайты',
      render: (_, record) => <SitesTableCell user={record} />,
    },
    {
      title: '',
      render: (_, record) => <ActionTableCell user={record} onEdit={onEditUser} onRemove={onRemoveUser} />,
    },
  ];

  return (
    <Table
      rowKey={(row) => row.name}
      dataSource={users}
      columns={columns}
      pagination={false}
      sticky={{ offsetHeader: 64 }}
      loading={loading}
    />
  );
};
