import { UsersV1User } from '@cms/volkswagen-widgets';
import { Space } from 'antd';
import React from 'react';

type FilesNameTableCellProps = {
  user: UsersV1User;
};

export const PhoneTableCell: React.FC<FilesNameTableCellProps> = ({ user }) => {
  return <Space size="middle">{/*<Typography.Text>{user.email}</Typography.Text>*/}</Space>;
};
