import { ContentVolkswagenV1Richtext } from '@cms/volkswagen-widgets';
import React from 'react';
import { RichTextEditor, styled, isFlowContent, RichTextEditorProps, ConfigType } from '@console/uikit';
import { StyledWrapperProps } from '@console/uikit';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type RichTextContentEditorProps = {
  value?: ContentVolkswagenV1Richtext['content'];
  onChange?: (value: ContentVolkswagenV1Richtext['content']) => void;
  disabled: boolean;
} & Partial<RichTextEditorProps>;

const CustomStylesWrapper = styled.div<StyledWrapperProps>`
  border: 1px dashed ${(props) => props.theme.colors.gray5};
`;

const StyledFlowEditor = styled(RichTextEditor)<RichTextEditorProps>`
  margin: 2px 0;
`;

const StyledInlineEditor = styled(StyledFlowEditor)<RichTextEditorProps>`
  display: inline;
  min-width: 30px;
  margin: 0;
  padding: 4px 2px;

  div {
    display: inline !important;
    line-height: 30px;
  }
`;

export const RichTextInput: React.FC<RichTextContentEditorProps> = React.memo(
  ({ value, onChange, disabled, presetsStyles, controlsConfig, entityControlsConfig }) => {
    if (!value) {
      console.warn(`Invalid structure RichTextInput, not found content`);

      return null;
    }

    const isFlow = isFlowContent(value);

    const handleChange = (value: ContentVolkswagenV1Richtext['content']) => {
      if (onChange && value) {
        onChange(value);
      }
    };

    const commonProps: Partial<RichTextEditorProps> = {
      initialValue: value,
      onChange: handleChange,
      presetsStyles,
      entityControlsConfig,
      customStylesWrapper: CustomStylesWrapper,
      disabled,
    };

    return (
      <>
        {isFlow ? (
          <StyledFlowEditor controlsConfig={controlsConfig} {...commonProps} />
        ) : (
          <StyledInlineEditor
            singleLine
            controlsConfig={{
              [ConfigType.Blockquote]: [],
              [ConfigType.Heading]: [],
              [ConfigType.List]: [],
              [ConfigType.Align]: [],
              ...controlsConfig,
            }}
            {...commonProps}
          />
        )}
      </>
    );
  },
);
