import { ActivityV1Status } from '@cms/volkswagen-widgets';
import { useDomains } from '@console/api';
import { Form } from 'antd';
import React from 'react';
import { useSitesContext } from '../../contexts/sites-context';
import { EditDomainFormValues, EditDomainModal } from './components/edit-domain-modal';

export type EditDomainModalContainerProps = {
  open: boolean;
  initialValues?: EditDomainFormValues;
  onClose: () => void;
};

export const EditDomainModalContainer: React.FC<EditDomainModalContainerProps> = ({ open, initialValues, onClose }) => {
  const { currentSite } = useSitesContext();

  const [form] = Form.useForm<EditDomainFormValues>();

  React.useEffect(() => {
    if (open) {
      if (initialValues) {
        form.setFieldsValue(initialValues);
      } else {
        form.resetFields();
      }
    }
  }, [open, initialValues, form]);

  const {
    meta: { isCreateLoading, isUpdateLoading },
    operations: { createDomainAsync, updateDomainAsync },
  } = useDomains({ enabled: false, site: currentSite?.name || '' });

  const handleSubmit = (values: EditDomainFormValues) => {
    if (initialValues) {
      updateDomainAsync({
        name: initialValues.name,
        body: {
          ...values,
          activity: { status: ActivityV1Status.Active },
        },
      })
        .then(() => {
          onClose();
        })
        .catch(() => null);
    } else {
      createDomainAsync({
        ...values,
        activity: { status: ActivityV1Status.Active },
      })
        .then(() => {
          onClose();
        })
        .catch(() => null);
    }
  };

  return (
    <EditDomainModal
      open={open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
