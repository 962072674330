import React, { FC, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteItem } from '../routes';

export const Switcher: FC<{ routes?: RouteItem[] }> = React.memo(({ routes, ...rest }) => {
  return (
    <Switch>
      {useMemo(() => {
        return (routes || []).map((route) => {
          const { name, path, routes: itemRoutes, redirect, exact } = route;
          const { component: Component } = route;
          return (
            <Route key={`${path}${name}`} path={path} exact={exact}>
              <Component routes={itemRoutes} redirect={redirect} {...rest} />
            </Route>
          );
        });
      }, [routes, rest])}
    </Switch>
  );
});

Switcher.displayName = 'Switcher';
