import { AutocompleteOptionItem, styled } from '@console/uikit';
import { Form, FormInstance, Input, Space, Typography } from 'antd';
import React from 'react';
import { PagePathFormItemContainer } from 'src/containers/page-path-form-item';

export type UpdateSiteFormValues = {
  title: string;
  path: AutocompleteOptionItem[];
};

const MainInfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

type UpdateSiteFormProps = {
  form: FormInstance<UpdateSiteFormValues>;
  onSubmit: (values: UpdateSiteFormValues) => void;
};

export const UpdateSiteForm: React.FC<UpdateSiteFormProps> = ({ form, onSubmit }) => {
  return (
    <Form form={form} onFinish={onSubmit} name="settings" layout="vertical">
      <MainInfoWrapper>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Title level={4}>Основная информация</Typography.Title>
          <Space size="large">
            <Form.Item label="Название сайта" name="title" rules={[{ required: true }]} style={{ width: 400 }}>
              <Input placeholder="Введите название" />
            </Form.Item>
            <Form.Item name="path" label="Корневая папка" rules={[{ required: true }]} style={{ width: 400 }}>
              <PagePathFormItemContainer />
            </Form.Item>
          </Space>
        </Space>
      </MainInfoWrapper>
    </Form>
  );
};
