import { PlusOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Node, TextNode, TopMenuNode } from '@cms/volkswagen-widgets';
import { Button, Form, Space } from 'antd';
import { GetPlaceholderedPathResult } from 'src/utils/content-path';
import { TopMenuItem } from '../top-menu-item';

const getInitialTab = (): ContentVolkswagenV1Node =>
  TopMenuNode.Link.generators.node({
    source: '',
    content: [TextNode.generators.node({ content: '' })],
  });

const getInitialSideIcon = (): ContentVolkswagenV1Node =>
  TopMenuNode.Link.generators.node({
    source: '',
    content: [TextNode.generators.node({ content: '' })],
    icon: '',
  });

type TopMenuTabTopBarProps = {
  disabled: boolean;
  topMenuData: GetPlaceholderedPathResult;
};

export const TopMenuTabTopBar: React.FC<TopMenuTabTopBarProps> = ({ disabled, topMenuData }) => {
  return (
    <Form.List name={[...topMenuData.path, 'items']}>
      {(fields, { add, remove, move }) => (
        <>
          {fields.map((field, index) => {
            // hardcode first TopMenuNode.Item for flyout menu
            if (index === 0) {
              return null;
            }

            // tabs, sideIcons edit
            return (
              <TopMenuItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 2 && index < fields.length - 1}
                isTopMovable={fields.length > 2 && index > 1}
              />
            );
          })}

          <Space direction="horizontal">
            {/* tabs */}
            <Button type="dashed" disabled={disabled} onClick={() => add(getInitialTab())} icon={<PlusOutlined />}>
              Добавить таб
            </Button>

            {/* sideIcons */}
            <Button type="dashed" disabled={disabled} onClick={() => add(getInitialSideIcon())} icon={<PlusOutlined />}>
              Добавить боковую иконку
            </Button>
          </Space>
        </>
      )}
    </Form.List>
  );
};
