import React from 'react';
import { MediaFile, useToggle } from 'src/hooks';
import { useFilesContext } from '../../contexts';
import { FilesControlContainer } from '../control';
import { FilesFilter } from './components/files-filter';
import { FilesHeader } from './components/files-header';

export const FilesHeaderContainer: React.FC = () => {
  const { selectedRows, setSearch, setEditPage, setEditFolder, setEditSnippet } = useFilesContext();

  const [filtersIsOpen, { onToggle: openFilters, offToggle: closeFilters }] = useToggle();

  const handleCreatePage = () => setEditPage(true);
  const handleCreateFolder = () => setEditFolder(true);
  const handleCreateSnippet = () => setEditSnippet(true);
  const handleCreateMedia = (files: MediaFile[]) => {
    if (files && files.length) {
      // setUploadFiles(files);
    }
  };

  if (selectedRows.length > 0) {
    return <FilesControlContainer />;
  }

  return (
    <>
      <FilesHeader
        onCreatePage={handleCreatePage}
        onCreateFolder={handleCreateFolder}
        onCreateSnippet={handleCreateSnippet}
        onCreateMedia={handleCreateMedia}
        onFilterOpen={openFilters}
        onSearch={setSearch}
      />
      <FilesFilter open={filtersIsOpen} onClose={closeFilters} />
    </>
  );
};
