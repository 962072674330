import { SnippetName } from '@cms/volkswagen-widgets';
import { SnippetNameExtend } from '@console/api';
import { BlockPreviewType, BlocksGroup } from './types';

const textsWidgets: BlockPreviewType[] = [
  {
    title: 'Текстовый блок',
    type: SnippetName.TextSection,
    description: 'С ссылками и без ссылок',
  },
  {
    title: 'Аккордеон',
    type: SnippetName.CopyAccordionSection,
    description: 'TODO',
  },
  {
    title: 'Две колонки',
    type: SnippetName.CopyColumnsSection,
    description: 'TODO',
  },
  {
    title: 'Таблица',
    type: SnippetName.TableSection,
    description: 'TODO',
  },
  {
    title: 'Вакансии блок',
    type: SnippetName.VacanciesGrid,
    description: 'TODO',
  },
  {
    title: 'Вакансии (аккордеон)',
    type: SnippetName.VacanciesAccordions,
    description: 'TODO',
  },
  {
    title: 'Вакансии',
    type: SnippetName.VacanciesSection,
    description: 'TODO',
  },
  {
    title: 'Отзывы',
    type: SnippetName.FeedbacksSection,
    description: 'TODO',
  },
  {
    title: 'Двигатели',
    type: SnippetName.EnginesSection,
    description: 'TODO',
  },
];

const slidersWidgets: BlockPreviewType[] = [
  {
    title: 'Карточка',
    type: SnippetName.Card,
    description: 'TODO',
  },
  {
    title: 'Карусель',
    type: SnippetName.HighlightCarouselSection,
    description: 'TODO',
  },
  {
    title: 'Спецсерии',
    type: SnippetName.SpecialSeriesSection,
    description: 'TODO',
  },
  {
    title: 'Особенности (ссылки с описанием)',
    type: SnippetName.FeatureCluster,
    description: 'TODO',
  },
];

const galleryWidgets: BlockPreviewType[] = [
  {
    title: 'Галерея',
    type: SnippetName.GallerySection,
    description: 'TODO',
  },
  {
    title: 'Особенности (аккордеон с изображением)',
    type: SnippetName.HighlightFeatureSection,
    description: 'TODO',
  },
  {
    title: 'Галерея плиткой (2-5 элементов)',
    type: SnippetName.MoodGallerySection,
    description: 'TODO',
  },
  {
    title: 'Галерея с текстом справа',
    type: SnippetName.GalleryColumnSection,
    description: 'Галерея в левой колонке, текст или цитата в правой',
  },
  {
    title: 'Блок превью для акций и новостей',
    type: SnippetName.TeaserSection,
    description: 'TODO',
  },
  {
    title: 'Блок превью с заполняемыми элементами',
    type: SnippetName.TeaserCustomSection,
    description: 'TODO',
  },
];

const bannersWidgets: BlockPreviewType[] = [
  {
    title: 'Изображение',
    type: SnippetName.ImageSection,
    description: 'TODO',
  },
  {
    title: 'Преимущества две колонки',
    type: SnippetName.FeaturesIconsSection,
    description: 'TODO',
  },
  {
    title: 'Преимущества с баннером',
    type: SnippetName.FeaturesIconsBannerSection,
    description: 'TODO',
  },
  {
    title: 'Преимещества две колонки строка',
    type: SnippetName.FeaturesIconsRow,
    description: 'TODO',
  },
  {
    title: 'Видео',
    type: SnippetName.VideoSection,
    description: 'С ссылками и без ссылок',
  },
  {
    title: 'Главный баннер в 2 колонки',
    type: SnippetName.EditorialStageSection,
    description: 'С ссылками и без ссылок',
  },
  {
    title: 'Текстовый баннер',
    type: SnippetName.PromptSection,
    description: 'TODO',
  },
  {
    title: 'Текстовый баннер c изображением',
    type: SnippetName.PromptImageSection,
    description: 'TODO',
  },
  {
    title: 'Главный баннер',
    type: SnippetName.FullscreenStageSection,
    description: 'TODO',
  },
  {
    title: 'Баннер текст поверх картинки',
    type: SnippetName.FullscreenBanner,
    description: 'TODO',
  },
  {
    title: 'Текстовый баннер с изображением',
    type: SnippetName.PresentationTeaserSection,
    description: 'TODO',
  },
];

const ctaWidgets: BlockPreviewType[] = [
  {
    title: 'Форма',
    type: SnippetName.FormSection,
    description: 'TODO',
  },
];

const placeholderWidgets: BlockPreviewType[] = [
  {
    title: 'Слот',
    type: SnippetNameExtend.Placeholder,
    description: 'TODO',
  },
  {
    title: 'Iframe',
    type: SnippetName.IframeSection,
    description: 'TODO',
  },
  {
    title: 'Скрипт',
    type: SnippetName.Script,
    description: 'TODO',
  },
];

const modelsWidgets: BlockPreviewType[] = [
  {
    title: 'Модель/комплектация',
    type: SnippetName.CarFragment,
    description: 'TODO',
  },
  {
    title: 'Модельный ряд',
    type: SnippetName.ModelsSection,
    description: 'TODO',
  },
  {
    title: 'Модельный ряд карусель',
    type: SnippetName.ModelsCarouselSection,
    description: 'TODO',
  },
  {
    title: 'Модельный ряд карусель фрагмент',
    type: SnippetName.ModelsCarouselCarFragment,
    description: 'TODO',
  },
  {
    title: 'Модельный ряд карусель таб',
    type: SnippetName.ModelsCarouselTab,
    description: 'TODO',
  },
  {
    title: 'Модельный ряд таб',
    type: SnippetName.ModelsTab,
    description: 'TODO',
  },
  {
    title: 'Комплектации фрагмент',
    type: SnippetName.BundlesCarFragment,
    description: 'TODO',
  },
  {
    title: 'Комплектации',
    type: SnippetName.BundlesSection,
    description: 'TODO',
  },
  {
    title: 'Сравнение',
    type: SnippetName.ComparisonSection,
    description: 'TODO',
  },
  {
    title: 'Автомобили в наличии',
    type: SnippetName.CarStockSection,
    description: 'TODO',
  },
  {
    title: 'Автомобили в наличии (карусель)',
    type: SnippetName.CarStockCarouselSection,
    description: 'TODO',
  },
];

const navigationWidgets: BlockPreviewType[] = [
  {
    title: 'Табы со ссылками',
    type: SnippetName.TabLinks,
    description: 'TODO',
  },
  {
    title: 'Табы',
    type: SnippetName.TabsSection,
    description: 'TODO',
  },
  {
    title: 'TopMenuPromoArea',
    type: SnippetName.TopMenuPromoArea,
    description: 'TODO',
  },
  {
    title: 'Верхнее меню',
    type: SnippetName.TopMenu,
    description: 'TODO',
  },
  {
    title: 'Нижнее меню',
    type: SnippetName.Footer,
    description: 'TODO',
  },
  {
    title: 'Хлебные крошки',
    type: SnippetName.Breadcrumbs,
    description: 'TODO',
  },
];

const modalsWidgets: BlockPreviewType[] = [
  {
    title: 'Дисклеймер',
    type: SnippetName.DisclaimerLayer,
    description: 'TODO',
  },
  {
    title: 'Меню CTA',
    type: SnippetName.SideMenu,
    description: 'TODO',
  },
];

const itemsWidgets: BlockPreviewType[] = [
  {
    title: 'Сотрудник',
    type: SnippetName.TeamItem,
    description: 'TODO',
  },
  {
    title: 'Отзыв',
    type: SnippetName.FeedbackItem,
    description: 'TODO',
  },
  {
    title: 'Двигатели таб',
    type: SnippetName.EnginesTab,
    description: 'TODO',
  },
];

const serviceWidgets: BlockPreviewType[] = [
  {
    title: 'Цитата',
    type: SnippetName.Quote,
    description: 'TODO',
  },
  {
    title: 'RichText',
    type: SnippetName.Richtext,
    description: 'TODO',
  },
  {
    title: 'Изображение без секции',
    type: SnippetName.Image,
    description: 'TODO',
  },
  {
    title: 'Видео без секции',
    type: SnippetName.Video,
    description: 'TODO',
  },
  {
    title: 'TeamGrid',
    type: SnippetName.TeamGrid,
    description: 'TODO',
  },
  {
    title: 'FooterBottomNav',
    type: SnippetName.FooterBottomNav,
    description: 'TODO',
  },
  {
    title: 'FooterSocials',
    type: SnippetName.FooterSocials,
    description: 'TODO',
  },
  {
    title: 'FooterColumn',
    type: SnippetName.FooterColumn,
    description: 'TODO',
  },
  {
    title: 'FooterContacts',
    type: SnippetName.FooterContacts,
    description: 'TODO',
  },
  {
    title: 'ContactInfo',
    type: SnippetName.ContactInfo,
    description: 'TODO',
  },
];

const contactsWidgets: BlockPreviewType[] = [
  {
    title: 'Окно быстрые контакты ',
    type: SnippetName.ContactsInteractionLayer,
    description: 'TODO',
  },
  {
    title: 'Окно контакты с картой',
    type: SnippetName.ContactFocusLayer,
    description: 'TODO',
  },
  {
    title: 'Сотрудники',
    type: SnippetName.TeamSection,
    description: 'TODO',
  },
  {
    title: 'Блок иконок',
    type: SnippetName.GridIconsSection,
    description: 'TODO',
  },
  {
    title: 'Контакты таб',
    type: SnippetName.ContactsTab,
    description: 'TODO',
  },
  {
    title: 'Контакты',
    type: SnippetName.ContactsSection,
    description: 'TODO',
  },
  {
    title: 'Описание ДЦ с изображениями',
    type: SnippetName.ContactsPreviewSection,
    description: 'TODO',
  },
  {
    title: 'Превью',
    type: SnippetName.TeaserItem,
    description: 'TODO',
  },
  {
    title: 'Превью с заполняемыми элементами',
    type: SnippetName.TeaserCustom,
    description: 'TODO',
  },
];

export const blocksPreviews: Record<BlocksGroup, BlockPreviewType[]> = {
  [BlocksGroup.All]: [
    ...bannersWidgets,
    ...contactsWidgets,
    ...ctaWidgets,
    ...galleryWidgets,
    ...modalsWidgets,
    ...modelsWidgets,
    ...navigationWidgets,
    ...placeholderWidgets,
    ...slidersWidgets,
    ...textsWidgets,
    ...itemsWidgets,
  ],
  [BlocksGroup.Banners]: bannersWidgets,
  [BlocksGroup.Contacts]: contactsWidgets,
  [BlocksGroup.CTA]: ctaWidgets,
  [BlocksGroup.Galleries]: galleryWidgets,
  [BlocksGroup.Modals]: modalsWidgets,
  [BlocksGroup.Models]: modelsWidgets,
  [BlocksGroup.Navigation]: navigationWidgets,
  [BlocksGroup.Placeholder]: placeholderWidgets,
  [BlocksGroup.Sliders]: slidersWidgets,
  [BlocksGroup.Texts]: textsWidgets,
  [BlocksGroup.Tiles]: [],
  [BlocksGroup.Items]: itemsWidgets,
  [BlocksGroup.Service]: serviceWidgets,
};
