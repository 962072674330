import { SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, RichtextTextNodesEditor, SnippetItemBlock } from '../../../custom-inputs';

export type TeamTabAccordionItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
};

export const TeamTabAccordionItem: React.FC<TeamTabAccordionItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...contentPath, index, 'accordionItem', 'content', 0]}
      allowedSnippets={[SnippetName.TeamGrid]}
    >
      <Alert message={`Аккордион-итем №${index + 1}`} type="info" style={{ marginBottom: 12 }} />

      {/* item.title */}
      <Form.Item
        label="Заголовок"
        name={[field.name, 'accordionItem', 'header']}
        fieldKey={[field.fieldKey, 'accordionItem', 'header']}
      >
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};
