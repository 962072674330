import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const CardEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexData, imageData, richtextData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });
    const imageData = getPlaceholderedPath({
      node,
      commonPath: [...flexData.path, 'content', 0, 'image'],
    });
    const richtextData = getPlaceholderedPath({
      node,
      commonPath: [...flexData.path, 'content', 1, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });

    return { flexData, imageData, richtextData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      {/* gap edit */}
      <Form.Item
        label="Отступ"
        name={[...flexData.path, 'styles', 0, 'spacing', 'vertical']}
        rules={[{ required: true }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {/* imageSrc edit */}
      <ImageNodeInputs imagePath={imageData.path} disabled={disabled} required />

      {/* content edit */}
      <RichtextFormItem label="Контент" contentFieldName={richtextData.path} disabled={disabled} />
    </Form>
  );
};
