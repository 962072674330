import { ContentVolkswagenV1GridColumn } from '@cms/volkswagen-widgets';
import { Slider } from 'antd';

const DOTS_COUNT = 10;

const getFrValue = (value?: ContentVolkswagenV1GridColumn): number => {
  const defaultValue = DOTS_COUNT / 2;
  if (!value?.width) return defaultValue;
  if (/^[0-9]+fr$/.test(value.width)) return Number(value.width.substring(0, value.width.length - 2)) || defaultValue;
  return defaultValue;
};

type CopyColumnsWidthInputProps = {
  value?: ContentVolkswagenV1GridColumn[];
  onChange?: (value: ContentVolkswagenV1GridColumn[]) => void;
  disabled: boolean;
};

export const CopyColumnsWidthInput: React.FC<CopyColumnsWidthInputProps> = ({ disabled, onChange, value = [] }) => {
  const width1 = getFrValue(value[0]);

  const handleChange = (newValue: number) => {
    onChange && onChange([{ width: `${newValue}fr` }, { width: `${DOTS_COUNT - newValue}fr` }]);
  };

  return (
    <Slider
      disabled={disabled}
      dots
      max={DOTS_COUNT - 1}
      min={1}
      onChange={handleChange}
      tipFormatter={(v = 1) => `${v}/${DOTS_COUNT - v}`}
      value={width1}
    />
  );
};
