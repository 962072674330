import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { NodePreview } from '../../../custom-inputs';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type MoodGalleryPreviewItemProps = {
  onChange?: (value: ContentVolkswagenV1Widget) => void;
  value?: ContentVolkswagenV1Widget;
};

export const MoodGalleryPreviewItem: React.FC<MoodGalleryPreviewItemProps> = ({ value }) => {
  return (
    <div style={{ width: '10%' }}>
      <NodePreview value={value} />
    </div>
  );
};
