import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { ContentSitesV1Domain, ContentSitesV1DomainKind } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { serverTimeToDefaultFormat } from '../../../../../utils/date';
import { DomainKindDropdown } from './domain-kind-dropdown';

const { confirm } = Modal;
const { Text, Title } = Typography;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  color: ${(props) => props.theme.colors.gray7};
  font-size: 20px;
`;

const StyledEditFilled = styled(EditFilled)`
  color: ${(props) => props.theme.colors.gray7};
  font-size: 20px;
`;

const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DomainManagementWrapper = styled.div`
  margin-top: 16px;
`;

type DomainTableProps = {
  domains: ContentSitesV1Domain[];
  loading: boolean;
  setEditDomain: (value: ContentSitesV1Domain | boolean) => void;
  onChangeDomainKind: (domain: ContentSitesV1Domain, newKind: ContentSitesV1DomainKind) => void;
  onRemoveDomain: (name: string) => void;
};

export const DomainTable: React.FC<DomainTableProps> = ({
  domains,
  loading,
  setEditDomain,
  onChangeDomainKind,
  onRemoveDomain,
}) => {
  const handleDelete = (item: ContentSitesV1Domain) => {
    confirm({
      title: 'Удаление домена',
      content: (
        <>
          <Text>Вы собираетесь удалить домен «{item.host}».</Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить домен?</Text>
        </>
      ),
      onOk() {
        return onRemoveDomain(item.name);
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  const columns: ColumnsType<ContentSitesV1Domain> = [
    {
      title: 'Имя домена',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'url домена',
      dataIndex: 'host',
      key: 'host',
    },
    {
      title: 'Статус домена',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Дата изменения',
      dataIndex: 'time',
      key: 'time',
      render: (_, record) => serverTimeToDefaultFormat(record.version.time),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <StyledActionsWrapper>
          <Space align="center">
            <DomainKindDropdown value={record.kind} onChange={(kind) => onChangeDomainKind(record, kind)} />
            <Button type="link" icon={<StyledEditFilled />} onClick={() => setEditDomain(record)} />
            <Button type="link" icon={<StyledDeleteFilled />} onClick={() => handleDelete(record)} />
          </Space>
        </StyledActionsWrapper>
      ),
    },
  ];

  return (
    <DomainManagementWrapper>
      <Table
        rowKey={(d) => d.name}
        columns={columns}
        dataSource={domains}
        loading={loading}
        pagination={false}
        title={() => (
          <StyledHeader>
            <Title level={4}>Управление доменами</Title>
            <Button type="link" icon={<PlusOutlined />} onClick={() => setEditDomain(true)}>
              Добавить домен
            </Button>
          </StyledHeader>
        )}
      />
    </DomainManagementWrapper>
  );
};
