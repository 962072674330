import { ContentVolkswagenV1TopMenuNode, TopMenuNode } from '@cms/volkswagen-widgets';
import { Alert, ColProps, Form, Input, Space } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { AttributeInput } from 'src/components/editors/custom-inputs';

const labelCol: ColProps = { span: 3 };

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type TopMenuItemInputProps = {
  value?: ContentVolkswagenV1TopMenuNode;
  onChange?: (value: ContentVolkswagenV1TopMenuNode) => void;
  disabled: boolean;
  field: FormListFieldData;
  index: number;
};

export const TopMenuItemInput: React.FC<TopMenuItemInputProps> = ({ value, disabled, field, index }) => {
  // wrong data
  if (!value?.topMenuLink) {
    return null;
  }

  // it's side icon
  if (value.topMenuLink.icon !== undefined) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert message={`Боковая иконка №${index}`} type="info" />

        {/* icon.href edit */}
        <Form.Item
          label="Ссылка"
          name={[field.name, 'topMenuLink', 'source']}
          fieldKey={[field.fieldKey, 'topMenuLink', 'source']}
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {/* icon.iconName edit */}
        <Form.Item
          label="Иконка"
          name={[field.name, 'topMenuLink', 'icon']}
          fieldKey={[field.fieldKey, 'topMenuLink', 'icon']}
          rules={[{ required: true }]}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <Input disabled={disabled} />
        </Form.Item>

        {/* icon.id edit */}
        <Form.Item
          label="Идентификатор элемента"
          name={[field.name, 'topMenuLink', 'attributes']}
          fieldKey={[field.fieldKey, 'topMenuLink', 'attributes']}
          style={{ margin: 0 }}
          labelCol={labelCol}
        >
          <AttributeInput attributeName={TopMenuNode.Link.attributes.id} disabled={disabled} />
        </Form.Item>
      </Space>
    );
  }

  // it's tab
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Alert message={`Таб №${index}`} type="info" />

      {/* tab.href edit */}
      <Form.Item
        label="Ссылка"
        name={[field.name, 'topMenuLink', 'source']}
        fieldKey={[field.fieldKey, 'topMenuLink', 'source']}
        rules={[{ required: true }]}
        style={{ margin: 0 }}
        labelCol={labelCol}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {/* tab.title edit */}
      <Form.Item
        label="Заголовок таба"
        name={[field.name, 'topMenuLink', 'content', 0, 'text', 'content']}
        fieldKey={[field.fieldKey, 'topMenuLink', 'content', 0, 'text', 'content']}
        style={{ margin: 0 }}
        labelCol={labelCol}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </Space>
  );
};
