import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { useRedirects } from '@console/api';
import React from 'react';
import { useSitesContext } from '../../contexts/sites-context';
import { ParsingState, RedirectCreateStatus } from '../redirects-tab-content/hooks';
import { ParsedRedirectsModal } from './components/parsed-redirects-modal';

export type ParsedRedirectsModalContainerProps = {
  isOpen: boolean;
  redirects: ParsingState['parsedRedirects'];
  onClose: () => void;
  onRedirectCreated: (name: string, status: RedirectCreateStatus) => void;
};

export const ParsedRedirectsModalContainer: React.FC<ParsedRedirectsModalContainerProps> = ({
  isOpen,
  redirects,
  onClose,
  onRedirectCreated,
}) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [progressPercent, setProgressPercent] = React.useState({ value: 0, hasErrors: false });

  const { currentSite } = useSitesContext();

  React.useEffect(() => setProgressPercent({ value: 0, hasErrors: false }), [isOpen]);

  const {
    operations: { createRedirectAsync },
  } = useRedirects({ enabled: false, site: currentSite?.name || '' });

  const handleCreateRedirects = async () => {
    setIsCreating(true);
    setProgressPercent({ value: 0, hasErrors: false });

    for await (const [index, redirect] of redirects.entries()) {
      const { created, ...body } = redirect;

      onRedirectCreated(redirect.name, RedirectCreateStatus.loading);

      await new Promise((resolve) => {
        setTimeout(() => resolve(true), 1500);
      });

      try {
        await createRedirectAsync(
          {
            ...body,
            activity: { status: ActivityV1Status.Active },
            visibility: {
              status: VisibilityV1Status.Public,
            },
          },
          {
            onError: () => {
              onRedirectCreated(redirect.name, RedirectCreateStatus.error);
              setProgressPercent((prev) => ({ ...prev, hasErrors: true }));
            },
            onSuccess: () => onRedirectCreated(redirect.name, RedirectCreateStatus.success),
            onSettled: () => setProgressPercent((prev) => ({ ...prev, value: ((index + 1) / redirects.length) * 100 })),
          },
        );
      } catch (e) {}
    }

    setIsCreating(false);
  };

  return (
    <ParsedRedirectsModal
      isOpen={isOpen}
      redirects={redirects}
      onClose={onClose}
      onCreateRedirects={handleCreateRedirects}
      isCreating={isCreating}
      progressPercent={progressPercent}
    />
  );
};
