import {
  CompositeDecorator as DraftCompositeDecorator,
  ContentBlock,
  ContentState,
  DraftDecorator,
  DraftDecoratorType,
} from 'draft-js';
import Immutable from 'immutable';
import React from 'react';

const Span: React.FC = (props) => <span>{props.children}</span>;

type ComponentProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  decoratorProps: Object[];
};

export class CompositeDecorator implements DraftDecoratorType {
  private readonly decorators: DraftCompositeDecorator[];

  constructor(decorators: Array<DraftDecorator> = []) {
    this.decorators = decorators.map((decorator) => {
      return new DraftCompositeDecorator([decorator]);
    });
  }

  getDecorations(block: ContentBlock, contentState: ContentState): Immutable.List<string> {
    const emptyTuples = Array(block.getText().length).fill(Array(this.decorators.length).fill(null));

    const decorations = this.decorators.reduce<string[][]>((tuples, decorator, index) => {
      const blockDecorations = decorator.getDecorations(block, contentState);

      return tuples.map((tuple, tupleIndex) => {
        return [...tuple.slice(0, index), blockDecorations.get(tupleIndex), ...tuple.slice(index + 1)];
      });
    }, emptyTuples);

    return Immutable.List(decorations.map((item) => JSON.stringify(item)));
  }

  getComponentForKey(key: string): React.FC<ComponentProps> {
    const tuple: string[] = JSON.parse(key);
    return (props) => {
      const { decoratorProps, ...compositionProps } = props;
      const Composed = tuple.reduce((Composition, decoration, index) => {
        if (decoration !== null) {
          const decorator = this.decorators[index];
          const Component = decorator.getComponentForKey(decoration);
          const componentProps = {
            ...compositionProps,
            ...decoratorProps[index],
          };
          return () => (
            <Component {...componentProps}>
              <Composition {...compositionProps} />
            </Component>
          );
        }
        return Composition;
      }, Span);
      return <Composed>{props.children}</Composed>;
    };
  }

  getPropsForKey(key: string): ComponentProps {
    const tuple: string[] = JSON.parse(key);
    return {
      decoratorProps: tuple.map((decoration, index) => {
        const decorator = this.decorators[index];
        return decoration !== null ? decorator.getPropsForKey(decoration) : {};
      }),
    };
  }
}
