import { MinusCircleOutlined } from '@ant-design/icons';
import { ContentVolkswagenV1Node } from '@cms/volkswagen-widgets';
import { Button, Checkbox, CheckboxProps, Form, FormInstance, Input, Typography } from 'antd';
import React from 'react';
import { RichtextFormItem } from 'src/components/editors/node-editors';
import {
  getPlaceholderedPath,
  GetPlaceholderedPathResult,
  PLACEHOLDER_KEY_PATH,
  TreePath,
} from 'src/utils/content-path';
import { ImageNodeInputs } from '../../../components/editors/groups/image-node-inputs';
import { PreviewChecker } from './preview-checker';

export type PreviewSettingsTabContentProps = {
  getFieldValue: FormInstance['getFieldValue'];
  setFields: FormInstance['setFields'];
  isBlueprint: boolean;
};

export const PreviewSettingsTabContent: React.FC<PreviewSettingsTabContentProps> = ({
  getFieldValue,
  setFields,
  isBlueprint,
}) => {
  const previewValue = getFieldValue('preview')?.[0];

  const node = previewValue?.placeholder?.content?.[0]?.blueprint || previewValue?.blueprint;
  const isRootPlaceholder = previewValue?.placeholder;

  const flexData: GetPlaceholderedPathResult = node
    ? getPlaceholderedPath({
        node,
        commonPath: ['content', 0, PLACEHOLDER_KEY_PATH, 'flex'],
        placeholderContentIndex: 1,
      })
    : { path: [], placeholdersCount: 0 };

  const linkData: GetPlaceholderedPathResult = node
    ? getPlaceholderedPath({
        node,
        commonPath: ['content', 0, PLACEHOLDER_KEY_PATH, 'link'],
      })
    : { path: [], placeholdersCount: 0 };

  const handleCreateClick = (node: ContentVolkswagenV1Node) => {
    const name = isRootPlaceholder ? ['preview', 0, 'placeholder', 'content', 0] : ['preview', 0];

    setFields([{ name, value: node }]);
  };

  const handleRemoveClick = () => {
    const name = isRootPlaceholder ? ['preview', 0, 'placeholder', 'content'] : 'preview';

    setFields([{ name, value: [] }]);
  };

  const handlePlaceholderChange: CheckboxProps['onChange'] = (e) => {
    let value = previewValue?.placeholder?.content?.[0] ? [previewValue?.placeholder?.content?.[0]] : [];

    if (e.target.checked) {
      value = [{ placeholder: { content: previewValue ? [previewValue] : [] } }];
    }

    setFields([{ name: 'preview', value }]);
  };

  const disabled = isBlueprint && !isRootPlaceholder;
  const blueprintPath: TreePath = [
    'preview',
    0,
    ...(isRootPlaceholder ? (['placeholder', 'content', 0] as TreePath) : []),
    'blueprint',
  ];

  return (
    <>
      {!isBlueprint && (
        <Form.Item>
          <Checkbox checked={isRootPlaceholder} onChange={handlePlaceholderChange} disabled={disabled}>
            Редактируемые настройки превью
          </Checkbox>
        </Form.Item>
      )}
      <PreviewChecker isPreviewExist={!!node} onCreateClick={handleCreateClick} disabled={disabled}>
        <Typography.Title level={4}>Данные в превью</Typography.Title>
        <Form.Item label="Ссылка на изображении" name={[...blueprintPath, ...linkData.path, 'source']}>
          <Input disabled={disabled} />
        </Form.Item>

        <ImageNodeInputs imagePath={[...blueprintPath, ...linkData.path, 'content', 0, 'image']} disabled={disabled} />

        <RichtextFormItem
          label="Заголовок"
          contentFieldName={[...blueprintPath, ...flexData.path, 'content', 0, 'blueprint', 'content', 0, 'richtext']}
          disabled={disabled}
        />

        <RichtextFormItem
          label="Подзаголовок"
          contentFieldName={[...blueprintPath, ...flexData.path, 'content', 1, 'blueprint', 'content', 0, 'richtext']}
          disabled={disabled}
        />

        <RichtextFormItem
          label="Текст"
          contentFieldName={[...blueprintPath, ...flexData.path, 'content', 2, 'blueprint', 'content', 0, 'richtext']}
          disabled={disabled}
        />
        <Button icon={<MinusCircleOutlined />} onClick={handleRemoveClick} disabled={disabled} block danger>
          Удалить превью
        </Button>
      </PreviewChecker>
    </>
  );
};
