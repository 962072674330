import { ContentVolkswagenV1Widget, snippetGenerators } from '@cms/volkswagen-widgets';
import { Checkbox, Divider, Form, FormProps, Input, Tooltip } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import cloneDeep from 'lodash/cloneDeep';
import { FormInstance } from 'rc-field-form/es/interface';
import React, { useMemo } from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton } from '../custom-inputs';
import { usePlaceholderActions } from '../hooks';
import { SectionNodeEditor, SectionNodeEditorProps } from '../node-editors';
import { HeadlineEditor } from '../node-editors/headline-editor';
import { NodeEditorProps } from '../types';

const sectionPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'section'];

const sectionPlaceholderPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, 'placeholder'];

const initialHeader = snippetGenerators.Section.grid([snippetGenerators.Richtext({ content: [] })]);

type SectionProps = {
  /** node from which the content of the section starts */
  node: ContentVolkswagenV1Widget;
  /**
   * must be used in all `Form.Item`
   * @link https://ant.design/components/form/#components-form-demo-dynamic-form-item
   */
  formListFieldData: FormListFieldData;
  /** full path from root to section content */
  sectionContentPath: TreePath;
  /** add/remove placeholder along a given path */
  onPlaceholderChange: (path: TreePath, isEnabled: boolean) => void;
  getFieldValue: FormInstance['getFieldValue'];
};

export function withSection(
  WrappedComponent: React.ComponentType<SectionProps>,
  options?: SectionNodeEditorProps['options'],
): React.ComponentType<NodeEditorProps> {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithSection = ({ node, onSave, formName }: NodeEditorProps) => {
    const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

    const handleFinish: FormProps['onFinish'] = (values) => {
      const clone = cloneDeep(values);

      mutateNodePlaceholders(clone);
      onSave(clone);
    };

    const { sectionData } = useMemo(() => {
      const sectionData = getPlaceholderedPath({
        node,
        commonPath: sectionPath,
      });

      return {
        sectionData,
      };
    }, [node]);

    const disabledSection = sectionData.placeholdersCount === 0;

    return (
      <Form name={formName} initialValues={node} onFinish={handleFinish}>
        {/* hack, used for deep cloning node to antd Form state */}
        <Form.Item name="blueprint" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Divider orientation="right" style={{ marginTop: 0 }}>
          Общие настройки
        </Divider>

        {/* section placeholder */}
        <Form.Item>
          <Checkbox
            defaultChecked={sectionData.placeholdersCount === 2}
            disabled={disabledSection}
            onChange={(e) => onPlaceholderChange(sectionPlaceholderPath, e.target.checked)}
          >
            <Tooltip
              placement="bottom"
              title={`При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование "Общих настроек"`}
            >
              Редактируемые общие настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>

        {/* section align, size, top/bottom bounds */}
        <SectionNodeEditor
          attributesPath={[...sectionData.path, 'attributes']}
          themesPath={[...sectionData.path, 'themes']}
          disabled={disabledSection}
          options={options}
        />

        {/* headline or content */}
        <Form.Item shouldUpdate style={{ marginTop: 24 }}>
          {({ getFieldValue }) => (
            <Form.List name={[...sectionData.path, 'content']}>
              {(fields, { add, remove }) => (
                <>
                  {/* add headline button */}
                  {fields.length === 1 && (
                    <Form.Item>
                      <AddEnrichedNodeButton
                        add={(node) => add(node, 0)}
                        nodeToEnrich={initialHeader}
                        isDisabled={false}
                        label="Добавить заголовок"
                      />
                    </Form.Item>
                  )}

                  {/* headline or content */}
                  {fields.map((field, index) =>
                    fields.length > 1 && index === 0 ? (
                      <HeadlineEditor
                        key={field.key}
                        formListFieldData={field}
                        disabled={disabledSection}
                        onRemove={() => remove(field.name)}
                      />
                    ) : (
                      <React.Fragment key={field.key}>
                        <Divider orientation="right">Настройки блока</Divider>
                        <WrappedComponent
                          node={getFieldValue([...sectionData.path, 'content', index])}
                          formListFieldData={field}
                          onPlaceholderChange={onPlaceholderChange}
                          sectionContentPath={[...sectionData.path, 'content', index]}
                          getFieldValue={getFieldValue}
                        />
                      </React.Fragment>
                    ),
                  )}
                </>
              )}
            </Form.List>
          )}
        </Form.Item>
      </Form>
    );
  };

  ComponentWithSection.displayName = `withSection(${displayName})`;

  return ComponentWithSection;
}
