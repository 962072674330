import { ContentVolkswagenV1StackStyle, snippetGenerators } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';
import { RichtextFormItem } from '../../node-editors';
import { CustomCheckbox } from '../../custom-inputs';

const commonStackPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'stack'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const FullscreenBannerEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { stackPath } = React.useMemo(() => {
    const stackPath = getPlaceholderedPath({
      node,
      commonPath: commonStackPath,
    });

    return {
      stackPath,
    };
  }, [node]);

  const disabled = stackPath.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={stackPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <ImageNodeInputs imagePath={[...stackPath.path, 'content', 0, 'box', 'content', 'image']} disabled={disabled} />

      <Form.Item name={[...stackPath.path, 'styles']}>
        <CustomCheckbox<ContentVolkswagenV1StackStyle[]>
          disabled={disabled}
          label="Половина высоты экрана"
          check={(value) =>
            Boolean(value && value[0].height === snippetGenerators.FullscreenBanner.helpers.fullscreenBannerHalfHeight)
          }
          checkedValue={[
            {
              height: snippetGenerators.FullscreenBanner.helpers.fullscreenBannerHalfHeight,
            },
          ]}
          uncheckedValue={[
            {
              height: snippetGenerators.FullscreenBanner.helpers.fullscreenBannerHeight,
            },
          ]}
        />
      </Form.Item>

      <RichtextFormItem
        label="Заголовок"
        contentFieldName={[
          ...stackPath.path,
          'content',
          1,
          'grid',
          'content',
          1,
          'flex',
          'content',
          0,
          'blueprint',
          'content',
          0,
          'richtext',
        ]}
        disabled={disabled}
      />

      <RichtextFormItem
        label="Контент"
        contentFieldName={[
          ...stackPath.path,
          'content',
          1,
          'grid',
          'content',
          3,
          'flex',
          'content',
          0,
          'blueprint',
          'content',
          0,
          'richtext',
        ]}
        disabled={disabled}
      />
    </Form>
  );
};
