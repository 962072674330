import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React, { useState } from 'react';
import { useToggle } from 'src/hooks';
import { Breadcrumbs, MainLayout } from '../../components';
import { MediaView } from '../../components/media/media-header';
import { useMedia } from '../../hooks/use-media';
import { MediaCardsContainer } from './components/media-cards-container';
import { MediaHeaderContainer } from './components/media-header-container';
import { MediaTableContainer } from './components/media-table-container';
import { CreateFolderModalContainer } from './containers/create-folder-modal-container';
import { MediaPageContextProvider } from './contexts/media-context';

export const MediaPage: React.FC = () => {
  const [search, setSearch] = React.useState<string>();
  const [selectedRows, setSelectedRows] = useState<ContentFilesV1File[]>([]);
  const [sort, setSort] = React.useState('name');
  const [view, setView] = React.useState<MediaView>(MediaView.Table);
  const [createFolderModalIsOpen, { onToggle: openCreateFolderModal, offToggle: closeCreateFolderModal }] = useToggle();

  const media = useMedia({ key: 'path', search });

  return (
    <MediaPageContextProvider
      value={{
        selectedRows,
        setSelectedRows,
        search,
        setSearch,
        sort,
        setSort,
        view,
        setView,
        openCreateFolderModal,
        ...media,
      }}
    >
      <MainLayout header={<MediaHeaderContainer />}>
        <Breadcrumbs {...media.breadcrumbs} homeTitle="Все файлы" />
        {view === MediaView.Table && <MediaTableContainer />}
        {view === MediaView.Card && <MediaCardsContainer />}
      </MainLayout>
      <CreateFolderModalContainer open={createFolderModalIsOpen} onClose={closeCreateFolderModal} />
    </MediaPageContextProvider>
  );
};
