import { ContentVolkswagenV1Node, PaginationNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AttributeInput, AttributesSelect } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { TeaserItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const possibleContentViews = [
  { value: PaginationNode.contentView.List, label: 'Список' },
  { value: PaginationNode.contentView.ListPadding, label: 'Список (с отступом)' },
  { value: PaginationNode.contentView.GridTwo, label: 'Две колонки' },
  { value: PaginationNode.contentView.GridTwoPadding, label: 'Две колонки (с отступом)' },
  { value: PaginationNode.contentView.GridThree, label: 'Три колонки' },
];

const paginationPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'pagination'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapItemAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  paginationItem: PaginationNode.Item.generators.entity({
    content: [node],
  }),
});

export const TeaserCustomSnippetEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { paginationData } = React.useMemo(() => {
    const paginationData = getPlaceholderedPath({
      node,
      commonPath: paginationPath,
    });

    return { paginationData };
  }, [node]);

  const disabled = paginationData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={paginationData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.Item name={[...paginationData.path, 'attributes']} label="Вид контента">
        <AttributesSelect
          attributeName={PaginationNode.attributes.contentView}
          disabled={disabled}
          possibleValues={possibleContentViews}
        />
      </Form.Item>

      <Form.Item name={[...paginationData.path, 'attributes']} label="Ссылка на все элементы">
        <AttributeInput attributeName={PaginationNode.attributes.showAllHref} disabled={disabled} />
      </Form.Item>

      <Form.Item name={[...paginationData.path, 'size']} label="Начальное количество элементов">
        <Input type="number" disabled={disabled} />
      </Form.Item>

      <Form.List name={[...paginationData.path, 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <TeaserItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[...paginationData.path, 'items']}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapItemAdd}
              isDisabled={disabled}
              label="Добавить элемент-превью"
              noStyle
              allowedSnippets={[SnippetName.TeaserItem]}
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
