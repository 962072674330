import { AlignBounds, SectionNode, SizeBounds } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';
import { TreePath } from 'src/utils/content-path';
import { AttributeInput, AttributesRadioGroup, ThemeCheckbox } from '../../custom-inputs';
import { PreviewBounds } from './components/preview-bounds';
import { PreviewMargin } from './components/preview-margin';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  border: 1px solid ${(props) => props.theme.colors.gray5};
  padding: 16px;
  position: relative;

  &::before {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    top: -6px;
    left: 16px;
    content: 'Позиционирование блока';
    background-color: white;
    padding: 0 8px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.blue5};
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -1px;
    content: '';
    background-image: repeating-linear-gradient(
      80deg,
      ${(props) => props.theme.colors.blue3} 0 1px,
      transparent 1px 100%
    );
    background-size: calc(100% / 24) calc(100% / 24);
  }
`;

type Options = {
  disabledVerticalBounds?: boolean;
  disabledHorizontalBounds?: boolean;
};

export type SectionNodeEditorProps = {
  attributesPath: TreePath;
  themesPath: TreePath;
  disabled: boolean;
  options?: Options;
};

export const SectionNodeEditor: React.FC<SectionNodeEditorProps> = ({
  attributesPath,
  themesPath,
  disabled,
  options,
}) => (
  <Container>
    <div>
      {!options?.disabledVerticalBounds && (
        <Form.Item name={themesPath} style={{ marginBottom: 8 }}>
          <ThemeCheckbox label="Отступ сверху" themeName={SectionNode.themes.topBounds} disabled={disabled} />
        </Form.Item>
      )}
      {!options?.disabledHorizontalBounds && (
        <>
          <Form.Item label="Выравнивание блока" name={attributesPath} style={{ marginBottom: 8 }}>
            <AttributesRadioGroup
              possibleValues={[
                { value: AlignBounds.left, label: 'Слева' },
                {
                  value: AlignBounds.center,
                  label: 'По центру',
                },
                { value: AlignBounds.right, label: 'Справа' },
              ]}
              defaultValue={AlignBounds.center}
              attributeName={SectionNode.attributes.alignBounds}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item label="Общая ширина блока" name={attributesPath} style={{ marginBottom: 8 }}>
            <AttributesRadioGroup
              possibleValues={[
                {
                  value: SizeBounds.large,
                  label: '12 колонок',
                },
                {
                  value: SizeBounds.medium,
                  label: '16 колонок',
                },
                {
                  value: SizeBounds.small,
                  label: '22 колонки',
                },
                {
                  value: AttributesRadioGroup.defaultValue,
                  label: 'Вся ширина',
                },
              ]}
              attributeName={SectionNode.attributes.sizeBounds}
              disabled={disabled}
            />
          </Form.Item>
        </>
      )}
      {!options?.disabledVerticalBounds && (
        <Form.Item name={themesPath} style={{ marginBottom: 8 }}>
          <ThemeCheckbox label="Отступ снизу" themeName={SectionNode.themes.bottomBounds} disabled={disabled} />
        </Form.Item>
      )}
      <Form.Item name={attributesPath} label="ID секции">
        <AttributeInput attributeName={SectionNode.attributes.id} disabled={disabled} />
      </Form.Item>
    </div>

    {/* preview */}
    <PreviewContainer>
      <Form.Item noStyle name={themesPath}>
        <PreviewMargin themeName={SectionNode.themes.topBounds} />
      </Form.Item>
      <Form.Item noStyle name={attributesPath}>
        <PreviewBounds />
      </Form.Item>
      <Form.Item noStyle name={themesPath}>
        <PreviewMargin themeName={SectionNode.themes.bottomBounds} />
      </Form.Item>
    </PreviewContainer>
  </Container>
);
