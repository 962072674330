import { DownOutlined } from '@ant-design/icons';
import { ContentSitesV1DomainKind } from '@cms/volkswagen-widgets';
import { Dropdown, Menu, MenuProps } from 'antd';
import React from 'react';

type DomainKindDropdownProps = {
  value: ContentSitesV1DomainKind;
  onChange: (value: ContentSitesV1DomainKind) => void;
};

export const DomainKindDropdown: React.FC<DomainKindDropdownProps> = ({ value, onChange }) => {
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onChange(e.key as ContentSitesV1DomainKind);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={ContentSitesV1DomainKind.Main}>{ContentSitesV1DomainKind.Main}</Menu.Item>
      <Menu.Item key={ContentSitesV1DomainKind.Mirror}>{ContentSitesV1DomainKind.Mirror}</Menu.Item>
      <Menu.Item key={ContentSitesV1DomainKind.None}>{ContentSitesV1DomainKind.None}</Menu.Item>
      <Menu.Item key={ContentSitesV1DomainKind.Preview}>{ContentSitesV1DomainKind.Preview}</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {value} <DownOutlined />
      </a>
    </Dropdown>
  );
};
