import { ContentSitesV1Domain, ContentSitesV1DomainsAPIApi, GoogleRpcStatus } from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Domain>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveDomainData = {
  removeDomain: UseMutateFunction<Data, Error, Variables>;
  removeDomainAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveDomain(
  domainsApi: ContentSitesV1DomainsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseRemoveDomainData {
  const queryClient = useQueryClient();

  const {
    mutate: removeDomain,
    mutateAsync: removeDomainAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>(
    (name) =>
      domainsApi.contentSitesV1DomainsAPIDeleteDomain(
        site, // siteName: string,
        name, // domainName: string,
        undefined, // siteRevision?: string,
        undefined, // domainRevision?: string,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка удаления домена',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { removeDomain, removeDomainAsync, isRemoveLoading };
}
