import { Dropdown, Menu } from 'antd';
import React, { useCallback } from 'react';
import { useEntity } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { ControlButton } from '../../control-button';
import { StyledControl, StyledDropdownControl, StyledDropdownMenu } from '../../../../elements';
import { VerticalDividerEntityType } from './types';

export * from './types';
export * from './decorator';

const COLORS = [
  {
    label: 'серая',
    value: 'rgb(223, 228, 232)',
  },
];

export type VerticalDividerProps = {
  color: string;
};

export const VerticalDividerEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(
  ({ editorState, setEditorState }) => {
    const [createDivider] = useEntity<VerticalDividerProps, VerticalDividerEntityType>(
      editorState,
      setEditorState,
      'VERTICAL_DIVIDER',
      'IMMUTABLE',
    );

    const handleChange = useCallback(
      (color: string) => {
        createDivider(
          {
            color,
          },
          ' ',
        );
      },
      [createDivider],
    );

    return (
      <StyledControl>
        <Dropdown
          trigger={['click']}
          overlay={
            <StyledDropdownMenu>
              {COLORS.map(({ label, value }) => (
                <Menu.Item key={label}>
                  <ControlButton key={`phone-entity-${label}`} title={label} onToggle={handleChange} value={value}>
                    {label}
                  </ControlButton>
                </Menu.Item>
              ))}
            </StyledDropdownMenu>
          }
        >
          <StyledDropdownControl>
            <ControlButton>vertical divider</ControlButton>
          </StyledDropdownControl>
        </Dropdown>
      </StyledControl>
    );
  },
);

VerticalDividerEntity.displayName = 'VerticalDividerEntity';
