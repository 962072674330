import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, ContentButtonsInput } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const disallowedSnippets: AddSnippetButtonProps['disallowedSnippets'] = [
  SnippetName.VacanciesGrid,
  SnippetName.TabsSection,
  SnippetName.GalleryColumn,
  SnippetName.GallerySection,
  SnippetName.CopyAccordionSection,
  SnippetName.CopyColumnsSection,
  SnippetName.MoodGallery,
  SnippetName.HighlightFeatureSection,
];

export const VacanciesGridEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { flexData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });

    return { flexData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.List name={[...flexData.path, 'content', 0, 'grid', 'content']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((_, index) => (
              <ContentButtonsInput
                contentPath={[...flexData.path, 'content', 0, 'grid', 'content', index]}
                label={`Контент #${index}`}
                onDelete={() => remove(index)}
                disallowedSnippets={disallowedSnippets}
              />
            ))}
            <AddSnippetButton
              add={add}
              isDisabled={disabled}
              label="Добавить описание"
              disallowedSnippets={disallowedSnippets}
            />
          </>
        )}
      </Form.List>
      <ContentButtonsInput contentPath={[...flexData.path, 'content', 1]} label={`Дополнительный контент`} />
    </Form>
  );
};
