import { Button } from 'antd';
import React from 'react';

import { SitesControl } from 'src/pages/sites/components/sites-control';
import { StyledTabHeaderWrapper } from '../../../components/elements';

type PublicationTabHeaderProps = {
  onPublish: () => void;
};

export const PublicationTabHeader: React.FC<PublicationTabHeaderProps> = ({ onPublish }) => {
  return (
    <StyledTabHeaderWrapper>
      <SitesControl />
      <Button type="primary" onClick={onPublish}>
        Опубликовать
      </Button>
    </StyledTabHeaderWrapper>
  );
};
