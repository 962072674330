import { ContentVolkswagenV1Widget, snippetGenerators, SnippetName, SnippetPathByName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddSnippetButton } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';
import { ImageOrQuote } from './components/image-or-quote';

const commonGridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const FeedbackItemEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const gridPath = getPlaceholderedPath({
    node,
    commonPath: commonGridPath,
  });

  const disabled = gridPath.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[...gridPath.path, 'content']}>
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const firstNode: ContentVolkswagenV1Widget = getFieldValue([
                  ...gridPath.path,
                  'content',
                  0,
                  'box',
                  'content',
                ]);
                const secondNode: ContentVolkswagenV1Widget = getFieldValue([
                  ...gridPath.path,
                  'content',
                  1,
                  'box',
                  'content',
                ]);

                const isImageExist =
                  firstNode?.blueprint?.source === SnippetPathByName.Image ||
                  secondNode?.blueprint?.source === SnippetPathByName.Image;

                return (
                  <>
                    {!isImageExist && (
                      <AddSnippetButton
                        add={(node) => add(node, 0)}
                        mapBeforeAdd={snippetGenerators.FeedbackItem.helpers.feedbacksItemWrapImage}
                        isDisabled={disabled}
                        label="Добавить изображение"
                        allowedSnippets={[SnippetName.Image]}
                      />
                    )}
                  </>
                );
              }}
            </Form.Item>
            {fields.map((field, index) => (
              <ImageOrQuote
                key={field.key}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                contentPath={[...gridPath.path, 'content']}
              />
            ))}
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const firstNode: ContentVolkswagenV1Widget = getFieldValue([...gridPath.path, 'content', 0]);
                const secondNode: ContentVolkswagenV1Widget = getFieldValue([...gridPath.path, 'content', 1]);

                const isQuoteExist =
                  firstNode?.blueprint?.source === SnippetPathByName.Quote ||
                  secondNode?.blueprint?.source === SnippetPathByName.Quote;

                return (
                  <>
                    {!isQuoteExist && (
                      <AddSnippetButton
                        add={add}
                        isDisabled={disabled}
                        label="Добавить цитату"
                        allowedSnippets={[SnippetName.Quote]}
                      />
                    )}
                    <Form.ErrorList errors={errors} />
                  </>
                );
              }}
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
