import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React from 'react';
import { getFileVersion } from 'src/utils/entities';
import { serverTimeToDefaultFormat } from '../../utils/date';

type FilesTimeTableCellProps = {
  file: ContentFilesV1File;
};

export const FilesTimeTableCell: React.FC<FilesTimeTableCellProps> = ({ file }) => {
  const time = getFileVersion(file)?.time;
  const user = getFileVersion(file)?.author?.name;

  return (
    <span>
      {serverTimeToDefaultFormat(time)} {user ? `(${user})` : ''}
    </span>
  );
};
