import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { Modal, notification, Typography } from 'antd';
import { useFilesContext } from 'src/pages/files/contexts';
import { getFilePath, getFileTitle } from 'src/utils/entities';

const { confirm } = Modal;
const { Text } = Typography;

function getFileName(file: ContentFilesV1File) {
  if (file.filesV1Folder) {
    return { parental: 'папки', accusative: 'папку' };
  }
  if (file.volkswagenV1Page) {
    return { parental: 'страницы', accusative: 'страницу' };
  }
  if (file.volkswagenV1PageBlueprint) {
    return { parental: 'ярлыка страницы', accusative: 'ярлык страницы' };
  }
  if (file.filesV1FolderBlueprint) {
    return { parental: 'ярлыка папки', accusative: 'ярлык папки' };
  }
  return { parental: '', accusative: '' };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDeleteFile(file: ContentFilesV1File) {
  const {
    service: {
      operations: { removeFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  const showDeleteConfirm = () => {
    confirm({
      title: `Удаление ${getFileName(file).parental}`,
      content: (
        <>
          <Text>
            Вы собираетесь удалить {getFileName(file).accusative} «{getFileTitle(file)}».
          </Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить {getFileName(file).accusative}?</Text>
        </>
      ),
      onOk() {
        return removeFileAsync((getFilePath(file) || '').substring(1)).then(() => {
          resetSelectedRows();
          notification.error({
            message: 'Файл успешно удалён',
          });
        });
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  return { showDeleteConfirm };
}
