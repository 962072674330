import { SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH } from 'src/utils/content-path';
import { AddSnippetButton, AddSnippetButtonProps, ThemeStylesHeightInput } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { CarouselItem } from './component';

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.CarFragment];

export const SpecialSeriesEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const themeData = getPlaceholderedPath({
      node,
      commonPath: ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'theme'],
    });

    const disabled = themeData.placeholdersCount === 0;

    const handlePlaceholderChange = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        <Form.Item>
          <Checkbox
            defaultChecked={themeData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholderChange(e.target.checked)}
          >
            Редактируемое
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Высота изображений"
          fieldKey={[formListFieldData.fieldKey, ...themeData.path, 'styles']}
          name={[formListFieldData.name, ...themeData.path, 'styles']}
        >
          <ThemeStylesHeightInput disabled={disabled} themeKey="flex" />
        </Form.Item>
        <Form.List
          name={[formListFieldData.name, ...themeData.path, 'content', 0, 'flex', 'content', 0, 'carousel', 'items']}
        >
          {(fields, { add, remove, move }) => (
            <>
              {fields.map((field, index) => (
                <CarouselItem
                  key={field.key}
                  move={move}
                  remove={remove}
                  index={index}
                  field={field}
                  disabled={disabled}
                  isBottomMovable={fields.length > 1 && index < fields.length - 1}
                  isTopMovable={fields.length > 1 && index > 0}
                  carouselContentPath={[
                    ...sectionContentPath,
                    ...themeData.path,
                    'content',

                    0,
                    'flex',
                    'content',
                    0,
                    'carousel',
                    'items',
                  ]}
                  allowedSnippets={allowedSnippets}
                />
              ))}
              <AddSnippetButton
                add={add}
                mapBeforeAdd={CarouselItem.mapper}
                isDisabled={disabled}
                label="Добавить итем"
                allowedSnippets={allowedSnippets}
              />
            </>
          )}
        </Form.List>
      </>
    );
  },
);
