import { d6, HeadingNode } from '@cms/volkswagen-widgets';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { StyledControl, StyledControlsBlock, StyledDropdownControl, StyledDropdownMenu } from '../../../elements';
import { ConfigType, useBlock, useConfigByStyles } from '../../../hooks';
import { BlockRenderMapType, ControlsProps, StyleItem } from '../../../types';
import { ControlButton } from '../control-button';

const { StyledModifiedText } = d6;

export enum HeaderBlockType {
  headerOne = 'header-one',
  headerTwo = 'header-two',
  headerThree = 'header-three',
  headerFour = 'header-four',
  headerFive = 'header-five',
  // headerSix = 'header-six',
}

const HEADER_TYPES: StyleItem<HeaderBlockType>[] = [
  { label: 'H1', style: HeaderBlockType.headerOne },
  { label: 'H2', style: HeaderBlockType.headerTwo },
  { label: 'H3', style: HeaderBlockType.headerThree },
  { label: 'H4', style: HeaderBlockType.headerFour },
  { label: 'H5', style: HeaderBlockType.headerFive },
  // { label: 'H6', style: HeaderBlockType.headerSix },
];

export const headingMapToLevel: { [key in HeaderBlockType]: number } = {
  [HeaderBlockType.headerOne]: 1,
  [HeaderBlockType.headerTwo]: 2,
  [HeaderBlockType.headerThree]: 3,
  [HeaderBlockType.headerFour]: 4,
  [HeaderBlockType.headerFive]: 5,
};

export const headerBlockRender: BlockRenderMapType = Object.values(HeaderBlockType).reduce<BlockRenderMapType>(
  (result, headerStyle) => {
    return {
      ...result,
      [headerStyle]: {
        element: ({ children, ...props }) => {
          const level = headingMapToLevel[headerStyle];

          return (
            <StyledModifiedText
              tag={HeadingNode.headingTagMap[level]}
              appearance={HeadingNode.headingAppearanceMap[level]}
              {...props}
            >
              {children}
            </StyledModifiedText>
          );
        },
      },
    };
  },
  {},
);

export const HeaderBlock: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleHeader, currentBlockType] = useBlock<HeaderBlockType>(editorState, setEditorState);
  const types = useConfigByStyles(ConfigType.Heading, HEADER_TYPES);

  const active = types.find(({ style }) => style === currentBlockType);

  if (!types.length) {
    return null;
  }

  return (
    <StyledControlsBlock>
      <StyledControl>
        <Dropdown
          trigger={['click']}
          overlay={
            <StyledDropdownMenu>
              {types.map(({ label, style: blockType, name }) => (
                <Menu.Item key={label}>
                  <ControlButton
                    title={name}
                    isActive={blockType === currentBlockType}
                    value={blockType}
                    onToggle={toggleHeader}
                  >
                    {label}
                  </ControlButton>
                </Menu.Item>
              ))}
            </StyledDropdownMenu>
          }
        >
          <StyledDropdownControl>
            <ControlButton title="Заголовок" isActive={!!active}>
              {active ? active.label : 'H'}
            </ControlButton>
          </StyledDropdownControl>
        </Dropdown>
      </StyledControl>
    </StyledControlsBlock>
  );
});

HeaderBlock.displayName = 'HeaderBlock';
