import { ContentSitesV1Site } from '@cms/volkswagen-widgets';
import { Modal, notification, Typography } from 'antd';

const { confirm } = Modal;
const { Text } = Typography;

function getFileName(sites: ContentSitesV1Site[]) {
  if (sites.length === 1) {
    return { parental: 'сайта', accusative: 'сайт' };
  }

  if (sites.length > 1) {
    return { parental: 'сайтов', accusative: 'сайты' };
  }

  return { parental: '', accusative: '' };
}

export const useDeleteSites = (
  sites: ContentSitesV1Site[],
  onDelete: () => Promise<void>,
): { showDeleteConfirm: () => void } => {
  const showDeleteConfirm = () => {
    confirm({
      title: `Удаление ${getFileName(sites).parental}`,
      content: (
        <>
          <Text>
            Вы собираетесь удалить {getFileName(sites).accusative} {sites.map((site) => `«${site.title}»`).join(', ')}.
          </Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить?</Text>
        </>
      ),
      onOk() {
        return onDelete().then(() => {
          notification.success({
            message: `${getFileName(sites).accusative} успешно ${sites.length > 1 ? 'удалены' : 'удалён'}`,
          });
        });
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  return { showDeleteConfirm };
};
