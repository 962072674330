import { AppstoreFilled, ProfileFilled } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Input, Space, Typography } from 'antd';
import React from 'react';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledControlsWrapper = styled(Space)`
  line-height: 1;
`;

const StyledProfileFilled = styled(ProfileFilled)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? props.theme.colors.blue7 : props.theme.colors.gray7)};
  font-size: 24px;
`;

const StyledAppstoreFilled = styled(AppstoreFilled)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? props.theme.colors.blue7 : props.theme.colors.gray7)};
  font-size: 24px;
`;

export type MediaHeaderProps = {
  sort: string;
  view: MediaView;
  setSearch(value: string): void;
  setSort(value: string): void;
  setView(value: MediaView): void;
  openCreateFolderModal(): void;
  onUploadClick(): void;
};

export enum MediaView {
  Table,
  Card,
}

export const MediaHeader: React.FC<MediaHeaderProps> = ({
  // sort,
  view,
  setSearch,
  // setSort,
  setView,
  onUploadClick,
  openCreateFolderModal,
}) => {
  return (
    <StyledWrapper>
      <Typography.Text>Медиа</Typography.Text>
      <StyledControlsWrapper size="middle">
        <Input.Search
          placeholder="Поиск по файлам"
          allowClear
          enterButton
          onSearch={setSearch}
          style={{ width: 300 }}
        />
        {/*<Select value={sort} style={{ width: 240 }} onChange={setSort} defaultValue="name">*/}
        {/*  <Select.Option value="name">По названию</Select.Option>*/}
        {/*  <Select.Option value="date">По дате</Select.Option>*/}
        {/*  <Select.Option value="size">По размеру</Select.Option>*/}
        {/*  <Select.Option value="type">По типу файла</Select.Option>*/}
        {/*</Select>*/}
        <Button onClick={openCreateFolderModal}>Добавить папку</Button>
        <Button type="primary" onClick={onUploadClick}>
          Загрузить файлы
        </Button>
        <Space>
          <Button
            type="text"
            icon={<StyledProfileFilled selected={view === MediaView.Table} />}
            onClick={() => setView(MediaView.Table)}
          />
          <Button
            type="text"
            icon={<StyledAppstoreFilled selected={view === MediaView.Card} />}
            onClick={() => setView(MediaView.Card)}
          />
        </Space>
      </StyledControlsWrapper>
    </StyledWrapper>
  );
};
