import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const gridContentPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid', 'content'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const FeaturesIconsRowEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { gridContentData, linkData, imageData, richtextData } = React.useMemo(() => {
    const gridContentData = getPlaceholderedPath({
      node,
      commonPath: gridContentPath,
    });
    const linkData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 0, 'link'],
    });
    const imageData = getPlaceholderedPath({
      node,
      commonPath: [...linkData.path, 'content', 0, 'image'],
    });
    const richtextData = getPlaceholderedPath({
      node,
      commonPath: [...gridContentData.path, 1, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });

    return { gridContentData, linkData, imageData, richtextData };
  }, [node]);

  const disabled = gridContentData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>
      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridContentData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <Form.Item label="Ссылка" name={[...linkData.path, 'source']}>
        <Input disabled={disabled} />
      </Form.Item>
      <ImageNodeInputs imagePath={imageData.path} required disabled={disabled} />
      <RichtextFormItem label="Контент" contentFieldName={richtextData.path} disabled={disabled} />
    </Form>
  );
};
