import { ContentSitesV1Domain, ContentSitesV1DomainKind } from '@cms/volkswagen-widgets';
import { useDomains } from '@console/api';
import React from 'react';
import { useSitesContext } from 'src/pages/sites/contexts/sites-context';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useMainTabDomains() {
  const { currentSite } = useSitesContext();

  const [editDomain, setEditDomain] = React.useState<ContentSitesV1Domain | boolean>(false);

  const {
    models: { domainsResponse },
    meta: { isListLoading, isListFetching, isUpdateLoading, isRemoveLoading },
    operations: { updateDomain, removeDomain },
  } = useDomains({ enabled: true, site: currentSite?.name || '' });

  const domains = domainsResponse?.domains || [];

  const onChangeDomainKind = (domain: ContentSitesV1Domain, newKind: ContentSitesV1DomainKind) => {
    updateDomain({ name: domain.name, body: { ...domain, kind: newKind } });
  };

  const onRemoveDomain = (name: string) => {
    removeDomain(name);
  };

  return {
    editDomain,
    setEditDomain,
    domains,
    loading: isListLoading || isListFetching || isUpdateLoading || isRemoveLoading,
    onChangeDomainKind,
    onRemoveDomain,
  };
}
