import { BoldOutlined, ItalicOutlined, StrikethroughOutlined, UnderlineOutlined } from '@ant-design/icons';
import React from 'react';
import { StyledControl } from '../../../elements';
import { ConfigType, useConfigByStyles, useInline } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';

export type TextStyleInlineType = 'BOLD' | 'ITALIC' | 'STRIKETHROUGH' | 'UNDERLINE';

const INLINE_STYLES: StyleItem<TextStyleInlineType>[] = [
  {
    label: 'Bold',
    style: 'BOLD',
    icon: <BoldOutlined />,
    name: 'Полужирный',
  },
  {
    label: 'Italic',
    style: 'ITALIC',
    icon: <ItalicOutlined />,
    name: 'Курсив',
  },
  {
    label: 'Underline',
    style: 'UNDERLINE',
    icon: <UnderlineOutlined />,
    name: 'Подчёркнутый',
  },
  {
    label: 'Strikethrough',
    style: 'STRIKETHROUGH',
    icon: <StrikethroughOutlined />,
    name: 'Зачёркнутый',
  },
];

export const TextStyleInline: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleTextStyle, currentStyle] = useInline<TextStyleInlineType>(editorState, setEditorState);

  const types = useConfigByStyles(ConfigType.TextStyle, INLINE_STYLES);

  if (!types.length) {
    return null;
  }

  return (
    <>
      {types.map(({ label, style, name, icon }) => (
        <StyledControl key={label}>
          <ControlButton title={name} isActive={currentStyle.has(style)} value={style} onToggle={toggleTextStyle}>
            {icon || label}
          </ControlButton>
        </StyledControl>
      ))}
    </>
  );
});

TextStyleInline.displayName = 'TextStyleInline';
