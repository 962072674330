import { PhoneOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useCallback } from 'react';
import { DataType, useEntityControls, useEntity } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { ControlButton } from '../../control-button';
import { StyledDropdownControl, StyledDropdownMenu, StyledControl } from '../../../../elements';
import { PhoneEntityType, PhoneProps } from './types';

export * from './decorator';
export * from './types';

export const PhoneEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const phoneData = useEntityControls<PhoneProps[]>(DataType.Phone, []);

  const [addPhone] = useEntity<PhoneProps, PhoneEntityType>(editorState, setEditorState, 'TEXTPHONE');

  const handleChange = useCallback(
    (phone: string) => {
      addPhone(
        {
          phone,
        },
        phone,
      );
    },
    [addPhone],
  );

  const isExistPhones = Array.isArray(phoneData) && phoneData.length;

  return (
    <StyledControl>
      <Dropdown
        disabled={!isExistPhones}
        trigger={['click']}
        overlay={
          <StyledDropdownMenu>
            {isExistPhones &&
              phoneData.map(({ phone }) => (
                <Menu.Item key={phone}>
                  <ControlButton key={`phone-entity-${phone}`} title={phone} onToggle={handleChange} value={phone}>
                    {phone}
                  </ControlButton>
                </Menu.Item>
              ))}
          </StyledDropdownMenu>
        }
      >
        <StyledDropdownControl>
          <ControlButton title="Телефон" disabled={!isExistPhones}>
            <PhoneOutlined />
          </ControlButton>
        </StyledDropdownControl>
      </Dropdown>
    </StyledControl>
  );
});

PhoneEntity.displayName = 'PhoneEntity';
