import { blue, cyan, geekblue, gold, green, lime, magenta, purple, red, volcano, yellow } from '@ant-design/colors';
import { ColorName, ColorNumber, ThemeDefinition } from './definitions';

const mapAntColorsToObject = <C extends ColorName>(
  colors: string[],
  label: C,
): Record<`${C}${ColorNumber}`, string> => {
  return colors.reduce(
    (acc, val, i) => ({ ...acc, [`${label}${i + 1}`]: val }),
    {} as Record<`${C}${ColorNumber}`, string>,
  );
};

/**
 * Use colors from:
 * https://www.figma.com/file/Xp5YCNFYdEggIdFpKzO4G8/Ant-Design-System-for-Figma-2.0-(Light-Theme)?node-id=371%3A8955
 */
export const colorsTheme: ThemeDefinition['colors'] = {
  ...mapAntColorsToObject(red, 'red'),
  ...mapAntColorsToObject(volcano, 'volcano'),
  ...mapAntColorsToObject(gold, 'gold'),
  ...mapAntColorsToObject(yellow, 'yellow'),
  ...mapAntColorsToObject(lime, 'lime'),
  ...mapAntColorsToObject(green, 'green'),
  ...mapAntColorsToObject(cyan, 'cyan'),
  ...mapAntColorsToObject(blue, 'blue'),
  ...mapAntColorsToObject(geekblue, 'geekblue'),
  ...mapAntColorsToObject(purple, 'purple'),
  ...mapAntColorsToObject(magenta, 'magenta'),
  gray1: '#ffffff',
  gray2: '#fafafa',
  gray3: '#f5f5f5',
  gray4: '#f0f0f0',
  gray5: '#d9d9d9',
  gray6: '#bfbfbf',
  gray7: '#8c8c8c',
  gray8: '#595959',
  gray9: '#262626',
  gray10: '#000000',
  background1: '#f0f2f5',
  background2: '#001529',
  background3: '#000c17',
};
