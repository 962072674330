import { ContentVolkswagenV1Node, TextNode, TopMenuNode } from '@cms/volkswagen-widgets';

export const getInitialMenuLink = (): ContentVolkswagenV1Node =>
  TopMenuNode.Link.generators.node({
    source: '',
    content: [TextNode.generators.node({ content: '' })],
  });

export const getInitialMenuItem = (): ContentVolkswagenV1Node =>
  TopMenuNode.Item.generators.node({
    header: [TextNode.generators.node({ content: '' })],
    items: [],
    content: [],
  });

export const getInitialMainLink = (): ContentVolkswagenV1Node =>
  TopMenuNode.Link.generators.node({
    source: '',
    content: [TextNode.generators.node({ content: '' })],
    themes: [TopMenuNode.Link.themes.mainLink],
  });

export const getInitialAdditionalLink = (): ContentVolkswagenV1Node =>
  TopMenuNode.Link.generators.node({
    source: '',
    content: [TextNode.generators.node({ content: '' })],
    themes: [TopMenuNode.Link.themes.additionalLink],
  });
