import { mapNodesToComponents } from '@cms/volkswagen-widgets';
import * as Nodes from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import React from 'react';
import { RichTextEditorProps } from '@console/uikit';
import { TreePath } from '../../../../../../utils/content-path';
import { NodeType } from '../../../../../../utils/nodes';
import { RichTextInput } from '../richtext-input';

type MapNodesType = (
  nodes: Nodes.ContentVolkswagenV1Node | Nodes.ContentVolkswagenV1Node[] | undefined,
  path: TreePath,
  richTextProps: Partial<Omit<RichTextEditorProps, 'onChange'>>,
) => React.ReactNode;

export function getComponent(
  node: Nodes.ContentVolkswagenV1Node | undefined,
  index: number,
  mapRichTextNodesToComponents: MapNodesType,
  path: TreePath,
  richTextProps: Partial<Omit<RichTextEditorProps, 'onChange'>>,
): React.ReactNode {
  if (!node) {
    return null;
  }

  const {
    blueprint,
    divider,
    emphasis,
    heading,
    image,
    link,
    list,
    listItem,
    paragraph,
    placeholder,
    quoting,
    richtext,
    strike,
    strong,
    text,
    underline,
  } = node;

  const currentName = Object.keys(node).find((item) => item) as NodeType;
  const currentPath: TreePath = currentName ? [...path, currentName] : path;

  if (blueprint) {
    return mapRichTextNodesToComponents(blueprint.content, currentPath, richTextProps);
  }

  if (divider) {
    return <Nodes.DividerNode key={divider.id || index} {...divider} />;
  }

  if (emphasis) {
    return (
      <Nodes.EmphasisNode key={emphasis.id || index} {...emphasis}>
        {mapRichTextNodesToComponents(emphasis.content, currentPath, richTextProps)}
      </Nodes.EmphasisNode>
    );
  }

  if (heading) {
    return (
      <Nodes.HeadingNode key={heading.id || index} {...heading}>
        {mapRichTextNodesToComponents(heading.content, currentPath, richTextProps)}
      </Nodes.HeadingNode>
    );
  }

  if (image) {
    return <Nodes.ImageNode key={image.id || index} {...image} />;
  }

  if (link) {
    return (
      <Nodes.LinkNode key={link.id || index} {...link}>
        {mapRichTextNodesToComponents(link.content, currentPath, richTextProps)}
      </Nodes.LinkNode>
    );
  }

  if (list) {
    return (
      <Nodes.ListNode key={list.id || index} {...list}>
        {mapRichTextNodesToComponents(list.items, currentPath, richTextProps)}
      </Nodes.ListNode>
    );
  }

  if (listItem) {
    return (
      <Nodes.ListNode.Item key={listItem.id || index} {...listItem}>
        {mapRichTextNodesToComponents(listItem.content, currentPath, richTextProps)}
      </Nodes.ListNode.Item>
    );
  }

  if (paragraph) {
    return (
      <Nodes.ParagraphNode key={paragraph.id || index} {...paragraph}>
        {mapRichTextNodesToComponents(paragraph.content, currentPath, richTextProps)}
      </Nodes.ParagraphNode>
    );
  }

  if (placeholder) {
    if (richTextProps.disabled) {
      return mapNodesToComponents(placeholder.content);
    }

    return (
      <Form.Item
        key={[...currentPath, 'content', placeholder.id].toString()}
        noStyle
        name={[...currentPath, 'content']}
      >
        <RichTextInput disabled={false} {...richTextProps} />
      </Form.Item>
    );
  }

  if (quoting) {
    return (
      <Nodes.QuotingNode key={quoting.id || index} {...quoting}>
        {mapRichTextNodesToComponents(quoting.content, currentPath, richTextProps)}
      </Nodes.QuotingNode>
    );
  }

  if (richtext) {
    return (
      <Nodes.RichtextNode key={richtext.id || index} {...richtext}>
        {mapRichTextNodesToComponents(richtext.content, currentPath, richTextProps)}
      </Nodes.RichtextNode>
    );
  }

  if (strike) {
    return (
      <Nodes.StrikeNode key={strike.id || index} {...strike}>
        {mapRichTextNodesToComponents(strike.content, currentPath, richTextProps)}
      </Nodes.StrikeNode>
    );
  }

  if (strong) {
    return (
      <Nodes.StrongNode key={strong.id || index} {...strong}>
        {mapRichTextNodesToComponents(strong.content, currentPath, richTextProps)}
      </Nodes.StrongNode>
    );
  }

  if (text) {
    return (
      <Nodes.TextNode key={text.id || index} {...text}>
        {text.content}
      </Nodes.TextNode>
    );
  }

  if (underline) {
    return (
      <Nodes.UnderlineNode key={underline.id || index} {...underline}>
        {mapRichTextNodesToComponents(underline.content, currentPath, richTextProps)}
      </Nodes.UnderlineNode>
    );
  }

  return null;
}

export const mapRichTextNodesToComponents: MapNodesType = (nodes, path, richTextProps) => {
  if (Array.isArray(nodes)) {
    return nodes.map((item, index) =>
      getComponent(item, index, mapRichTextNodesToComponents, [...path, 'content', index], richTextProps),
    );
  }

  return getComponent(nodes, 0, mapRichTextNodesToComponents, path, richTextProps);
};
