import { ActivityV1Status, ContentVolkswagenV1Widget, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import React from 'react';
import { useFilesContext } from '../../contexts';
import { EditSnippetFormValues, EditSnippetModal } from './components/edit-snippet-modal';

export type EditSnippetModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const EditSnippetModalContainer: React.FC<EditSnippetModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<EditSnippetFormValues>();

  const {
    breadcrumbs: { path },
    editSnippet,
    service: {
      meta: { isCreateLoading, isUpdateLoading },
      operations: { createFileAsync },
    },
  } = useFilesContext();

  React.useEffect(() => {
    if (open) {
      if (typeof editSnippet === 'boolean') {
        const initContent: ContentVolkswagenV1Widget = {
          placeholder: {
            attributes: [],
            content: [],
            visibility: { status: VisibilityV1Status.Private },
          },
        };

        form.resetFields();
        form.setFieldsValue({
          content: JSON.stringify(initContent),
        });
      } else {
        form.setFieldsValue({
          title: editSnippet.title,
        });
      }
    }
  }, [open, editSnippet, form]);

  const snippet = typeof editSnippet === 'boolean' ? null : editSnippet;

  const handleSubmit = (values: EditSnippetFormValues) => {
    (snippet
      ? Promise.reject()
      : createFileAsync({
          volkswagenV1Snippet: {
            path: path ? `/${path}/${values.name}` : `/${values.name}`,
            title: values.title,
            activity: { status: ActivityV1Status.Active },
            visibility: { status: VisibilityV1Status.Public },
            content: [JSON.parse(values.content)],
          },
        })
    )
      .then(() => {
        onClose();
      })
      .catch(() => null);
  };

  return (
    <EditSnippetModal
      open={open}
      loading={isCreateLoading || isUpdateLoading}
      form={form}
      isCreate={!snippet}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
