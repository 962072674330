import { d6 } from '@cms/volkswagen-widgets';
import { Select } from 'antd';
import React from 'react';
import { styled } from '@console/uikit';

const StyledOptionBox = styled.span`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 5px;
  }
`;

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type IconSelectProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled: boolean;
  isRequired?: boolean;
};

const d6Icons = Object.keys(d6.Icons.iconMap) as Array<keyof typeof d6.Icons.iconMap>;

export const IconSelect: React.FC<IconSelectProps> = ({ value, onChange, disabled, isRequired }) => (
  <Select value={value} disabled={disabled} onChange={onChange}>
    {!isRequired && <Select.Option value="">Без иконки</Select.Option>}
    {d6Icons.map((icon) => {
      const Icon = d6.Icons[icon];

      if (!Icon) return null;

      return (
        <Select.Option key={icon} value={icon}>
          <StyledOptionBox>
            <Icon />
            {icon}
          </StyledOptionBox>
        </Select.Option>
      );
    })}
  </Select>
);
