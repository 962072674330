import { Tooltip } from 'antd';
import React, { PropsWithChildren, useCallback } from 'react';
import styled from 'styled-components';

const StyledButtonContent = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
}>`
  padding: 5px 12px;
  ${(props) => props.isActive && 'color: #1890ff;'}
  ${(props) =>
    props.disabled &&
    `
        color: gray;
        cursor: default;
    `}
`;

const StyledControlButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export interface ControlButtonProps<T> {
  title?: string;
  isActive?: boolean;
  disabled?: boolean;
  onToggle?(value: T | undefined): void;
  value?: T;
}

export const ControlButton = React.memo(
  <T,>({ children, isActive = false, onToggle, value, disabled, title }: PropsWithChildren<ControlButtonProps<T>>) => {
    const handleMouseDown = useCallback(
      (e) => {
        e.preventDefault();
        if (onToggle) {
          onToggle(value);
        }
      },
      [value, onToggle],
    );

    return (
      <Tooltip placement="top" title={title}>
        <StyledControlButton>
          <StyledButtonContent
            onMouseDown={!disabled ? handleMouseDown : undefined}
            isActive={isActive}
            disabled={disabled}
          >
            {children}
          </StyledButtonContent>
        </StyledControlButton>
      </Tooltip>
    );
  },
);

ControlButton.displayName = 'ControlButton';
