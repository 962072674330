import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';

const commonGridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

export const QuoteEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { gridPath, richTextPath, signPath } = React.useMemo(() => {
    const gridPath = getPlaceholderedPath({
      node,
      commonPath: commonGridPath,
    });

    const richTextPath = getPlaceholderedPath({
      node,
      commonPath: [
        ...gridPath.path,
        'content',
        1,
        'flex',
        'content',
        0,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });

    const signPath = getPlaceholderedPath({
      node,
      commonPath: [
        ...gridPath.path,
        'content',
        1,
        'flex',
        'content',
        1,
        'grid',
        'content',
        1,
        'flex',
        'content',
        0,
        'blueprint',
        'content',
        0,
        'richtext',
      ],
    });

    return {
      gridPath,
      richTextPath,
      signPath,
    };
  }, [node]);

  const disabled = gridPath.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={gridPath.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <RichtextFormItem label="Подпись" contentFieldName={richTextPath.path} disabled={disabled} />

      <RichtextFormItem label="Автор" contentFieldName={signPath.path} disabled={disabled} />
    </Form>
  );
};
