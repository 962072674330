import { VisibilityV1Status, ActivityV1Status } from '@cms/volkswagen-widgets';
import { Form, Input, Typography } from 'antd';
import React from 'react';
import { StatusArea } from '../../../components';
import { MetaInput } from './meta-input';

export type MainSettingsTabContentProps = {
  isPageMode: boolean;
  isEmptyContent: boolean;
};

export const MainSettingsTabContent: React.FC<MainSettingsTabContentProps> = ({ isPageMode, isEmptyContent }) => (
  <>
    <Typography.Title level={4}>Основная информация</Typography.Title>
    <Form.Item name="title" label="Заголовок" wrapperCol={{ span: 8 }} rules={[{ required: true }]}>
      <Input placeholder="Введите заголовок" />
    </Form.Item>
    {isPageMode && (
      <Form.Item name="name" label="Имя">
        <Input placeholder="Введите имя" />
      </Form.Item>
    )}
    <Form.Item name="description" label="Описание">
      <Input.TextArea autoSize={true} placeholder="Введите описание" />
    </Form.Item>

    {isPageMode && (
      <>
        <Typography.Title level={4}>Статус</Typography.Title>
        <StatusArea
          name={['visibility']}
          checkboxProps={{
            truthyValue: VisibilityV1Status.Public,
            falsyValue: VisibilityV1Status.Private,
          }}
        >
          Видимость
        </StatusArea>
        <StatusArea
          name={['activity']}
          checkboxProps={{
            truthyValue: ActivityV1Status.Active,
            falsyValue: ActivityV1Status.Disabled,
          }}
        >
          Активность
        </StatusArea>
      </>
    )}
    <Form.Item name="attributes">
      <MetaInput />
    </Form.Item>
  </>
);
