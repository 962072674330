import { d6 } from '@cms/volkswagen-widgets';
import { VideoPlayerV2VideoProps } from '@cms/volkswagen-widgets/dist/d6';
import { Modal } from 'antd';
import React, { useMemo } from 'react';
import { BlockRoot } from '../../../pages/page-detail/components/block/block-root';
import { getLastPathParam } from '../../../utils/string';

const { VideoPlayerV2 } = d6;

type VideoModalProps = {
  open: boolean;
  src?: string;
  onClose(): void;
};

export const VideoPreviewModal: React.FC<VideoModalProps> = ({ open, onClose, src }) => {
  const VideoComponent = useMemo(() => {
    const Comp = React.memo<VideoPlayerV2VideoProps>(
      ({ innerRef, onPlay, onPause, onEnded, onTimeUpdate, onLoadedMetadata, onDurationChange, muted }) => {
        return (
          <video
            ref={innerRef}
            onPlay={onPlay}
            onPause={onPause}
            onEnded={onEnded}
            onTimeUpdate={onTimeUpdate}
            onLoadedMetadata={onLoadedMetadata}
            onDurationChange={onDurationChange}
            muted={muted}
            playsInline
            preload="none"
            controls={false}
            autoPlay
          >
            <source src={src} />
          </video>
        );
      },
    );
    Comp.displayName = 'VideoComponent';
    return Comp;
  }, [src]);

  return (
    <Modal
      title={`Видео ${getLastPathParam(src || '')}`}
      visible={open}
      onCancel={onClose}
      destroyOnClose
      footer={null}
    >
      <BlockRoot>
        <VideoPlayerV2 videoComponent={VideoComponent} showFullscreenBtn autoPlay showSoundControls />
      </BlockRoot>
    </Modal>
  );
};
