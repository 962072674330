import { SnippetPathByName } from '@cms/volkswagen-widgets';
import { NodeEditorProps } from '../types';
import { BreadcrumbsEditor } from './breadcrumbs-editor';
import { BundlesCarouselCarFragmentEditor } from './bundles-car-fragment-editor';
import { BundlesEditor } from './bundles-editor';
import { CarFragmentEditor } from './car-fragment-editor';
import { CarStockEditor } from './car-stock-editor';
import { CardEditor } from './card-editor';
import { ComparisonEditor } from './comparison-editor';
import { ContactInfoEditor } from './contact-info-editor';
import { ContactsEditor } from './contacts-editor';
import { ContactsFocusLayerEditor } from './contacts-focus-layer-editor';
import { ContactsInteractionLayerEditor } from './contacts-interaction-layer-editor';
import { ContactsPreviewEditor } from './contacts-preview';
import { ContactsTabEditor } from './contacts-tab-editor';
import { CopyAccordionEditor } from './copy-accordion-editor';
import { CopyColumnsEditor } from './copy-columns-editor';
import { DisclaimerLayerEditor } from './disclaimer-layer-editor';
import { EditorialStageEditor } from './editorial-stage-editor';
import { EnginesEditor } from './engines-editor';
import { EnginesTabEditor } from './engines-tab-editor';
import { FeatureClusterEditor } from './feature-cluster';
import { FeaturesIconsBannerEditor } from './features-icons-banner-editor';
import { FeaturesIconsEditor } from './features-icons-editor';
import { FeaturesIconsRowEditor } from './features-icons-row-editor';
import { FeedbackItemEditor } from './feedback-item-editor';
import { FeedbacksEditor } from './feedbacks-editor';
import { FooterBottomNavEditor } from './footer-bottom-nav-editor';
import { FooterColumnEditor } from './footer-column-editor';
import { FooterContactsEditor } from './footer-contacts-editor';
import { FooterEditor } from './footer-editor';
import { FooterSocialsEditor } from './footer-socials-editor';
import { FormEditor } from './form-editor';
import { FullscreenBannerEditor } from './fullscreen-banner-editor';
import { FullscreenStageEditor } from './fullscreen-stage-editor';
import { GalleryColumnEditor } from './gallery-column-editor';
import { GalleryEditor } from './gallery-editor';
import { GridIconsEditor } from './grid-icons-editor';
import { HighlightCarouselEditor } from './highlight-carousel-editor';
import { HighlightFeatureEditor } from './highlight-feature-editor';
import { IframeEditor } from './iframe-editor';
import { ImageEditor } from './image-editor';
import { ImageSnippetEditor } from './image-snippet-editor';
import { ModelsCarouselCarFragmentEditor } from './models-carousel-car-fragment-editor';
import { ModelsCarouselEditor } from './models-carousel-editor';
import { ModelsCarouselTabEditor } from './models-carousel-tab-editor';
import { ModelsEditor } from './models-editor';
import { ModelsTabEditor } from './models-tab-editor';
import { MoodGalleryEditor } from './mood-gallery-editor';
import { PresentationTeaserEditor } from './presentation-teaser-editor';
import { PromptEditor } from './prompt-editor';
import { PromptImageEditor } from './prompt-image-editor';
import { QuoteEditor } from './quote-editor';
import { RichtextEditor } from './richtext-editor';
import { ScriptEditor } from './script-editor';
import { SideMenuEditor } from './side-menu-editor';
import { SpecialSeriesEditor } from './special-series-editor';
import { TabLinksEditor } from './tab-links-editor';
import { TableEditor } from './table-editor';
import { TabsEditor } from './tabs-editor';
import { TeamEditor } from './team-editor';
import { TeamGridEditor } from './team-grid-editor';
import { TeamItemEditor } from './team-item-editor';
import { TeaserCustomEditor } from './teaser-custom-editor';
import { TeaserCustomSnippetEditor } from './teaser-custom-snippet-editor';
import { TeaserEditor } from './teaser-editor';
import { TeaserItemEditor } from './teaser-item-editor';
import { TextEditor } from './text-editor';
import { TopMenuEditor } from './top-menu-editor';
import { TopMenuPromoAreaEditor } from './top-menu-promo-area-editor';
import { VacanciesAccordionsEditor } from './vacancies-accordions-editor';
import { VacanciesEditor } from './vacancies-editor';
import { VacanciesGridEditor } from './vacancies-grid-editor';
import { VideoEditor } from './video-editor';
import { VideoSnippetEditor } from './video-snippet-editor';
/**
 * this object cannot be moved to `src\pages\page-detail\components\widget-editor-drawer.tsx`
 * due to circular dependency
 */
export const EditorBySnippetPathname: Record<string, React.ComponentType<NodeEditorProps>> = {
  [SnippetPathByName.BundlesCarFragment]: BundlesCarouselCarFragmentEditor,
  [SnippetPathByName.BundlesSection]: BundlesEditor,
  [SnippetPathByName.Card]: CardEditor,
  [SnippetPathByName.CarFragment]: CarFragmentEditor,
  [SnippetPathByName.CarStockCarouselSection]: CarStockEditor,
  [SnippetPathByName.CarStockSection]: CarStockEditor,
  [SnippetPathByName.ComparisonSection]: ComparisonEditor,
  [SnippetPathByName.ContactFocusLayer]: ContactsFocusLayerEditor,
  [SnippetPathByName.ContactInfo]: ContactInfoEditor,
  [SnippetPathByName.ContactsInteractionLayer]: ContactsInteractionLayerEditor,
  [SnippetPathByName.ContactsPreviewSection]: ContactsPreviewEditor,
  [SnippetPathByName.ContactsSection]: ContactsEditor,
  [SnippetPathByName.ContactsTab]: ContactsTabEditor,
  [SnippetPathByName.CopyAccordionSection]: CopyAccordionEditor,
  [SnippetPathByName.CopyColumnsSection]: CopyColumnsEditor,
  [SnippetPathByName.DisclaimerLayer]: DisclaimerLayerEditor,
  [SnippetPathByName.EditorialStageSection]: EditorialStageEditor,
  [SnippetPathByName.EnginesSection]: EnginesEditor,
  [SnippetPathByName.EnginesTab]: EnginesTabEditor,
  [SnippetPathByName.FeatureCluster]: FeatureClusterEditor,
  [SnippetPathByName.FeaturesIconsBannerSection]: FeaturesIconsBannerEditor,
  [SnippetPathByName.FeaturesIconsRow]: FeaturesIconsRowEditor,
  [SnippetPathByName.FeaturesIconsSection]: FeaturesIconsEditor,
  [SnippetPathByName.FeedbackItem]: FeedbackItemEditor,
  [SnippetPathByName.FeedbacksSection]: FeedbacksEditor,
  [SnippetPathByName.TeaserSection]: TeaserEditor,
  [SnippetPathByName.FormSection]: FormEditor,
  [SnippetPathByName.Footer]: FooterEditor,
  [SnippetPathByName.FooterBottomNav]: FooterBottomNavEditor,
  [SnippetPathByName.FooterColumn]: FooterColumnEditor,
  [SnippetPathByName.FooterContacts]: FooterContactsEditor,
  [SnippetPathByName.FooterSocials]: FooterSocialsEditor,
  [SnippetPathByName.FullscreenBanner]: FullscreenBannerEditor,
  [SnippetPathByName.FullscreenStageSection]: FullscreenStageEditor,
  [SnippetPathByName.GalleryColumnSection]: GalleryColumnEditor,
  [SnippetPathByName.GallerySection]: GalleryEditor,
  [SnippetPathByName.GridIconsSection]: GridIconsEditor,
  [SnippetPathByName.HighlightCarouselSection]: HighlightCarouselEditor,
  [SnippetPathByName.HighlightFeatureSection]: HighlightFeatureEditor,
  [SnippetPathByName.IframeSection]: IframeEditor,
  [SnippetPathByName.Image]: ImageSnippetEditor,
  [SnippetPathByName.ImageSection]: ImageEditor,
  [SnippetPathByName.ModelsCarouselCarFragment]: ModelsCarouselCarFragmentEditor,
  [SnippetPathByName.ModelsCarouselSection]: ModelsCarouselEditor,
  [SnippetPathByName.ModelsCarouselTab]: ModelsCarouselTabEditor,
  [SnippetPathByName.ModelsSection]: ModelsEditor,
  [SnippetPathByName.ModelsTab]: ModelsTabEditor,
  [SnippetPathByName.MoodGallerySection]: MoodGalleryEditor,
  [SnippetPathByName.PresentationTeaserSection]: PresentationTeaserEditor,
  [SnippetPathByName.PromptSection]: PromptEditor,
  [SnippetPathByName.PromptImageSection]: PromptImageEditor,
  [SnippetPathByName.Quote]: QuoteEditor,
  [SnippetPathByName.Richtext]: RichtextEditor,
  [SnippetPathByName.Script]: ScriptEditor,
  [SnippetPathByName.SideMenu]: SideMenuEditor,
  [SnippetPathByName.SpecialSeriesSection]: SpecialSeriesEditor,
  [SnippetPathByName.TableSection]: TableEditor,
  [SnippetPathByName.TabLinks]: TabLinksEditor,
  [SnippetPathByName.TabsSection]: TabsEditor,
  [SnippetPathByName.TeamSection]: TeamEditor,
  [SnippetPathByName.TeamGrid]: TeamGridEditor,
  [SnippetPathByName.TeamItem]: TeamItemEditor,
  [SnippetPathByName.TextSection]: TextEditor,
  [SnippetPathByName.TopMenu]: TopMenuEditor,
  [SnippetPathByName.TopMenuPromoArea]: TopMenuPromoAreaEditor,
  [SnippetPathByName.VacanciesAccordions]: VacanciesAccordionsEditor,
  [SnippetPathByName.VacanciesGrid]: VacanciesGridEditor,
  [SnippetPathByName.VacanciesSection]: VacanciesEditor,
  [SnippetPathByName.Video]: VideoSnippetEditor,
  [SnippetPathByName.VideoSection]: VideoEditor,
  [SnippetPathByName.TeaserItem]: TeaserItemEditor,
  [SnippetPathByName.TeaserCustom]: TeaserCustomSnippetEditor,
  [SnippetPathByName.TeaserCustomSection]: TeaserCustomEditor,
  [SnippetPathByName.Breadcrumbs]: BreadcrumbsEditor,
};
