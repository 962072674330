import {
  ContentBlock,
  DraftEntityMutability,
  DraftEntityType,
  DraftInlineStyle,
  EditorState,
  EntityInstance,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import Immutable from 'immutable';
import { useCallback, useMemo } from 'react';
import {
  getExistEntityByType,
  getSelectedBlocks,
  getSelectedEntityKey,
  removeInlineStyles,
  toggleInlineStyle,
} from '../utils';

/**
 *
 * inlineList - при передаче этого параметра toggleStyle работает, как radio buttons
 * То есть при клике на контрол стили из inlineList будут удалены
 * И применится тот стиль на котором был клик
 */
export const useInline = <T extends string>(
  editorState: EditorState,
  setEditorState: (state: EditorState | ((prevState: EditorState) => EditorState)) => void,
  inlineList?: T[],
): [(style: T) => void, DraftInlineStyle] => {
  const toggleStyle = useCallback(
    (toggledStyle: T) => {
      setEditorState((prevState) => {
        const removedStylesEditorState =
          inlineList && inlineList.length
            ? removeInlineStyles<T>(
                inlineList.filter((inlineStyle) => inlineStyle !== toggledStyle),
                prevState,
              )
            : prevState;
        return toggledStyle && toggledStyle.length
          ? toggleInlineStyle(toggledStyle, removedStylesEditorState)
          : prevState;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorState],
  );

  const currentStyle = useMemo(() => editorState.getCurrentInlineStyle(), [editorState]);
  return [toggleStyle, currentStyle];
};

export const useBlock = <T extends string>(
  editorState: EditorState,
  setEditorState: (state: EditorState | ((prevState: EditorState) => EditorState)) => void,
): [(blockType: T) => void, T] => {
  const handleChange = useCallback(
    (blockStyle: T) => {
      setEditorState((prevState) => RichUtils.toggleBlockType(prevState, blockStyle));
    },
    [setEditorState],
  );
  const selection = editorState.getSelection();
  const blockType = useMemo(
    () => editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType(),
    [editorState, selection],
  );
  return [handleChange, blockType as T];
};

export const useBlockMeta = <T extends Record<string, unknown> | string>(
  editorState: EditorState,
  setEditorState: (state: EditorState | ((prevState: EditorState) => EditorState)) => void,
  dataKey?: string,
): [(value: T) => void, Immutable.Map<string, T> | T] => {
  const selection = editorState.getSelection();
  const contentBlock = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const data = dataKey ? contentBlock.getData().get(dataKey) : contentBlock.getData();

  const changeData = useCallback(
    (data: T) => {
      setEditorState((prevState) => {
        const selection = prevState.getSelection();
        const currentContent = prevState.getCurrentContent();
        const currentData = currentContent.getBlockForKey(selection.getStartKey()).getData();

        return EditorState.push(
          prevState,
          Modifier.mergeBlockData(
            currentContent,
            selection,
            Immutable.Map(
              dataKey
                ? {
                    [dataKey]: currentData.get(dataKey) !== data ? data : '',
                  }
                : data,
            ),
          ),
          'change-block-data',
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorState],
  );

  return [changeData, data];
};

export const useEntity = <
  T extends Record<string, unknown> = Record<string, unknown>,
  E extends DraftEntityType = DraftEntityType,
>(
  editorState: EditorState,
  setEditorState: (state: EditorState | ((prevState: EditorState) => EditorState)) => void,
  entityType: E,
  mutability: DraftEntityMutability = 'MUTABLE',
): [(data?: T, insertContent?: string) => void, () => void, T | undefined, boolean, string] => {
  const selectedEntityKey = useMemo((): string => {
    return getSelectedEntityKey(editorState);
  }, [editorState]);

  const existEntity = useMemo<EntityInstance | undefined>(() => {
    return getExistEntityByType(editorState, selectedEntityKey, entityType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, selectedEntityKey]);

  const existEntityData = useMemo<T | undefined>(() => {
    return existEntity ? existEntity.getData() : undefined;
  }, [existEntity]);

  const isSelectedEntity = useMemo<boolean>(() => {
    const selection = editorState.getSelection();

    return !selection.isCollapsed();
  }, [editorState]);

  const selectedText = useMemo<string>(() => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
  }, [editorState]);

  const addEntity = useCallback(
    (data: Partial<T> = {}, insertContent?: string) => {
      setEditorState((prevState) => {
        // TODO Можно сделать forceSelection над entity
        //  чтобы без выделения просто находясь на entity можно было редактировать
        const contentState = prevState.getCurrentContent();
        const selection = prevState.getSelection();
        const contentStateWithEntity = contentState.createEntity(entityType, mutability, data);

        const createdEntityKey = contentStateWithEntity.getLastCreatedEntityKey();

        let newEditorState = prevState;
        // если выделен какой-то текст
        if (!selection.isCollapsed()) {
          // применение entity к тексту
          const newContentState = Modifier.applyEntity(contentState, selection, createdEntityKey);

          newEditorState = EditorState.push(prevState, newContentState, 'apply-entity');
        } else {
          // вставка entity после курсора
          newEditorState = prevState;
          if (typeof insertContent === 'string') {
            const newContentState = Modifier.insertText(
              contentState,
              selection,
              insertContent,
              undefined,
              createdEntityKey,
            );
            newEditorState = EditorState.push(newEditorState, newContentState, 'insert-characters');
          }
        }
        return newEditorState;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorState],
  );

  const editEntity = useCallback(
    (data: Partial<T> = {}) => {
      setEditorState((prevState) => {
        const contentState = prevState.getCurrentContent();
        const editSelectedEntityKey = getSelectedEntityKey(prevState);
        const editExistEntity = getExistEntityByType(prevState, editSelectedEntityKey, entityType);

        const contentStateWithAppliedEntity = contentState.mergeEntityData(editSelectedEntityKey, {
          ...editExistEntity?.getData(),
          ...data,
        });

        return EditorState.push(prevState, contentStateWithAppliedEntity, 'apply-entity');
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorState],
  );

  const removeEntity = useCallback(() => {
    setEditorState((prevState) => {
      const removeSelectedEntityKey = getSelectedEntityKey(prevState);
      const removeExistEntity = getExistEntityByType(prevState, removeSelectedEntityKey, entityType);
      if (removeExistEntity) {
        const currentContentState = prevState.getCurrentContent();

        const newContentState = Modifier.applyEntity(currentContentState, prevState.getSelection(), null);
        return EditorState.push(prevState, newContentState, 'apply-entity');
      }
      return prevState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEditorState]);

  const changeEntity = useCallback(
    (data?: T, insertEntity?: string) => {
      if (existEntity) {
        editEntity(data);
      } else {
        addEntity(data, insertEntity);
      }
    },
    [editEntity, addEntity, existEntity],
  );

  return [changeEntity, removeEntity, existEntityData, isSelectedEntity, selectedText];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMixedInlineWithBlockData = <T extends string>(
  editorState: EditorState,
  setEditorState: (state: EditorState | ((prevState: EditorState) => EditorState)) => void,
  type: string,
) => {
  const toggle = useCallback(
    (style: T /*data: Partial<T> = {}*/) => {
      setEditorState((editorState) => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
          const start = selection.getStartOffset();
          const end = selection.getEndOffset();
          const startKey = selection.getStartKey();
          const endKey = selection.getEndKey();

          const selectedBlocks = getSelectedBlocks(editorState);

          const blocks = selectedBlocks.reduce<{
            inlineBlocks: ContentBlock[];
            fullBlocks: ContentBlock[];
          }>(
            (result, block) => {
              const isStartBlockSelected =
                startKey === block.getKey() && start === 0 && (block.getKey() !== endKey || end === block.getLength());
              const isBetweenBlockSelected = block.getKey() !== startKey && block.getKey() !== endKey;
              const isEndBlockSelected =
                block.getKey() !== startKey && block.getKey() === endKey && block.getLength() === end;
              if (isStartBlockSelected || isBetweenBlockSelected || isEndBlockSelected) {
                return {
                  ...result,
                  fullBlocks: [...result.fullBlocks, block],
                };
              }

              return {
                ...result,
                inlineBlocks: [...result.inlineBlocks, block],
              };
            },
            {
              fullBlocks: [],
              inlineBlocks: [],
            },
          );

          let newEditorState = editorState;

          if (blocks.inlineBlocks.length) {
            const startBlock = blocks.inlineBlocks[0];
            const endBlock = blocks.inlineBlocks[blocks.inlineBlocks.length - 1];
            const isOnlyInlineSelected = !blocks.fullBlocks.length;
            if (isOnlyInlineSelected) {
              newEditorState = toggleInlineStyle(`${type}-${style}`, editorState);
            } else {
              if (startBlock.getKey() === startKey) {
                const newSelection = new SelectionState({
                  anchorKey: startBlock.getKey(),
                  anchorOffset: start,

                  focusKey: startBlock.getKey(),
                  focusOffset: startBlock.getLength(),
                });

                newEditorState = EditorState.push(
                  newEditorState,
                  Modifier.applyInlineStyle(newEditorState.getCurrentContent(), newSelection, `${type}-${style}`),
                  'change-inline-style',
                );
              }
              if (endBlock.getKey() === endKey) {
                const newSelection = new SelectionState({
                  anchorKey: endBlock.getKey(),
                  anchorOffset: 0,

                  focusKey: endBlock.getKey(),
                  focusOffset: end,
                });

                newEditorState = EditorState.push(
                  newEditorState,
                  Modifier.applyInlineStyle(newEditorState.getCurrentContent(), newSelection, `${type}-${style}`),
                  'change-inline-style',
                );

                // return newEditorState;
              }
            }
          }

          if (blocks.fullBlocks.length) {
            const startBlock = blocks.fullBlocks[0];
            const endBlock = blocks.fullBlocks[blocks.fullBlocks.length - 1];
            const newSelection = new SelectionState({
              anchorKey: startBlock.getKey(),
              anchorOffset: 0,

              focusKey: endBlock.getKey(),
              focusOffset: endBlock.getLength(),
            });

            const currentData = newEditorState.getCurrentContent().getBlockForKey(newSelection.getStartKey()).getData();

            if (Boolean(currentData.get(type))) {
              const newData = currentData.toJS();
              delete newData[type];
              newEditorState = EditorState.push(
                newEditorState,
                Modifier.setBlockData(newEditorState.getCurrentContent(), newSelection, Immutable.Map(newData)),
                'change-block-data',
              );
            } else {
              newEditorState = EditorState.push(
                newEditorState,
                Modifier.mergeBlockData(
                  newEditorState.getCurrentContent(),
                  newSelection,
                  Immutable.Map({
                    [type]: style,
                  }),
                ),
                'change-block-data',
              );
            }
          }

          return EditorState.acceptSelection(newEditorState, selection);
        }
        return editorState;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditorState],
  );

  const toggleBlockPlaceholder = useCallback(
    (style: string) => {
      setEditorState((prevState) => {
        const selection = prevState.getSelection();
        const currentContent = prevState.getCurrentContent();
        const currentData = currentContent.getBlockForKey(selection.getStartKey()).getData();

        const newData = currentData.toJS();
        if (currentData.has(type)) {
          delete newData[type];
        } else {
          newData[type] = style;
        }

        return EditorState.push(
          prevState,
          Modifier.setBlockData(currentContent, selection, Immutable.Map(newData)),
          'change-block-data',
        );
      });
    },
    [setEditorState, type],
  );

  const block = useMemo(
    () => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()),
    [editorState],
  );

  const currentStyle = useMemo(() => editorState.getCurrentInlineStyle(), [editorState]);

  const isSelectedBlock = useMemo<boolean>(() => {
    const selection = editorState.getSelection();

    return !selection.isCollapsed();
  }, [editorState]);

  return [toggle, block, currentStyle, toggleBlockPlaceholder, isSelectedBlock] as const;
};
