import { PlusOutlined } from '@ant-design/icons';
import { LinkNode, ParagraphNode, RichtextNode, TextNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Form, FormProps, Input, FormInstance, Row, Col } from 'antd';
import { FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { FormListItemContainer } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const flexPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'flex'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const emptyNavTemplate = RichtextNode.generators.node({
  content: [
    ParagraphNode.generators.node({
      content: [
        LinkNode.generators.node({
          source: '',
          attributes: [
            {
              name: LinkNode.attributes.emphasis,
              values: ['none'],
            },
          ],
          content: [
            TextNode.generators.node({
              attributes: [
                {
                  name: TextNode.attributes.appearance,
                  values: ['copy150'],
                },
                {
                  name: TextNode.attributes.color,
                  values: ['#001e50'],
                },
              ],
              content: '',
            }),
          ],
        }),
      ],
    }),
  ],
});

const dividerTempate = ParagraphNode.generators.node({
  content: [
    TextNode.generators.node({
      attributes: [
        {
          name: TextNode.attributes.appearance,
          values: ['copy150'],
        },
        {
          name: TextNode.attributes.color,
          values: ['#001e50'],
        },
      ],
      content: '|',
    }),
  ],
});

export const FooterBottomNavEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const handleAddNav =
    (
      setFields: FormInstance['setFields'],
      getFieldValue: FormInstance['getFieldValue'],
      add: FormListOperation['add'],
      index: number,
    ) =>
    () => {
      const prevPath = [...flexData.path, 'content', 0, 'flex', 'content', index, 'richtext', 'content'];

      if (index + 1 > 0) {
        const previousNav = getFieldValue(prevPath);

        setFields([
          {
            name: prevPath,
            value: [...previousNav, dividerTempate],
          },
        ]);
      }

      add(emptyNavTemplate);
    };

  const handleRemoveNav =
    (
      setFields: FormInstance['setFields'],
      getFieldValue: FormInstance['getFieldValue'],
      remove: FormListOperation['remove'],
      index: number,
      fieldsLength: number,
    ) =>
    () => {
      const prevPath = [...flexData.path, 'content', 0, 'flex', 'content', index - 1, 'richtext', 'content'];

      if (index === fieldsLength - 1 && index !== 0) {
        const previousNav = getFieldValue(prevPath);

        setFields([
          {
            name: prevPath,
            value: previousNav.slice(0, 1),
          },
        ]);
      }

      remove(index);
    };

  const { flexData } = React.useMemo(() => {
    const flexData = getPlaceholderedPath({
      node,
      commonPath: flexPath,
    });

    return { flexData };
  }, [node]);

  const disabled = flexData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={flexData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[...flexData.path, 'content', 0, 'flex', 'content']}>
        {(fields, { add, remove }) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFields }) => (
              <>
                {fields.map((field, index) => {
                  const linkPath = ['richtext', 'content', 0, 'paragraph', 'content', 0, 'link'];

                  const textPath = [...linkPath, 'content', 0, 'text', 'content'];

                  return (
                    <FormListItemContainer
                      disabled={disabled}
                      onDelete={handleRemoveNav(setFields, getFieldValue, remove, index, fields.length)}
                      key={field.key}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Текст"
                            name={[field.name, ...textPath]}
                            fieldKey={[field.fieldKey, ...textPath]}
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <Input disabled={disabled} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Ссылка"
                            name={[field.name, ...linkPath, 'source']}
                            fieldKey={[field.fieldKey, ...linkPath, 'source']}
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            <Input disabled={disabled} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </FormListItemContainer>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    disabled={disabled}
                    onClick={handleAddNav(setFields, getFieldValue, add, fields.length - 1)}
                    icon={<PlusOutlined />}
                    block
                  >
                    Добавить ссылку
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.Item>
        )}
      </Form.List>

      <Form.Item
        label="Копирайт"
        name={[
          ...flexData.path,
          'content',
          1,
          'flex',
          'content',
          0,
          'richtext',
          'content',
          0,
          'paragraph',
          'content',
          0,
          'text',
          'content',
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </Form>
  );
};
