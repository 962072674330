import { Alert } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, SnippetItemBlock } from '../../../../custom-inputs';

export type QuoteGalleryItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

export const GalleryColumnItem: React.FC<QuoteGalleryItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
  allowedSnippets,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...contentPath, index, 'galleryItem', 'content', 0, 'box', 'content']}
      allowedSnippets={allowedSnippets}
    >
      <Alert message={`Элемент №${index + 1}`} type="info" style={{ marginBottom: 12 }} />
    </SnippetItemBlock>
  );
};
