import React from 'react';
import { SitesControl } from 'src/pages/sites/components/sites-control';
import { StyledTabHeaderWrapper } from '../../../components/elements';

export const RobotsTabHeader: React.FC = () => {
  return (
    <StyledTabHeaderWrapper>
      <SitesControl />
    </StyledTabHeaderWrapper>
  );
};
