import { Button, Input, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { FromMedia, FromMediaProps } from './components/from-media';

const { TabPane } = Tabs;

export type MediaModalContainerProps = {
  open: boolean;
  path?: string;
  url?: string;
  mediaType?: FromMediaProps['mediaType'];
  onClose(): void;
  onSave(path: string): void;
};

enum Tab {
  media = 'media',
  url = 'url',
}

export const MediaModalContainer: React.FC<MediaModalContainerProps> = ({
  open,
  url,
  path,
  onClose,
  onSave,
  mediaType,
}) => {
  const [tabKey, setTabKey] = useState(url ? Tab.url : Tab.media);
  const [src, setSrc] = useState(url || '');

  const handleSave = (path: string) => {
    onSave(path);
    onClose();
  };

  const handleTabsChange = (key: string) => {
    setTabKey(key as Tab);
  };

  return (
    <Modal
      title={`Импорт медиа`}
      visible={open}
      onCancel={onClose}
      destroyOnClose
      width="80vw"
      footer={
        tabKey === Tab.url
          ? [
              <Button key="submit" type="primary" disabled={!src} onClick={() => handleSave(src)}>
                Сохранить
              </Button>,
            ]
          : []
      }
    >
      <Tabs activeKey={tabKey} onChange={handleTabsChange}>
        <TabPane tab="Из медиа" key={Tab.media}>
          <FromMedia path={path} onSave={handleSave} mediaType={mediaType} />
        </TabPane>
        <TabPane tab="По ссылке" key={Tab.url}>
          <Input value={src} onChange={(e) => setSrc(e.target.value)} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
