import React, { PropsWithChildren } from 'react';
import { Checkbox, CheckboxProps } from 'antd';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type CustomCheckboxProps<T> = {
  value?: T;
  onChange?: (value: T) => void;
  check: (value?: T) => boolean;
  checkedValue: T;
  uncheckedValue: T;
  label: string;
  disabled: boolean;
};

export const CustomCheckbox = <T,>({
  value,
  onChange,
  check,
  checkedValue,
  uncheckedValue,
  label,
  disabled,
}: PropsWithChildren<CustomCheckboxProps<T>>): React.ReactElement => {
  const isChecked = check(value);

  const handleChange: CheckboxProps['onChange'] = (event) => {
    onChange && onChange(event.target.checked ? checkedValue : uncheckedValue);
  };

  return (
    <Checkbox checked={isChecked} onChange={handleChange} disabled={disabled}>
      {label}
    </Checkbox>
  );
};
