import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  isAuth: boolean;
  redirectPath?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = React.memo(({ isAuth, children, redirectPath, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuth ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: redirectPath || '/login',
            state: { from: location },
          }}
        />
      )
    }
  />
));

PrivateRoute.displayName = 'PrivateRoute';
