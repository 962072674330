import {
  ContentSitesV1Redirect,
  ContentSitesV1RedirectProps,
  ContentSitesV1RedirectsAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentSitesV1Redirect>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = ContentSitesV1RedirectProps;

export type UseCreateRedirectData = {
  createRedirect: UseMutateFunction<Data, Error, Variables>;
  createRedirectAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateRedirect(
  redirectsApi: ContentSitesV1RedirectsAPIApi,
  queryKey: QueryKey,
  site: string,
): UseCreateRedirectData {
  const queryClient = useQueryClient();

  const {
    mutate: createRedirect,
    mutateAsync: createRedirectAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>(
    (body) =>
      redirectsApi.contentSitesV1RedirectsAPICreateRedirect(
        site, // siteName: string,
        body, // body: ContentSitesV1RedirectProps,
        // siteRevision?: string,
        // options?: any
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: (error) => {
        notification.error({
          message: 'Ошибка создания редиректа',
          description: error?.response?.data.message,
        });
      },
    },
  );

  return { createRedirect, createRedirectAsync, isCreateLoading };
}
