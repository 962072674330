import { AttributesV1Attribute } from '@cms/volkswagen-widgets';
import { AutoComplete, AutoCompleteProps, Form, FormInstance } from 'antd';
import React from 'react';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type AttributeAutocompleteProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  attributeName: string;
  valueName?: (number | string)[];
  disabled: boolean;
  options: AutoCompleteProps['options'];
};

/**
 * Input looks for an attribute by name. Creates it when it is not null value.
 * Removes attribute if it is null value. Input works only with first attribute string value.
 * @example [{ name: 'attributeName', values: ['not null value'] }]
 */
export const AttributeAutocomplete: React.FC<AttributeAutocompleteProps> = ({
  value = [],
  onChange,
  attributeName,
  valueName,
  disabled,
  options,
}) => {
  const currentValue = value.find(({ name }) => name === attributeName)?.values?.[0] ?? '';

  const handleChange: (setFields: FormInstance['setFields']) => AutoCompleteProps['onChange'] =
    (setFields) => (strValue) => {
      const newValue = value.filter(({ name }) => name !== attributeName);

      onChange && onChange([...newValue, ...(strValue ? [{ name: attributeName, values: [strValue] }] : [])]);

      if (valueName) {
        setFields([
          {
            name: valueName,
            value: options?.find((opt) => opt.value === strValue)?.label,
          },
        ]);
      }
    };

  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFields }) => {
        return (
          <AutoComplete value={currentValue} onChange={handleChange(setFields)} disabled={disabled} options={options} />
        );
      }}
    </Form.Item>
  );
};
