import {
  nodeHasTheme,
  d6,
  ContentVolkswagenV1Heading,
  ContentVolkswagenV1Richtext,
  ContentVolkswagenV1Link,
  EmphasisNode,
  StrikeNode,
  UnderlineNode,
  StrongNode,
  HeadingNode,
  ParagraphNode,
  QuotingNode,
  TextNode,
  ListNode,
  LinkNode,
  ImageNode,
} from '@cms/volkswagen-widgets';
import { RawDraftContentBlock } from 'draft-js';
import { BlockType } from '../components/controls/blocks';
import { ALIGN_KEY } from '../components/controls/blocks/align-block';
import { BLOCKQUOTE_KEY } from '../components/controls/blocks/blockquote-block';
import { HeaderBlockType, headingMapToLevel } from '../components/controls/blocks/header-block';
import { THEME_KEY } from '../components/controls/blocks/theme-block';
import { RawEntityType } from '../components/controls/entities';
import { IconProps } from '../components/controls/entities/icon-entity';
import { PLACEHOLDER_KEY } from '../components/controls/entities/placeholder-entity';
import { InlineType } from '../components/controls/inline';
import { TextColorInlineType } from '../components/controls/inline/text-color-inline';
import { TextSizeInlineType } from '../components/controls/inline/text-size-inline';

import { generatePlaceholderNodeProps } from '../consts';
import { convertFrom } from './contract-converter/from';
import { convertTo } from './contract-converter/to';
import { ContractContent, FlowContent } from './contract-converter/types';
import {
  extractAttributesFromObject,
  extractNode,
  extractObjectFromAttributes,
  modifyTextNode,
} from './contract-converter/utils';
import { Converter } from './types';

const headingMapFromContract: { [key: number]: HeaderBlockType } = {
  1: HeaderBlockType.headerOne,
  2: HeaderBlockType.headerTwo,
  3: HeaderBlockType.headerThree,
  4: HeaderBlockType.headerFour,
  5: HeaderBlockType.headerFive,
};

const extractAttributesFromBlockData = <T extends RawDraftContentBlock = RawDraftContentBlock>(block: T) => {
  return block.data && typeof block.data === 'object'
    ? {
        ...extractAttributesFromObject({
          [ALIGN_KEY]: block.data?.align,
          [THEME_KEY]: block.data?.theme,
        }),
      }
    : {};
};

const wrapNodes = <T extends FlowContent = FlowContent>(
  block: RawDraftContentBlock,
  child: T,
): Pick<FlowContent, 'quoting' | 'placeholder'> | T => {
  let result: Pick<FlowContent, 'quoting' | 'placeholder'> | T = child;
  if (block.data?.blockquote) {
    result = QuotingNode.generators.node({
      ...extractAttributesFromObject({
        variant: block.data?.blockquote,
      }),
      content: [child as FlowContent],
    });
  }

  if (block.data?.[`${PLACEHOLDER_KEY}`]) {
    result = {
      placeholder: {
        ...generatePlaceholderNodeProps(
          block.data?.[`${PLACEHOLDER_KEY}`] === 'new' ? undefined : block.data?.[`${PLACEHOLDER_KEY}`],
        ),
        content: [result as FlowContent],
      },
    };
  }

  return result;
};

export const extractChild = (node: ContractContent): { type: string; attr?: Record<string, string> | undefined } => {
  const [extractedNode, type] = extractNode(node);
  const attr = extractObjectFromAttributes(extractedNode?.attributes);

  let childType = 'unstyled';
  let childAttr = undefined;
  if (extractedNode && 'content' in extractedNode && Array.isArray(extractedNode.content) && extractedNode.content[0]) {
    const child = extractChild(extractedNode.content[0] as ContractContent);
    childType = child.type;
    childAttr = child.attr;
  }

  if (extractedNode && 'items' in extractedNode && Array.isArray(extractedNode.items) && extractedNode.items[0]) {
    const child = extractChild(extractedNode.items[0] as ContractContent);
    childType = child.type;
    childAttr = child.attr;
  }

  if (childType !== 'unstyled') {
    return {
      type: childType,
      attr: { ...attr, ...childAttr },
    };
  }

  if (type === 'heading') {
    return {
      type: headingMapFromContract[(extractedNode as ContentVolkswagenV1Heading)?.level],
      attr: { ...attr, ...childAttr },
    };
  }

  if (type === 'list') {
    return {
      type: attr?.ordered === 'true' ? 'ordered-list-item' : 'unordered-list-item',
      attr: { ...attr, ...childAttr },
    };
  }

  return {
    type: 'unstyled',
    attr: { ...attr, ...childAttr },
  };
};

export const contractConverter: Converter<ContentVolkswagenV1Richtext['content']> = {
  from(contract) {
    return convertFrom<InlineType, BlockType, RawEntityType>({
      separationBlocks(node) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, type] = extractNode(node);
        return type === 'placeholder' || type === 'list';
      },
      styleFrom(node) {
        const [extractedNode, type] = extractNode(node);
        const attr = extractObjectFromAttributes(extractedNode?.attributes);
        switch (type) {
          case 'strong':
            return {
              style: 'BOLD',
            };
          case 'emphasis':
            return {
              style: 'ITALIC',
            };
          case 'underline':
            return {
              style: 'UNDERLINE',
            };
          case 'strike':
            return {
              style: 'STRIKETHROUGH',
            };
          case 'placeholder':
            return {
              style: `placeholder-${extractedNode?.id}`,
            };
          case 'text': {
            const result = [];
            if (attr?.appearance) {
              result.push({
                style: attr.appearance as TextSizeInlineType,
              });
            }

            if (attr?.color) {
              result.push({
                style: attr.color as TextColorInlineType,
              });
            }

            return result;
          }
          default:
            return undefined;
        }
      },
      blockFrom(node) {
        const [extractedNode, type] = extractNode(node);
        const attr = extractObjectFromAttributes(extractedNode?.attributes);

        switch (type) {
          case 'paragraph': {
            return {
              type: 'unstyled',
              data: {
                [ALIGN_KEY]: attr?.align,
                [THEME_KEY]: attr?.theme,
              },
            };
          }
          case 'heading':
            return {
              type: headingMapFromContract[(extractedNode as ContentVolkswagenV1Heading).level],
              data: {
                [ALIGN_KEY]: attr?.align,
                [THEME_KEY]: attr?.theme,
              },
            };
          case 'list': {
            const child =
              extractedNode && 'items' in extractedNode && Array.isArray(extractedNode.items) && extractedNode.items[0];
            const { attr: childAttr } = child ? extractChild(child) : { attr: {} };

            return {
              type: attr?.ordered === 'true' ? 'ordered-list-item' : 'unordered-list-item',
              data: {
                [ALIGN_KEY]: childAttr?.align,
                [THEME_KEY]: attr?.theme,
              },
            };
          }
          case 'placeholder': {
            const child =
              extractedNode &&
              'content' in extractedNode &&
              Array.isArray(extractedNode.content) &&
              extractedNode.content[0];

            if (child) {
              const { type, attr: childAttr } = extractChild(child);

              return {
                type: type as BlockType,
                data: {
                  [ALIGN_KEY]: childAttr?.align,
                  [THEME_KEY]: childAttr?.theme,
                  [BLOCKQUOTE_KEY]: childAttr?.variant,
                  [PLACEHOLDER_KEY]: extractedNode?.id,
                },
              };
            }

            return {
              type: 'unstyled',
              data: {
                [PLACEHOLDER_KEY]: extractedNode?.id,
              },
            };
          }
          case 'quoting': {
            const child =
              extractedNode &&
              'content' in extractedNode &&
              Array.isArray(extractedNode.content) &&
              extractedNode.content[0];

            if (child) {
              const { type, attr: childAttr } = extractChild(child);

              return {
                type: type as BlockType,
                data: {
                  [ALIGN_KEY]: childAttr?.align,
                  [THEME_KEY]: childAttr?.theme,
                  [BLOCKQUOTE_KEY]: attr?.variant,
                },
              };
            }

            return undefined;
          }
          default:
            return undefined;
        }
      },
      entityFrom(node) {
        const [extractedNode, type] = extractNode(node);
        const attr = extractObjectFromAttributes(extractedNode?.attributes);

        switch (type) {
          case 'link': {
            const isDisclaimer = nodeHasTheme((extractedNode as ContentVolkswagenV1Link)?.themes || [], 'disclaimer');

            if (isDisclaimer) {
              return {
                type: 'DISCLAIMER',
                mutability: 'IMMUTABLE',
                data: {
                  href: (extractedNode as ContentVolkswagenV1Link)?.source,
                  index: attr?.index ? Number(attr?.index) : undefined,
                },
              };
            }

            return {
              type: 'LINK',
              mutability: 'MUTABLE',
              data: {
                tag: 'a',
                href: (extractedNode as ContentVolkswagenV1Link).source,
                target: (extractedNode as ContentVolkswagenV1Link).target as d6.LinkTarget,
                rel: (extractedNode as ContentVolkswagenV1Link).relation as d6.LinkRel,
                ...(attr && attr[LinkNode.attributes.size]
                  ? {
                      size: attr[LinkNode.attributes.size] as d6.CustomCTAProps['size'],
                    }
                  : {}),
                ...(attr && attr[LinkNode.attributes.iconEncoded]
                  ? {
                      iconEncoded: attr[LinkNode.attributes.iconEncoded] as d6.CustomCTAProps['iconEncoded'],
                    }
                  : {}),
                ...(attr && attr[LinkNode.attributes.iconKind]
                  ? {
                      iconKind: attr[LinkNode.attributes.iconKind] as d6.CustomCTAProps['iconKind'],
                    }
                  : {}),
                ...(attr && attr[LinkNode.attributes.iconVariant]
                  ? {
                      iconVariant: attr[LinkNode.attributes.iconVariant] as d6.CustomCTAProps['iconVariant'],
                    }
                  : {}),
                ...(attr && attr[LinkNode.attributes.iconPosition]
                  ? {
                      iconPosition: attr[LinkNode.attributes.iconPosition] as d6.CustomCTAProps['iconPosition'],
                    }
                  : {}),
                ...(attr && attr[LinkNode.attributes.iconFlow]
                  ? {
                      iconFlow: attr[LinkNode.attributes.iconFlow] as d6.CustomCTAProps['iconFlow'],
                    }
                  : {}),
                ...(attr && attr.emphasis
                  ? {
                      emphasis: attr.emphasis as d6.CustomCTAProps['emphasis'],
                    }
                  : {}),
              },
            };
          }

          case 'image':
            if (attr?.kind) {
              return {
                type: 'ICON',
                mutability: 'IMMUTABLE',
                data: {
                  kind: attr.kind,
                  variant: attr?.variant as IconProps['variant'],
                  color: attr?.color,
                  // encoded: attr?.encoded,
                },
              };
            }
            return undefined;
          default:
            return undefined;
        }
      },
    })(contract);
  },
  to(contentState) {
    return convertTo<InlineType, BlockType, RawEntityType>({
      plainTextTo(text: string) {
        return TextNode.generators.node({
          content: text,
        });
      },
      unionBlocks(node, prevNode) {
        if (node.placeholder && prevNode.placeholder) {
          return node.placeholder?.id === prevNode.placeholder?.id;
        }

        if (node.list && prevNode.list) {
          const attr = extractObjectFromAttributes(node.list.attributes);
          const prevAttr = extractObjectFromAttributes(prevNode.list.attributes);

          return Boolean(
            attr &&
              prevAttr &&
              ((attr.ordered === 'false' && prevAttr.ordered === 'false') ||
                (attr.ordered === 'true' && prevAttr.ordered === 'true')),
          );
        }

        return false;
      },
      styleToWrapper(style, children) {
        if (style.includes(PLACEHOLDER_KEY)) {
          const id = style.replace(`${PLACEHOLDER_KEY}-`, '');

          return {
            placeholder: {
              ...generatePlaceholderNodeProps(id === 'new' ? undefined : id),
              content: children,
            },
          };
        }
      },
      styleTo(style, child) {
        const colorList = Object.values<TextColorInlineType>(TextColorInlineType);
        const sizeList = Object.values<TextSizeInlineType>(TextSizeInlineType);

        if (sizeList.includes(style as TextSizeInlineType)) {
          return modifyTextNode([child], (child) => ({
            text: {
              ...child.text,
              attributes: [
                ...(child.text.attributes ? child.text.attributes : []),
                ...(extractAttributesFromObject({
                  appearance: style,
                }).attributes || []),
              ],
            },
          }))[0];
        }

        if (colorList.includes(style as TextColorInlineType)) {
          return modifyTextNode([child], (child) => ({
            text: {
              ...child.text,
              attributes: [
                ...(child.text.attributes ? child.text.attributes : []),
                ...(extractAttributesFromObject({
                  color: style,
                }).attributes || []),
              ],
            },
          }))[0];
        }

        switch (style) {
          case 'BOLD':
            return StrongNode.generators.node({
              content: [child],
            });
          case 'ITALIC': {
            return EmphasisNode.generators.node({
              content: [child],
            });
          }
          case 'STRIKETHROUGH':
            return StrikeNode.generators.node({
              content: [child],
            });
          case 'UNDERLINE':
            return UnderlineNode.generators.node({
              content: [child],
            });
          default:
            return child;
        }
      },
      blockTo(block, children) {
        switch (block.type) {
          case 'unstyled': {
            if (!children.length) {
              return wrapNodes(
                block,
                ParagraphNode.generators.node({
                  content: [
                    TextNode.generators.node({
                      content: '\n',
                    }),
                  ],
                }),
              );
            }

            return wrapNodes(
              block,
              ParagraphNode.generators.node({
                content: children,
                ...extractAttributesFromBlockData(block),
              }),
            );
          }
          case HeaderBlockType.headerOne:
          case HeaderBlockType.headerTwo:
          case HeaderBlockType.headerThree:
          case HeaderBlockType.headerFour:
          case HeaderBlockType.headerFive:
            return wrapNodes(
              block,
              HeadingNode.generators.node({
                content: children,
                level: headingMapToLevel[block.type],
                ...extractAttributesFromBlockData(block),
              }),
            );
          case 'ordered-list-item':
          case 'unordered-list-item': {
            return wrapNodes(
              block,
              ListNode.generators.node({
                ...extractAttributesFromObject({
                  ordered: String(block.type === 'ordered-list-item'),
                }),
                items: [
                  ListNode.Item.generators.node({
                    content: [
                      ParagraphNode.generators.node({
                        content: children,
                        ...extractAttributesFromBlockData(block),
                      }),
                    ],
                  }),
                ],
              }),
            );
          }
          default:
            return undefined;
        }
      },
      entityTo(entity, children) {
        switch (entity.type) {
          case 'LINK': {
            const {
              href: source,
              target,
              rel: relation,
              tag: _,
              ...rest
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } = entity.data as any;

            return LinkNode.generators.node({
              source,
              target,
              relation,
              ...extractAttributesFromObject(rest),
              content: children,
            });
          }
          case 'DISCLAIMER':
            return LinkNode.generators.node({
              source: entity.data.href || '',
              themes: ['disclaimer'],
              ...extractAttributesFromObject({
                index: String(entity.data.index),
              }),
              content: children,
            });
          case 'ICON':
            return ImageNode.generators.node({
              source: '',
              ...extractAttributesFromObject({
                kind: entity.data.kind,
                variant: entity.data.variant,
                color: entity.data.color,
              }),
            });
          default:
            return undefined;
        }
      },
    })(contentState);
  },
};
