import { Dropdown, Menu } from 'antd';
import React from 'react';
import { css } from 'styled-components';
import { ConfigType, useBlockMeta, useConfigByStyles } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';
import { StyledControl, StyledDropdownControl, StyledDropdownMenu } from '../../../elements';

export enum ThemeBlockType {
  main = 'main',
  inverted = 'inverted',
  image = 'image',
}
const THEME_TYPES: StyleItem<ThemeBlockType>[] = [
  { label: 'Основная', style: ThemeBlockType.main },
  { label: 'Инвертированная', style: ThemeBlockType.inverted },
  { label: 'Изображение', style: ThemeBlockType.image },
];

export const THEME_KEY = 'theme';

export const themeStyles = css`
  .${THEME_KEY}-${ThemeBlockType.main} {
    color: black;
  }

  .${THEME_KEY}-${ThemeBlockType.inverted} {
    color: white;
    background: #3f85ff54;
  }
`;

export const ThemeBlock: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleData, currentBlockData] = useBlockMeta<ThemeBlockType>(editorState, setEditorState, THEME_KEY);

  const types = useConfigByStyles(ConfigType.Blockquote, THEME_TYPES);

  if (!types.length) {
    return null;
  }

  const active = types.find((type) => type.style === currentBlockData);

  return (
    <StyledControl isDivider>
      <Dropdown
        trigger={['click']}
        overlay={
          <StyledDropdownMenu>
            {types.map(({ label, style: blockDataValue }) => (
              <Menu.Item key={label}>
                <ControlButton
                  isActive={currentBlockData === blockDataValue}
                  value={blockDataValue}
                  onToggle={toggleData}
                >
                  {label}
                </ControlButton>
              </Menu.Item>
            ))}
          </StyledDropdownMenu>
        }
      >
        <StyledDropdownControl>
          <ControlButton>Тема{active?.label ? `: ${active?.label}` : ''}</ControlButton>
        </StyledDropdownControl>
      </Dropdown>
    </StyledControl>
  );
});

ThemeBlock.displayName = 'ThemeBlock';
