import React from 'react';
import { DeleteOutlined, DownCircleOutlined, EditOutlined, RetweetOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { styled } from '@console/uikit';
import { TreePath } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import { SnippetsSelect, SnippetsSelectProps } from './snippets-select';

const ControlsWrapper = styled.div`
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  top: 8px;
  right: 8px;
`;

const Container = styled(EditorContainer)<{ disabled: boolean }>`
  transition: all 0.3s;

  &:hover {
    ${(props) => !props.disabled && `border-color: ${props.theme.colors.blue5}`}
  }

  &:hover > ${ControlsWrapper} {
    opacity: 1;
  }

  ${(props) =>
    props.disabled &&
    `
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.65);
        cursor: not-allowed;
    }`}
`;

type SnippetItemBlockProps = {
  /** путь до контента, если не передать, то редактирование и замена сниппета будет недоступна */
  contentPath?: TreePath;
  disabled: boolean;
  onDelete?: () => void;
  onMoveTop?: () => void;
  onMoveBottom?: () => void;
  allowedSnippets?: SnippetsSelectProps['allowedSnippets'];
  disallowedSnippets?: SnippetsSelectProps['disallowedSnippets'];
  isHiddenEditing?: boolean;
};

export const StyledWrapper = styled.div`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

export const SnippetItemBlock: React.FC<SnippetItemBlockProps> = ({
  children,
  disabled,
  contentPath,
  onDelete,
  onMoveBottom,
  onMoveTop,
  allowedSnippets,
  disallowedSnippets,
  isHiddenEditing,
}) => {
  return (
    <SnippetsSelect contentPath={contentPath} allowedSnippets={allowedSnippets} disallowedSnippets={disallowedSnippets}>
      {({ source, handleOpenEditDrawer, handleOpenReplaceDrawer }) => (
        <Container disabled={disabled} label={source}>
          <StyledWrapper>{children}</StyledWrapper>
          <ControlsWrapper>
            <Space>
              {onMoveTop && (
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={onMoveTop}
                  icon={<UpCircleOutlined />}
                  title="Переместить наверх"
                />
              )}
              {onMoveBottom && (
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={onMoveBottom}
                  icon={<DownCircleOutlined />}
                  title="Переместить вниз"
                />
              )}
              {onDelete && (
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                  title={`Удалить ${source || ''}`}
                />
              )}
              {!isHiddenEditing && handleOpenEditDrawer && (
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={handleOpenEditDrawer}
                  icon={<EditOutlined />}
                  title={`Редактировать ${source || ''}`}
                />
              )}
              {handleOpenReplaceDrawer && (
                <Button
                  size="small"
                  type="primary"
                  shape="circle"
                  onClick={handleOpenReplaceDrawer}
                  icon={<RetweetOutlined />}
                  title="Заменить"
                />
              )}
            </Space>
          </ControlsWrapper>
        </Container>
      )}
    </SnippetsSelect>
  );
};
