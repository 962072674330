import { ContentSitesV1DomainKind } from '@cms/volkswagen-widgets';
import { Button, Form, FormInstance, Input, Modal, Select } from 'antd';
import React from 'react';

export type EditDomainFormValues = {
  name: string;
  host: string;
  kind: ContentSitesV1DomainKind;
};

export type EditDomainModalProps = {
  open: boolean;
  loading: boolean;
  form: FormInstance<EditDomainFormValues>;
  initialValues?: EditDomainFormValues;
  onClose: () => void;
  onSubmit: (values: EditDomainFormValues) => void;
};

export const EditDomainModal: React.FC<EditDomainModalProps> = ({
  open,
  loading,
  form,
  initialValues,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal
      title={`${initialValues ? 'Редактирование' : 'Создание'} домена`}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button loading={loading} key="submit" type="primary" onClick={form.submit}>
          {initialValues ? 'Сохранить' : 'Создать'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Имя домена" name="name" rules={[{ required: true }]}>
          <Input placeholder="Введите имя домена" />
        </Form.Item>

        <Form.Item label="url домена" name="host" rules={[{ required: true }]}>
          <Input placeholder="Введите url домена" />
        </Form.Item>

        <Form.Item label="Вид домена" name="kind" rules={[{ required: true }]}>
          <Select placeholder="Выберите статус домена">
            <Select.Option value={ContentSitesV1DomainKind.Main}>{ContentSitesV1DomainKind.Main}</Select.Option>
            <Select.Option value={ContentSitesV1DomainKind.Mirror}>{ContentSitesV1DomainKind.Mirror}</Select.Option>
            <Select.Option value={ContentSitesV1DomainKind.None}>{ContentSitesV1DomainKind.None}</Select.Option>
            <Select.Option value={ContentSitesV1DomainKind.Preview}>{ContentSitesV1DomainKind.Preview}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
