import { ContentSitesV1RedirectKind, ContentSitesV1RedirectProps } from '@cms/volkswagen-widgets';
import React from 'react';
import { parseString } from '@fast-csv/parse';
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';

export enum ParsingStatus {
  loading = 'loading',
  success = 'success',
  initial = 'initial',
}

export enum RedirectCreateStatus {
  initial = 'initial',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

type ParsedRedirects = ContentSitesV1RedirectProps & { created: RedirectCreateStatus };

export type ParsingState = {
  status: ParsingStatus;
  parsedRedirects: ParsedRedirects[];
};

type UseParseRedirectsCsv = {
  parsingState: ParsingState;
  handleParseCsvFile: (file: File) => Promise<boolean>;
  handleResetState: () => void;
  handleRedirectStatusChange: (name: string, status: RedirectCreateStatus) => void;
};

const getCsvString = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });

const mapRedirectKind = (kind: string) => {
  switch (kind) {
    case '301':
      return ContentSitesV1RedirectKind.Permanent;
    case '302':
      return ContentSitesV1RedirectKind.Temporary;
    case 'rewrite':
      return ContentSitesV1RedirectKind.Internal;
    default:
      return ContentSitesV1RedirectKind.None;
  }
};

export const useParseRedirectsCsv = (): UseParseRedirectsCsv => {
  const [parsingState, setParsingState] = React.useState<ParsingState>({
    status: ParsingStatus.initial,
    parsedRedirects: [],
  });

  const handleRedirectStatusChange = (name: string, status: RedirectCreateStatus) => {
    const index = parsingState.parsedRedirects.findIndex((redirect) => redirect.name === name);

    if (index < 0) return;

    setParsingState((prev) => ({
      ...prev,
      parsedRedirects: [
        ...prev.parsedRedirects.slice(0, index),
        { ...prev.parsedRedirects[index], created: status },
        ...prev.parsedRedirects.slice(index + 1),
      ],
    }));
  };

  const handleParseCsvFile = React.useCallback(async (file: File) => {
    if (file.type !== 'text/csv') {
      notification.error({ message: 'Файл не является расширением .CSV' });
      return false;
    }

    try {
      setParsingState({ status: ParsingStatus.loading, parsedRedirects: [] });

      const csv = await getCsvString(file);

      const parsedRedirects: ParsingState['parsedRedirects'] = [];

      parseString(csv, {
        headers: ['source', 'target', 'kind', 'weight'],
        delimiter: ';',
      })
        .on('error', () => {
          throw new Error();
        })
        .on('data', (data) => {
          const kind = mapRedirectKind(data.kind);
          const weight = data.weight ? Number(data.weight) : undefined;

          if (kind === ContentSitesV1RedirectKind.None) return;

          parsedRedirects.push({ ...data, kind, weight, name: uuidv4(), created: RedirectCreateStatus.initial });
        })
        .on('end', () => {
          setParsingState({ status: ParsingStatus.success, parsedRedirects });
        });
    } catch (e) {
      setParsingState({ status: ParsingStatus.initial, parsedRedirects: [] });

      notification.error({ message: 'При загрузке файла произошла ошибка' });
    } finally {
      return false;
    }
  }, []);

  const handleResetState = () => setParsingState({ status: ParsingStatus.initial, parsedRedirects: [] });

  return {
    parsingState,
    handleParseCsvFile,
    handleResetState,
    handleRedirectStatusChange,
  };
};
