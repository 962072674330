import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { AttributesV1Attribute } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { styled } from '@console/uikit';
import { Alert, Button, Col, Form, Input, InputProps, Row, Select, SelectProps, Space, Tag, Typography } from 'antd';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import React, { ChangeEventHandler, useMemo, useState } from 'react';

export type MetaInputProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  disabled?: boolean;
};

const StyledEditOutlined = styled(EditOutlined)`
  margin-left: 7px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
`;

const CustomTag: React.FC<
  CustomTagProps & {
    onChangeInputValue(value: string): void;
  }
> = ({ onChangeInputValue, ...props }) => {
  const { label, value, onClose } = props;
  const handleClickEdit = () => {
    onChangeInputValue(value as string);
    onClose();
  };

  return (
    <Tag {...props} style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}>
      {label}
      <StyledEditOutlined onClick={handleClickEdit} />
    </Tag>
  );
};

const CustomSelect: React.FC<{ value?: string[]; onChange?: (value: []) => void }> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange: SelectProps<string[]>['onChange'] = () => {
    setInputValue('');
  };

  const handleInput: SelectProps<string[]>['onSearch'] = (value) => {
    setInputValue(value);
  };

  return (
    <Select
      inputValue={inputValue}
      onSearch={handleInput}
      value={value as []}
      onSelect={handleChange}
      onChange={onChange}
      tagRender={(props) => <CustomTag {...props} onChangeInputValue={setInputValue} />}
      placeholder="Введите значения"
      mode="tags"
      open={false}
      allowClear
    />
  );
};

const META_PREFIX = '--meta--';

export const MetaInput: React.FC<MetaInputProps> = React.memo(({ value = [], onChange }): React.ReactElement => {
  const { metas, attrs } = useMemo(
    () =>
      value.reduce<{
        metas: (AttributesV1Attribute & { index: number })[];
        attrs: (AttributesV1Attribute & { index: number })[];
      }>(
        (result, attr, index) => {
          return {
            ...result,
            ...(attr.name.indexOf(META_PREFIX) === 0
              ? {
                  metas: [
                    ...result.metas,
                    {
                      name: attr.name,
                      values: attr.values,
                      index,
                    },
                  ],
                }
              : {
                  attrs: [
                    ...result.attrs,
                    {
                      name: attr.name,
                      values: attr.values,
                      index,
                    },
                  ],
                }),
          };
        },
        {
          metas: [],
          attrs: [],
        },
      ),
    [value],
  );

  const handleChangeMetaName =
    (index: number): InputProps['onChange'] =>
    (event) => {
      const newValue: AttributesV1Attribute[] = [...value];
      newValue[index].name = `${META_PREFIX}${event.target.value}`;
      onChange && onChange(newValue);
    };

  const handleChangeMetaValues =
    (index: number): InputProps['onChange'] =>
    (event) => {
      const newValue: AttributesV1Attribute[] = [...value];
      newValue[index].values = [event.target.value];
      onChange && onChange(newValue);
    };
  const handleChangeAttrName =
    (index: number): InputProps['onChange'] =>
    (event) => {
      const newValue: AttributesV1Attribute[] = [...value];
      newValue[index].name = event.target.value;
      onChange && onChange(newValue);
    };

  const handleChangeAttrValues = (index: number) => (values: string[]) => {
    const newValue: AttributesV1Attribute[] = [...value];
    newValue[index].values = values;
    onChange && onChange(newValue);
  };

  const add = (item: AttributesV1Attribute, index?: number) => {
    if (onChange) {
      if (typeof index === 'number') {
        const newValue = [...value];
        newValue.splice(index, 0, item);
      } else {
        onChange([...value, item]);
      }
    }
  };

  const remove = (index: number) => {
    if (onChange) {
      const newValue: AttributesV1Attribute[] = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    }
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Space direction="horizontal">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Укажите метатеги страницы
          </Typography.Title>
          <Button type="link" size="small" onClick={() => add({ name: META_PREFIX, values: [] })}>
            + Добавить метатег
          </Button>
        </Space>
        {metas?.length === 0 ? (
          <Alert type="info" message="Метатеги не указаны" />
        ) : (
          metas?.map((meta) => (
            <Row gutter={24} key={meta.index}>
              <Col span={8}>
                <Form.Item label="Имя">
                  <Input
                    placeholder="Введите имя"
                    value={meta.name.replace(META_PREFIX, '')}
                    onChange={handleChangeMetaName(meta.index)}
                  />
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Значение">
                  <Input.TextArea
                    placeholder="Введите имя"
                    value={meta?.values ? meta.values[0] : ''}
                    rows={1}
                    autoSize
                    onChange={handleChangeMetaValues(meta.index) as ChangeEventHandler<HTMLTextAreaElement> | undefined}
                  />
                </Form.Item>
              </Col>
              <Form.Item label=" ">
                <Button
                  type="text"
                  icon={<MinusCircleOutlined />}
                  size="small"
                  onClick={() => remove(meta.index)}
                  danger
                />
              </Form.Item>
            </Row>
          ))
        )}
      </Space>

      <Space direction="vertical" style={{ width: '100%' }} size="small">
        <Space direction="horizontal">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Укажите атрибуты страницы
          </Typography.Title>
          <Button type="link" size="small" onClick={() => add({ name: '', values: [] })}>
            + Добавить атрибут
          </Button>
        </Space>
        {attrs?.length === 0 ? (
          <Alert type="info" message="Атрибуты не указаны" />
        ) : (
          attrs?.map((attr) => (
            <Row gutter={24} key={attr.index}>
              <Col span={8}>
                <Form.Item label="Имя">
                  <Input placeholder="Введите имя" value={attr.name} onChange={handleChangeAttrName(attr.index)} />
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Значение">
                  <CustomSelect value={attr.values} onChange={handleChangeAttrValues(attr.index)} />
                </Form.Item>
              </Col>
              <Form.Item label=" ">
                <Button
                  type="text"
                  icon={<MinusCircleOutlined />}
                  size="small"
                  onClick={() => remove(attr.index)}
                  danger
                />
              </Form.Item>
            </Row>
          ))
        )}
      </Space>
    </>
  );
});
