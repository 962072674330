import { Checkbox, Form } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { withSection } from '../../hocs';
import { RichtextFormItem } from '../../node-editors';
import { HalfHeightCheckbox } from './components';

const gridPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

export const EditorialStageEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { gridData, imageData, richtextData } = React.useMemo(() => {
      const gridData = getPlaceholderedPath({
        node,
        commonPath: gridPath,
      });

      const imageData = getPlaceholderedPath({
        node,
        commonPath: [...gridData.path, 'content', 0, 'box', 'content', 'image'],
      });
      const richtextData = getPlaceholderedPath({
        node,
        commonPath: [...gridData.path, 'content', 1, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
      });

      return { gridData, imageData, richtextData };
    }, [node]);

    const disabled = gridData.placeholdersCount === 0;

    const handlePlaceholder = (checked: boolean) => {
      onPlaceholderChange([...sectionContentPath, 'blueprint', 'content', 0, 'placeholder'], checked);
    };

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={gridData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => handlePlaceholder(e.target.checked)}
          >
            Редактируемое
          </Checkbox>
        </Form.Item>
        <ImageNodeInputs
          formListFieldData={formListFieldData}
          imagePath={imageData.path}
          disabled={disabled}
          required
        />
        <RichtextFormItem
          label="Текст"
          contentFieldName={[formListFieldData.name, ...richtextData.path]}
          disabled={disabled}
        />
        <HalfHeightCheckbox gridPath={[...sectionContentPath, ...gridData.path]} disabled={disabled} />
      </>
    );
  },
);
