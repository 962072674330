import { SnippetName } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { NodePreview, SnippetItemBlock } from '../custom-inputs';

export type TeaserItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
};

export const TeaserItem: React.FC<TeaserItemProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...contentPath, index, 'paginationItem', 'content', 0]}
      allowedSnippets={[SnippetName.TeaserItem]}
    >
      <div style={{ width: '20%' }}>
        <Form.Item
          name={[field.name, 'paginationItem', 'content', 0]}
          fieldKey={[field.fieldKey, 'paginationItem', 'content', 0]}
        >
          <NodePreview />
        </Form.Item>
      </div>
    </SnippetItemBlock>
  );
};
