import { GoogleRpcStatus, UsersV1UsersAPIApi } from '@cms/volkswagen-widgets';
import { UsersV1User } from '@cms/volkswagen-widgets/dist/core/api-cms/api';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<UsersV1User>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = string;

export type UseRemoveUserData = {
  removeUser: UseMutateFunction<Data, Error, Variables>;
  removeUserAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isRemoveLoading: boolean;
};

export function useRemoveUser(userApi: UsersV1UsersAPIApi, queryKey: QueryKey, isInvalidate = true): UseRemoveUserData {
  const queryClient = useQueryClient();

  const {
    mutate: removeUser,
    mutateAsync: removeUserAsync,
    isLoading: isRemoveLoading,
  } = useMutation<Data, Error, Variables>((userName) => userApi.usersV1UsersAPIDeleteUser(userName), {
    onSuccess: () => {
      if (isInvalidate) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (error) => {
      notification.error({
        message: 'Ошибка удаления пользователя',
        description: error?.response?.data.message,
      });
    },
  });

  return { removeUser, removeUserAsync, isRemoveLoading };
}
