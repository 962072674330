import {
  ActivityV1Activity,
  AttributesV1Attribute,
  ContentFilesV1File,
  VersionV1Version,
} from '@cms/volkswagen-widgets';

const extractNode = <T extends ContentFilesV1File>(node: T): T[keyof T] => {
  const values = Object.values(node);
  return values[0] as T[keyof T];
};

export function getFilePath(file: ContentFilesV1File): string | undefined {
  const node = extractNode(file);

  return node?.path;
}

export function getFileTitle(file: ContentFilesV1File): string | undefined {
  const node = extractNode(file);

  return node?.title;
}

export function getFileActivity(file: ContentFilesV1File): ActivityV1Activity | undefined {
  const node = extractNode(file);

  return node?.activity;
}

export function getFileVersion(file: ContentFilesV1File): VersionV1Version | undefined {
  const node = extractNode(file);

  return node?.version;
}

export function getFileSize(file: ContentFilesV1File): number | undefined {
  const node = extractNode<Pick<ContentFilesV1File, 'mediaV1Media'>>(file);

  return node?.size;
}

export const getAttribute = (attributes: AttributesV1Attribute[] = [], name: string): string => {
  return (attributes || []).reduce<string>((mime, attr): string => {
    const values = attr.values || [];
    if (attr.name === name && values.length > 0) {
      return values[values.length - 1];
    }
    return mime;
  }, '');
};

export function getFileAttribute(file: ContentFilesV1File, attributeName: string): string {
  const node = extractNode<Pick<ContentFilesV1File, 'mediaV1Media'>>(file);

  return getAttribute(node?.attributes, attributeName);
}
