import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { RichtextFormItem } from 'src/components/editors/node-editors';

export type MoodGalleryFirstItemProps = {
  disabled: boolean;
  field: FormListFieldData;
};

export const MoodGalleryFirstItem: React.FC<MoodGalleryFirstItemProps> = ({ disabled, field }) => {
  return (
    <RichtextFormItem
      label="Контент"
      formListFieldData={field}
      contentFieldName={['galleryItem', 'content', 0, 'blueprint', 'content', 0, 'richtext']}
      disabled={disabled}
    />
  );
};
