import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import React from 'react';
import { getFileActivity } from 'src/utils/entities';
import { StatusTag } from '../status-tag';

type FilesStatusTableCellProps = {
  file: ContentFilesV1File;
};

export const FilesStatusTableCell: React.FC<FilesStatusTableCellProps> = ({ file }) => {
  const status = getFileActivity(file)?.status;

  if (status === null || status === undefined) {
    return null;
  }

  return <StatusTag status={status} />;
};
