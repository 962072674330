import { ContentFilesV1FilesAPIApi, ContentFilesV1ListFilesResponse } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseListContentData = {
  filesResponse: ContentFilesV1ListFilesResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type ListFilesParams = {
  enabled: boolean;
  path?: string;
  types?: string[];
  search?: string;
  sort?: Array<
    | 'FILE_SORT_NONE'
    | 'FILE_SORT_PATH_ASC'
    | 'FILE_SORT_PATH_DESC'
    | 'FILE_SORT_TIME_ASC'
    | 'FILE_SORT_TIME_DESC'
    | 'FILE_SORT_TYPE_ASC'
    | 'FILE_SORT_TYPE_DESC'
  >;
  size?: number;
};

export function useListFiles(
  filesApi: ContentFilesV1FilesAPIApi,
  queryKey: QueryKey,
  params: ListFilesParams,
): UseListContentData {
  const { enabled, path, types, search, size } = params;

  const {
    data: filesResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      filesApi.contentFilesV1FilesAPIListFiles(
        undefined, // activityEq
        undefined, // activityNeq
        size, // pageSize
        undefined, // pathToken
        path ? [path] : undefined, // pathEq
        undefined, // pathNeq
        search, // search
        undefined, // sort
        types ?? undefined, // typeEq
        undefined, // typeNeq
        undefined, // visibilityEq
        undefined, // visibilityNeq
      ),
    { enabled },
  );

  return {
    filesResponse: filesResponse?.data,
    isListLoading,
    isListFetching,
  };
}
