import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { ContentSitesV1Robot, ContentSitesV1RobotRule } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

const { confirm } = Modal;
const { Text } = Typography;

function getRobotRule(rule: ContentSitesV1RobotRule): string {
  if ('host' in rule) return `Host: ${rule.host?.url}`;
  if ('sitemap' in rule) return `Sitemap: ${rule.sitemap?.url}`;
  if ('allow' in rule) return `Allow: ${rule.allow?.path}`;
  if ('disallow' in rule) return `Disallow: ${rule.disallow?.path}`;
  if ('crawlDelay' in rule) return `Crawl-delay: ${rule.crawlDelay?.seconds}`;
  if ('cleanParam' in rule) return `Clean-param: ${rule.cleanParam?.params.join('&')} ${rule.cleanParam?.path}`;
  return '';
}

const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDeleteFilled = styled(DeleteFilled)`
  font-size: 20px;
  color: ${(props) => props.theme.colors.gray7};
`;

const StyledEditFilled = styled(EditFilled)`
  color: ${(props) => props.theme.colors.gray7};
  font-size: 20px;
`;

const StyledRulesWrapper = styled.div`
  white-space: pre;
  background-color: ${(props) => props.theme.colors.gray2};
  font-family: 'Courier New', Courier, monospace;
  padding: 8px 16px;
`;

type RobotsTableProps = {
  robots: ContentSitesV1Robot[];
  onRemoveRobot: (robotName: string) => void;
  setEditRobot: (value: ContentSitesV1Robot) => void;
};

export const RobotsTable: React.FC<RobotsTableProps> = ({ robots, onRemoveRobot, setEditRobot }) => {
  const handleDelete = (item: ContentSitesV1Robot) => {
    confirm({
      title: 'Удаление группы',
      content: (
        <>
          <Text>Вы собираетесь удалить группу «{item.agent}».</Text>
          <br />
          <Text>Отменить это действие будет невозможно!</Text>
          <br />
          <br />
          <Text>Удалить группу?</Text>
        </>
      ),
      onOk() {
        onRemoveRobot(item.name);
      },
      okText: 'Удалить',
      okType: 'danger',
      cancelText: 'Отменить',
    });
  };

  const columns: ColumnsType<ContentSitesV1Robot> = [
    {
      title: 'Группа',
      dataIndex: 'agent',
      key: 'agent',
    },
    {
      title: 'Правила',
      render: (_, record) => <StyledRulesWrapper>{record.rules?.map(getRobotRule).join('\n')}</StyledRulesWrapper>,
    },
    {
      title: '',
      render: (_, record) => (
        <StyledActionsWrapper>
          <Space align="center">
            <Button type="link" icon={<StyledEditFilled />} onClick={() => setEditRobot(record)} />
            <Button type="link" icon={<StyledDeleteFilled />} onClick={() => handleDelete(record)} />
          </Space>
        </StyledActionsWrapper>
      ),
    },
  ];

  return <Table rowKey={(robot) => robot.name} dataSource={robots} columns={columns} pagination={false} />;
};
