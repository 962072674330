import { AccordionNode, BoxNode, ContentVolkswagenV1Node, IframeNode, SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { RichtextFormItem } from 'src/components/editors/node-editors';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButton, RichtextTextNodesEditor, SnippetItemBlock } from '../../../custom-inputs';
import { ContactsFocusLayerAccordionItem } from './contacts-focus-layer-accordion-item';

const mapAccordionAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  accordionItem: AccordionNode.Item.generators.entity({
    header: [],
    side: [
      BoxNode.generators.node({
        styles: [{ width: '100%', height: '300px' }],
        content: IframeNode.generators.node({
          source: '',
        }),
      }),
    ],
    content: [node],
  }),
});

export type ContactsFocusLayerTabProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  contentPath: TreePath;
};

export const ContactsFocusLayerTab: React.FC<ContactsFocusLayerTabProps> = ({
  field,
  disabled,
  move,
  remove,
  isBottomMovable,
  isTopMovable,
  index,
  contentPath,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Alert message={`Таб №${index + 1}`} type="info" style={{ marginBottom: 12 }} />

      {/* tab.tabTitle */}
      <Form.Item
        label="Название таба"
        name={[field.name, 'tabsItem', 'header']}
        fieldKey={[field.fieldKey, 'tabsItem', 'header']}
      >
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>

      {/* tab.title */}
      <RichtextFormItem
        label="Заголовок таба"
        formListFieldData={field}
        contentFieldName={['tabsItem', 'content', 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext']}
        disabled={disabled}
      />

      {/* tab.accordions */}
      <Form.List name={[field.name, 'tabsItem', 'content', 0, 'flex', 'content', 1, 'accordion', 'items']}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <ContactsFocusLayerAccordionItem
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
                contentPath={[
                  ...contentPath,
                  field.fieldKey,
                  'tabsItem',
                  'content',
                  0,
                  'flex',
                  'content',
                  1,
                  'accordion',
                  'items',
                ]}
                disallowedSnippets={[SnippetName.ContactFocusLayer, SnippetName.CopyAccordion]}
              />
            ))}
            <AddSnippetButton
              add={add}
              mapBeforeAdd={mapAccordionAdd}
              isDisabled={disabled}
              label="Добавить аккордион-итем"
              noStyle
              allowedSnippets={[SnippetName.ContactInfo]}
            />
          </>
        )}
      </Form.List>
    </SnippetItemBlock>
  );
};
