import { DesignTokenSizeEnum } from '@cms/volkswagen-widgets/dist/d6';
import { Select } from 'antd';

type D6SizesInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled: boolean;
};

export const D6SizesInput: React.FC<D6SizesInputProps> = ({ disabled, onChange, value }) => {
  return (
    <Select disabled={disabled} value={value} onChange={onChange} style={{ width: 200 }}>
      {Object.keys(DesignTokenSizeEnum).map((size) => (
        <Select.Option key={size} value={size}>
          {size}
        </Select.Option>
      ))}
    </Select>
  );
};
