import { Button, Modal, Progress, Space, Table, TableProps, Tag } from 'antd';
import React from 'react';
import { ParsingState, RedirectCreateStatus } from '../../redirects-tab-content/hooks';

export type ParsedRedirectsModalProps = {
  isOpen: boolean;
  isCreating: boolean;
  onClose: () => void;
  onCreateRedirects: () => void;
  redirects: ParsingState['parsedRedirects'];
  progressPercent: { value: number; hasErrors: boolean };
};

const RecordStatusColors = {
  [RedirectCreateStatus.initial]: 'gray',
  [RedirectCreateStatus.error]: 'red',
  [RedirectCreateStatus.loading]: 'gold',
  [RedirectCreateStatus.success]: 'green',
} as const;

export const ParsedRedirectsModal: React.FC<ParsedRedirectsModalProps> = ({
  isOpen,
  isCreating,
  redirects,
  progressPercent,
  onClose,
  onCreateRedirects,
}) => {
  const columns: TableProps<ParsingState['parsedRedirects'][number]>['columns'] = [
    {
      title: 'Тип редиректа',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: 'URL старой страницы',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: 'URL новой страницы',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Приоритет',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Создано',
      align: 'center',
      render: (_, record) => <Tag color={RecordStatusColors[record.created]}>{record.created}</Tag>,
    },
  ];

  return (
    <Modal
      title="Редиректы"
      visible={isOpen}
      width="auto"
      onCancel={isCreating ? undefined : onClose}
      destroyOnClose
      footer={
        <Space>
          <Progress
            key="progress"
            type="circle"
            width={70}
            percent={progressPercent.value}
            status={progressPercent.hasErrors ? 'exception' : 'normal'}
          />
          <Button key="cancel" onClick={isCreating ? undefined : onClose} disabled={isCreating}>
            Отменить
          </Button>
          <Button key="create" loading={isCreating} type="primary" onClick={onCreateRedirects} disabled={isCreating}>
            Создать
          </Button>
        </Space>
      }
    >
      <Table dataSource={redirects} rowKey="name" columns={columns} pagination={false} />
    </Modal>
  );
};
