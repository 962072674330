import {
  ContentSitesV1Robot,
  ContentSitesV1RobotAllow,
  ContentSitesV1RobotCleanParam,
  ContentSitesV1RobotCrawlDelay,
  ContentSitesV1RobotDisallow,
  ContentSitesV1RobotHost,
  ContentSitesV1RobotRule,
  ContentSitesV1RobotSitemap,
} from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Form, Modal } from 'antd';
import React from 'react';
import { KeysOfUnion } from 'src/utils/types';
import { EditRobotRulesTable } from './edit-robot-rules-table';

function getFormRobotRule(rule: ContentSitesV1RobotRule): EditRobotRulesFormValues['rules'][0] {
  if ('host' in rule) return { rule: 'host', host: rule.host };
  if ('sitemap' in rule) return { rule: 'sitemap', sitemap: rule.sitemap };
  if ('allow' in rule) return { rule: 'allow', allow: rule.allow };
  if ('disallow' in rule) return { rule: 'disallow', dissalow: rule.disallow };
  if ('crawlDelay' in rule) return { rule: 'crawlDelay', crawlDelay: rule.crawlDelay };
  if ('cleanParam' in rule) return { rule: 'cleanParam', cleanParam: rule.cleanParam };

  return { rule: 'allow' };
}

export type EditRobotRulesFormValues = {
  rules: Array<{
    rule: KeysOfUnion<ContentSitesV1RobotRule>;
    host?: ContentSitesV1RobotHost;
    sitemap?: ContentSitesV1RobotSitemap;
    allow?: ContentSitesV1RobotAllow;
    dissalow?: ContentSitesV1RobotDisallow;
    crawlDelay?: ContentSitesV1RobotCrawlDelay;
    cleanParam?: ContentSitesV1RobotCleanParam;
  }>;
};

export type EditRobotRulesModalProps = {
  editRobot: ContentSitesV1Robot | null;
  loading: boolean;
  onClose: () => void;
  onUpdateRobot: (robot: ContentSitesV1Robot, values: EditRobotRulesFormValues) => Promise<unknown>;
};

const StyledFormList = styled(Form.List)`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const EditRobotRulesModal: React.FC<EditRobotRulesModalProps> = ({
  editRobot,
  loading,
  onClose,
  onUpdateRobot,
}) => {
  const [form] = Form.useForm<EditRobotRulesFormValues>();

  React.useEffect(() => {
    if (editRobot) {
      form.setFieldsValue({
        rules: editRobot.rules?.map(getFormRobotRule) ?? [],
      });
    }
  }, [form, editRobot]);

  const handleFinish = (values: EditRobotRulesFormValues) => {
    if (editRobot) {
      onUpdateRobot(editRobot, values)
        .then(() => {
          onClose();
        })
        .catch(() => null);
    }
  };

  return (
    <Modal
      title="Редактирование группы"
      visible={Boolean(editRobot)}
      onCancel={onClose}
      width="60vw"
      footer={[
        <Button key="cancel" onClick={onClose}>
          Отменить
        </Button>,
        <Button loading={loading} key="submit" type="primary" onClick={form.submit}>
          Создать
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleFinish} layout="horizontal">
        <StyledFormList name="rules">
          {(fields, { add, remove }) => <EditRobotRulesTable fields={fields} add={add} remove={remove} />}
        </StyledFormList>
      </Form>
    </Modal>
  );
};
