import { ContentSitesV1ListRedirectsResponse, ContentSitesV1RedirectsAPIApi } from '@cms/volkswagen-widgets';
import { QueryKey, useQuery } from 'react-query';

type UseGetRedirectsData = {
  redirectsResponse: ContentSitesV1ListRedirectsResponse | undefined;
  isListLoading: boolean;
  isListFetching: boolean;
};

export type GetRedirectsParams = {
  site: string;
  enabled: boolean;
};

export function useGetRedirects(
  redirectsApi: ContentSitesV1RedirectsAPIApi,
  queryKey: QueryKey,
  params: GetRedirectsParams,
): UseGetRedirectsData {
  const { site, enabled } = params;

  const {
    data: redirectsResponse,
    isLoading: isListLoading,
    isFetching: isListFetching,
  } = useQuery(
    queryKey,
    () =>
      redirectsApi
        .contentSitesV1RedirectsAPIListRedirects(
          site, // siteName: string,
          undefined, // siteRevision?: string,
          undefined, // activityEq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // activityNeq?: Array<'STATUS_NONE' | 'STATUS_ACTIVE' | 'STATUS_DISABLED' | 'STATUS_ARCHIVED'>,
          undefined, // kindEq?: Array<'REDIRECT_KIND_NONE' | 'REDIRECT_KIND_MAIN' | 'REDIRECT_KIND_PREVIEW' | 'REDIRECT_KIND_MIRROR'>,
          undefined, // kindNeq?: Array<'REDIRECT_KIND_NONE' | 'REDIRECT_KIND_MAIN' | 'REDIRECT_KIND_PREVIEW' | 'REDIRECT_KIND_MIRROR'>,
          undefined, // nameEq?: Array<string>, nameNeq?: Array<string>,
          undefined, // nameNeq?: Array<string>, nameNeq?: Array<string>,
          undefined, // pageSize?: number,
          undefined, // pageToken?: string,
          undefined, // search?: string,
          undefined, // sort?: Array<'REDIRECT_SORT_NONE' | 'REDIRECT_SORT_NAME_ASC' | 'REDIRECT_SORT_NAME_DESC' | 'REDIRECT_SORT_TIME_ASC' | 'REDIRECT_SORT_TIME_DESC'>,
          undefined, // visibilityEq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
          undefined, // visibilityNeq?: Array<'STATUS_NONE' | 'STATUS_PUBLIC' | 'STATUS_INTERNAL' | 'STATUS_PRIVATE'>,
          undefined, // options?: any
        )
        .then((resp) => resp.data),
    { enabled },
  );

  return {
    redirectsResponse,
    isListLoading,
    isListFetching,
  };
}
