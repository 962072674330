import { styled } from '@console/uikit';
import React from 'react';
import { Button, List, Typography, Space } from 'antd';

type ListItem = {
  title: string;
  url?: string;
};

type PublicationListProps = {
  version: string;
  createdBy?: {
    date: Date;
    author: string;
  };
  data: ListItem[];
};

const StyledListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PublicationList: React.FC<PublicationListProps> = ({ data, createdBy, version }) => {
  const header = (
    <Space direction="vertical">
      <Typography.Text strong>Публикация {version}</Typography.Text>
      {createdBy && (
        <Typography.Text>
          {createdBy.date.toLocaleString()} {createdBy.author}
        </Typography.Text>
      )}
    </Space>
  );

  const footer = (
    <StyledFooter>
      <Button type="primary">Опубликовать</Button>
    </StyledFooter>
  );

  const renderItem = (item: ListItem) => (
    <StyledListItem>
      {item.title}
      {item.url && (
        <Button type="link" href={item.url}>
          Перейти
        </Button>
      )}
    </StyledListItem>
  );

  return <List header={header} footer={!createdBy && footer} bordered dataSource={data} renderItem={renderItem} />;
};
