import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import React from 'react';
import { d6 } from '@cms/volkswagen-widgets';
import { useNodesToComponents } from '../../../hooks/use-nodes-to-components';

const { IntegratorRoot, ThemeProvider } = d6;

export type NodePreviewProps = {
  onChange?: (value: ContentVolkswagenV1Widget) => void;
  value?: ContentVolkswagenV1Widget;
};

export const NodePreview: React.FC<NodePreviewProps> = ({ value }) => {
  const components = useNodesToComponents(value);

  return (
    <IntegratorRoot>
      <ThemeProvider theme="main">{components}</ThemeProvider>
    </IntegratorRoot>
  );
};
