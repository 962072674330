import { Dropdown, Menu } from 'antd';
import React from 'react';
import { css } from 'styled-components';
import { ConfigType, useBlockMeta, useConfigByStyles } from '../../../hooks';
import { ControlButton } from '../control-button';
import { ControlsProps, StyleItem } from '../../../types';
import { StyledControl, StyledDropdownControl, StyledDropdownMenu } from '../../../elements';

export enum BlockquoteBlockType {
  Variant1 = 'variant1',
  Variant2 = 'variant2',
}

const BLOCKQUOTE_TYPES: StyleItem<BlockquoteBlockType>[] = [
  {
    label: 'Вариант 1',
    style: BlockquoteBlockType.Variant1,
  },
  {
    label: 'Вариант 2',
    style: BlockquoteBlockType.Variant2,
  },
];

export const BLOCKQUOTE_KEY = 'blockquote';

export const blockquoteStyles = css`
  .${BLOCKQUOTE_KEY}-${BlockquoteBlockType.Variant1} {
    position: relative;
    padding-left: 10px;

    &:after {
      content: '';
      width: 2px;
      height: 100%;
      background-color: rgb(223, 228, 232);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .${BLOCKQUOTE_KEY}-${BlockquoteBlockType.Variant2} {
    position: relative;
    padding-left: 25px;

    &:after {
      content: '';
      width: 20px;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='22' viewBox='0 0 46 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M46 3.99261C43.8829 5.19039 42.1712 6.22181 40.8649 7.08688C39.5586 7.95195 38.2297 9.09426 36.8784 10.5139C35.5721 11.8891 34.5923 13.2976 33.9392 14.7394C33.286 16.1812 32.8243 18.0554 32.5541 20.3623H35.7973C38.5901 20.3623 40.786 21.0388 42.3851 22.3919C43.9842 23.7449 44.7838 25.7301 44.7838 28.3475C44.7838 30.2107 44.0743 31.9519 42.6554 33.5712C41.2365 35.1904 39.2883 36 36.8108 36C32.9369 36 30.1667 34.7468 28.5 32.2403C26.8333 29.7338 26 26.4399 26 22.3586C26 19.475 26.6306 16.8466 27.8919 14.4732C29.1532 12.0998 30.6847 9.98153 32.4865 8.1183C34.3333 6.21071 36.2928 4.5915 38.3649 3.26063C40.4369 1.92975 42.1712 0.842888 43.5676 0L46 3.99261ZM20 3.99261C17.8829 5.19039 16.1712 6.22181 14.8649 7.08688C13.5586 7.95195 12.2297 9.09426 10.8784 10.5139C9.61711 11.8447 8.64865 13.2421 7.97297 14.7061C7.29729 16.1701 6.82433 18.0554 6.55405 20.3623H9.7973C12.5901 20.3623 14.786 21.0388 16.3851 22.3919C17.9842 23.7449 18.7838 25.7301 18.7838 28.3475C18.7838 30.2107 18.0743 31.9519 16.6554 33.5712C15.2365 35.1904 13.2883 36 10.8108 36C6.93692 36 4.16668 34.7468 2.5 32.2403C0.833325 29.7338 0 26.4399 0 22.3586C0 19.475 0.630624 16.8466 1.89189 14.4732C3.15316 12.0998 4.68468 9.98153 6.48649 8.1183C8.33334 6.21071 10.2928 4.5915 12.3649 3.26063C14.4369 1.92975 16.1712 0.842888 17.5676 0L20 3.99261Z' fill='black'/%3E%3C/svg%3E%0A");
      position: absolute;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const BlockquoteBlock: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleData, currentBlockData] = useBlockMeta<BlockquoteBlockType>(editorState, setEditorState, BLOCKQUOTE_KEY);

  const types = useConfigByStyles(ConfigType.Blockquote, BLOCKQUOTE_TYPES);

  if (!types.length) {
    return null;
  }

  const active = types.find((type) => type.style === currentBlockData);

  return (
    <StyledControl>
      <Dropdown
        trigger={['click']}
        overlay={
          <StyledDropdownMenu>
            {types.map(({ label, style: blockDataValue }) => (
              <Menu.Item key={label}>
                <ControlButton
                  isActive={currentBlockData === blockDataValue}
                  value={blockDataValue}
                  onToggle={toggleData}
                >
                  {label}
                </ControlButton>
              </Menu.Item>
            ))}
          </StyledDropdownMenu>
        }
      >
        <StyledDropdownControl>
          <ControlButton>Цитата{active?.label ? `: ${active?.label}` : ''}</ControlButton>
        </StyledDropdownControl>
      </Dropdown>
    </StyledControl>
  );
});

BlockquoteBlock.displayName = 'BlockquoteBlock';
