import { CloudUploadOutlined, UndoOutlined } from '@ant-design/icons';
import { ContentFilesV1File } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button } from 'antd';
import React from 'react';
import { useToggle } from 'src/hooks';
import { StyledTabHeaderWrapper } from '../../components/elements';
import { SitesControl } from '../../components/sites-control';
import { CreateSiteModalContainer } from '../create-site-modal';
import SitesFilterDrawer from './components/sites-filter-drawer';
import { StructureTabControl } from './components/structure-tab-control';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

type StructureTabHeaderContainerProps = {
  onSearch: (value: string) => void;
  selectedFiles: ContentFilesV1File[];
  isPublishLoading: boolean;
  onClearSelection: () => void;
  onPublishClick: () => void;
};

const StructureTabHeaderContainer: React.FC<StructureTabHeaderContainerProps> = ({
  onSearch,
  selectedFiles,
  isPublishLoading,
  onClearSelection,
  onPublishClick,
}) => {
  const [filtersIsOpen, { onToggle: openFilters, offToggle: closeFilters }] = useToggle();

  const [createSiteModalIsOpen, { onToggle: openCreateSiteModal, offToggle: closeCreateSiteModal }] = useToggle();

  return selectedFiles.length ? (
    <StyledWrapper>
      <Button icon={<CloudUploadOutlined />} type="default" onClick={onPublishClick} loading={isPublishLoading}>
        Опубликовать
      </Button>
      <Button icon={<UndoOutlined />} type="link" onClick={onClearSelection}>
        Сбросить выделение
      </Button>
    </StyledWrapper>
  ) : (
    <StyledTabHeaderWrapper>
      <SitesControl />
      <StructureTabControl onCreateSite={openCreateSiteModal} onFilterOpen={openFilters} onSearch={onSearch} />
      <SitesFilterDrawer open={filtersIsOpen} onClose={closeFilters} />
      <CreateSiteModalContainer open={createSiteModalIsOpen} onClose={closeCreateSiteModal} />
    </StyledTabHeaderWrapper>
  );
};

export default StructureTabHeaderContainer;
