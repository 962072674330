import { Button, Drawer } from 'antd';
import React from 'react';
import styled from 'styled-components';

export type DrawerFormEditorProps = {
  title: string;
  isVisible: boolean;
  loading?: boolean;
  submitHandler?: () => void;
  buttons?: (JSX.Element | undefined)[];
  closeHandler: () => void;
  formName?: string;
};

const StyledButtonsWrapper = styled.div`
  text-align: right;

  > * + * {
    margin-left: 8px;
  }
`;

export const DrawerFormEditor: React.FC<DrawerFormEditorProps> = ({
  title,
  isVisible,
  buttons,
  submitHandler,
  children,
  closeHandler,
  formName,
  loading = false,
}) => {
  return (
    <Drawer
      title={title}
      placement="right"
      visible={isVisible}
      width="90%"
      closable
      onClose={closeHandler}
      destroyOnClose
      footer={
        buttons ? (
          <StyledButtonsWrapper>{buttons}</StyledButtonsWrapper>
        ) : (
          <StyledButtonsWrapper>
            <Button onClick={closeHandler} loading={loading}>
              Закрыть
            </Button>
            <Button onClick={submitHandler} type="primary" htmlType="submit" loading={loading} form={formName}>
              Сохранить изменения
            </Button>
          </StyledButtonsWrapper>
        )
      }
    >
      {children}
    </Drawer>
  );
};
