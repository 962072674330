import { d6 } from '@cms/volkswagen-widgets';
import { Popover } from 'antd';
import Draft, {
  ContentState,
  convertFromHTML,
  convertToRaw,
  DraftHandleValue,
  Editor as DraftEditor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import React, { useCallback, useRef } from 'react';
import { ControlsContext } from '../hooks/controls-context';
import { RichTextProps } from '../types';
import { getBlockStyle, toggleInlineStyles } from '../utils';
import { Controls } from './controls';
import { headerBlockRender } from './controls/blocks/header-block';
import { listBlockRender } from './controls/blocks/list-block';
import { customStyleMap } from './controls/inline';
import { BlockStyledWrapper, StyledWrapper } from '../elements';
import Immutable from 'immutable';

const { IntegratorRoot, ThemeProvider } = d6;

const blockRenderMap = Immutable.Map({
  ...headerBlockRender,
  ...listBlockRender,
});

// Include 'paragraph' as a valid block and updated the unstyled element but
// keep support for other draft default block types
const extendedBlockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(blockRenderMap);

export const Editor: React.FC<RichTextProps> = React.memo<RichTextProps>(
  ({
    editorState,
    setEditorState,
    placeholder,
    presetsStyles,
    controlsConfig,
    entityControlsConfig,
    disabled,
    singleLine,
    isClearButton = true,
    onBlur,
    className,
    internalEditor,
    customStylesWrapper: CustomStylesWrapper,
  }) => {
    const editor = useRef<DraftEditor | null>(null);
    const isFocus = editorState.getSelection().getHasFocus();

    const onChangeWithPresets = useCallback(
      (editorState: EditorState): void => {
        setEditorState(() => {
          if (presetsStyles && !editorState.getCurrentContent().hasText()) {
            return toggleInlineStyles(Object.values(presetsStyles).flat(1), editorState);
          }

          return editorState;
        });
      },
      [presetsStyles, setEditorState],
    );

    const focus = useCallback(() => {
      if (editor && editor.current) {
        editor.current.focus();
      }
    }, [editor]);

    // автофокус на поле ввода при изменении контента
    // useEffect(() => {
    //   if (!disabled) {
    //     focus();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [editorState.getCurrentContent()]);

    const handleKeyCommand = useCallback(
      (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
          setEditorState(newState);
          return 'handled';
        }
        return 'not-handled';
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [editorState, setEditorState],
    );

    const mapKeyToEditorCommand = useCallback(
      (e) => {
        switch (e.keyCode) {
          case 9:
            const newEditorState = RichUtils.onTab(e, editorState, 4);
            if (newEditorState !== editorState) {
              setEditorState(newEditorState);
            }
            return null;
        }
        return getDefaultKeyBinding(e);
      },
      [editorState, setEditorState],
    );

    const handleReturn = (_: React.KeyboardEvent<unknown>, editorState: EditorState) => {
      // RichText in single line
      if (singleLine) {
        return 'handled';
      }

      // Logic for save metadata after split blocks, like data align and placeholder
      const selection = editorState.getSelection();
      if (selection.isCollapsed()) {
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const currentBlock = contentState.getBlockForKey(startKey);
        if (currentBlock) {
          const blockData = currentBlock.getData();
          if (blockData) {
            const newContentState = Modifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
            let splitState = EditorState.push(editorState, newContentState, 'split-block');
            const currentSelection = splitState.getSelection();
            const afterMergeStylesContentState = Modifier.mergeBlockData(
              splitState.getCurrentContent(),
              currentSelection,
              blockData,
            );
            splitState = EditorState.push(editorState, afterMergeStylesContentState, 'split-block');
            setEditorState(splitState);
            return 'handled';
          }
        }
      }

      return 'not-handled';
    };

    const handlePastedText = (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
      // Logic for save metadata after pasted text from buffer, like data align and placeholder
      if (html || text) {
        const { contentBlocks, entityMap } = convertFromHTML(html || '', undefined);

        const contentState = html
          ? ContentState.createFromBlockArray(contentBlocks, entityMap)
          : ContentState.createFromText(text);

        // получили block data от блока на котором курсор
        const selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const currentBlock = editorState.getCurrentContent().getBlockForKey(startKey);
        const blockData = currentBlock.getData();

        // применили block data к контенту из буфера
        const mergeBlockDataEditorState = EditorState.push(
          editorState,
          Modifier.mergeBlockData(
            contentState,
            new SelectionState({
              anchorKey: contentState.getFirstBlock().getKey(),
              anchorOffset: 0,

              focusKey: contentState.getLastBlock().getKey(),
              focusOffset: contentState.getLastBlock().getLength(),
            }),
            blockData,
          ),
          'change-block-data',
        );

        // вставляем контент из буфера под курсор
        const fragmentContentState = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          mergeBlockDataEditorState.getCurrentContent().getBlockMap(),
        );
        const fragmentEditorState = EditorState.push(editorState, fragmentContentState, 'insert-fragment');

        setEditorState(EditorState.acceptSelection(fragmentEditorState, fragmentEditorState.getSelection()));

        return 'handled';
      }

      return 'not-handled';
    };

    const Wrapper = CustomStylesWrapper || StyledWrapper;

    return (
      <ControlsContext.Provider
        value={{
          config: controlsConfig,
          entityConfig: entityControlsConfig,
          isClearButton,
        }}
      >
        <IntegratorRoot>
          <ThemeProvider theme="main">
            <Wrapper className={className} isFocus={isFocus} isDisabled={Boolean(disabled)}>
              <BlockStyledWrapper>
                <Popover
                  trigger="click"
                  visible={!disabled && isFocus}
                  getPopupContainer={(trigger) => trigger.parentElement || document.body}
                  content={
                    <Controls
                      editorState={editorState}
                      setEditorState={setEditorState}
                      disabled={disabled}
                      disclaimerEditor={internalEditor}
                    />
                  }
                >
                  <div
                    style={{
                      display: 'inline-block',
                      width: '100%',
                    }}
                    onClick={focus}
                  >
                    <DraftEditor
                      blockStyleFn={getBlockStyle}
                      blockRenderMap={extendedBlockRenderMap}
                      customStyleMap={{
                        ...customStyleMap,
                      }}
                      editorState={editorState}
                      handleReturn={handleReturn}
                      handleKeyCommand={handleKeyCommand}
                      keyBindingFn={mapKeyToEditorCommand}
                      handlePastedText={handlePastedText}
                      onChange={onChangeWithPresets}
                      placeholder={placeholder}
                      ref={editor}
                      readOnly={disabled}
                      onBlur={onBlur}
                    />
                  </div>
                </Popover>
              </BlockStyledWrapper>
            </Wrapper>
          </ThemeProvider>
        </IntegratorRoot>
      </ControlsContext.Provider>
    );
  },
);

Editor.displayName = 'Editor';
