import { styled } from '@console/uikit';
import { Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumbs, EditFolderFormValues, EditFolderModal } from '../../../components';
import { MediaCards } from '../../../components/media/media-cards';
import { MediaHeader, MediaView } from '../../../components/media/media-header';
import { MediaTable } from '../../../components/media/media-table';
import { useToggle, useUploader } from '../../../hooks';
import { useMedia } from '../../../hooks/use-media';
import { isImage, isPdf, isVideo } from '../../../utils/media';
import { removeLastPathParam } from '../../../utils/string';

export type FromMediaProps = {
  onSave(path: string): void;
  path?: string;
  mediaType?: 'video' | 'image' | 'pdf';
};

const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding: 8px 0;
`;

export const FromMedia: React.FC<FromMediaProps> = ({ path, mediaType, onSave }) => {
  const [search, setSearch] = useState<string>();
  const [form] = Form.useForm<EditFolderFormValues>();
  const [sort, setSort] = useState('name');
  const [view, setView] = useState<MediaView>(MediaView.Table);
  const [createFolderModalIsOpen, { onToggle: openCreateFolderModal, offToggle: closeCreateFolderModal }] = useToggle();

  const {
    breadcrumbs,
    uploadFiles,
    files,
    fileProgresses,
    fileStatuses,
    isListFetching,
    isCreateLoading,
    createFolder,
    removeFile,
  } = useMedia({
    search,
    defaultPath: path ? removeLastPathParam(path.substring(1)) : '',
  });

  const onUploadClick = useUploader(uploadFiles);

  const handleFolderSubmit = (values: EditFolderFormValues) => {
    createFolder(values.name, values.title).then(() => {
      form.resetFields();
      closeCreateFolderModal();
    });
  };

  useEffect(() => {
    return () => {
      breadcrumbs.clearBreadcrumbs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredFiles = useMemo(
    () =>
      mediaType
        ? files.filter((file) => {
            if (file.mediaV1Media) {
              switch (mediaType) {
                case 'image':
                  return isImage(file.mediaV1Media);
                case 'video':
                  return isVideo(file.mediaV1Media);
                case 'pdf':
                  return isPdf(file.mediaV1Media);
                default:
                  return true;
              }
            }

            return true;
          })
        : files,
    [files, mediaType],
  );

  return (
    <>
      <MediaHeader
        view={view}
        sort={sort}
        setView={setView}
        setSort={setSort}
        setSearch={setSearch}
        onUploadClick={onUploadClick}
        openCreateFolderModal={openCreateFolderModal}
      />
      <StyledBreadcrumbs {...breadcrumbs} homeTitle="Все файлы" />
      {view === MediaView.Table && (
        <MediaTable
          files={filteredFiles}
          loading={isListFetching}
          addBreadcrumb={breadcrumbs.addBreadcrumb}
          removeMedia={removeFile}
          path={path}
          onSelectFile={onSave}
          progresses={fileProgresses}
          statuses={fileStatuses}
        />
      )}
      {view === MediaView.Card && (
        <MediaCards
          files={filteredFiles}
          progresses={fileProgresses}
          isLoading={isListFetching}
          addBreadcrumb={breadcrumbs.addBreadcrumb}
          removeFile={removeFile}
        />
      )}
      <EditFolderModal
        open={createFolderModalIsOpen}
        loading={isCreateLoading}
        form={form}
        onClose={closeCreateFolderModal}
        onSubmit={handleFolderSubmit}
        isCreate
      />
    </>
  );
};
