import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import { CopyModal, CopyFormValues } from '../../../../components';
import { useFilesContext } from '../../contexts';

export type CopyFolderModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const CopyFolderModalContainer: React.FC<CopyFolderModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<CopyFormValues>();

  const {
    copyFolder,
    service: {
      meta: { isUpdateLoading },
      operations: { createFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  // const filesService = useFiles({
  //   enabled: open && Boolean(copyFolder),
  //   path: `${copyFolder?.path}/*`,
  // });

  // const isExistContentFolder = Boolean(filesService.models.filesResponse?.result.total);
  // const isExistContentLoading = filesService.meta.isListLoading;

  useEffect(() => {
    if (open) {
      if (copyFolder) {
        form.setFieldsValue({
          name: '',
          title: copyFolder.title,
          path: [],
        });
      } else {
        form.resetFields();
      }
    }
  }, [open, copyFolder, form]);

  const handleSubmit = (values: CopyFormValues) => {
    if (copyFolder) {
      const path = `/${values.path.map((p) => p.value).join('/')}`;

      createFileAsync({
        filesV1Blueprint: {
          path: `${path}/${values.name}`,
          source: copyFolder.path,
          title: values.title,
          activity: { status: ActivityV1Status.Active },
          visibility: { status: VisibilityV1Status.Public },
        },
      })
        .then(() => {
          onClose();
          notification.success({
            message: `Создана ссылка на папку ${copyFolder.path} в ${path}`,
          });
          resetSelectedRows();
        })
        .catch(() => null);
    }
  };

  return (
    <CopyModal
      open={open}
      isLoadingSave={isUpdateLoading}
      isLoadingFetch={false}
      form={form}
      title="папку"
      fileTitle={copyFolder?.title || ''}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
