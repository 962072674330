import { BoxNode, ContentVolkswagenV1Node, GalleryNode, SnippetName } from '@cms/volkswagen-widgets';
import { Checkbox, Form, Tooltip } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { EditorContainer } from '@console/uikit';
import { AddSnippetButton, AddSnippetButtonProps } from '../../custom-inputs';
import { withSection } from '../../hocs';
import { GalleryColumnContent, GalleryColumnItem } from './components';

const galleryPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid', 'content', 0, 'gallery'];

const flexTextPath: TreePath = [
  'blueprint',
  'content',
  0,
  PLACEHOLDER_KEY_PATH,
  'grid',
  'content',
  1,
  'flex',
  'content',
  0,
  'flex',
];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const mapGalleryAdd = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  galleryItem: GalleryNode.Item.generators.entity({
    content: [
      BoxNode.generators.node({
        styles: [
          {
            height: '100%',
          },
        ],
        content: node,
      }),
    ],
  }),
});

const allowedSnippets: AddSnippetButtonProps['allowedSnippets'] = [SnippetName.Image, SnippetName.Video];

export const GalleryColumnEditor = withSection(
  ({ node, formListFieldData, onPlaceholderChange, sectionContentPath }) => {
    const { galleryData, flexData } = React.useMemo(() => {
      const galleryData = getPlaceholderedPath({
        node,
        commonPath: galleryPath,
      });

      const flexData = getPlaceholderedPath({
        node,
        commonPath: flexTextPath,
      });

      return { galleryData, flexData };
    }, [node]);

    const disabled = galleryData.placeholdersCount === 0;

    return (
      <>
        {/* placeholder edit */}
        <Form.Item>
          <Checkbox
            defaultChecked={galleryData.placeholdersCount === 2}
            disabled={disabled}
            onChange={(e) => onPlaceholderChange([...sectionContentPath, ...placeholderPath], e.target.checked)}
          >
            <Tooltip title="При включенной настройке после наследования сниппета или страницы содержащей сниппет на унаследованной версии будет доступно редактирование контента">
              Редактируемые настройки при наследовании
            </Tooltip>
          </Checkbox>
        </Form.Item>
        <EditorContainer label="Первая колонка">
          <Form.List name={[formListFieldData.name, ...galleryData.path, 'items']}>
            {(fields, { add, remove, move }) => (
              <>
                {fields.map((field, index) => (
                  <GalleryColumnItem
                    key={field.key}
                    move={move}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    isBottomMovable={fields.length > 1 && index < fields.length - 1}
                    isTopMovable={fields.length > 1 && index > 0}
                    contentPath={[...sectionContentPath, ...galleryData.path, 'items']}
                    allowedSnippets={allowedSnippets}
                  />
                ))}
                <AddSnippetButton
                  add={add}
                  mapBeforeAdd={mapGalleryAdd}
                  isDisabled={disabled}
                  label="Добавить медиа"
                  allowedSnippets={allowedSnippets}
                />
              </>
            )}
          </Form.List>
        </EditorContainer>
        <EditorContainer label="Вторая колонка">
          <Form.List name={[formListFieldData.name, ...flexData.path, 'content']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <GalleryColumnContent
                    key={field.key}
                    remove={remove}
                    index={index}
                    field={field}
                    disabled={disabled}
                    contentPath={[...sectionContentPath, ...flexData.path, 'content']}
                  />
                ))}
                {fields.length < 1 && (
                  <AddSnippetButton
                    add={add}
                    isDisabled={disabled}
                    label="Добавить контент"
                    allowedSnippets={[SnippetName.Quote, SnippetName.Richtext]}
                  />
                )}
              </>
            )}
          </Form.List>
        </EditorContainer>
      </>
    );
  },
  {
    disabledHorizontalBounds: true,
  },
);
