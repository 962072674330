import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { SnippetItemBlock } from '../../../custom-inputs';
import { TopMenuItemInput } from './top-menu-item-input';

export type TopMenuItemProps = {
  disabled: boolean;
  field: FormListFieldData;
  index: number;
  isBottomMovable: boolean;
  isTopMovable: boolean;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
};

export const TopMenuItem: React.FC<TopMenuItemProps> = ({
  disabled,
  field,
  index,
  isBottomMovable,
  isTopMovable,
  move,
  remove,
}) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
    >
      <Form.Item noStyle style={{ margin: 0 }} name={[field.name]} fieldKey={[field.fieldKey]}>
        <TopMenuItemInput index={index} field={field} disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};
