import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { WidgetName } from '@console/api';
import React from 'react';
import { MultiplyWidgetDrawer } from './components/multiply-widget-drawer';
import { SingleWidgetDrawer } from './components/single-widget-drawer';

export type AddWidgetDrawerProps = {
  allowedSnippets?: WidgetName[];
  disallowedSnippets?: WidgetName[];
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onSave: (node: ContentVolkswagenV1Widget) => void;
};

export const AddWidgetDrawer: React.FC<AddWidgetDrawerProps> = (props) => {
  const isSingleWidget = Boolean(props.allowedSnippets?.length === 1);

  return isSingleWidget ? (
    <SingleWidgetDrawer
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name={props.allowedSnippets![0]}
      loading={props.loading}
      onSave={props.onSave}
      open={props.open}
      onClose={props.onClose}
    />
  ) : (
    <MultiplyWidgetDrawer {...props} />
  );
};
