import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type UseQuery<T> = [T, (value: T) => void, () => void];

export const useSearch = <T extends string = string>(key: string, defaultValue?: T): UseQuery<T> => {
  const location = useLocation();
  const history = useHistory();

  const query = (new URLSearchParams(location.search).get(key) || defaultValue) as T;

  const clear = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);
    history.push({ search: searchParams.toString() });
  }, [history, key, location.search]);

  const set = useCallback(
    (value: T) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(key, value);
      history.push({ search: searchParams.toString() });
    },
    [history, key, location.search],
  );

  useEffect(() => {
    if (!query && defaultValue) {
      set(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [query, set, clear];
};
