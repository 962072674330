import { AttributesV1Attribute } from '@cms/volkswagen-widgets';
import { Select, SelectProps } from 'antd';
import React from 'react';

type PossibleValue = {
  value: string;
  label: React.ReactNode;
};

const DEFAULT_VALUE = 'DEFAULT_VALUE';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
export type AttributesSelectProps = {
  value?: AttributesV1Attribute[];
  onChange?: (value: AttributesV1Attribute[]) => void;
  attributeName: string;
  possibleValues: PossibleValue[];
  disabled: boolean;
};

export const AttributesSelect: React.FC<AttributesSelectProps> & {
  defaultValue: string;
} = ({ value, onChange, attributeName, possibleValues, disabled }) => {
  if (!value) {
    throw new Error(`Invalid structure AttributesSelect, not found attributes`);
  }

  const currentValue = value.find(({ name }) => name === attributeName)?.values?.[0] ?? DEFAULT_VALUE;

  const handleChange: SelectProps<string>['onChange'] = (selected) => {
    const newValue = value.filter(({ name }) => name !== attributeName);

    onChange &&
      onChange([...newValue, ...(selected === DEFAULT_VALUE ? [] : [{ name: attributeName, values: [selected] }])]);
  };

  return (
    <Select value={currentValue} onChange={handleChange} disabled={disabled}>
      {possibleValues.map((possibleValue, index) => (
        <Select.Option key={index} value={possibleValue.value}>
          {possibleValue.label}
        </Select.Option>
      ))}
    </Select>
  );
};

AttributesSelect.defaultValue = DEFAULT_VALUE;
