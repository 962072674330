import { ContentVolkswagenV1Node, SnippetName } from '@cms/volkswagen-widgets';
import { Alert, Button } from 'antd';
import React, { useEffect } from 'react';
import { useLoadSnippet } from 'src/hooks';

export type PreviewCheckerProps = {
  isPreviewExist: boolean;
  disabled: boolean;
  onCreateClick: (node: ContentVolkswagenV1Node) => void;
};

export const PreviewChecker: React.FC<PreviewCheckerProps> = ({
  isPreviewExist,
  disabled,
  children,
  onCreateClick,
}) => {
  const { snippet, isSnippetLoading, loadSnippet, resetState } = useLoadSnippet();

  useEffect(() => {
    if (snippet) {
      onCreateClick(snippet);
      resetState();
    }
  }, [snippet, onCreateClick, resetState]);

  const handleCreateClick = () => loadSnippet(SnippetName.TeaserItem);

  const message = `Превью для страницы отсутствует. ${disabled ? 'Редактирование превью запрещено' : ''}`;

  return isPreviewExist ? (
    <>{children}</>
  ) : (
    <Alert
      type="info"
      message={message}
      action={
        <Button type="primary" loading={isSnippetLoading} onClick={handleCreateClick} disabled={disabled}>
          Создать превью
        </Button>
      }
    />
  );
};
