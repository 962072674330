import {
  ContentState,
  convertFromRaw,
  DraftBlockType,
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
} from 'draft-js';

import { ConvertFromMdxConfig, FlowContent, ContractContent } from './types';
import { extractNode, extractRangesFromNodes, extractTextFromNodes } from './utils';
import { ContentVolkswagenV1Richtext, ContentVolkswagenV1Node } from '@cms/volkswagen-widgets';

const separate = <
  S = DraftInlineStyleType,
  B extends DraftBlockType = DraftBlockType,
  E extends RawDraftEntity = RawDraftEntity,
>(
  nodes: ContractContent[],
  config: ConvertFromMdxConfig<S, B, E>,
): ContractContent[] => {
  return nodes.reduce<ContractContent[]>((result, node) => {
    const [extractedNode, type] = extractNode(node);

    if (
      extractedNode &&
      'content' in extractedNode &&
      Array.isArray(extractedNode.content) &&
      extractedNode.content.length &&
      config.separationBlocks &&
      config.separationBlocks(node)
    ) {
      return [
        ...result,
        ...separate(extractedNode.content as FlowContent[], config).map((item) => {
          return {
            [type]: {
              ...node[type],
              content: [item],
            },
          };
        }),
      ];
    }

    if (
      extractedNode &&
      'items' in extractedNode &&
      Array.isArray(extractedNode.items) &&
      extractedNode.items.length &&
      config.separationBlocks &&
      config.separationBlocks(node)
    ) {
      return [
        ...result,
        ...separate(extractedNode.items as FlowContent[], config).map((item) => {
          return {
            [type]: {
              ...node[type],
              items: [item],
            },
          };
        }),
      ];
    }

    return [...result, node];
  }, []);
};

const getBlockContent = <
  S = DraftInlineStyleType,
  B extends DraftBlockType = DraftBlockType,
  E extends RawDraftEntity = RawDraftEntity,
>(
  rawNode: FlowContent,
  config: ConvertFromMdxConfig<S, B, E>,
): ContractContent[] => {
  const [node] = extractNode(rawNode);

  if (node) {
    if ('items' in node && Array.isArray(node.items)) {
      return node.items;
    }

    if ('content' in node && Array.isArray(node.content)) {
      // если контент блока тоже блочный
      // берём первую ноду в контенте т.к. она в случае вложенности блока в блок всегда единственная
      // т.к. до этого была сделана сепарация с помощью функции separate()
      if (node.content[0] && config.blockFrom(node.content[0])) {
        const [extractedChildNode] = extractNode<ContentVolkswagenV1Node>(node.content[0]);

        if ('content' in extractedChildNode && Array.isArray(extractedChildNode.content)) {
          return extractedChildNode.content;
        }

        if ('items' in extractedChildNode && extractedChildNode.items && Array.isArray(extractedChildNode.items)) {
          return extractedChildNode.items;
        }
      }

      return node.content;
    }

    return [node] as ContractContent[];
  }

  return [];
};

export const convertFrom =
  <S = DraftInlineStyleType, B extends DraftBlockType = DraftBlockType, E extends RawDraftEntity = RawDraftEntity>(
    config: ConvertFromMdxConfig<S, B, E>,
  ) =>
  (content: ContentVolkswagenV1Richtext['content']): ContentState => {
    const state: RawDraftContentState = {
      blocks: [],
      entityMap: {},
    };

    const defaultBlock: RawDraftContentBlock = {
      key: '',
      type: 'unstyled',
      text: '',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
    };

    const separatedNodes = separate(content || [], config);

    const preparedState = separatedNodes.reduce<RawDraftContentState>((result, node) => {
      const nodes = getBlockContent(node, config);

      const { inlineStyleRanges, entityRanges, entityMap } = extractRangesFromNodes<S>(
        nodes,
        result.entityMap,
        config.styleFrom,
        config.entityFrom,
      );

      result.blocks = [
        ...result.blocks,
        {
          ...defaultBlock,
          ...config.blockFrom(node as FlowContent),
          text: extractTextFromNodes(nodes).replace(/\n/g, ''),
          inlineStyleRanges,
          entityRanges,
        },
      ];

      result.entityMap = entityMap;

      return {
        ...result,
      };
    }, state);

    return convertFromRaw(preparedState);
  };
