import { ContentVolkswagenV1Node, FeatureClusterNode } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import React from 'react';
import { TreePath } from 'src/utils/content-path';
import { AddSnippetButtonProps, RichtextTextNodesEditor, SnippetItemBlock } from '../../../custom-inputs';

export type FeatureClusterItemProps = {
  field: FormListFieldData;
  move: FormListOperation['move'];
  remove: FormListOperation['remove'];
  disabled: boolean;
  isTopMovable: boolean;
  isBottomMovable: boolean;
  index: number;
  featureClusterItem: TreePath;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
};

const mapFeatureClusterItem = (node: ContentVolkswagenV1Node): ContentVolkswagenV1Node => ({
  featureClusterItem: FeatureClusterNode.Item.generators.entity({
    content: [node],
    header: [],
  }),
});

export const FeatureClusterItem: React.FC<FeatureClusterItemProps> & {
  mapper: typeof mapFeatureClusterItem;
} = ({ field, disabled, move, remove, isBottomMovable, isTopMovable, index, featureClusterItem, allowedSnippets }) => {
  return (
    <SnippetItemBlock
      disabled={disabled}
      onDelete={() => remove(field.name)}
      onMoveTop={isTopMovable ? () => move(index, index - 1) : undefined}
      onMoveBottom={isBottomMovable ? () => move(index, index + 1) : undefined}
      contentPath={[...featureClusterItem, index, 'featureClusterItem', 'content', 0]}
      allowedSnippets={allowedSnippets}
    >
      <Form.Item
        label={`Заголовок итема #${index + 1}`}
        name={[field.name, 'featureClusterItem', 'header']}
        fieldKey={[field.fieldKey, 'featureClusterItem', 'header']}
        style={{ marginBottom: 0 }}
      >
        <RichtextTextNodesEditor disabled={disabled} />
      </Form.Item>
    </SnippetItemBlock>
  );
};

FeatureClusterItem.mapper = mapFeatureClusterItem;
