import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TabsNode } from '@cms/volkswagen-widgets';
import { Button, Checkbox, Form, FormProps, Input, Space } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import styled from 'styled-components';
import { RichtextTextNodesEditor } from '../../custom-inputs';
import { usePlaceholderActions } from '../../hooks';
import { NodeEditorProps } from '../../types';

const tabsPath: TreePath = ['blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'tabs'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'placeholder'];

const StyledSpace = styled(Space)`
  display: flex;
  margin-bottom: 8px;

  .ant-space-item:nth-child(2) {
    width: 100%;
  }
`;

export const TabLinksEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const { tabsData } = React.useMemo(() => {
    const tabsData = getPlaceholderedPath({
      node,
      commonPath: tabsPath,
    });

    return { tabsData };
  }, [node]);

  const disabled = tabsData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={tabsData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>

      <Form.List name={[...tabsData.path, 'items']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <StyledSpace key={key} align="baseline">
                <Form.Item
                  {...restField}
                  label="Ссылка"
                  name={[name, 'tabsLink', 'source']}
                  fieldKey={[fieldKey, 'tabsLink', 'source']}
                  rules={[{ required: true }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>
                <Form.Item
                  label="Заголовок"
                  name={[name, 'tabsLink', 'content']}
                  fieldKey={[fieldKey, 'tabsLink', 'content']}
                >
                  <RichtextTextNodesEditor disabled={disabled} />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </StyledSpace>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add(
                    TabsNode.Link.generators.node({
                      source: '',
                      content: [],
                    }),
                  )
                }
                block
                icon={<PlusOutlined />}
              >
                Добавить таб
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
