import { DraftDecorator } from 'draft-js';
import React from 'react';
import styled from 'styled-components';
import { DecoratorComponentProps } from '../../../../types';
import { findEntities } from '../../../../utils';
import { IconEntityType, IconProps, IconVariant } from './types';
import { d6 } from '@cms/volkswagen-widgets';

const { Icons } = d6;

const StyledWrapper = styled.span<{ color?: string }>`
  position: relative;
  color: ${(props) => props.color};

  svg {
    margin-right: -2px;
  }
`;

export const variants: IconVariant[] = ['small', 'default', 'large'];

export const getIconComponent = (
  kind: string,
): {
  IconComponent: React.ComponentType<Pick<IconProps, 'variant'>> | null;
  availableVariants: typeof variants;
} => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Icon = Icons[kind];

  if (!Icon || !Icon.render) {
    return { IconComponent: null, availableVariants: [] };
  }

  return {
    IconComponent: Icon,
    availableVariants: variants.filter((variant) => {
      return Icon.render({ variant });
    }),
  };
};

const Icon: React.FC<DecoratorComponentProps> = (props) => {
  const { kind, variant, color }: IconProps = props.contentState.getEntity(props.entityKey).getData();

  const { IconComponent } = getIconComponent(kind);

  return (
    <StyledWrapper color={color}>
      {IconComponent && <IconComponent variant={variant} />}
      <span>{props.children}</span>
    </StyledWrapper>
  );
};

export const iconDecorator: DraftDecorator = {
  strategy: findEntities<IconEntityType>('ICON'),
  component: Icon,
};
