import { ContentVolkswagenV1Widget } from '@cms/volkswagen-widgets';
import { Form } from 'antd';
import React, { useState } from 'react';
import { AddWidgetDrawer, AddWidgetDrawerProps } from 'src/components/drawers';
import { WidgetEditorDrawer } from 'src/components/drawers/widget-editor-drawer';
import { TreePath } from 'src/utils/content-path';

enum DrawerType {
  edit = 'edit',
  replace = 'replace',
}

export type SnippetsSelectProps = {
  contentPath?: TreePath;
  children: ({
    handleOpenEditDrawer,
    handleOpenReplaceDrawer,
    currentDrawer,
  }: {
    source?: string;
    handleOpenEditDrawer?: () => void;
    handleOpenReplaceDrawer?: () => void;
    currentDrawer?: DrawerType;
  }) => React.ReactNode;
  allowedSnippets?: AddWidgetDrawerProps['allowedSnippets'];
  disallowedSnippets?: AddWidgetDrawerProps['disallowedSnippets'];
};

export const SnippetsSelect: React.FC<SnippetsSelectProps> = ({
  contentPath,
  allowedSnippets,
  disallowedSnippets,
  children,
}) => {
  const [currentDrawer, setCurrentDrawer] = useState<DrawerType>();

  const handleCloseDrawer = () => setCurrentDrawer(undefined);
  const handleOpenEditDrawer = () => setCurrentDrawer(DrawerType.edit);
  const handleOpenReplaceDrawer = () => setCurrentDrawer(DrawerType.replace);

  if (!contentPath) {
    return <>{children({})}</>;
  }

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, setFields }) => {
          const node: ContentVolkswagenV1Widget = getFieldValue(contentPath);

          return (
            <>
              {children({
                source: node?.blueprint?.source,
                currentDrawer,
                handleOpenEditDrawer,
                handleOpenReplaceDrawer,
              })}
              <AddWidgetDrawer
                open={currentDrawer === DrawerType.replace || (currentDrawer === DrawerType.edit && !node)}
                onClose={handleCloseDrawer}
                onSave={(node) => {
                  setFields([
                    {
                      name: contentPath,
                      value: node,
                    },
                  ]);
                  handleCloseDrawer();

                  return Promise.resolve();
                }}
                loading={false}
                allowedSnippets={allowedSnippets}
                disallowedSnippets={disallowedSnippets}
              />
              <WidgetEditorDrawer
                node={currentDrawer === DrawerType.edit ? node : null}
                loading={false}
                title="Изменение сниппета"
                onClose={handleCloseDrawer}
                onSave={(node) => {
                  // handleCloseEdit необходимо вызывать перед setFields,
                  // т.к. внутренняя форма начинает ререндер по старым путям(<Form.Item shouldUpdate />),
                  // вследствие чего мутация плейсхолдеров ломает структуру
                  handleCloseDrawer();
                  setFields([
                    {
                      name: contentPath,
                      value: node,
                    },
                  ]);
                }}
              />
            </>
          );
        }}
      </Form.Item>
    </>
  );
};
