import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { useSites } from '@console/api';
import { Form } from 'antd';
import React from 'react';

import { CreateSiteFormValues, CreateSiteModal } from './components/create-site-modal';

export type CreateSiteModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateSiteModalContainer: React.FC<CreateSiteModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<CreateSiteFormValues>();

  const {
    meta: { isCreateLoading },
    operations: { createSiteAsync },
  } = useSites({ enabled: false });

  const handleSubmit = (values: CreateSiteFormValues) => {
    createSiteAsync({
      ...values,
      path: '/' + values.path.map((p) => p.value).join('/'),
      activity: { status: ActivityV1Status.Active },
      visibility: { status: VisibilityV1Status.Private },
    })
      .then(() => {
        form.resetFields();
        onClose();
      })
      .catch(() => null);
  };

  return (
    <CreateSiteModal open={open} loading={isCreateLoading} form={form} onClose={onClose} onSubmit={handleSubmit} />
  );
};
