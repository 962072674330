import { Form } from 'antd';
import { styled } from '@console/uikit';

export const StyledInlineFormItem = styled(Form.Item)`
  &&& {
    display: flex;
    flex-direction: row;

    > * + * {
      margin-left: 15px;
    }

    > * {
      display: flex;
      padding: 0;
    }
  }
`;
