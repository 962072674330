import React from 'react';
import { useFiles } from '@console/api';
import { Autocomplete, AutocompleteOptionItem } from '@console/uikit';
import { getFilePath } from 'src/utils/entities';
import { getLastPathParam } from 'src/utils/string';

// After wrapped by Form.Item with name property,
// value(or other property defined by valuePropName)
// onChange(or other property defined by trigger)
// props will be added to form controls
// https://ant.design/components/form/#Form.Item
type PagePathFormItemContainerProps = {
  value?: AutocompleteOptionItem[];
  onChange?: (values: AutocompleteOptionItem[]) => void;
};

export const PagePathFormItemContainer: React.FC<PagePathFormItemContainerProps> = ({ value = [], onChange }) => {
  const {
    meta: { isListLoading: loadingOptions },
    models: { filesResponse },
  } = useFiles({
    enabled: true,
    path: value?.length ? `/${value.map((v) => v.value).join('/')}/*` : '/*',
    types: ['files.v1.folder', 'files.v1.folderBlueprint'],
  });

  const options = React.useMemo(
    () =>
      filesResponse?.files?.map<AutocompleteOptionItem>((file) => ({
        label: getLastPathParam(getFilePath(file) || ''),
        value: getLastPathParam(getFilePath(file) || ''),
      })) ?? [],
    [filesResponse],
  );

  const handleChange = (values: AutocompleteOptionItem[]) => {
    onChange && onChange(values);
  };

  return <Autocomplete values={value} options={options} loadingOptions={loadingOptions} onChange={handleChange} />;
};
