import { Form } from 'antd';
import React from 'react';
import { useMediaPageContext } from '../../contexts/media-context';
import { EditFolderFormValues, EditFolderModal } from 'src/components/files';

export type CreateFolderModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const CreateFolderModalContainer: React.FC<CreateFolderModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<EditFolderFormValues>();

  const { isCreateLoading, createFolder } = useMediaPageContext();

  const handleSubmit = (values: EditFolderFormValues) => {
    createFolder(values.name, values.title).then(() => {
      form.resetFields();
      onClose();
    });
  };

  return (
    <EditFolderModal
      open={open}
      loading={isCreateLoading}
      form={form}
      onClose={onClose}
      onSubmit={handleSubmit}
      isCreate
    />
  );
};
