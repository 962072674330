import { EditOutlined, DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { styled } from '@console/uikit';
import { Button, Row, Space } from 'antd';
import React from 'react';

export type UsersListHeaderControlProps = {
  onClearSelection: () => void;
  onEditClick: () => void;
  onRemoveClick: () => void;
};

const StyledRow = styled(Row)`
  height: 100%;
`;

export const UsersListHeaderControl: React.FC<UsersListHeaderControlProps> = ({
  onClearSelection,
  onEditClick,
  onRemoveClick,
}) => {
  return (
    <StyledRow justify="space-between" align="middle">
      <Space>
        <Button icon={<EditOutlined />} onClick={onEditClick}>
          Редактировать
        </Button>
        <Button icon={<DeleteOutlined />} onClick={onRemoveClick}>
          Удалить
        </Button>
      </Space>
      <Button icon={<UndoOutlined />} type="link" onClick={onClearSelection}>
        Сбросить выделение
      </Button>
    </StyledRow>
  );
};
