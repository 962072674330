import { FileService } from '@console/api';
import React from 'react';
import { PageFileSettingsModal, PageFileSettingsModalProps } from 'src/containers';
import { usePageDetail, ViewState } from '../../contexts';

export type PageSettingsModalContainerProps = {
  fileService: FileService;
};

export const PageSettingsModalContainer: React.FC<PageSettingsModalContainerProps> = ({ fileService }) => {
  const { isVisible, setDefaultState } = usePageDetail(ViewState.settings);

  const isBlueprint = !!fileService.models.fileResponse?.volkswagenV1PageBlueprint;

  const handleUpdatePage: PageFileSettingsModalProps['updatePage'] = ({ path, props, onSuccess }) => {
    fileService.operations.updateFile(
      {
        filePath: path,
        body: {
          [!isBlueprint ? 'volkswagenV1Page' : 'volkswagenV1PageBlueprint']: {
            ...props,
          },
        },
      },
      {
        onSuccess,
      },
    );
  };

  return (
    <PageFileSettingsModal
      pageData={
        fileService.models.fileResponse?.volkswagenV1Page || fileService.models.fileResponse?.volkswagenV1PageBlueprint
      }
      updatePage={handleUpdatePage}
      isLoading={fileService.meta.isFileLoading}
      isUpdateLoading={fileService.meta.isUpdateLoading}
      isVisible={isVisible}
      type={PageFileSettingsModal.type.Page}
      onCancel={setDefaultState}
      isBlueprint={isBlueprint}
    />
  );
};
