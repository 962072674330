import { ActivityV1Status, VisibilityV1Status } from '@cms/volkswagen-widgets';
import { useFile } from '@console/api';
import { generateOptions } from '@console/uikit';
import { Form, notification } from 'antd';
import React, { useEffect } from 'react';
import { getLastPathParam, removeLastPathParam } from 'src/utils/string';
import { CopyFormValues, CopyModal } from '../../../../components';
import { removeIdsOnPage } from '../../../../utils/snippets';
import { useFilesContext } from '../../contexts';

export type CopyPageModalContainerProps = {
  open: boolean;
  onClose: () => void;
};

export const CopyPageModalContainer: React.FC<CopyPageModalContainerProps> = ({ open, onClose }) => {
  const [form] = Form.useForm<CopyFormValues>();

  const {
    copyPage,
    service: {
      meta: { isCreateLoading },
      operations: { createFileAsync },
    },
    resetSelectedRows,
  } = useFilesContext();

  const {
    models: { fileResponse },
    meta: { isFileLoading },
  } = useFile({
    enabled: open && Boolean(copyPage),
    path: `${copyPage?.path}`,
  });

  const fullPage = fileResponse?.volkswagenV1Page;

  useEffect(() => {
    if (open) {
      if (copyPage) {
        form.setFieldsValue({
          name: `${getLastPathParam(copyPage.path)}-copy`,
          title: copyPage.title,
          path: generateOptions(removeLastPathParam(copyPage.path).substring(1)),
        });
      } else {
        form.resetFields();
      }
    }
  }, [open, copyPage, form]);

  const handleCopySubmit = (values: CopyFormValues) => {
    if (fullPage) {
      const path = `/${values.path.map((p) => p.value).join('/')}`;

      createFileAsync({
        filesV1Blueprint: {
          path: `${path}/${values.name}`,
          source: fullPage.path,
          title: values.title,
          activity: { status: ActivityV1Status.Active },
          visibility: { status: VisibilityV1Status.Public },
        },
      })
        .then(() => {
          onClose();
          notification.success({
            message: `Создана ссылка на страницу ${fullPage.title} в ${path}`,
          });
          resetSelectedRows();
        })
        .catch(() => null);
    }
  };

  const handleFullCopySubmit = (values: CopyFormValues) => {
    if (fullPage) {
      const path = `/${values.path.map((p) => p.value).join('/')}`;
      const newPage = removeIdsOnPage(fullPage);

      createFileAsync({
        volkswagenV1Page: {
          ...newPage,
          path: `${path}/${values.name}`,
          title: values.title,
        },
      })
        .then(() => {
          onClose();
          notification.success({
            message: `Создана новая страница ${values.title} в ${path}`,
          });
          resetSelectedRows();
        })
        .catch(() => null);
    }
  };

  return (
    <CopyModal
      open={open}
      isLoadingSave={isCreateLoading}
      isLoadingFetch={isFileLoading}
      form={form}
      title="страницу"
      fileTitle={copyPage?.title || ''}
      isFullCopyAvailable
      onClose={onClose}
      onSubmit={(values) => {
        if (values.isFullCopy) {
          handleFullCopySubmit(values);
        } else {
          handleCopySubmit(values);
        }
      }}
    />
  );
};
