import { DraftDecorator } from 'draft-js';
import React from 'react';
import { DecoratorComponentProps } from '../../../../types';
import { findEntities } from '../../../../utils';
import { LinkEntityProps, LinkEntityType } from './types';
import { d6 } from '@cms/volkswagen-widgets';

const { CustomCTA } = d6;

const Link: React.FC<DecoratorComponentProps> = (props) => {
  const linkProps: LinkEntityProps = props.contentState.getEntity(props.entityKey).getData();
  return (
    <CustomCTA {...linkProps} emphasis={linkProps.emphasis || 'none'}>
      {props.children}
    </CustomCTA>
  );
};

export const linkDecorator: DraftDecorator = {
  strategy: findEntities<LinkEntityType>('LINK'),
  component: Link,
};
