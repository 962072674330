import {
  ContentFilesV1CreateFileProps,
  ContentFilesV1File,
  ContentFilesV1FilesAPIApi,
  GoogleRpcStatus,
} from '@cms/volkswagen-widgets';
import { notification } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, UseMutateAsyncFunction, UseMutateFunction, useMutation, useQueryClient } from 'react-query';

type Data = AxiosResponse<ContentFilesV1File>;
type Error = AxiosError<GoogleRpcStatus>;
type Variables = ContentFilesV1CreateFileProps;

export type UseCreateFileData = {
  createFile: UseMutateFunction<Data, Error, Variables>;
  createFileAsync: UseMutateAsyncFunction<Data, Error, Variables>;
  isCreateLoading: boolean;
};

export function useCreateFile(
  filesApi: ContentFilesV1FilesAPIApi,
  queryKey: QueryKey,
  isInvalidate = true,
): UseCreateFileData {
  const queryClient = useQueryClient();

  const {
    mutate: createFile,
    mutateAsync: createFileAsync,
    isLoading: isCreateLoading,
  } = useMutation<Data, Error, Variables>((body) => filesApi.contentFilesV1FilesAPICreateFile(body), {
    onSuccess: () => {
      if (isInvalidate) {
        queryClient.invalidateQueries(queryKey);
      }
    },
    onError: (error) => {
      notification.error({
        message: 'Ошибка создания файла',
        description: error?.response?.data.message,
      });
    },
  });

  return { createFile, createFileAsync, isCreateLoading };
}
