import { ContentSitesV1ListDomainsResponse } from '@cms/volkswagen-widgets';
import { domainsApi, Service } from '../../../';
import { useCreateDomain, UseCreateDomainData } from './actions/useCreateDomain';
import { GetDomainsParams, useGetDomains } from './actions/useGetDomains';
import { useRemoveDomain, UseRemoveDomainData } from './actions/useRemoveDomain';
import { useUpdateDomain, UseUpdateDomainData } from './actions/useUpdateDomain';

type Models = {
  domainsResponse: ContentSitesV1ListDomainsResponse | undefined;
};

type Operations = {
  createDomain: UseCreateDomainData['createDomain'];
  createDomainAsync: UseCreateDomainData['createDomainAsync'];
  updateDomain: UseUpdateDomainData['updateDomain'];
  updateDomainAsync: UseUpdateDomainData['updateDomainAsync'];
  removeDomain: UseRemoveDomainData['removeDomain'];
  removeDomainAsync: UseRemoveDomainData['removeDomainAsync'];
};

type Meta = {
  isListLoading: boolean;
  isListFetching: boolean;
  isCreateLoading: boolean;
  isUpdateLoading: boolean;
  isRemoveLoading: boolean;
};

type Parameters = GetDomainsParams;

export type DomainsService = Service<Models, Operations, Meta>;

export const useDomains = (params: Parameters): DomainsService => {
  const { site } = params;
  const queryKey = ['domains', site];

  const { isListFetching, isListLoading, domainsResponse } = useGetDomains(domainsApi, queryKey, params);

  const { isCreateLoading, createDomain, createDomainAsync } = useCreateDomain(domainsApi, queryKey, site);
  const { isUpdateLoading, updateDomain, updateDomainAsync } = useUpdateDomain(domainsApi, queryKey, site);
  const { isRemoveLoading, removeDomain, removeDomainAsync } = useRemoveDomain(domainsApi, queryKey, site);

  return {
    models: {
      domainsResponse,
    },
    operations: {
      createDomain,
      createDomainAsync,
      updateDomain,
      updateDomainAsync,
      removeDomain,
      removeDomainAsync,
    },
    meta: {
      isListLoading,
      isListFetching,
      isCreateLoading,
      isUpdateLoading,
      isRemoveLoading,
    },
  };
};
