import { Button, Form, Space } from 'antd';
import React from 'react';
import { AddSnippetButtonProps, SnippetsSelect } from 'src/components/editors/custom-inputs';
import { TreePath } from 'src/utils/content-path';

export type ContentButtonsInputProps = {
  contentPath: TreePath;
  label: string;
  onDelete?: () => void;
  allowedSnippets?: AddSnippetButtonProps['allowedSnippets'];
  disallowedSnippets?: AddSnippetButtonProps['disallowedSnippets'];
  hideReplaceButton?: boolean;
};

export const ContentButtonsInput: React.FC<ContentButtonsInputProps> = ({
  contentPath,
  label,
  onDelete,
  allowedSnippets,
  disallowedSnippets,
  hideReplaceButton,
}) => (
  <Form.Item label={label}>
    <SnippetsSelect contentPath={contentPath} allowedSnippets={allowedSnippets} disallowedSnippets={disallowedSnippets}>
      {({ source, handleOpenEditDrawer, handleOpenReplaceDrawer }) => (
        <Space>
          {source ? (
            <>
              <Button onClick={handleOpenEditDrawer}>Изменить</Button>
              {!hideReplaceButton && <Button onClick={handleOpenReplaceDrawer}>Заменить</Button>}
              {onDelete && (
                <Button onClick={onDelete} danger>
                  Удалить
                </Button>
              )}
            </>
          ) : (
            <>
              <Button onClick={handleOpenReplaceDrawer}>Добавить</Button>
            </>
          )}
        </Space>
      )}
    </SnippetsSelect>
  </Form.Item>
);
