import { UsersV1User } from '@cms/volkswagen-widgets';
import { useUsers } from '@console/api/src/modules/user';
import React, { useState } from 'react';
import { MainLayout } from '../../components';
import { UsersListHeader } from './components/users-list-header';
import { CreateUserModalContainer } from './containers/create-user-modal-container';
import { UsersTableContainer } from './containers/users-table';
import { UsersPageContextProvider, UsersTabs } from './contexts/users-context';

export const UsersPage: React.FC = () => {
  const [selectedUsers, setSelectedUsers] = useState<UsersV1User[]>([]);
  const [editUser, setEditUser] = useState<UsersV1User | boolean>(false);
  const [tab, setTab] = useState<UsersTabs>(UsersTabs.UserList);

  const service = useUsers({
    enabled: true,
  });

  const handleClearSelection = () => setSelectedUsers([]);

  return (
    <UsersPageContextProvider
      value={{
        tab,
        setTab,
        selectedUsers,
        setSelectedUsers,
        service,
        editUser,
        setEditUser,
      }}
    >
      <MainLayout
        header={
          <UsersListHeader
            selectedUsers={selectedUsers}
            onClearSelection={handleClearSelection}
            onEditClick={() => null}
            onRemoveClick={() => null}
            onCreateClick={() => setEditUser(true)}
          />
        }
      >
        {tab === UsersTabs.UserList && <UsersTableContainer />}
        {tab === UsersTabs.GroupList && <>Groups</>}
        <CreateUserModalContainer
          open={Boolean(editUser)}
          onClose={() => setEditUser(false)}
          isCreate={typeof editUser === 'boolean'}
        />
      </MainLayout>
    </UsersPageContextProvider>
  );
};
