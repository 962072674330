import { ImageNode, ParagraphNode, RichtextNode, TextNode } from '@cms/volkswagen-widgets';
import { Checkbox, Form, FormProps, Input } from 'antd';
import React from 'react';
import { getPlaceholderedPath, PLACEHOLDER_KEY_PATH, TreePath } from 'src/utils/content-path';
import { AddEnrichedNodeButton } from '../../custom-inputs';
import { ImageNodeInputs } from '../../groups/image-node-inputs';
import { usePlaceholderActions } from '../../hooks';
import { RichtextFormItem } from '../../node-editors';
import { NodeEditorProps } from '../../types';
import { BundlesFeature } from './components/bundles-feature';

const defaultFeature = RichtextNode.generators.node({
  content: [
    ParagraphNode.generators.node({
      content: [
        ImageNode.generators.node({
          attributes: [
            {
              name: ImageNode.attributes.kind,
              values: ['Checkmark'],
            },
            {
              name: ImageNode.attributes.variant,
              values: ['small'],
            },
            {
              name: ImageNode.attributes.color,
              values: ['#001E50'],
            },
          ],
        }),
      ],
    }),
    ParagraphNode.generators.node({
      content: [
        TextNode.generators.node({
          content: '',
          attributes: [
            {
              name: TextNode.attributes.appearance,
              values: ['label150'],
            },
          ],
        }),
      ],
    }),
  ],
});

const mainGridPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, PLACEHOLDER_KEY_PATH, 'grid'];

const placeholderPath: TreePath = ['blueprint', 'content', 0, 'blueprint', 'content', 0, 'placeholder'];

export const BundlesCarouselCarFragmentEditor: React.FC<NodeEditorProps> = ({ formName, node, onSave }) => {
  const { onPlaceholderChange, mutateNodePlaceholders } = usePlaceholderActions();

  const handleFinish: FormProps['onFinish'] = (values) => {
    mutateNodePlaceholders(values);
    onSave(values);
  };

  const {
    backgroundData,
    contentFeaturesItemsData,
    featuresTitleTextData,
    contentRichtextData,
    mainGridData,
    srcImageData,
    titleRichtextData,
  } = React.useMemo(() => {
    const mainGridData = getPlaceholderedPath({
      node,
      commonPath: mainGridPath,
    });
    const contentGridData = getPlaceholderedPath({
      node,
      commonPath: [...mainGridData.path, 'content', 1, 'flex', 'content', 0, 'grid', 'content'],
    });
    // content
    const contentRichtextData = getPlaceholderedPath({
      node,
      commonPath: [...contentGridData.path, 0, 'blueprint', 'content', 0, 'richtext'],
    });
    // featuresTitle
    const featuresTitleTextData = getPlaceholderedPath({
      node,
      commonPath: [
        ...contentGridData.path,
        1,
        'grid',
        'content',
        0,
        'grid',
        'content',
        0,
        'paragraph',
        'content',
        0,
        'strong',
        'content',
        0,
        'text',
      ],
    });
    // featuresItems
    const contentFeaturesItemsData = getPlaceholderedPath({
      node,
      commonPath: [...contentGridData.path, 1, 'grid', 'content', 1, 'grid', 'content'],
    });
    // background
    const backgroundData = getPlaceholderedPath({
      node,
      commonPath: [...mainGridData.path, 'content', 0, 'flex', 'content', 0, 'box', 'content', 'background'],
    });
    const boxGridContentData = getPlaceholderedPath({
      node,
      commonPath: [...backgroundData.path, 'content', 'grid', 'content'],
    });
    // title
    const titleRichtextData = getPlaceholderedPath({
      node,
      commonPath: [...boxGridContentData.path, 0, 'flex', 'content', 0, 'blueprint', 'content', 0, 'richtext'],
    });
    // src
    const srcImageData = getPlaceholderedPath({
      node,
      commonPath: [...boxGridContentData.path, 2, 'flex', 'content', 0, 'box', 'content', 'image'],
    });

    return {
      backgroundData,
      contentFeaturesItemsData,
      featuresTitleTextData,
      contentRichtextData,
      mainGridData,
      srcImageData,
      titleRichtextData,
    };
  }, [node]);

  const disabled = mainGridData.placeholdersCount === 0;

  return (
    <Form name={formName} initialValues={node} onFinish={handleFinish}>
      {/* hack, used for deep cloning node to antd Form state */}
      <Form.Item name="blueprint" noStyle>
        <Input type="hidden" />
      </Form.Item>

      {/* placeholder edit */}
      <Form.Item>
        <Checkbox
          defaultChecked={mainGridData.placeholdersCount === 2}
          disabled={disabled}
          onChange={(e) => onPlaceholderChange(placeholderPath, e.target.checked)}
        >
          Редактируемое
        </Checkbox>
      </Form.Item>
      <RichtextFormItem label="Заголовок" contentFieldName={titleRichtextData.path} disabled={disabled} />
      <RichtextFormItem label="Контент" contentFieldName={contentRichtextData.path} disabled={disabled} />
      <ImageNodeInputs imagePath={srcImageData.path} disabled={disabled} required />
      <Form.Item label="Цвет фона" name={[...backgroundData.path, 'styles', 0, 'color']} rules={[{ required: true }]}>
        <Input disabled={disabled} />
      </Form.Item>

      {/* features */}
      <Form.Item
        label="Заголовок особенностей"
        name={[...featuresTitleTextData.path, 'content']}
        rules={[{ required: true }]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.List name={contentFeaturesItemsData.path}>
        {(fields, { add, remove, move }) => (
          <>
            {fields.map((field, index) => (
              <BundlesFeature
                key={field.key}
                move={move}
                remove={remove}
                index={index}
                field={field}
                disabled={disabled}
                isBottomMovable={fields.length > 1 && index < fields.length - 1}
                isTopMovable={fields.length > 1 && index > 0}
              />
            ))}

            <AddEnrichedNodeButton
              add={add}
              nodeToEnrich={defaultFeature}
              isDisabled={disabled}
              label="Добавить особенность"
            />
          </>
        )}
      </Form.List>
    </Form>
  );
};
