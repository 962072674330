import { UsersV1User } from '@cms/volkswagen-widgets';
import { styled } from '@console/uikit';
import { Button, Input, Space, Typography } from 'antd';
import React from 'react';
import { UsersListHeaderControl, UsersListHeaderControlProps } from './components/control';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const StyledControlsWrapper = styled(Space)`
  line-height: 1;
`;

export type UsersListHeaderProps = UsersListHeaderControlProps & {
  selectedUsers: UsersV1User[];
  onCreateClick: () => void;
};

export const UsersListHeader: React.FC<UsersListHeaderProps> = ({ selectedUsers, onCreateClick, ...props }) => {
  // const { tab, setTab } = useUsersPageContext();

  return (
    <>
      {selectedUsers.length ? (
        <UsersListHeaderControl {...props} />
      ) : (
        <StyledWrapper>
          <Typography.Text>Список пользователей</Typography.Text>
          {/*<Tabs activeKey={`${tab}`} onChange={(key) => setTab(Number(key))}>*/}
          {/*  <TabPane tab="Список пользователей" key={UsersTabs.UserList} />*/}
          {/*  <TabPane tab="Список групп" key={UsersTabs.GroupList} />*/}
          {/*</Tabs>*/}
          <StyledControlsWrapper>
            <Input.Search
              placeholder="Поиск пользователей"
              allowClear
              enterButton
              onSearch={() => null}
              style={{ width: 300 }}
            />
            <Button type="primary" onClick={onCreateClick}>
              Добавить
            </Button>
          </StyledControlsWrapper>
        </StyledWrapper>
      )}
    </>
  );
};
