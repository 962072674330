import { Drawer } from 'antd';
import React from 'react';
import { usePageDetail, ViewState } from '../contexts';

export const StructureDrawer: React.FC = () => {
  const { isVisible, setDefaultState } = usePageDetail(ViewState.structure);

  return (
    <Drawer title="Структура" visible={isVisible} onClose={setDefaultState}>
      В разработке...
    </Drawer>
  );
};
