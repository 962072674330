import React, { useCallback } from 'react';
import { css } from 'styled-components';
import { useMixedInlineWithBlockData } from '../../../../hooks';
import { ControlsProps } from '../../../../types';
import { ControlButton } from '../../control-button';
import { StyledControl, StyledControlsBlock } from '../../../../elements';
import { PLACEHOLDER_KEY } from './types';

export * from './types';
export * from './decorator';

export const placeholderStyles = css`
  *[class*='${PLACEHOLDER_KEY}'] {
    padding: 3px 5px;
    border: 1px dashed #91d5ff;
    margin: 4px 8px;
  }
`;

export const PlaceholderEntity: React.FC<ControlsProps> = React.memo<ControlsProps>(
  ({ editorState, setEditorState }) => {
    const [createPlaceholder, currentBlock, currentStyle, togglePlaceholder, isSelectedBlock] =
      useMixedInlineWithBlockData<string>(editorState, setEditorState, `${PLACEHOLDER_KEY}`);

    const currentPlaceholderStyle = currentStyle.toArray().find((style) => style.includes(PLACEHOLDER_KEY));

    const currentPlaceholderBlock = currentBlock.getData().has(PLACEHOLDER_KEY);

    const handlePlaceholder = useCallback(() => {
      if (isSelectedBlock) {
        createPlaceholder('new');
      } else {
        togglePlaceholder('new');
      }
    }, [createPlaceholder, isSelectedBlock, togglePlaceholder]);

    const isActive = Boolean(currentPlaceholderStyle || currentPlaceholderBlock);

    return (
      <StyledControlsBlock>
        <StyledControl>
          <ControlButton
            title="Плейсхолдер"
            isActive={isActive}
            disabled={Boolean(currentPlaceholderStyle) || (Boolean(currentPlaceholderBlock) && isSelectedBlock)}
            value={PLACEHOLDER_KEY}
            onToggle={handlePlaceholder}
          >
            Плейсхолдер
            {currentPlaceholderStyle ? ': строчный' : ''}
            {currentPlaceholderBlock ? ': блочный' : ''}
          </ControlButton>
        </StyledControl>
      </StyledControlsBlock>
    );
  },
);

PlaceholderEntity.displayName = 'PlaceholderEntity';
