import { OrderedListOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { d6 } from '@cms/volkswagen-widgets';
import React from 'react';
import { ConfigType, useBlock, useConfigByStyles } from '../../../hooks';
import { ControlButton } from '../control-button';
import { BlockRenderMapType, ControlsProps, StyleItem } from '../../../types';
import { StyledControl, StyledControlsBlock } from '../../../elements';

const { StyledModifiedText, TextTag, OrderedList, UnorderedList } = d6;

export type ListBlockType = 'unordered-list-item' | 'ordered-list-item';

const LIST_TYPES: StyleItem<ListBlockType>[] = [
  {
    label: 'UL',
    style: 'unordered-list-item',
    icon: <UnorderedListOutlined />,
    name: 'Не нумерованый список',
  },
  {
    label: 'OL',
    style: 'ordered-list-item',
    icon: <OrderedListOutlined />,
    name: 'Нумерованый список',
  },
];

const OrderedWrapper: React.FC = ({ children, ...props }) => (
  <StyledModifiedText tag={TextTag.div} {...props}>
    <OrderedList>{children}</OrderedList>
  </StyledModifiedText>
);

const UnorderedWrapper: React.FC = ({ children, ...props }) => (
  <StyledModifiedText tag={TextTag.div} {...props}>
    <UnorderedList>{children}</UnorderedList>
  </StyledModifiedText>
);

export const listBlockRender: BlockRenderMapType<ListBlockType> = {
  'ordered-list-item': {
    element: 'div',
    wrapper: <OrderedWrapper />,
  },
  'unordered-list-item': {
    element: 'div',
    wrapper: <UnorderedWrapper />,
  },
};

export const ListBlock: React.FC<ControlsProps> = React.memo<ControlsProps>(({ editorState, setEditorState }) => {
  const [toggleList, currentBlockType] = useBlock<ListBlockType>(editorState, setEditorState);

  const types = useConfigByStyles(ConfigType.List, LIST_TYPES);

  if (!types.length) {
    return null;
  }

  return (
    <StyledControlsBlock>
      {types.map(({ label, style: blockType, name, icon }) => (
        <StyledControl key={label}>
          <ControlButton
            title={name}
            key={label}
            isActive={blockType === currentBlockType}
            value={blockType}
            onToggle={toggleList}
          >
            {icon}
          </ControlButton>
        </StyledControl>
      ))}
    </StyledControlsBlock>
  );
});

ListBlock.displayName = 'ListBlock';
