import { PlusCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { css, styled } from '@console/uikit';

type IconCssProps = {
  $visible: boolean;
};

const commonIconCss = css<IconCssProps>`
  position: absolute;
  left: 50%;
  font-size: 35px;
  color: ${(props) => props.theme.colors.blue6};
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.25s ease;
  z-index: 2;
  pointer-events: ${(props) => (props.$visible ? 'all' : 'none')};
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 50%;

  &:hover {
    color: ${(props) => props.theme.colors.blue7};
  }
`;

const AddAboveControl = styled(PlusCircleOutlined)<IconCssProps>`
  ${commonIconCss}
  top: 0;
  transform: translate(-50%, -50%);
`;

const AddBelowControl = styled(PlusCircleOutlined)<IconCssProps>`
  ${commonIconCss}
  bottom: 0;
  transform: translate(-50%, 50%);
`;

type AddControlsProps = {
  visible: boolean;
  onAddBlockAbove: () => void;
  onAddBlockBelow: () => void;
};

export const AddControls: React.FC<AddControlsProps> = ({ visible, onAddBlockAbove, onAddBlockBelow }) => {
  return (
    <>
      <AddAboveControl $visible={visible} onClick={onAddBlockAbove} />
      <AddBelowControl $visible={visible} onClick={onAddBlockBelow} />
    </>
  );
};
