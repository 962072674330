import { UsersV1User } from '@cms/volkswagen-widgets';
import { Space } from 'antd';
import React from 'react';
import { serverTimeToDefaultFormat } from '../../../../../utils/date';

type FilesNameTableCellProps = {
  user: UsersV1User;
};

export const TimeTableCell: React.FC<FilesNameTableCellProps> = ({ user }) => {
  const time = user?.version?.time;
  const author = user?.version?.author?.name;

  return (
    <Space size="middle">
      {serverTimeToDefaultFormat(time)} {author ? `(${author})` : ''}
    </Space>
  );
};
