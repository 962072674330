import { ContentVolkswagenV1Widget, SnippetName } from '@cms/volkswagen-widgets';
import React, { useEffect } from 'react';
import { WidgetName } from '@console/api';
import { WidgetEditorDrawer } from '../../widget-editor-drawer';
import { useLoadSnippet } from 'src/hooks';

export type SingleWidgetDrawerProps = {
  name: WidgetName;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onSave: (node: ContentVolkswagenV1Widget) => void;
};

export const SingleWidgetDrawer: React.FC<SingleWidgetDrawerProps> = ({ loading, name, onSave, open, onClose }) => {
  const { snippet, loadSnippet, resetState } = useLoadSnippet();

  const handleSave = (node: ContentVolkswagenV1Widget) => {
    onSave(node);
    onClose();
    resetState();
  };

  const handleClose = () => {
    onClose();
    resetState();
  };

  useEffect(() => {
    if (!snippet) return loadSnippet(name as SnippetName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet]);

  return (
    <WidgetEditorDrawer
      node={open && snippet ? snippet : null}
      loading={loading}
      title="Добавление блока"
      onClose={handleClose}
      onSave={handleSave}
    />
  );
};
